import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';

import CustomerDetails from './components/CustomerDetails';
import EntityDrawerContainer from '../../../ui-component/entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../../../ui-component/entity-drawer-layout/EntityDrawerHeader';
import UpdatedStyleWrapper from '../../../ui-component/updated-style-wrapper';
import CustomerNotes from '../customer-notes';

interface CustomerInfoModalProps {
    customerId: string;
    onClose: () => void;
}

const CustomerInfoDialog = ({ customerId, onClose }: CustomerInfoModalProps) => {
    const [value, setValue] = useState(0);
    const handleTabsChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <EntityDrawerHeader title="Customer Info" onClose={onClose} />
                <Tabs textColor="secondary" indicatorColor="secondary" value={value} onChange={handleTabsChange}>
                    <Tab label="Details" />
                    <Tab label="Notes" />
                </Tabs>

                {value === 0 && <CustomerDetails customerId={customerId} onClose={onClose} />}
                {value === 1 && <CustomerNotes customerId={customerId} />}
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default CustomerInfoDialog;
