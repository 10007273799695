import { memo } from 'react';

// material-ui
import { Box, Drawer, Stack } from '@mui/material';

// project imports
import MenuList from './MenuList';
import { drawerWidth } from 'store/constant';
import UpdatedStyleWrapper from '../../../ui-component/updated-style-wrapper';
import UserDetails from './UserDetails';
import LogoutBtn from './LogoutBtn';

// ==============================|| SIDEBAR DRAWER ||============================== //

export interface SidebarProps {
    drawerOpen?: boolean;
    drawerToggle?: () => void;
}

const Sidebar = ({ drawerOpen, drawerToggle }: SidebarProps) => (
    <UpdatedStyleWrapper>
        <nav aria-label="mailbox folders">
            <Drawer
                variant="temporary"
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                <Stack
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        maxWidth: drawerWidth,
                        backgroundColor: 'background.default',
                        color: 'text.primary',
                        borderRight: 'none',
                        px: 2
                    }}
                >
                    <UserDetails />
                    <Box>
                        <MenuList />
                    </Box>

                    <LogoutBtn />
                </Stack>
            </Drawer>
        </nav>
    </UpdatedStyleWrapper>
);

export default memo(Sidebar);
