import { Autocomplete, Box, CircularProgress, Divider, IconButton, Stack, TextField, Typography } from '@mui/material';
import useLocationOptions from '../../hooks/options/useLocationOptions';
import { FC, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../hooks/redux';
import CloseIcon from '@mui/icons-material/Close';
import LocationLedger from './components/LocationLedger';

const CashLedger: FC<{ onClose: () => void }> = ({ onClose }) => {
    const { selectedLocation } = useAppSelector((state) => state.calendarFilter);
    const [locationId, setLocationId] = useState<number | null>(null);
    const { locations, isLoading, getNameById } = useLocationOptions();

    const options = useMemo(() => locations.map(({ id }) => id), [locations]);

    const selectedLocationId = selectedLocation?.id;

    useEffect(() => {
        if (!locationId && !isLoading) {
            setLocationId(selectedLocationId || options[0] || null);
        }
    }, [isLoading, locationId, options, selectedLocationId]);

    return (
        <Stack spacing={2} px={2} sx={{ height: '100%' }}>
            <Stack spacing={2} sx={{ flexGrow: 0, flexShrink: 0 }}>
                <Stack direction="row" mb={2} alignItems="center" justifyContent="space-between">
                    <Typography variant="h3">Cash Ledger</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Autocomplete
                    value={locationId}
                    getOptionLabel={(option) => getNameById(option) ?? ''}
                    options={options}
                    onChange={(_, v) => {
                        setLocationId(v);
                    }}
                    renderInput={(props) => (
                        <TextField
                            label="Current Location"
                            {...props}
                            sx={{
                                '& .MuiAutocomplete-clearIndicator': {
                                    display: 'none'
                                }
                            }}
                            InputProps={{
                                ...props.InputProps,
                                endAdornment: isLoading ? (
                                    <CircularProgress size="1.5rem" sx={{ position: 'relative', left: '1.5rem' }} />
                                ) : (
                                    props.InputProps.endAdornment
                                )
                            }}
                        />
                    )}
                    loading={isLoading}
                    disabled={isLoading}
                />

                <Divider />
            </Stack>

            <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>{locationId ? <LocationLedger locationId={locationId} /> : null}</Box>
        </Stack>
    );
};

export default CashLedger;
