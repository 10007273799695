import { FC, useCallback, useState } from 'react';
import AttachmentsGallery from '../AttachmentsGallery';
import CbImageUpload, { CbFileUploadProps } from '../form/cb-image-upload';

type AppointmentImageGalleryProps = Pick<CbFileUploadProps, 'value' | 'onDelete' | 'onDrop'>;

const AppointmentImageGallery: FC<AppointmentImageGalleryProps> = ({ value, onDelete, onDrop }) => {
    const [open, setOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const openGallery = useCallback((index: number) => {
        setCurrentImage(index);
        setOpen(true);
    }, []);

    return (
        <>
            <CbImageUpload value={value} onDelete={onDelete} onDrop={onDrop} onPreview={openGallery} maxFiles={5} />
            <AttachmentsGallery
                attachments={value}
                isOpen={open}
                onClose={() => setOpen(false)}
                currImg={currentImage}
                setCurrImg={setCurrentImage}
                onDelete={onDelete}
            />
        </>
    );
};

export default AppointmentImageGallery;
