import { useCallback, useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Box, Button, Stack, Typography } from '@mui/material';
import RestartAlt from '@mui/icons-material/RestartAlt';
import CBModal from './cb-modal/CBModal';
import { Theme, useMediaQuery } from '@material-ui/core';

interface ImageEditorProps {
    isOpen: boolean;
    src: string;
    onClose: () => void;
    onCrop: (blob: Blob) => void;
}

const CBImageEditor = ({ isOpen, src, onClose, onCrop }: ImageEditorProps) => {
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    const [cropper, setCropper] = useState<any>();

    const getCropData = useCallback(async () => {
        if (typeof cropper !== 'undefined') {
            const blob = await fetch(cropper.getCroppedCanvas().toDataURL()).then((res) => res.blob());
            onCrop(blob);
            onClose();
        }
    }, [cropper, onClose, onCrop]);

    const resetCropper = useCallback(() => {
        if (typeof cropper !== 'undefined') {
            cropper.reset();
        }
    }, [cropper]);

    return (
        <CBModal
            maxWidth={matchSm ? false : 'md'}
            fullWidth
            fullScreen={matchSm}
            onClose={onClose}
            open={isOpen}
            title="Edit image"
            closeButtonText="Cancel"
            okButtonText="Save Image"
            onClickOk={getCropData}
            specialContent={
                <Button onClick={resetCropper} startIcon={<RestartAlt />}>
                    Drop changes
                </Button>
            }
        >
            {src && (
                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%' }}>
                        <Typography sx={{ fontStyle: 'italic', textAlign: 'right' }}>*Double click to move image</Typography>
                        <Cropper
                            style={{ height: 400, width: '100%' }}
                            zoomTo={0.5}
                            initialAspectRatio={1}
                            preview=".img-preview"
                            src={src}
                            viewMode={1}
                            minCropBoxHeight={10}
                            minCropBoxWidth={10}
                            background={false}
                            responsive
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides
                        />
                    </Box>
                    <Box sx={{ display: 'inline-block', boxSizing: 'border-box' }}>
                        <Typography variant="h4">Result preview</Typography>
                        <Box
                            className="img-preview"
                            sx={{
                                width: '100%',
                                height: '300px',
                                overflow: 'hidden'
                            }}
                        />
                    </Box>
                </Stack>
            )}
        </CBModal>
    );
};

export default CBImageEditor;
