import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import { IFileUploaderData, ImageData as ModifiedImageData } from '../../../models/IImage';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTheme } from '@material-ui/core/styles';
import ImagePreview from './components/ImagePreview';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { useAppDispatch } from '../../../hooks/redux';
import CBImageEditor from '../../CBImageEditor';
import { v4 } from 'uuid';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import useImagePreview from './hooks/use-image-preview';

export type CbFileUploadProps = {
    value: Array<ModifiedImageData | IFileUploaderData>;
    onDrop: (files: Array<File>) => void;
    onDelete: (id?: number | string) => void;
    onUpdate?: (data: IFileUploaderData, oldId?: number) => void;
    onPreview?: (index: number) => void;
    maxFiles?: number;
};

const fileMaxSize = 20000; // KB

const CbImageUpload: FC<CbFileUploadProps> = ({ value, onDrop, onDelete, onUpdate, onPreview, maxFiles = 1 }) => {
    const [crop, setCrop] = useState<ModifiedImageData | IFileUploaderData | null>(null);
    const dispatch = useAppDispatch();
    const [errors, setErrors] = useState<FileRejection[]>([]);
    const theme = useTheme();

    const uploadFormVisible = useMemo(() => value.length < maxFiles, [maxFiles, value.length]);

    const handleCrop = useCallback(
        (blob: Blob) => {
            if (crop && onUpdate) {
                const oldId = typeof crop.id === 'string' ? undefined : crop.id;

                onUpdate(
                    {
                        id: typeof crop.id === 'string' ? crop.id : v4(),
                        file: blob
                    },
                    oldId
                );
            }
        },
        [crop, onUpdate]
    );

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles,
        onDrop: (acceptedFiles, fileRejections) => {
            onDrop(acceptedFiles);
            setErrors(fileRejections);
        },
        accept: ['image/jpeg', 'image/jpg', 'image/jpe', 'image/png', 'image/gif'],
        maxSize: fileMaxSize * 1024 // 300b * 1024 = 300KB
    });

    const confirmDeleteImage = useCallback(
        (id?: number | string) => {
            dispatch(
                openConfirmPopup({
                    onConfirm: () => onDelete(id),
                    confirmText: `Delete`,
                    text: 'Are you sure you want to delete this image?'
                })
            );
        },
        [dispatch, onDelete]
    );

    const getPreview = useImagePreview();

    const src = useMemo(() => {
        if (crop) {
            return getPreview(crop) ?? '';
        }

        return '';
    }, [crop, getPreview]);

    return (
        <Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {value.map((v, index) => (
                    <ImagePreview
                        key={v.id}
                        url={getPreview(v)}
                        onDelete={() => confirmDeleteImage(v.id)}
                        onResize={onUpdate ? () => setCrop(v) : undefined}
                        onPreview={onPreview ? () => onPreview(index) : undefined}
                    />
                ))}
                {uploadFormVisible ? (
                    <Box
                        {...getRootProps({
                            sx: {
                                color: 'grey.800',
                                border: `1px dashed`,
                                borderColor: 'grey.300',
                                borderRadius: theme.spacing(0.5),
                                display: 'flex',
                                alignItems: 'center',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                backgroundColor: 'grey.50',
                                width: '100px',
                                height: '100px',
                                outline: 'none',
                                cursor: 'pointer'
                            }
                        })}
                    >
                        <input {...getInputProps()} />
                        <AddOutlinedIcon />
                        <Box>Upload</Box>
                    </Box>
                ) : null}
            </Box>

            {errors.map((error, index) => (
                <Fragment key={error.file.name + index}>
                    {error.errors.map((err, i) => (
                        <FormHelperText error key={err.code + i}>
                            {err.message}
                        </FormHelperText>
                    ))}
                </Fragment>
            ))}
            <CBImageEditor isOpen={!!crop} src={src} onClose={() => setCrop(null)} onCrop={handleCrop} />
        </Box>
    );
};
export default CbImageUpload;
