import { Box, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FC, useCallback } from 'react';

const EmptyIcon = () => (
    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3342_16162)">
            <circle cx="24.5" cy="24" r="24" fill="#FBFBFB" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.3281 19.744C11.3281 12.4687 17.226 6.5708 24.5013 6.5708C31.7767 6.5708 37.6745 12.4686 37.6745 19.744V38.3199L34.7115 35.0612L31.2779 38.8376L27.8442 35.0612L24.3708 38.8813L21.0773 35.259L17.8129 38.8492L14.3687 35.0612L11.3281 38.4053V19.744Z"
                fill="#708B9E"
                fillOpacity="0.3"
            />
            <path
                d="M37.6745 38.3199L36.7497 39.1609L38.9245 41.5528V38.3199H37.6745ZM34.7115 35.0612L35.6364 34.2203L34.7115 33.2031L33.7867 34.2203L34.7115 35.0612ZM31.2779 38.8376L30.353 39.6785L31.2779 40.6957L32.2027 39.6785L31.2779 38.8376ZM27.8442 35.0612L28.7691 34.2203L27.8442 33.2031L26.9194 34.2203L27.8442 35.0612ZM24.3708 38.8813L23.446 39.7222L24.3708 40.7394L25.2957 39.7222L24.3708 38.8813ZM21.0773 35.259L22.0021 34.4181L21.0773 33.4009L20.1524 34.4181L21.0773 35.259ZM17.8129 38.8492L16.8881 39.6901L17.8129 40.7073L18.7378 39.6901L17.8129 38.8492ZM14.3687 35.0612L15.2936 34.2203L14.3687 33.2031L13.4439 34.2203L14.3687 35.0612ZM11.3281 38.4053H10.0781V41.6381L12.253 39.2462L11.3281 38.4053ZM24.5013 5.3208C16.5356 5.3208 10.0781 11.7783 10.0781 19.744H12.5781C12.5781 13.159 17.9163 7.8208 24.5013 7.8208V5.3208ZM38.9245 19.744C38.9245 11.7783 32.467 5.3208 24.5013 5.3208V7.8208C31.0863 7.8208 36.4245 13.159 36.4245 19.744H38.9245ZM38.9245 38.3199V19.744H36.4245V38.3199H38.9245ZM33.7867 35.9021L36.7497 39.1609L38.5994 37.479L35.6364 34.2203L33.7867 35.9021ZM32.2027 39.6785L35.6364 35.9021L33.7867 34.2203L30.353 37.9966L32.2027 39.6785ZM26.9194 35.9021L30.353 39.6785L32.2027 37.9966L28.7691 34.2203L26.9194 35.9021ZM25.2957 39.7222L28.7691 35.9021L26.9194 34.2203L23.446 38.0403L25.2957 39.7222ZM20.1524 36.0999L23.446 39.7222L25.2957 38.0403L22.0021 34.4181L20.1524 36.0999ZM18.7378 39.6901L22.0021 36.0999L20.1524 34.4181L16.8881 38.0083L18.7378 39.6901ZM13.4439 35.9021L16.8881 39.6901L18.7378 38.0083L15.2936 34.2203L13.4439 35.9021ZM12.253 39.2462L15.2936 35.9021L13.4439 34.2203L10.4033 37.5644L12.253 39.2462ZM10.0781 19.744V38.4053H12.5781V19.744H10.0781Z"
                fill="#0A3A5B"
            />
            <circle cx="19.3281" cy="19.3154" r="3" fill="#0A3A5B" />
            <circle cx="29.6719" cy="19.3154" r="3" fill="#0A3A5B" />
            <rect x="21.375" y="25.0518" width="6.25383" height="1.15457" rx="0.577286" fill="#0A3A5B" />
        </g>
        <defs>
            <clipPath id="clip0_3342_16162">
                <rect width="48" height="48" fill="white" transform="translate(0.5)" />
            </clipPath>
        </defs>
    </svg>
);

const EmptySearchResults: FC<{ onClose?: () => void }> = ({ onClose }) => {
    const navigate = useNavigate();

    const handleCreateCustomer = useCallback(() => {
        navigate('/customer/create');
        onClose?.();
    }, [navigate, onClose]);

    return (
        <Box sx={{ width: '580px', maxWidth: '100%' }}>
            <Stack
                spacing={3}
                pt={2}
                sx={{
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '300px',
                    maxWidth: '100%',
                    margin: '0 auto'
                }}
            >
                <EmptyIcon />
                <Box sx={{ color: 'grey.800', fontSize: '0.875rem', lineHeight: '1.25rem' }}>We could not find who you are looking for</Box>
                <Button color="primary" variant="contained" sx={{ width: '100%' }} onClick={handleCreateCustomer}>
                    Create new customer
                </Button>
            </Stack>
        </Box>
    );
};

export default EmptySearchResults;
