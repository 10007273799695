import { useState, useCallback, useRef, ReactChild, MouseEvent } from 'react';
import { Box, Button, IconButton, Theme, useMediaQuery } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CommonBottomDrawer from './common-bottom-drawer';
import ConditionalTooltip from './conditional-tooltip';
import StickyFormFooter from './form/sticky-form-footer';

interface InfoTooltipProps {
    label?: string;
    text: string | ReactChild;
    color?: 'primary' | 'secondary';
}

const tooltipContentSx = {
    '& figure': {
        ml: 0,
        mr: 0
    },

    '& figure a': {
        color: 'inherit'
    },

    '& img': {
        display: 'block',
        maxWidth: '100%',
        height: 'auto'
    }
};

const InfoTooltip = ({ text, label, color }: InfoTooltipProps) => {
    const isMobile = useMediaQuery((params: Theme) => params.breakpoints.down('mobile'));
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const tooltipRef = useRef<HTMLDivElement>(null);

    const content = useCallback(() => {
        if (typeof text === 'string') {
            // eslint-disable-next-line react/no-danger
            return <Box className="tooltipContentRoot" sx={tooltipContentSx} dangerouslySetInnerHTML={{ __html: text }} />;
        }

        return text;
    }, [text]);

    const handleOpen = useCallback(
        (e: MouseEvent) => {
            if (isMobile) {
                e.stopPropagation();
                setIsOpen(true);
            }
        },
        [isMobile]
    );

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <>
            <ConditionalTooltip ref={tooltipRef} title={content()} sx={{ ml: 1 }} condition={!isMobile} arrow>
                <IconButton size="small" onClick={handleOpen} color={color ?? 'inherit'} disableRipple>
                    <InfoOutlinedIcon color="inherit" fontSize="small" />
                </IconButton>
            </ConditionalTooltip>

            {isMobile ? (
                <CommonBottomDrawer open={isOpen} onClose={handleClose} title={label ?? 'Hint'}>
                    {content()}
                    <StickyFormFooter>
                        <Button color="primary" variant="contained" onClick={handleClose}>
                            Got it
                        </Button>
                    </StickyFormFooter>
                </CommonBottomDrawer>
            ) : null}
        </>
    );
};

export default InfoTooltip;
