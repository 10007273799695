const useDownloadFile = () => {
    const handleDownload = (url: string, name?: string) => {
        const parsedName: string = url.split('/').pop() ?? 'File';
        window
            .fetch(url, { method: 'GET' })
            .then((res) => res.blob())
            .then((blob: Blob) => {
                const fileUrl = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = fileUrl;
                a.download = name ?? parsedName;
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch((err) => {
                throw err;
            });
    };

    return { handleDownload };
};

export default useDownloadFile;
