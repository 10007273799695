import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import {
    CampaignStatus,
    IReviewCampaign,
    IReviewCampaignEntry,
    IReviewCampaignPayload,
    IReviewCampaignScore
} from '../models/IReviewManagement';
import { IBaseParams, IPaginateResponse } from '../models/IPaginateResponse';

const reviewManagementAPI = createApi({
    reducerPath: 'reviewManagementAPI',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Campaigns', 'CampaignEntries', 'CampaignScores'],
    endpoints: (build) => ({
        getReviewCampaigns: build.query<IPaginateResponse<IReviewCampaign[]>, unknown>({
            query: () => ({
                url: 'review-campaigns',
                method: 'GET',
                params: {
                    per_page: -1
                }
            }),
            providesTags: ['Campaigns']
        }),

        createReviewCampaign: build.mutation<unknown, IReviewCampaignPayload>({
            query: (data) => ({
                url: 'review-campaigns',
                method: 'POST',
                data
            }),
            invalidatesTags: ['Campaigns']
        }),

        updateReviewCampaign: build.mutation<unknown, IReviewCampaignPayload & { id: number }>({
            query: ({ id, ...data }) => ({
                url: `review-campaigns/${id}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['Campaigns']
        }),

        getReviewCampaignEntries: build.query<
            IPaginateResponse<IReviewCampaignEntry[]>,
            IBaseParams & {
                status: CampaignStatus;
            }
        >({
            query: ({ per_page = -1, page = 1, search = null, sort = null, order = null, trashed = null, status }) => ({
                url: `review-campaign-entries`,
                params: { status, page, per_page, order, sort },
                method: 'GET'
            }),
            providesTags: ['CampaignEntries']
        }),

        dropCampaignEntry: build.mutation<IReviewCampaignEntry, number | string>({
            query: (entryId) => ({
                url: `review-campaign-entries/${entryId}/drop`,
                method: 'PUT'
            }),
            invalidatesTags: ['CampaignEntries']
        }),

        reactivateCampaignEntry: build.mutation<number, number | string>({
            query: (entryId) => ({
                url: `review-campaign-entries/${entryId}/reactivate`,
                method: 'PUT'
            }),
            invalidatesTags: ['CampaignEntries']
        }),

        getReviewCampaignScores: build.query<IPaginateResponse<IReviewCampaignScore[]>, IBaseParams>({
            query: ({ per_page = -1, page = 1, sort = null, order = null }) => ({
                url: 'review-campaign-scores',
                method: 'GET',
                params: { page, per_page, sort, order }
            }),
            providesTags: ['CampaignScores']
        })
    })
});

export default reviewManagementAPI;
