import { useMemo } from 'react';
import employeeAPI from '../services/EmployeeService';
import { skipToken } from '@reduxjs/toolkit/query';

const useGetUsernameById = (id?: number | null) => {
    const { data } = employeeAPI.useGetEmployeeQuery(id ? String(id) : skipToken);

    return useMemo(() => {
        if (data) {
            const { firstname, lastname } = data.user;
            return `${firstname} ${lastname}`;
        }

        return '';
    }, [data]);
};

export default useGetUsernameById;
