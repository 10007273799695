import { Elements } from '@stripe/react-stripe-js';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

const StripeWrapper: FC<PropsWithChildren<{ payment_intent_client_secret: string }>> = ({ payment_intent_client_secret, children }) => {
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);

    const storageItem = localStorage.getItem(payment_intent_client_secret);
    const bookingData = storageItem ? JSON.parse(storageItem) : null;

    // eslint-disable-next-line no-underscore-dangle
    const stripeKey = bookingData?._key;

    useEffect(() => {
        if (!stripePromise && stripeKey) {
            setStripePromise(loadStripe(stripeKey));
        }
    }, [stripePromise, stripeKey]);
    return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeWrapper;
