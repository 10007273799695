import { useCallback } from 'react';
import { BookingData } from '../../views/scheduling-widget/widget-wizard/types';
import { axiosServices } from '../../utils/axios';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { setCouponError } from '../../store/slices/widgetSlice';
import appointmentAPI from '../../services/AppointmentService';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import useShowSnackbar from '../useShowSnackbar';
import { useAppDispatch } from '../redux';

type SubmitBookingResponse = {
    message: string;
    appointment: { uuid: string; coupon_result?: boolean };
};

const useSubmitBookingWidget = () => {
    const { showSnackbar } = useShowSnackbar();
    const dispatch = useAppDispatch();

    const onSubmitBookingWidget = useCallback(
        (company_slug: string, bookingData: BookingData, onSuccessCb: (res: SubmitBookingResponse) => void) => {
            dispatch(startSubmitting());
            axiosServices
                .post(`/public/company/${company_slug}/appointment`, bookingData)
                .then((res) => {
                    showSnackbar({
                        message: 'Appointment created',
                        alertSeverity: SnackBarTypes.Success
                    });
                    if (res.data.coupon_result === false) {
                        dispatch(setCouponError('Coupon is not applied!'));
                    }
                    dispatch(appointmentAPI.util.invalidateTags(['Appointment']));

                    onSuccessCb(res.data);
                })
                .catch((err) => {
                    if (err.errors && err.errors['customer.email']) {
                        showSnackbar({
                            message: 'Invalid email address',
                            alertSeverity: SnackBarTypes.Error
                        });
                    } else if (err.errors && err.errors['customer.phone']) {
                        showSnackbar({
                            message: 'Invalid phone number',
                            alertSeverity: SnackBarTypes.Error
                        });
                    } else {
                        showSnackbar({
                            message: err.message,
                            alertSeverity: SnackBarTypes.Error
                        });
                    }
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [dispatch, showSnackbar]
    );

    return { onSubmitBookingWidget };
};

export default useSubmitBookingWidget;
