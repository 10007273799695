import { FC, ReactNode } from 'react';
import { Box, TableCell, TableRow } from '@mui/material';

const TableEmpty: FC<{ colSpan?: number; text?: ReactNode }> = ({ colSpan = 1, text = 'Nothing to display' }) => (
    <TableRow>
        <TableCell colSpan={colSpan}>
            <Box sx={{ textAlign: 'center' }}>{text}</Box>
        </TableCell>
    </TableRow>
);

export default TableEmpty;
