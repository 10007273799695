import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { IAppointment } from '../../../../../../../../models/IAppointment';
import { getServiceName } from '../../../../../../../../utils/services';
import QRCode from 'react-qr-code';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined';
import PermanentEntityDrawer from '../../../../../../../PermanentEntityDrawer';
import EntityDrawerContent from '../../../../../../../entity-drawer-layout/EntityDrawerContent';
import SelfCheckoutForm from '../../../../../../../self-checkout/self-checkout-form';
import SectionHeading from '../../../../../../../SectionHeading';

type SelfCheckoutDrawerProps = {
    open: boolean;
    onClose: () => void;
    appointment: IAppointment | null;
};

const DrawerSection: FC<PropsWithChildren<{ label: string; icon?: ReactNode }>> = ({ label, icon, children }) => (
    <Stack spacing={0.5} sx={{ border: '1px solid', borderColor: 'grey.200', borderRadius: '16px' }} divider={<Divider />}>
        <Stack direction="row" spacing={1.5} alignItems="center" sx={{ p: 2 }}>
            {icon ? <Box sx={{ color: 'newColors.darkBlue', display: 'flex', alignItems: 'center' }}>{icon}</Box> : null}
            <Box
                component="span"
                sx={{
                    color: 'grey.800',
                    fontSize: '1rem',
                    lineHeight: '1.5',
                    fontWeight: 600
                }}
            >
                {label}
            </Box>
        </Stack>
        <Box sx={{ p: 2 }}>{children}</Box>
    </Stack>
);

const SelfCheckoutDrawer: FC<SelfCheckoutDrawerProps> = ({ open, onClose, appointment }) => (
    <PermanentEntityDrawer
        open={open}
        onClose={onClose}
        title={
            <Stack direction="row" spacing={2} alignItems="center">
                <KeyboardArrowLeftOutlinedIcon fontSize="small" />
                <span>Self Checkout</span>
            </Stack>
        }
    >
        <EntityDrawerContent>
            <Stack spacing={2}>
                <SectionHeading>{getServiceName(appointment?.services || []).fullValue}</SectionHeading>
                {appointment?.self_checkout_url ? (
                    <DrawerSection label="Link to Self Checkout" icon={<QrCodeOutlinedIcon />}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            sx={{
                                width: '162px',
                                border: '1px solid',
                                borderColor: 'grey.300',
                                borderRadius: '8px',
                                p: 2,
                                mx: 'auto'
                            }}
                        >
                            <QRCode size={128} value={appointment?.self_checkout_url ?? ''} />
                        </Box>
                    </DrawerSection>
                ) : null}

                {appointment ? (
                    <DrawerSection icon={<OpenInNewOutlinedIcon />} label="Self Checkout Request">
                        <SelfCheckoutForm open onClose={onClose} phone={appointment.customer.phone} appointmentId={appointment.id} />
                    </DrawerSection>
                ) : null}
            </Stack>
        </EntityDrawerContent>
    </PermanentEntityDrawer>
);
export default SelfCheckoutDrawer;
