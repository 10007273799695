const regExps = {
    name: /^[A-Za-z][A-Za-z0-9 .'-]*$/,
    letters: /^[a-zA-Z ]*$/gi,
    address: /^[A-Za-z0-9 -~]+$/,
    nonPersonName: /^[A-Za-z0-9 -~]+$/,
    password: /^[!-~]{8,}$/,
    postal_code: /^[a-zA-Z0-9 -]*$/gi
};

export default regExps;
