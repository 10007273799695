import { FC, useCallback, useMemo, useState } from 'react';
import { IOrderedPayment } from '../../../../../../../../../../models/IPayment';
import { Box, Button, Divider, IconButton, Stack } from '@mui/material';
import moment from 'moment/moment';
import { useAppSelector } from '../../../../../../../../../../hooks/redux';
import PaymentAmount from './PaymentAmount';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useNewPaymentOptions from '../../new-payment-form/use-new-payment-options';
import CommonBottomDrawer from '../../../../../../../../../common-bottom-drawer';

type PaymentListItemProps = {
    payment: IOrderedPayment;
    onEdit?: () => void;
    onDelete: () => void;
};

const PaymentListItem: FC<PaymentListItemProps> = ({ payment, onEdit, onDelete }) => {
    const [open, setOpen] = useState(false);
    const { getIcon, getLabel } = useNewPaymentOptions();
    const { selectedEvent } = useAppSelector((state) => state.calendar);

    const timezone = selectedEvent?.location.time_zone ?? 'UTC';
    const data = useMemo(
        () => [
            {
                label: 'Date',
                value: moment(payment.datetime).tz(timezone).format('MMM D, YYYY')
            },
            {
                label: 'Type',
                value: getLabel(payment.reason)
            },
            {
                label: 'Amount',
                value: <PaymentAmount payment={payment} />
            }
        ],
        [getLabel, payment, timezone]
    );

    const handleEdit = useCallback(() => {
        onEdit?.();
        setOpen(false);
    }, [onEdit]);

    const handleDelete = useCallback(() => {
        onDelete();
        setOpen(false);
    }, [onDelete]);

    const methodName = useMemo(() => {
        const iconMethods = ['paypal', 'zelle', 'venmo'];
        if (payment.method && !iconMethods.some((m) => m === payment.method)) {
            return getLabel(payment.method);
        }

        return null;
    }, [getLabel, payment.method]);

    return (
        <>
            <Stack
                sx={{
                    border: '1px solid',
                    borderColor: 'grey.200',
                    borderRadius: '16px',
                    color: 'grey.800',
                    fontSize: '0.875rem',
                    lineHeight: 1.5
                }}
            >
                <Stack direction="row" spacing={1} sx={{ paddingY: 0.5, paddingX: 2, alignItems: 'center' }}>
                    {payment.method ? (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                color: 'newColors.darkBlue'
                            }}
                        >
                            {getIcon(payment.method)}
                        </Box>
                    ) : null}

                    <Box
                        sx={{
                            flexGrow: 1,
                            flexShrink: 1,
                            fontWeight: 600,
                            fontSize: '1rem'
                        }}
                    >
                        {methodName}
                    </Box>
                    <Box>
                        <IconButton color="inherit" onClick={() => setOpen(true)}>
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                </Stack>
                <Divider />
                <Stack sx={{ paddingY: 1.5, paddingX: 2 }} spacing={1}>
                    {data.map((item) => (
                        <Stack key={item.label} direction="row" justifyContent="space-between">
                            <Box>{item.label}</Box>
                            <Box sx={{ textAlign: 'right', opacity: 0.6 }}>{item.value}</Box>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
            <CommonBottomDrawer open={open} onClose={() => setOpen(false)} title="Select option">
                <Stack spacing={1}>
                    {onEdit && (
                        <Button variant="text" color="primary" className="forcedBg" onClick={handleEdit}>
                            Edit
                        </Button>
                    )}

                    <Button variant="text" color="error" className="forcedBg" onClick={handleDelete}>
                        Delete
                    </Button>
                </Stack>
            </CommonBottomDrawer>
        </>
    );
};

export default PaymentListItem;
