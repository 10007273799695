import { FC } from 'react';
import { Box } from '@mui/material';

const ProductDefaultImage: FC<{ size?: number }> = ({ size = 48 }) => (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="8" fill="#DADCE0" />
        <path d="M32 21L23.2424 7L19.1212 14L15 21H32Z" fill="#9AA0A6" />
        <rect x="7" y="27" width="13" height="13" fill="#9AA0A6" />
        <circle cx="34" cy="33" r="7" fill="#9AA0A6" />
    </svg>
);

type CardImageProps = {
    src?: string | null;
    size?: number;
};

const CardImage: FC<CardImageProps> = ({ src, size = 48 }) => (
    <Box
        sx={{
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: '8px',
            overflow: 'hidden',
            '& img': {
                width: '100%',
                height: '100%',
                display: 'block',
                objectFit: 'cover'
            }
        }}
    >
        {src ? <img src={src} alt="" /> : <ProductDefaultImage size={size} />}
    </Box>
);

export default CardImage;
