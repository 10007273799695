import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type CbPortalProps = PropsWithChildren<{ query: string; disableCondition?: boolean }>;

const CbPortal: FC<CbPortalProps> = ({ query, disableCondition, children }) => {
    const [container, setContainer] = useState<Element | null>(null);

    useEffect(() => {
        const elem = document.querySelector(query);
        if (!container && elem) {
            setContainer(elem);
        }
        if (container && !elem) {
            setContainer(null);
        }
    }, [container, query]);

    if (container && !disableCondition) {
        return createPortal(children, container);
    }

    return <>{children}</>;
};

export default CbPortal;
