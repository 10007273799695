import { FC, PropsWithChildren, useMemo } from 'react';
import { Box, Drawer, IconButton, Theme, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LoadingSpinner from './LoadingSpinner';

export type CommonDrawerProps = {
    open: boolean;
    title?: string;
    titleCaret?: boolean;
    onClose?: () => void;
    spacing?: number;
    isLoading?: boolean;
};

export const useCommonSettingsDrawerPx = (spacing?: number) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('mobile'));
    return useMemo(() => {
        if (!spacing) {
            return isMobile ? 2 : 3;
        }

        return spacing;
    }, [isMobile, spacing]);
};

const CommonDrawer: FC<PropsWithChildren<CommonDrawerProps>> = ({ children, title, titleCaret, onClose, open, spacing, isLoading }) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('md'));
    const px = useCommonSettingsDrawerPx(spacing);
    return (
        <Drawer open={open} anchor="right" onClose={onClose}>
            <Box
                className="CommonDrawer__Inner"
                px={px}
                sx={{
                    width: '100vw',
                    maxWidth: isMobile ? undefined : '720px',
                    flexGrow: 1,
                    height: 'fit-content',
                    display: 'grid',
                    gridTemplateRows: title ? 'auto 1fr' : '1fr',
                    gridTemplateColumns: '100%'
                }}
            >
                {isLoading ? (
                    <Box sx={{ gridRow: 'span 2' }}>
                        <LoadingSpinner />
                    </Box>
                ) : (
                    <>
                        {title ? (
                            <Box
                                className="CommonDrawer__Header"
                                sx={{
                                    position: 'sticky',
                                    top: 0,
                                    backgroundColor: 'background.paper',
                                    zIndex: 10,
                                    borderBottom: '1px solid',
                                    borderBottomColor: 'grey.200',
                                    mb: 2,
                                    width: '100%',
                                    pt: isMobile ? 2 : 0
                                }}
                            >
                                {onClose && (
                                    <Box sx={{ display: 'flex', justifyContent: isMobile ? 'flex-start' : 'flex-end' }}>
                                        <IconButton onClick={onClose} color={isMobile ? 'secondary' : 'inherit'}>
                                            {isMobile ? <ArrowBackIcon /> : <CloseIcon />}
                                        </IconButton>
                                    </Box>
                                )}
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontWeight: 400,
                                        fontSize: isMobile ? '1.25rem' : '1.5rem',
                                        lineHeight: 1.1,
                                        py: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8px'
                                    }}
                                >
                                    {!isMobile && title && titleCaret ? <ChevronLeftIcon color="inherit" /> : null}
                                    {title}
                                </Typography>
                            </Box>
                        ) : null}
                        <Box
                            className="CommonDrawer__Content"
                            sx={{
                                width: '100%'
                            }}
                        >
                            {children}
                        </Box>
                    </>
                )}
            </Box>
        </Drawer>
    );
};
export default CommonDrawer;
