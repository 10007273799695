import { FC } from 'react';
import { IAppointment } from '../models/IAppointment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { Tooltip } from '@mui/material';
import { appDateTimeFormat } from '../store/constant';
import moment from 'moment-timezone';

const AppointmentDepositOpenedIndicator: FC<{ depositRequest?: IAppointment['deposit_request']; forceMonochrome?: boolean }> = ({
    depositRequest,
    forceMonochrome = false
}) => {
    if (depositRequest && !depositRequest.paid_at) {
        return !depositRequest.opened_at ? (
            <Tooltip title="Deposit Request Page wasn't opened">
                <MoneyOffIcon color={forceMonochrome ? 'inherit' : 'error'} />
            </Tooltip>
        ) : (
            <Tooltip title={`Deposit Request Page was opened on ${moment(depositRequest.opened_at).format(appDateTimeFormat)}`}>
                <AttachMoneyIcon color={forceMonochrome ? 'inherit' : 'success'} />
            </Tooltip>
        );
    }

    return null;
};

export default AppointmentDepositOpenedIndicator;
