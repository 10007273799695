import { Box, Button, Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { FC, useCallback, useContext, useMemo } from 'react';
import { AbilityContext } from '../../utils/roles/Can';
import { SET_MENU } from '../../store/actions';
import { useAppDispatch } from '../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import usePlanName from '../../hooks/usePlanName';
import useAuth from '../../hooks/useAuth';

const CreateOrganizationBtn: FC<{ isInSideBar?: boolean; actionCb?: () => void }> = ({ isInSideBar, actionCb }) => {
    const { user } = useAuth();
    const ability = useContext(AbilityContext);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const planName = usePlanName();

    const handleCreateCompany = useCallback(() => {
        navigate('/organization/create', { replace: true });
        actionCb?.();
        if (isInSideBar) {
            dispatch({ type: SET_MENU, opened: false });
        }
    }, [dispatch, isInSideBar, navigate, actionCb]);

    const isNewOrgButtonDisabled = useMemo(() => {
        if (!user?.companyOwner?.limits.max) return false;
        return user?.companyOwner?.limits.counts.companies >= (user?.companyOwner?.limits.max?.companies ?? 0);
    }, [user]);

    const disabledTooltipText = useMemo(
        () =>
            user?.companyOwner?.limits.max?.companies === 1
                ? 'Your current plan is limited to having only one organization. If you need to create more than one, please contact support to upgrade your plan.'
                : `You're on the ${planName} subscription plan. Please contact support to upgrade your plan to be able to add more staff or locations`,
        [planName, user]
    );

    if (ability.can('create', 'company')) {
        return (
            <>
                {ability.can('create', 'company') && (
                    <Tooltip
                        title={disabledTooltipText}
                        disableHoverListener={!isNewOrgButtonDisabled}
                        disableFocusListener={!isNewOrgButtonDisabled}
                        disableTouchListener={!isNewOrgButtonDisabled}
                    >
                        <Box component="span" sx={{ display: 'block', p: 1 }}>
                            <Button
                                disabled={isNewOrgButtonDisabled}
                                onClick={handleCreateCompany}
                                endIcon={<AddCircleIcon />}
                                sx={{ width: '100%', padding: '6px 18px', justifyContent: 'center' }}
                                className="forcedBg"
                            >
                                New Organization
                            </Button>
                        </Box>
                    </Tooltip>
                )}
            </>
        );
    }

    return null;
};

export default CreateOrganizationBtn;
