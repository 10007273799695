import { BottomNavigation, BottomNavigationAction, Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useMatch } from 'react-router-dom';
import EventNoteOutlined from '@mui/icons-material/EventNoteOutlined';
import BottomSearchFilter from '../../ui-component/search-filter/BottomSearchFilter';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useMemo, useRef, useState } from 'react';
import Badge from '@mui/material/Badge';
import { IconBell } from '@tabler/icons';
import useNotifications from '../../hooks/useNotifications';
import NotificationsPopper from '../MainLayout/Header/NotificationSection/NotificationsPopper';
import useNotificationsListener from '../../hooks/useNotificationsListener';
import useNotificationItem from '../../hooks/useNotificationItem';
import UpdatedStyleWrapper from '../../ui-component/updated-style-wrapper';

const activeSx = {
    color: 'primary.main',
    paddingBottom: '4px',
    borderBottom: '4px solid !important',
    borderBottomColor: 'currentColor'
} as const;

const shakingSx = {
    '@keyframes horizontal-shaking': {
        '0%': { transform: 'translateX(0)' },
        '25%': { transform: 'translateX(3px)' },
        '50%': { transform: 'translateX(-3px)' },
        '75%': { transform: 'translateX(3px)' },
        '100%': { transform: 'translateX(0)' }
    },

    animation: 'horizontal-shaking 0.3s infinite'
};

const CBBottomNav = () => {
    const [popup, setPopup] = useState<'search' | 'notifications' | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const navigate = useNavigate();
    const match = useMatch('/calendar');

    const { data, markReadAll, notificationsLength, unreadNotificationsLength } = useNotifications();
    const { current, dismissNotification } = useNotificationsListener();
    const { notificationData, handleClickNotification } = useNotificationItem(current);

    const notificationIcon = useMemo(() => {
        if (notificationData.icon) {
            return notificationData.icon;
        }

        return <IconBell />;
    }, [notificationData.icon]);

    const anchorRef = useRef<HTMLButtonElement | null>(null);

    const openSearch = useCallback(() => {
        setPopup('search');
    }, []);

    const handleNotificationsOpen = useCallback((v: boolean) => {
        setPopup(v ? 'notifications' : null);
    }, []);

    const currentId = current?.id;
    const notificationClickAction = useCallback(() => {
        if (currentId) {
            handleClickNotification();
            dismissNotification();
        } else {
            handleNotificationsOpen(true);
        }
    }, [currentId, dismissNotification, handleClickNotification, handleNotificationsOpen]);

    const onPressReadAll = () => {
        markReadAll(null);
    };

    const closeAll = useCallback(() => {
        setPopup(null);
    }, []);

    if (!isMobile) {
        return null;
    }

    return (
        <UpdatedStyleWrapper>
            <Box sx={{ position: 'fixed', left: 0, right: 0, bottom: 0, zIndex: 999 }}>
                <BottomNavigation sx={{ height: '48px', '& .MuiBottomNavigationAction-root': { pt: 1 } }}>
                    <BottomNavigationAction icon={<SearchIcon />} onClick={openSearch} sx={popup === 'search' ? activeSx : undefined} />
                    <BottomNavigationAction
                        onClick={() => navigate('/calendar')}
                        icon={<EventNoteOutlined />}
                        sx={match ? activeSx : undefined}
                    />
                    <BottomNavigationAction
                        ref={anchorRef}
                        icon={
                            <Box sx={current ? shakingSx : undefined}>
                                <Badge
                                    color="error"
                                    overlap="circular"
                                    variant="dot"
                                    invisible={!unreadNotificationsLength}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    {notificationIcon}
                                    {/* <IconBell stroke={1.5} size="1.5rem" /> */}
                                </Badge>
                            </Box>
                        }
                        onClick={notificationClickAction}
                        sx={popup === 'notifications' ? activeSx : undefined}
                    />
                </BottomNavigation>

                <BottomSearchFilter open={popup === 'search'} onClose={closeAll} />
                {anchorRef ? (
                    <NotificationsPopper
                        placement="top-end"
                        open={popup === 'notifications'}
                        anchorRef={anchorRef}
                        data={data}
                        setOpen={handleNotificationsOpen}
                        onReadAll={onPressReadAll}
                        notificationsLength={notificationsLength}
                        unreadNotificationsLength={unreadNotificationsLength}
                    />
                ) : null}
            </Box>
        </UpdatedStyleWrapper>
    );
};

export default CBBottomNav;
