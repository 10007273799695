import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import useEntityDrawerSpacing from './use-entity-drawer-spacing';

const EntityDrawerContent: FC<PropsWithChildren<{ noSpacing?: boolean }>> = ({ children, noSpacing }) => {
    const { spacingX } = useEntityDrawerSpacing();

    return (
        <Box
            sx={{
                height: '100%',
                overflow: 'auto',
                paddingY: noSpacing ? undefined : 2,
                paddingX: noSpacing ? undefined : spacingX
            }}
        >
            {children}
        </Box>
    );
};

export default EntityDrawerContent;
