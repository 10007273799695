import { FC, PropsWithChildren, useCallback, useState } from 'react';
import { ButtonBase, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PaymentSummarySpoiler: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [open, setOpen] = useState(false);

    const handleToggle = useCallback(() => {
        setOpen((prev) => !prev);
    }, []);

    return (
        <>
            <ButtonBase
                sx={{
                    ml: 2,
                    p: 0.5,
                    pl: 1,
                    border: '1px solid',
                    borderColor: 'grey.300',
                    color: 'grey.800',
                    borderTopLeftRadius: '4px',
                    borderTopRightRadius: '4px',
                    mb: '-1px',
                    gap: '4px'
                }}
                onClick={handleToggle}
            >
                {open ? 'Less' : 'More'}
                <ExpandMoreIcon sx={{ fontSize: '1rem', transform: `rotate(${open ? '180deg' : 0})` }} />
            </ButtonBase>
            <Collapse in={open}>{children}</Collapse>
        </>
    );
};

export default PaymentSummarySpoiler;
