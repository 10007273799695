import { Box, FormControlLabel, FormHelperText, Switch } from '@mui/material';
import InfoTooltip from '../../InfoTooltip';
import { FC, useMemo } from 'react';
import useTooltips from '../../../hooks/use-tooltips';

type LabeledSwitchProps = {
    value?: boolean | null;
    onChange: (e: unknown, v: boolean) => void;
    label: string;
    hint?: string;
    tooltipKey?: string;
    error?: string | null;
    fullWidth?: boolean;
};

const LabeledSwitch: FC<LabeledSwitchProps> = ({ value, onChange, label, hint, fullWidth, error, tooltipKey }) => {
    const { getTooltipTextByKey } = useTooltips();
    const tooltipText = useMemo(() => {
        if (tooltipKey) {
            return getTooltipTextByKey(tooltipKey);
        }

        if (hint) {
            return hint;
        }
        return null;
    }, [getTooltipTextByKey, hint, tooltipKey]);
    return (
        <FormControlLabel
            sx={{
                '&.MuiFormControlLabel-root': { ml: 0, mr: 0, gap: '8px' },
                width: fullWidth ? '100%' : undefined,
                justifyContent: fullWidth ? 'space-between' : undefined
            }}
            control={<Switch value={!!value} checked={!!value} onChange={onChange} />}
            labelPlacement="start"
            label={
                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                    <Box component="span" sx={{ fontWeight: 600, color: 'grey.800' }}>
                        {label}
                        {error ? <FormHelperText error>{error}</FormHelperText> : null}
                    </Box>
                    {tooltipText ? <InfoTooltip label={label} text={tooltipText} /> : null}
                </Box>
            }
        />
    );
};

export default LabeledSwitch;
