import * as Yup from 'yup';
import { IService, PaymentType } from '../../../models/IService';

const servicesSchema = Yup.object().shape({
    service: Yup.object()
        .shape({ id: Yup.number() })
        .test('service', 'Service is required', (v) => !!v.id)
        .required('Service is required')
        .typeError('Service is required'),
    price: Yup.number()
        .min(0)
        .nullable()
        .required('Service price is required')
        .typeError('Service price must be a number')
        // eslint-disable-next-line func-names
        .test('price', '', function (v) {
            const { path, parent, createError } = this;
            const isPrepaid = parent.service?.payment_type === PaymentType.Prepaid;

            if (isPrepaid) {
                const price = v ? parseFloat(String(v)) : 0;
                const deposit = parent.prepay ? parseFloat(String(parent.prepay)) : 0;
                const isValid = deposit <= price;

                return (
                    isValid ||
                    createError({
                        path,
                        message: `Service price must be greater or equal to service deposit ($${parent.prepay})`
                    })
                );
            }

            return true;
        }),

    prepay: Yup.mixed().when('service', {
        is: (service: IService | undefined) => service && service.payment_type === PaymentType.Prepaid,
        then: Yup.number().min(0).typeError('Deposit must be a number').required('Deposit is required'),
        otherwise: Yup.mixed().nullable().notRequired()
    }),

    materials_amount: Yup.mixed().when('service', {
        is: (service: IService | undefined) => service && !!service.use_materials,
        then: Yup.number()
            .min(0)
            .typeError('Material Amount must be a number')
            .typeError('Material Amount is required')
            .label('Material Amount'),
        otherwise: Yup.mixed().nullable().notRequired()
    })
});

export default servicesSchema;
