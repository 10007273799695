import { SvgIcon } from '@mui/material';

const RepManagementIcon = () => (
    <SvgIcon>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_2982_86341" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24" fill="currentColor" />
            </mask>
            <g mask="url(#mask0_2982_86341)">
                <path
                    d="M16.7 14.5L20.5 11.25L23.5 11.5L19.1 15.325L20.4 21L17.85 19.45L16.7 14.5ZM14.35 7.2L13.3 4.75L14.45 2L16.75 7.425L14.35 7.2ZM4.325 21L5.95 13.975L0.5 9.25L7.7 8.625L10.5 2L13.3 8.625L20.5 9.25L15.05 13.975L16.675 21L10.5 17.275L4.325 21Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </SvgIcon>
);

export default RepManagementIcon;
