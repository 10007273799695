import moment, { Moment } from 'moment-timezone';
import { Typography, TypographyProps } from '@mui/material';
import { SxProps } from '@material-ui/system';
import { ElementType } from 'react';

interface TimeZoneViewProps extends Pick<TypographyProps, 'variant'> {
    time_zone: string;
    sx?: SxProps;
    offsetOnly?: boolean;
    showOffset?: boolean;
    component?: ElementType;
}

const TimeZoneView = ({ time_zone, sx, offsetOnly, variant, showOffset = true, component = 'p' }: TimeZoneViewProps) => {
    const tz: Moment = moment.tz(time_zone);
    const offset = tz.format('Z');
    const tz_abbr = tz.format('z');
    const label = tz_abbr === 'UTC' ? '(UTC)' : `(${tz_abbr}${showOffset ? ` / UTC${offset}` : ''})`;
    if (offsetOnly) {
        return tz.utcOffset() !== 0 ? (
            <Typography sx={{ whiteSpace: 'nowrap', ...sx }} variant={variant} component={component}>
                {offset}
            </Typography>
        ) : null;
    }
    return (
        <Typography sx={{ whiteSpace: 'nowrap', ...sx }} variant={variant} component={component}>
            {label}
        </Typography>
    );
};

export default TimeZoneView;
