const timezones = [
    { location: 'US/Canada', label: 'Pacific Time - US & Canada', timezone: 'Canada/Pacific' },
    { location: 'US/Canada', label: 'Mountain Time - US & Canada', timezone: 'Canada/Mountain' },
    { location: 'US/Canada', label: 'Central Time - US & Canada', timezone: 'Canada/Central' },
    { location: 'US/Canada', label: 'Eastern Time - US & Canada', timezone: 'Canada/Eastern' },
    { location: 'US/Canada', label: 'Alaska Time', timezone: 'US/Alaska' },
    { location: 'US/Canada', label: 'Arizona, Yukon Time', timezone: 'US/Arizona' },
    { location: 'US/Canada', label: 'Newfoundland Time', timezone: 'Canada/Newfoundland' },
    { location: 'US/Canada', label: 'Hawaii Time', timezone: 'US/Hawaii' },
    { location: 'America', label: 'America/Adak', timezone: 'America/Adak' },
    { location: 'America', label: 'Buenos Aires Time', timezone: 'America/Buenos_Aires' },
    { location: 'America', label: 'Asuncion Time', timezone: 'America/Asuncion' },
    { location: 'America', label: 'Bogota, Jamaica, Lima Time', timezone: 'America/Bogota' },
    { location: 'America', label: 'America/Campo Grande', timezone: 'America/Campo_Grande' },
    { location: 'America', label: 'Caracas Time', timezone: 'America/Caracas' },
    { location: 'America', label: 'America/Godthab', timezone: 'America/Godthab' },
    { location: 'America', label: 'Atlantic Time', timezone: 'America/Goose_Bay' },
    { location: 'America', label: 'Saskatchewan, Guatemala, Costa Rica Time', timezone: 'America/Costa_Rica' },
    { location: 'America', label: 'America/Havana', timezone: 'America/Havana' },
    { location: 'America', label: 'America/Mazatlan', timezone: 'America/Mazatlan' },
    { location: 'America', label: 'Mexico City Time', timezone: 'America/Mexico_City' },
    { location: 'America', label: 'Montevideo Time', timezone: 'America/Montevideo' },
    { location: 'America', label: 'America/Miquelon', timezone: 'America/Miquelon' },
    { location: 'America', label: 'America/Noronha', timezone: 'America/Noronha' },
    { location: 'America', label: 'Santiago Time', timezone: 'America/Santiago' },
    { location: 'America', label: 'America/Santa Isabel', timezone: 'America/Santa_Isabel' },
    { location: 'America', label: 'Atlantic Standard Time', timezone: 'America/Santo_Domingo' },
    { location: 'America', label: 'Brasilia Time', timezone: 'America/Sao_Paulo' },
    { location: 'Africa', label: 'Africa/Cairo', timezone: 'Africa/Cairo' },
    { location: 'Africa', label: 'Central Africa Time', timezone: 'Africa/Johannesburg' },
    { location: 'Africa', label: 'West Africa Time', timezone: 'Africa/Lagos' },
    { location: 'Africa', label: 'Africa/Windhoek', timezone: 'Africa/Windhoek' },
    { location: 'Asia', label: 'Jordan Time', timezone: 'Asia/Amman' },
    { location: 'Asia', label: 'Baghdad, East Africa Time', timezone: 'Asia/Baghdad' },
    { location: 'Asia', label: 'Asia/Baku', timezone: 'Asia/Baku' },
    { location: 'Asia', label: 'Lebanon Time', timezone: 'Asia/Beirut' },
    { location: 'Asia', label: 'Syria Time', timezone: 'Asia/Damascus' },
    { location: 'Asia', label: 'Asia/Dhaka', timezone: 'Asia/Dhaka' },
    { location: 'Asia', label: 'Dubai Time', timezone: 'Asia/Dubai' },
    { location: 'Asia', label: 'Asia/Gaza', timezone: 'Asia/Gaza' },
    { location: 'Asia', label: 'Asia/Irkutsk', timezone: 'Asia/Irkutsk' },
    { location: 'Asia', label: 'Indochina Time', timezone: 'Asia/Jakarta' },
    { location: 'Asia', label: 'Israel Time', timezone: 'Asia/Jerusalem' },
    { location: 'Asia', label: 'Kabul Time', timezone: 'Asia/Kabul' },
    { location: 'Asia', label: 'Pacific/Majuro', timezone: 'Pacific/Majuro' },
    { location: 'Asia', label: 'Pakistan, Maldives Time', timezone: 'Asia/Karachi' },
    { location: 'Asia', label: 'Kathmandu Time', timezone: 'Asia/Kathmandu' },
    { location: 'Asia', label: 'India, Sri Lanka Time', timezone: 'Asia/Kolkata' },
    { location: 'Asia', label: 'Krasnoyarsk Time', timezone: 'Asia/Krasnoyarsk' },
    { location: 'Asia', label: 'Asia/Omsk', timezone: 'Asia/Omsk' },
    { location: 'Asia', label: 'Asia/Rangoon', timezone: 'Asia/Rangoon' },
    { location: 'Asia', label: 'China, Singapore, Perth', timezone: 'Asia/Singapore' },
    { location: 'Asia', label: 'Tehran Time', timezone: 'Asia/Tehran' },
    { location: 'Asia', label: 'Japan, Korea Time', timezone: 'Asia/Tokyo' },
    { location: 'Asia', label: 'Asia/Vladivostok', timezone: 'Asia/Vladivostok' },
    { location: 'Asia', label: 'Asia/Yakutsk', timezone: 'Asia/Yakutsk' },
    { location: 'Asia', label: 'Yekaterinburg Time', timezone: 'Asia/Yekaterinburg' },
    { location: 'Asia', label: 'Asia/Yerevan', timezone: 'Asia/Yerevan' },
    { location: 'Atlantic', label: 'Azores Time', timezone: 'Atlantic/Azores' },
    { location: 'Atlantic', label: 'Cape Verde Time', timezone: 'Atlantic/Cape_Verde' },
    { location: 'Australia', label: 'Adelaide Time', timezone: 'Australia/Adelaide' },
    { location: 'Australia', label: 'Brisbane Time', timezone: 'Australia/Brisbane' },
    { location: 'Australia', label: 'Australia/Darwin', timezone: 'Australia/Darwin' },
    { location: 'Australia', label: 'Australia/Eucla', timezone: 'Australia/Eucla' },
    { location: 'Australia', label: 'Australia/Lord Howe', timezone: 'Australia/Lord_Howe' },
    { location: 'Australia', label: 'Australia/Perth', timezone: 'Australia/Perth' },
    { location: 'Australia', label: 'Sydney, Melbourne Time', timezone: 'Australia/Sydney' },
    { location: 'UTC', label: 'UTC Time', timezone: 'UTC' },
    { location: 'Europe', label: 'Central European Time', timezone: 'Europe/Berlin' },
    { location: 'Europe', label: 'Eastern European Time', timezone: 'Europe/Helsinki' },
    { location: 'Europe', label: 'UK, Ireland, Lisbon Time', timezone: 'Europe/London' },
    { location: 'Europe', label: 'Minsk Time', timezone: 'Europe/Minsk' },
    { location: 'Europe', label: 'Moscow Time', timezone: 'Europe/Moscow' },
    { location: 'Europe', label: 'Turkey Time', timezone: 'Europe/Istanbul' },
    { location: 'Pacific', label: 'Pacific/Apia', timezone: 'Pacific/Apia' },
    { location: 'Pacific', label: 'Auckland Time', timezone: 'Pacific/Auckland' },
    { location: 'Pacific', label: 'Pacific/Chatham', timezone: 'Pacific/Chatham' },
    { location: 'Pacific', label: 'Pacific/Easter', timezone: 'Pacific/Easter' },
    { location: 'Pacific', label: 'Pacific/Fiji', timezone: 'Pacific/Fiji' },
    { location: 'Pacific', label: 'Pacific/Gambier', timezone: 'Pacific/Gambier' },
    { location: 'Pacific', label: 'Pacific/Kiritimati', timezone: 'Pacific/Kiritimati' },
    { location: 'Pacific', label: 'Pacific/Majuro', timezone: 'Pacific/Majuro' },
    { location: 'Pacific', label: 'Pacific/Marquesas', timezone: 'Pacific/Marquesas' },
    { location: 'Pacific', label: 'Pacific/Norfolk', timezone: 'Pacific/Norfolk' },
    { location: 'Pacific', label: 'Pacific/Noumea', timezone: 'Pacific/Noumea' },
    { location: 'Pacific', label: 'Pacific/Pago Pago', timezone: 'Pacific/Pago_Pago' },
    { location: 'Pacific', label: 'Pacific/Pitcairn', timezone: 'Pacific/Pitcairn' },
    { location: 'Pacific', label: 'Pacific/Tarawa', timezone: 'Pacific/Tarawa' },
    { location: 'Pacific', label: 'Pacific/Tongatapu', timezone: 'Pacific/Tongatapu' }
];

export default timezones;
