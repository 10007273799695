import { useMemo } from 'react';
import useDepositFunctions from './useDepositFunctions';

interface PayButtonTextProps {
    predefinedAmount?: number;
    fee?: number | string;
}

const usePayButtonText = ({ predefinedAmount, fee = 0 }: PayButtonTextProps) => {
    const { servicePrice } = useDepositFunctions();
    const price = predefinedAmount ? parseFloat(String(predefinedAmount)) : servicePrice;

    const serviceFee = useMemo(() => (typeof fee === 'string' ? parseFloat(fee) : fee), [fee]);

    const payButtonTitle = () => `Pay $${price + serviceFee} ${serviceFee ? `(includes $${serviceFee} service fee)` : ''}`;

    return { payButtonTitle };
};

export default usePayButtonText;
