const MastercardIcon = () => (
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1895_10633)">
            <path
                d="M28.7992 0.499023H2.79922C1.36328 0.499023 0.199219 1.66308 0.199219 3.09902V18.699C0.199219 20.135 1.36328 21.299 2.79922 21.299H28.7992C30.2352 21.299 31.3992 20.135 31.3992 18.699V3.09902C31.3992 1.66308 30.2352 0.499023 28.7992 0.499023Z"
                fill="#252525"
            />
            <path
                d="M11.8984 17.3994C15.4883 17.3994 18.3984 14.4893 18.3984 10.8994C18.3984 7.30956 15.4883 4.39941 11.8984 4.39941C8.30859 4.39941 5.39844 7.30956 5.39844 10.8994C5.39844 14.4893 8.30859 17.3994 11.8984 17.3994Z"
                fill="#EB001B"
            />
            <path
                d="M19.6992 17.3994C23.2891 17.3994 26.1992 14.4893 26.1992 10.8994C26.1992 7.30956 23.2891 4.39941 19.6992 4.39941C16.1094 4.39941 13.1992 7.30956 13.1992 10.8994C13.1992 14.4893 16.1094 17.3994 19.6992 17.3994Z"
                fill="#F79E1B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7992 5.69824C17.378 6.88411 18.3992 8.77216 18.3992 10.8987C18.3992 13.0253 17.378 14.9133 15.7992 16.0992C14.2204 14.9133 13.1992 13.0253 13.1992 10.8987C13.1992 8.77216 14.2204 6.88411 15.7992 5.69824Z"
                fill="#FF5F00"
            />
        </g>
        <defs>
            <clipPath id="clip0_1895_10633">
                <rect width="31.2" height="20.8" fill="white" transform="translate(0.199219 0.499023)" />
            </clipPath>
        </defs>
    </svg>
);

export default MastercardIcon;
