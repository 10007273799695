import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IWidgetDeposit, IWidgetCouponResponse } from '../../views/scheduling-widget/widget-wizard/types';

interface WidgetSliceProps {
    deposit: IWidgetDeposit;
    noShowDeposit: IWidgetDeposit;
    coupon?: IWidgetCouponResponse;
    couponError?: string;
    created_uuid: string | null;
}

const initialState: WidgetSliceProps = {
    deposit: {
        required: false,
        amount: 0,
        sales_tax_amount: null
    },
    noShowDeposit: {
        required: false,
        amount: 0,
        sales_tax_amount: null
    },
    coupon: undefined,
    couponError: undefined,
    created_uuid: null
};

export const widgetSlice = createSlice({
    name: 'widgetSlice',
    initialState,
    reducers: {
        setDeposit: (state, action: PayloadAction<IWidgetDeposit>) => {
            state.deposit = action.payload;
        },
        setNoShowDeposit: (state, action: PayloadAction<IWidgetDeposit>) => {
            state.noShowDeposit = action.payload;
        },
        setCoupon: (state, action: PayloadAction<IWidgetCouponResponse | undefined>) => {
            state.coupon = action.payload;
        },
        setCouponError: (state, action: PayloadAction<string | undefined>) => {
            state.couponError = action.payload;
        },
        setCreatedUuid: (state, action: PayloadAction<string | null>) => {
            state.created_uuid = action.payload;
        }
    }
});

export const { setDeposit, setNoShowDeposit, setCoupon, setCouponError, setCreatedUuid } = widgetSlice.actions;
