import { Switch } from '@mui/material';
import InputRow from '../../form/InputRow';

interface NotificationsSwitchProps {
    value: boolean;
    onChange: (newValue: boolean) => void;
    disabled?: boolean;
}

const NotificationsSwitch = ({ value, onChange, disabled }: NotificationsSwitchProps) => (
    <InputRow label="Send Notifications" size="sm">
        <Switch checked={value} onChange={(e, checked) => onChange(checked)} disabled={disabled} />
    </InputRow>
);

export default NotificationsSwitch;
