import { useAppSelector } from '../hooks/redux';
import { DialogTypes } from '../store/slices/entityDialogsSlice';
import { Theme, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';

const useEntityDialogs = () => {
    const isDesktop = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.up('lg'));
    const { entityId, entityType } = useAppSelector((state) => state.entityDialogs);
    const showAsNormalDrawer = useMemo(() => entityType === DialogTypes.Ledger || !isDesktop, [entityType, isDesktop]);
    const open = useMemo(() => !!entityType, [entityType]);

    return { entityId, entityType, showAsNormalDrawer, open };
};

export default useEntityDialogs;
