import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const SquareStyledButton = styled(Button)(({ theme }) => ({
    height: '44px',
    width: '44px',
    minWidth: 'auto',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',

    '& .MuiButton-startIcon': {
        margin: 0
    },

    '&:disabled': {
        filter: 'saturate(0)'
    }
}));

export default SquareStyledButton;
