import { FC, useCallback, useMemo } from 'react';
import CommonDrawer, { useCommonSettingsDrawerPx } from '../../../ui-component/CommonSettingsDrawer';
import { Box, Button, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import InfoTooltip from '../../../ui-component/InfoTooltip';
import StickyFormFooter from '../../../ui-component/form/sticky-form-footer';
import useTooltips from '../../../hooks/use-tooltips';

type StripeFormDataType = {
    secret_key?: string;
    publishable_key?: string;
};

type StripeFormProps = {
    open: boolean;
    onClose: () => void;
    data?: StripeFormDataType | null;
    onSave: (data: StripeFormDataType) => void;
};

const StripeForm: FC<StripeFormProps> = ({ open, onClose, data, onSave }) => {
    const { getTooltipTextByKey } = useTooltips();
    const containerXSpacing = useCommonSettingsDrawerPx();
    const initialValues = useMemo(
        () => ({
            secret_key: data?.secret_key ?? '',
            publishable_key: data?.publishable_key ?? ''
        }),
        [data]
    );
    const { values, handleSubmit, handleChange, handleBlur, errors, touched, resetForm } = useFormik<StripeFormDataType>({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        initialValues,
        onSubmit: (formData) => {
            onSave(formData);
            onClose();
        },
        validationSchema: Yup.object().shape(
            {
                secret_key: Yup.string()
                    .trim()
                    .when('publishable_key', {
                        is: (v?: string | null) => !!v,
                        then: Yup.string()
                            .trim()
                            .required('The Stripe Secret Key field is required when Stripe Publishable Key is present.'),
                        otherwise: Yup.string().trim().nullable().notRequired()
                    }),
                publishable_key: Yup.string()
                    .trim()
                    .when('secret_key', {
                        is: (v?: string | null) => !!v,
                        then: Yup.string()
                            .trim()
                            .required('The Stripe Publishable Key field is required when Stripe Secret Key is present.'),
                        otherwise: Yup.string().trim().nullable().notRequired()
                    })
            },
            [['secret_key', 'publishable_key']]
        )
    });

    const tooltipText = useMemo(() => getTooltipTextByKey('settings.integrations.cc_processor.stripe.secret_key'), [getTooltipTextByKey]);

    const handleClose = useCallback(() => {
        onClose();
        resetForm();
    }, [resetForm, onClose]);

    return (
        <CommonDrawer open={open} title="Stripe Details" onClose={handleClose}>
            <Box
                component="form"
                noValidate
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%'
                }}
                onSubmit={handleSubmit}
            >
                <Stack spacing={3}>
                    <TextField
                        id="secret_key"
                        name="secret_key"
                        label="Secret Key"
                        value={values.secret_key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.secret_key && !!touched.secret_key}
                        helperText={touched.secret_key ? errors.secret_key : undefined}
                        InputProps={{
                            endAdornment: tooltipText ? <InfoTooltip label="Secret Key" text={tooltipText} /> : undefined
                        }}
                    />
                    <TextField
                        id="publishable_key"
                        name="publishable_key"
                        label="Publishable Key"
                        value={values.publishable_key}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!errors.publishable_key && !!touched.publishable_key}
                        helperText={touched.publishable_key ? errors.publishable_key : undefined}
                    />
                </Stack>
                <StickyFormFooter containerXSpacing={containerXSpacing}>
                    <Button className="forcedBg" color="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </StickyFormFooter>
            </Box>
        </CommonDrawer>
    );
};

export default StripeForm;
