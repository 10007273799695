import useAuth from '../../hooks/useAuth';
import companyAPI from '../../services/CompanyService';
import WidgetSettingsForm from './WidgetSettingsForm';
import LoadingSpinner from '../../ui-component/LoadingSpinner';
import ErrorCatcher from '../../ui-component/error-catcher';
import FixedRoute from '../../ui-component/fixed-route';

const WidgetSettings = () => {
    const { user } = useAuth();

    const { data, isFetching } = companyAPI.useGetCompanyQuery(user?.currentCompany.id.toString() || '', {
        refetchOnMountOrArgChange: true
    });

    return (
        <FixedRoute label="Widget Settings" backLink="/settings">
            {isFetching ? (
                <LoadingSpinner />
            ) : (
                <ErrorCatcher wrappedComponentName="WidgetSettings">{data ? <WidgetSettingsForm company={data} /> : null}</ErrorCatcher>
            )}
        </FixedRoute>
    );
};

export default WidgetSettings;
