import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { Theme, useMediaQuery, useTheme } from '@material-ui/core';
import StyledGreenButton from '../../../views/scheduling-widget/components/StyledGreenButton';

type WidgetServicePolicyProps = {
    name: string;
    serviceName: string;
    text: string;
    accepted: boolean;
    setAccepted: (v: boolean) => void;
};

const WidgetServicePolicy: FC<WidgetServicePolicyProps> = ({ name, serviceName, text, accepted, setAccepted }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const handleOpenDialog = useCallback(() => {
        setOpen(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <Box sx={{ userSelect: 'none', ml: -1, display: 'flex', alignItems: 'center', width: '100%' }}>
            <Checkbox
                size="small"
                checked={accepted}
                onChange={(e) => setAccepted(e.target.checked)}
                disableRipple
                aria-label={`I agree to the ${name} for the ${serviceName}`}
                sx={{ flexShrink: 0 }}
            />

            <Typography sx={{ flexGrow: 1, flexShrink: 1 }}>
                I agree to the{' '}
                <Link sx={{ cursor: 'pointer' }} onClick={handleOpenDialog}>
                    {name}
                </Link>{' '}
                for the {serviceName}
            </Typography>

            <Dialog
                open={open}
                sx={{ '& .MuiPaper-root': { p: 0 } }}
                onClose={handleCloseDialog}
                maxWidth="sm"
                fullWidth
                fullScreen={isMobile}
            >
                <DialogTitle
                    sx={{
                        color: theme.palette.widget.text,
                        fontSize: '24px',
                        lineHeight: '28px'
                    }}
                >
                    {name}
                </DialogTitle>
                <DialogContent>
                    <Typography
                        sx={{
                            color: theme.palette.widget.text,
                            fontSize: '16px'
                        }}
                    >
                        {text}
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <StyledGreenButton onClick={handleCloseDialog}>OK</StyledGreenButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default WidgetServicePolicy;
