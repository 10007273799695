import { FC, useState } from 'react';
import { Box, MenuItem, Stack, TextField, Theme, Typography, useMediaQuery } from '@mui/material';
import { ICompany, ICompanySettings } from '../../../models/ICompany';
import QRCode from 'react-qr-code';
import { FormikProps } from 'formik';
import CopyUrlButton from '../components/CopyUrlButton';

type CheckInTabProps = {
    company: ICompany;
    formInstance: FormikProps<ICompanySettings>;
};

const CheckInTab: FC<CheckInTabProps> = ({ company, formInstance }) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('mobile'));
    const isSmall = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    const [selectedLocationUrl, setSelectedLocationUrl] = useState('');
    const { values, handleChange, handleBlur } = formInstance;

    return (
        <Stack spacing={4} direction={isMobile ? 'column' : 'row'} pb={isMobile ? 2 : undefined}>
            <Stack spacing={3} sx={{ maxWidth: isMobile ? '100%' : '480px' }}>
                <Box>
                    <Typography variant="h4" mb={0.5}>
                        Check In
                    </Typography>
                </Box>

                <Stack spacing={2} sx={{ width: isMobile ? '100%' : '343px', maxWidth: '100%' }}>
                    <TextField
                        fullWidth
                        id="rce_checkin.custom_css"
                        name="rce_checkin.custom_css"
                        label="Custom CSS"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.rce_checkin?.custom_css ?? ''}
                        multiline
                        rows={4}
                    />

                    <TextField
                        fullWidth
                        label="Choose your location"
                        value={selectedLocationUrl}
                        onChange={(e) => setSelectedLocationUrl(e.target.value)}
                        select
                    >
                        <MenuItem value="">Choose your location</MenuItem>
                        {company.locations?.map((location) => (
                            <MenuItem key={location.id} value={location.rceCheckInUrlShort}>
                                {location.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    {selectedLocationUrl && !isMobile ? <CopyUrlButton url={selectedLocationUrl} /> : null}
                </Stack>
            </Stack>
            {selectedLocationUrl ? (
                <Stack spacing={3} alignItems="center">
                    <Box
                        p={3}
                        sx={{
                            border: '1px solid',
                            borderRadius: '8px',
                            borderColor: isMobile ? '#d9d9d9' : 'transparent',
                            width: isSmall ? '240px' : '304px',
                            maxWidth: '100%'
                        }}
                    >
                        <QRCode value={selectedLocationUrl} size={isSmall ? 192 : undefined} />
                    </Box>
                    {isMobile && <CopyUrlButton url={selectedLocationUrl} />}
                </Stack>
            ) : null}
        </Stack>
    );
};

export default CheckInTab;
