type ErrorItemType = { rule: string; message: string } | string;

const isErrorItemType = (input: any): input is ErrorItemType =>
    typeof input === 'string' || (input && typeof input === 'object' && 'rule' in input && 'message' in input);

const processErrorField = (errField: unknown) => {
    if (isErrorItemType(errField)) {
        return typeof errField === 'object' ? errField.message : errField;
    }

    return '';
};

const getErrorMessage = (err: any): string => {
    if (err && typeof err === 'object') {
        const { data, errors } = err;

        if (!!errors && typeof errors === 'object') {
            return Object.values(errors)
                .reduce<string[]>((prev, current) => {
                    const newVal = Array.isArray(current) ? current : [current];
                    return [...prev, ...newVal.map(processErrorField)];
                }, [])
                .filter((str) => !!str)
                .join('; ');
        }

        return String(data);
    }

    return String(err);
};

export default getErrorMessage;
