import { Box, Stack, FormControl, TextField, MenuItem, Button } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import MomentAdapter from '@mui/lab/AdapterMoment';
import momentTimezone from 'moment-timezone';
import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { IPayment } from '../../../../../../../../models/IPayment';
import { useAppSelector } from '../../../../../../../../hooks/redux';
import useExtendedFormik from '../../../../../../../../hooks/useExtendedFormik';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { methodOptions, paymentReasons, reasonOptions } from './PaymentInfoSelectOptions';
import useEntityDrawerSpacing from '../../../../../../../entity-drawer-layout/use-entity-drawer-spacing';
import EntityDrawerContent from '../../../../../../../entity-drawer-layout/EntityDrawerContent';
import PriceInput from '../../../../../../../PriceInput';
import EntityDrawerActions from '../../../../../../../entity-drawer-layout/EntityDrawerActions';

interface PaymentFormProps {
    savePayments: (data: IPayment) => void;
    paymentIndex: number | null;
    onCancel: () => void;
}

const PaymentForm = ({ savePayments, paymentIndex, onCancel }: PaymentFormProps) => {
    const { spacingX } = useEntityDrawerSpacing();
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const { moment } = new MomentAdapter({ instance: momentTimezone });

    const payment = useMemo(() => (paymentIndex !== null && selectedEvent?.payments ? selectedEvent.payments[paymentIndex] : null), [
        selectedEvent,
        paymentIndex
    ]);

    const formInitialValues = useMemo(
        () =>
            payment
                ? { ...payment } // clone object to make props mutable
                : {
                      datetime: moment()
                          .tz(selectedEvent?.location.time_zone || 'utc')
                          .toString(),
                      method: '',
                      reason: '',
                      amount: null,
                      code: ''
                  },
        [selectedEvent, moment, payment]
    );

    const validationSchema = Yup.object().shape({
        datetime: Yup.string().required('Date field is required'),
        reason: Yup.string().trim().required('Payment reason is required'),
        method: Yup.mixed().when('reason', {
            is: (value: string) => value !== 'coupon',
            then: Yup.string().trim().required('Payment method is required'),
            otherwise: Yup.string().notRequired()
        }),
        amount: Yup.mixed().when('reason', {
            is: (value: string) => value !== 'coupon',
            then: Yup.number().required('Payment amount is required').min(0.01),
            otherwise: Yup.mixed().notRequired().nullable()
        }),
        code: Yup.mixed().when('reason', {
            is: 'coupon',
            then: Yup.string().trim().required('Coupon Code is required'),
            otherwise: Yup.string().notRequired()
        })
    });

    const onSubmit = useCallback(
        (data: IPayment) => {
            savePayments({
                ...data,
                method: data.reason === 'coupon' ? undefined : data.method,
                amount: data.reason === 'coupon' ? undefined : data.amount
            });
        },
        [savePayments]
    );

    const { handleSubmit, values, touched, errors, handleChange, handleBlur, setFieldTouched, setFieldValue } = useExtendedFormik({
        initialValues: formInitialValues,
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema,
        onSubmit: (formData) => {
            formData.datetime = moment(formData.datetime).tz('UTC').toString();
            onSubmit({
                ...formData,
                amount: formData?.amount || undefined,
                method: formData.method || undefined,
                code: formData.code ? formData.code : undefined
            });
        }
    });

    const usedReasonOptions = useMemo(() => {
        if (!payment && !selectedEvent?.payments?.some((p) => p.reason === 'coupon')) {
            return reasonOptions;
        }

        return reasonOptions.filter((opt) => opt.value !== paymentReasons.coupon);
    }, [payment, selectedEvent]);

    return (
        <>
            <EntityDrawerContent>
                <Stack component="form" spacing={spacingX} noValidate onSubmit={handleSubmit} id="payment-info-form">
                    <Box>
                        <FormControl fullWidth error={Boolean(touched.datetime && errors.datetime)}>
                            <LocalizationProvider dateAdapter={MomentAdapter}>
                                <MobileDatePicker
                                    label="Date"
                                    inputFormat="MMM D, YYYY"
                                    mask="MMM D, YYYY"
                                    value={values.datetime}
                                    onChange={(value) => {
                                        if (value) {
                                            setFieldValue('datetime', value);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: <CalendarMonthOutlinedIcon />
                                            }}
                                        />
                                    )}
                                    disableCloseOnSelect={false}
                                />
                            </LocalizationProvider>
                        </FormControl>
                    </Box>
                    <Box>
                        <TextField
                            id="reason"
                            name="reason"
                            fullWidth
                            error={Boolean(touched.reason && errors.reason)}
                            value={values.reason}
                            label="Type"
                            select
                            onBlur={handleBlur}
                            onChange={(event) => {
                                setFieldValue('reason', event.target.value);
                                setFieldTouched('reason', false);
                            }}
                        >
                            {usedReasonOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                    {values.reason !== 'coupon' ? (
                        // Show method and amount if type is NOT 'coupon'
                        <>
                            <Box>
                                <TextField
                                    id="method"
                                    name="method"
                                    fullWidth
                                    error={Boolean(touched.method && errors.method)}
                                    value={values.method}
                                    label="Method"
                                    select
                                    onBlur={handleBlur}
                                    onChange={(event) => {
                                        setFieldValue('method', event.target.value);
                                        setFieldTouched('method', false);
                                    }}
                                >
                                    {methodOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box>
                                <TextField
                                    fullWidth
                                    error={Boolean(touched.amount && errors.amount)}
                                    name="amount"
                                    value={values.amount}
                                    label="Amount"
                                    inputProps={{ step: '0.01', min: '0.01' }}
                                    onChange={(event) => {
                                        handleChange(event);
                                        setFieldTouched('amount', false);
                                    }}
                                    // eslint-disable-next-line react/jsx-no-duplicate-props
                                    InputProps={{
                                        inputComponent: PriceInput as any
                                    }}
                                />
                            </Box>
                        </>
                    ) : (
                        // Show coupon code if payment type is 'coupon'
                        <Box>
                            <TextField
                                fullWidth
                                name="code"
                                value={values.code}
                                label="Coupon Code"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={Boolean(touched.code && errors.code)}
                                helperText={touched.code ? errors.code : undefined}
                            />
                        </Box>
                    )}
                </Stack>
            </EntityDrawerContent>
            <EntityDrawerActions>
                <Button color="primary" variant="text" className="forcedBg" onClick={onCancel}>
                    Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit" form="payment-info-form">
                    Confirm
                </Button>
            </EntityDrawerActions>
        </>
    );
};

export default PaymentForm;
