import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import PaymentForm from './elements/PaymentForm';
import { Box, Button, Stack } from '@mui/material';
import { IPayment } from '../../../../../../../models/IPayment';
import { startSubmitting, stopSubmitting } from '../../../../../../../store/slices/SubmittingSlice';
import { SnackBarTypes } from '../../../../../../../store/snackbarReducer';
import useShowSnackbar from '../../../../../../../hooks/useShowSnackbar';
import appointmentAPI from '../../../../../../../services/AppointmentService';
import { openConfirmPopup } from '../../../../../../../store/confirmPopupSlice';
import PaymentsTable from './elements/payments-table';
import PaymentsSummary from './elements/payments-summary';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import NewPaymentForm from './elements/new-payment-form';
import SelfCheckoutDrawer from './elements/SelfCheckoutDrawer';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import moment from 'moment-timezone';
import { usePayments } from '../../../../../../../hooks/appointments';
import SelfCheckoutTrigger from '../../../../../../self-checkout/self-checkout-trigger';
import useEntityDrawerSpacing from '../../../../../../entity-drawer-layout/use-entity-drawer-spacing';
import PermanentEntityDrawer from '../../../../../../PermanentEntityDrawer';
import SectionHeading from '../../../../../../SectionHeading';

const Payments = () => {
    const { isMobile, spacingX } = useEntityDrawerSpacing();
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();

    const { payments, coupon } = usePayments(selectedEvent);

    const [editPayment] = appointmentAPI.useUpdateAppointmentPaymentMutation();
    const [createPayment] = appointmentAPI.useCreateAppointmentPaymentMutation();
    const [deletePayment] = appointmentAPI.useDeleteAppointmentPaymentMutation();

    const [showForm, setShowForm] = useState<boolean>(false);
    const [showSelfCheckout, setShowSelfCheckout] = useState(false);

    const handleOpenSelfCheckout = useCallback(() => {
        setShowSelfCheckout(true);
    }, []);

    const [editablePaymentIndex, setEditablePaymentIndex] = useState<number | null>(null);

    const openCleanForm = useCallback(() => {
        setEditablePaymentIndex(null);
        setShowForm(true);
    }, []);

    const closeForm = useCallback(() => {
        setEditablePaymentIndex(null);
        setShowForm(false);
    }, []);

    const onError = useCallback(
        (e) => {
            showSnackbar({
                message: e.data || "Payment hasn't been Updated",
                alertSeverity: SnackBarTypes.Error
            });
        },
        [showSnackbar]
    );

    const onFinally = useCallback(() => {
        dispatch(stopSubmitting());
    }, [dispatch]);

    const savePayments = useCallback(
        (data: IPayment) => {
            if (!selectedEvent) return;

            if (editablePaymentIndex !== null) {
                dispatch(startSubmitting());
                editPayment({
                    appointmentId: selectedEvent.id.toString(),
                    data: Array.isArray(data) ? data[0] : data,
                    paymentIndex: editablePaymentIndex
                })
                    .unwrap()
                    .then(() => {
                        closeForm();
                    })
                    .catch(onError)
                    .finally(onFinally);
            }
        },
        [selectedEvent, dispatch, editablePaymentIndex, editPayment, onError, onFinally, closeForm]
    );

    const handleCreatePayments = useCallback(
        (id: string, newPayments: IPayment[]) => {
            dispatch(startSubmitting());
            createPayment({
                appointmentId: id,
                payments: newPayments
            })
                .unwrap()
                .then(closeForm)
                .catch(onError)
                .finally(onFinally);
        },
        [closeForm, createPayment, dispatch, onError, onFinally]
    );

    const handleCreateCoupon = useCallback(
        (code: string) => {
            if (selectedEvent) {
                const id = String(selectedEvent.id);
                const payment: IPayment = {
                    reason: 'coupon',
                    datetime: moment().tz('UTC').toString(),
                    code
                };
                handleCreatePayments(id, [payment]);
            }
        },
        [handleCreatePayments, selectedEvent]
    );

    const handleDeletePayment = useCallback(
        (index: number, isCoupon = false) => {
            dispatch(
                openConfirmPopup({
                    onConfirm: () => {
                        if (selectedEvent) {
                            dispatch(startSubmitting());
                            deletePayment({
                                appointmentId: selectedEvent.id.toString(),
                                paymentIndex: index
                            })
                                .unwrap()
                                .catch(onError)
                                .finally(onFinally);
                        }
                    },
                    confirmText: `Delete`,
                    text: `Are you sure you want to delete ${isCoupon ? 'discount code' : 'this payment'}?`
                })
            );
        },
        [dispatch, selectedEvent, deletePayment, onError, onFinally]
    );

    const onClickEdit = useCallback((index: number) => {
        setEditablePaymentIndex(index);
        setShowForm(true);
    }, []);

    return (
        <Box display="flex" flexDirection="column" height="100%" px={spacingX}>
            <>
                <Stack spacing={isMobile ? 1 : 1.5} mb={2} alignItems="center" direction="row" sx={{ width: '100%' }}>
                    <SectionHeading
                        sx={{
                            flexGrow: 1,
                            flexShrink: 1
                        }}
                    >
                        Payments
                    </SectionHeading>
                    {selectedEvent ? (
                        <SelfCheckoutTrigger
                            onClick={handleOpenSelfCheckout}
                            available={!!selectedEvent?.is_self_checkout_available}
                            disabled={isForeignAppointment || selectedEvent.balance_details?.balance_remaining === 0}
                            isMobile={isMobile}
                        />
                    ) : null}

                    <Button
                        onClick={openCleanForm}
                        disabled={isForeignAppointment}
                        variant="text"
                        className="forcedBg"
                        color="primary"
                        sx={{ alignSelf: 'flex-start', flexGrow: 0, flexShrink: 0 }}
                        endIcon={<AddOutlinedIcon />}
                        size={isMobile ? 'small' : undefined}
                    >
                        Add New
                    </Button>
                </Stack>

                <Box overflow="auto" sx={{ flexGrow: 1 }}>
                    <PaymentsTable payments={payments} onClickDelete={handleDeletePayment} onClickEdit={onClickEdit} />
                </Box>

                <PaymentsSummary
                    payment_coupon_props={{
                        coupon,
                        onCreateCoupon: handleCreateCoupon,
                        onDeleteCoupon: (index: number) => handleDeletePayment(index, true)
                    }}
                />
            </>

            <PermanentEntityDrawer
                open={showForm}
                onClose={closeForm}
                title={
                    <Stack direction="row" spacing={1} alignItems="center">
                        <KeyboardBackspaceOutlinedIcon />
                        <span>{editablePaymentIndex !== null ? 'Edit Payment' : 'New Payment'}</span>
                    </Stack>
                }
            >
                {editablePaymentIndex === null ? (
                    <NewPaymentForm onSubmit={handleCreatePayments} onCancel={closeForm} />
                ) : (
                    <PaymentForm paymentIndex={editablePaymentIndex} savePayments={savePayments} onCancel={closeForm} />
                )}
            </PermanentEntityDrawer>

            <SelfCheckoutDrawer open={showSelfCheckout} onClose={() => setShowSelfCheckout(false)} appointment={selectedEvent} />
        </Box>
    );
};

export default Payments;
