import { FC } from 'react';
import { Box, BoxProps } from '@mui/material';

const OptionTextWrapper: FC<Pick<BoxProps, 'children' | 'sx'>> = ({ children, sx }) => (
    <Box
        sx={{
            ...sx,
            '@media(max-width: 768px)': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        }}
    >
        {children}
    </Box>
);

export default OptionTextWrapper;
