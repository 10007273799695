import notificationAPI from '../services/NotificationService';
import { useMemo } from 'react';

const useNotifications = () => {
    const { data } = notificationAPI.useGetAccountNotificationsQuery(null);
    const [markReadAll] = notificationAPI.useMarkAllNotificationsAsReadMutation();

    const notificationsLength = useMemo(() => data?.length ?? 0, [data]);
    const unreadNotificationsLength = useMemo(() => data?.filter((notification) => !notification.read_at).length ?? 0, [data]);

    return { data, markReadAll, notificationsLength, unreadNotificationsLength };
};

export default useNotifications;
