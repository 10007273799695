import { IWidgetCompany } from '../models/ICompany';
import { IEmployee } from '../models/IEmployee';

const usePaymentGateways = (company?: Pick<IWidgetCompany, 'payment_gws'> | null, employee?: IEmployee | null) => {
    if (employee?.payment_gws) {
        return employee.payment_gws;
    }

    return company?.payment_gws;
};

export default usePaymentGateways;
