// mui
import { Stack, Avatar, MenuItem, Typography, TextField } from '@mui/material';
import { SxProps } from '@mui/system';

// project imports
import EllipsisTypography from '../../ui-component/optimized-text-fields/EllipsisTypography';
import useAuth from '../../hooks/useAuth';
import { stringToColor } from '../../store/constant';
import useChangeCompany from '../../hooks/useChangeCompany';
import preferredImageSize from '../../utils/preferred-image-size';
import CreateOrganizationBtn from './CreateOrganizationBtn';

interface OrganizationSwitcherProps {
    sx?: SxProps;
}

const OrganizationSwitcher = ({ sx }: OrganizationSwitcherProps) => {
    const { user } = useAuth();
    const { handleChangeCompany } = useChangeCompany();

    return (
        <>
            <TextField
                select
                id="company_id"
                name="company_id"
                value={user?.currentCompany.id}
                onChange={(e) => handleChangeCompany(Number(e.target.value))}
                variant="outlined"
                sx={{
                    ...sx,
                    maxWidth: '300px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',

                    '& .MuiOutlinedInput-input': {
                        pl: 1,
                        py: 1
                    },

                    '& .MuiOutlinedInput-notchedOutline': {
                        borderRadius: '20px'
                    },

                    '& .MuiSelect-outlined > .MuiStack-root > .MuiTypography-root': {
                        textTransform: 'uppercase',
                        fontSize: '0.875rem',
                        lineHeight: '1.25rem',
                        color: 'newColors.darkBlue',
                        fontWeight: 600
                    }
                }}
            >
                {user?.companies.map((company) => (
                    <MenuItem key={company.id} value={company.id} sx={{ px: 1.5, py: 2 }}>
                        <Stack direction="row" alignItems="center">
                            <Avatar
                                color="inherit"
                                src={preferredImageSize(company.logo, 'small')}
                                sx={{
                                    width: 24,
                                    height: 24,
                                    backgroundColor: company.logo ? 'transparent' : stringToColor(company.name),
                                    color: '#fff'
                                }}
                            >
                                <Typography>{company?.name.charAt(0).toUpperCase()}</Typography>
                            </Avatar>
                            <EllipsisTypography
                                maxWidth={500}
                                text={company.name}
                                sx={{
                                    fontWeight: 400,
                                    fontSize: '1rem',
                                    lineHeight: '1.5rem',
                                    color: 'grey.800'
                                }}
                            />
                        </Stack>
                    </MenuItem>
                ))}

                <CreateOrganizationBtn />
            </TextField>
        </>
    );
};

export default OrganizationSwitcher;
