import { FC, useEffect, useMemo, useRef } from 'react';
import { Alert, alpha, useTheme } from '@mui/material';

const ExistingCustomerAlert: FC<{ email?: string | null; phone?: string | null }> = ({ email, phone }) => {
    const theme = useTheme();
    const alertRef = useRef<any>(null);

    const text = useMemo(() => {
        const emailError = email ? `email ${email}` : null;
        const phoneError = phone ? `phone ${phone}` : null;
        const errors = [emailError, phoneError].filter((err) => !!err);

        if (emailError || phoneError) {
            return `A customer with ${[errors.join(' and ')]} already exists.`;
        }

        return null;
    }, [email, phone]);

    useEffect(() => {
        if (text) {
            alertRef?.current?.scrollIntoView({ block: 'start' });
        }
    }, [text]);

    return text ? (
        <Alert
            severity="warning"
            sx={{
                mb: 1,
                // @ts-ignore
                backgroundColor: theme.palette.orange ? alpha(theme.palette.orange.light, 0.3) : 'orange.light',
                p: 2,
                borderRadius: '1rem',
                color: 'orange.main',
                '& .MuiAlert-message': { color: 'inherit' },
                '& .MuiAlert-icon': {
                    color: 'inherit'
                }
            }}
            ref={alertRef}
        >
            {text}
        </Alert>
    ) : null;
};

export default ExistingCustomerAlert;
