import { Box, IconButton, Stack, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const RouteHeader: FC<{ label: string; backLink: string; sticky?: boolean; containerId?: string }> = ({
    label,
    backLink,
    sticky,
    containerId
}) => {
    const navigate = useNavigate();
    const handleBack = useCallback(() => {
        navigate(backLink);
    }, [navigate, backLink]);

    return (
        <Stack
            spacing={2}
            p={2}
            mb={2}
            sx={{
                borderBottom: '1px solid',
                borderBottomColor: 'grey.200',
                flexGrow: 0,
                flexShrink: 0,
                width: '100%',
                backgroundColor: 'background.default',
                zIndex: 10,
                top: 0,
                position: sticky ? 'sticky' : undefined
            }}
        >
            <Stack direction="row">
                <IconButton color="secondary" onClick={handleBack}>
                    <ArrowBackIcon />
                </IconButton>
                {containerId && <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }} id={containerId} />}
            </Stack>
            <Typography sx={{ fontSize: '1.25rem', color: 'grey.800' }}>{label}</Typography>
        </Stack>
    );
};

export default RouteHeader;
