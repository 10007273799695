const getSizeButtons = (size: 'sm' | 'md' | 'lg') => {
    switch (size) {
        case 'sm':
            return '132px';
        case 'md':
            return '166px';
        case 'lg':
            return '215px';

        default:
            return '166px';
    }
};

export default getSizeButtons;
