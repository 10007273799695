import EntityDrawerContent from '../../entity-drawer-layout/EntityDrawerContent';
import { FC } from 'react';
import appointmentAPI from '../../../services/AppointmentService';
import LoadingSpinner from '../../LoadingSpinner';
import AppointmentHistory from '../../appointment-history';
import useEntityDrawerSpacing from '../../entity-drawer-layout/use-entity-drawer-spacing';

const RecurringEventHistory: FC<{ id: number | string }> = ({ id }) => {
    const { data, isLoading } = appointmentAPI.useGetRecurringEventHistoryQuery(id);
    const { isMobile } = useEntityDrawerSpacing();
    return (
        <EntityDrawerContent>
            {isLoading && <LoadingSpinner />}
            {!isLoading && data && <AppointmentHistory data={data} isMobile={isMobile} />}
        </EntityDrawerContent>
    );
};

export default RecurringEventHistory;
