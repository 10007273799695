import { IWidgetCompany } from '../../../models/ICompany';
import { useMemo } from 'react';

const useWidgetDefaults = (query: string, data: IWidgetCompany) => {
    const getEntitySlugsFromQuery = (prefix: 'service' | 'location' | 'employee', values: string[]) => {
        const regexp = new RegExp(`${prefix}-`);
        return values.filter((v) => v.match(regexp)).map((v) => v.replace(regexp, ''));
    };

    return useMemo(() => {
        const queryArray = query.split('/');
        const { filteredServices, filteredLocations, filteredEmployees } = data;
        const services = getEntitySlugsFromQuery('service', queryArray);
        const locations = getEntitySlugsFromQuery('location', queryArray);
        const employees = getEntitySlugsFromQuery('employee', queryArray);

        return {
            services:
                filteredServices
                    ?.filter(({ slug }) => services.includes(slug))
                    .sort((a, b) => services.indexOf(a.slug) - services.indexOf(b.slug)) ?? [],
            locations: filteredLocations?.find(({ slug }) => slug && locations.includes(slug)) ?? null,
            employees: filteredEmployees?.find(({ slug }) => slug && employees.includes(slug)) ?? null
        };
    }, [data, query]);
};

export default useWidgetDefaults;
