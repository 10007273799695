const getArrayIntersections = (arrays: number[][]): number[] => {
    const inAllArrays = (v: number) => arrays.every((arr) => arr.includes(v));

    return arrays.reduce<number[]>((prev, current) => {
        const newValue = current.filter((value) => inAllArrays(value) && !prev.includes(value));
        return [...prev, ...newValue];
    }, []);
};

export default getArrayIntersections;
