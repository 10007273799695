import Alarm from '@mui/icons-material/Alarm';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import { IService, PaymentType } from '../../../../../models/IService';
import WidgetButton from '../../../components/WidgetButton';
import preferredImageSize from '../../../../../utils/preferred-image-size';
import { Box } from '@mui/material';

interface ServiceButtonProps {
    service: IService;
    onChoose: () => void;
    isSelected: boolean;
    handleClickInfo: () => void;
    disabled?: boolean;
}

const ServiceButton = ({ service, onChoose, isSelected, handleClickInfo, disabled }: ServiceButtonProps) => (
    <WidgetButton
        disabled={disabled}
        isSelected={isSelected}
        name={service.name}
        imageUrl={preferredImageSize(service.images[0], 'small')}
        onChoose={disabled ? undefined : onChoose}
        bottomContent={
            <>
                {service.payment_type === PaymentType.Prepaid && (
                    <Box component="span" sx={{ mr: 1 }}>
                        (requires deposit)
                    </Box>
                )}
                <Alarm />
                <span>{service.duration}m</span>
            </>
        }
        nameIcon={
            service.description ? (
                <InfoOutlined
                    onClick={(e) => {
                        if (!disabled) {
                            e.stopPropagation();
                            handleClickInfo();
                        }
                    }}
                />
            ) : null
        }
    />
);

export default ServiceButton;
