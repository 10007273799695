import { ICompany, IWidgetLinkBuilder } from '../../models/ICompany';
import { useCallback } from 'react';

const useGenerateLink = (company: ICompany) => {
    const generateLink = useCallback(
        (row: IWidgetLinkBuilder) => {
            let url = '';

            if (row.location_id) url += `/location-${company.locations?.find((l) => l.id === row.location_id)?.slug}`;

            if (row.service_ids?.length) {
                const services: string[] = [];

                row.service_ids.forEach((id) => {
                    const slug = company.services?.find((s) => s.id === id)?.slug;
                    if (slug) services.push(`/service-${slug}`);
                });

                url += services.join('');
            }

            if (row.employee_id) url += `/employee-${company.employees?.find((e) => e.id === row.employee_id)?.slug}`;

            return url;
        },

        [company.employees, company.locations, company.services]
    );

    return { generateLink };
};

export default useGenerateLink;
