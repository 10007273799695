import { FC } from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography } from '@mui/material';

const StyledText = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'error'
})<{ error?: boolean }>(({ theme, error }) => ({
    color: error ? 'error' : theme.palette.widget.text,
    margin: 0,
    fontSize: '24px',
    lineHeight: '24px',

    '@media(max-width: 768px)': {
        fontSize: '20px',
        lineHeight: '20px'
    }
}));

interface IWidgetStepTitleProps {
    title: string;
    error?: boolean;
}

const WidgetStepTitle: FC<IWidgetStepTitleProps> = ({ title, error }) => (
    <StyledText variant="h5" gutterBottom error={error}>
        {title}
    </StyledText>
);

export default WidgetStepTitle;
