import { useCallback, useMemo } from 'react';

// material-ui
import { Box, Typography } from '@material-ui/core';
import Transitions from '../../../../../ui-component/extended/Transitions';

// project imports
import { ServiceFormProps } from '../../types';
import { IService } from '../../../../../models/IService';
import StepTitle from '../../../components/StepTitle';

// third-party
import * as Yup from 'yup';
import { setCoupon, setCouponError } from '../../../../../store/slices/widgetSlice';
import { useAppDispatch } from '../../../../../hooks/redux';
import { FormHelperText } from '@mui/material';
import { getCommonServiceProvidersIds, getCommonServicesLocationIds } from '../../../../../utils/services';
import useExtendedFormik from '../../../../../hooks/useExtendedFormik';
import WidgetServiceSelect from '../../../../../ui-component/widgets/WidgetServiceSelect';

const validationSchema = Yup.object({
    service: Yup.array().of(Yup.object().nullable().required('Service is required')).min(1, 'At least one service is required!')
});

const ServiceForm = ({
    step,
    addProgress,
    services,
    serviceData,
    setServiceData,
    setLocationData,
    setProviderData,
    handleNext,
    handleBack,
    setHideNav,
    submitted,
    useMultiservices,
    selectedOptionIds,
    clickOptionId
}: ServiceFormProps) => {
    const dispatch = useAppDispatch();

    const { values, errors, handleSubmit, setErrors, setFieldValue, touched, setFieldTouched } = useExtendedFormik({
        enableReinitialize: true,
        initialValues: { service: serviceData },
        validationSchema,
        onSubmit: (formData) => {
            setServiceData(formData.service);
            addProgress(step);
            handleNext();
        }
    });

    const allowedLocations = useMemo(() => getCommonServicesLocationIds(serviceData), [serviceData]);

    const allowedEmployees = useMemo(() => getCommonServiceProvidersIds(serviceData), [serviceData]);

    const isDisabled = useCallback(
        (service: IService) => {
            const matchLoc = !allowedLocations.length || service.locations?.some(({ id }) => allowedLocations.includes(id));
            const matchProvider = !allowedEmployees.length || service.employees?.some(({ id }) => allowedEmployees.includes(id));

            return !matchLoc || !matchProvider;
        },
        [allowedEmployees, allowedLocations]
    );

    const onChange = useCallback(
        (v: IService[]) => {
            setFieldValue('service', v);
            setServiceData(v);
            setErrors({});
            setFieldTouched('service', true);
            setLocationData(null);
            setProviderData(null);
            dispatch(setCoupon());
            dispatch(setCouponError());

            if (v.length && !useMultiservices) {
                handleNext();
            }
        },
        [
            dispatch,
            handleNext,
            setErrors,
            setFieldTouched,
            setFieldValue,
            setLocationData,
            setProviderData,
            setServiceData,
            useMultiservices
        ]
    );

    const showServiceInfo = useCallback(() => {
        setHideNav(true);
    }, [setHideNav]);

    const closeInfoDialog = useCallback(() => {
        setHideNav(false);
    }, [setHideNav]);

    return (
        <Transitions type="fade" in>
            <StepTitle error={!!errors.service} title="Select Service" step={step} handleBack={handleBack} submitted={submitted} />
            {touched.service && errors.service && (
                <FormHelperText error sx={{ mb: 1 }}>
                    {errors.service}
                </FormHelperText>
            )}
            <Box component="form" onSubmit={handleSubmit} id={`widget-form-${step}`} sx={{ maxWidth: '100% !important' }}>
                {services && services.length > 0 && (
                    <WidgetServiceSelect
                        onChange={onChange}
                        value={values.service}
                        options={services}
                        useMultiservices={useMultiservices}
                        disableFn={isDisabled}
                        openInfoCb={showServiceInfo}
                        closeInfoCb={closeInfoDialog}
                        selectedServiceOptions={selectedOptionIds}
                        onServiceOptionToggle={clickOptionId}
                    />
                )}
                {services && services.length === 0 && <Typography>No active services for this Organization</Typography>}
            </Box>
        </Transitions>
    );
};

export default ServiceForm;
