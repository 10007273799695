import { ICustomer, ICustomerPayload } from '../models/ICustomer';
import { useCallback } from 'react';
import { startSubmitting, stopSubmitting } from '../store/slices/SubmittingSlice';
import { useAppDispatch } from './redux';
import checkForUniqueErrors from '../utils/checkForUniqueErrors';
import { SnackBarTypes } from '../store/snackbarReducer';
import customerAPI from '../services/CustomerService';
import useShowSnackbar from './useShowSnackbar';

const useCreateCustomer = (
    successCb: (customer: ICustomer) => void,
    errorCb: (data: { phone?: string | null; email?: string | null }) => void
) => {
    const dispatch = useAppDispatch();
    const [createCustomer] = customerAPI.useCreateCustomerMutation();
    const [updateCustomer] = customerAPI.useUpdateCustomerMutation();

    const { showSnackbar } = useShowSnackbar();

    const commonErrorCb = useCallback(
        (err: any) => {
            dispatch(stopSubmitting());
            showSnackbar({
                message: err.data || 'Error occurred, customer was not updated.',
                alertSeverity: SnackBarTypes.Error
            });
        },
        [dispatch, showSnackbar]
    );

    return useCallback(
        (formData: ICustomerPayload & { id?: number }) => {
            dispatch(startSubmitting());
            const { id, ...rest } = formData;

            const mutation = id ? updateCustomer({ id, ...rest }) : createCustomer(rest);

            mutation
                .unwrap()
                .then(successCb)
                .catch((err) => {
                    const emailUniqueErr = checkForUniqueErrors(err.errors.email);
                    const phoneUniqueErr = checkForUniqueErrors(err.errors.phone);
                    if ((emailUniqueErr || phoneUniqueErr) && !id) {
                        errorCb({
                            email: emailUniqueErr ? formData.email : undefined,
                            phone: phoneUniqueErr ? formData.phone : undefined
                        });
                    } else {
                        commonErrorCb(err);
                    }
                });
        },
        [commonErrorCb, createCustomer, dispatch, errorCb, successCb, updateCustomer]
    );
};

export default useCreateCustomer;
