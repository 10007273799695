import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import { IBaseParams, IPaginateResponse } from '../models/IPaginateResponse';
import { IProduct, IProductBrand, IProductCategory, IProductPayload } from '../models/IProduct';

interface IUpdateProductPayload extends IProductPayload {
    id: number;
}

const productsAPI = createApi({
    reducerPath: 'productAPI',
    tagTypes: ['Products', 'Brands', 'Categories'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchProducts: build.query<IPaginateResponse<IProduct[]>, IBaseParams>({
            query: ({ per_page = -1, page = 1, search = null, sort = null, order = null, trashed = null }) => ({
                url: `/products`,
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order,
                    trashed
                }
            }),
            providesTags: ['Products']
        }),

        fetchSingleProduct: build.query<IProduct, number>({
            query: (productId) => ({
                url: `/products/${productId}`,
                method: 'GET'
            }),
            providesTags: ['Products']
        }),

        getProduct: build.query<IProduct, number>({
            query: (id) => ({
                url: `/products/${id}`,
                method: 'GET'
            }),
            providesTags: ['Products']
        }),

        createProduct: build.mutation<unknown, IProductPayload>({
            query: (data) => ({
                url: `products/`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['Products']
        }),

        updateProduct: build.mutation<unknown, IUpdateProductPayload>({
            query: ({ id, ...data }) => ({
                url: `products/${id}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['Products']
        }),

        deleteProduct: build.mutation<unknown, number>({
            query: (id) => ({
                url: `products/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Products']
        }),

        fetchProductBrands: build.query<IPaginateResponse<IProductBrand[]>, null>({
            query: () => ({
                url: `/product-brands`,
                method: 'GET',
                params: {
                    per_page: -1,
                    page: 1
                }
            }),
            providesTags: ['Brands']
        }),

        createBrand: build.mutation<IProductBrand, string>({
            query: (name) => ({
                url: `product-brands/`,
                method: 'POST',
                data: { name }
            })
        }),

        updateBrand: build.mutation<IProductBrand, IProductBrand>({
            query: ({ id, name }) => ({
                url: `product-brands/${id}`,
                method: 'PUT',
                data: { name }
            }),
            invalidatesTags: ['Products']
        }),

        deleteBrand: build.mutation<unknown, number>({
            query: (id) => ({
                url: `product-brands/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Products']
        }),

        fetchProductCategories: build.query<IPaginateResponse<IProductCategory[]>, IBaseParams>({
            query: ({ per_page = -1, page = 1, search = null, sort = null, order = null, trashed = null }) => ({
                url: `/product-categories`,
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order,
                    trashed
                }
            }),
            providesTags: ['Categories']
        }),

        createCategory: build.mutation<IProductCategory, string>({
            query: (name) => ({
                url: `product-categories/`,
                method: 'POST',
                data: { name }
            })
        }),

        updateCategory: build.mutation<IProductCategory, IProductCategory>({
            query: ({ id, name }) => ({
                url: `product-categories/${id}`,
                method: 'PUT',
                data: { name }
            }),
            invalidatesTags: ['Products']
        }),

        deleteCategory: build.mutation<unknown, number>({
            query: (id) => ({
                url: `product-categories/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Products']
        })
    })
});

export default productsAPI;
