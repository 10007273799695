import { FC, PropsWithChildren, useMemo } from 'react';
import { Box, Drawer, useMediaQuery, useTheme } from '@mui/material';
import useNavigationMode from '../../layout/use-navigation-mode';
import RouteHeader from './components/RouteHeader';

type FixedRoutePropsType = PropsWithChildren<{
    label: string;
    backLink: string;
    containerId?: string;
    hideTabletRouteHeader?: boolean;
}>;

const FixedRoute: FC<FixedRoutePropsType> = ({ children, hideTabletRouteHeader, ...rest }) => {
    const navMode = useNavigationMode();
    const theme = useTheme();

    const sidebarNav = useMemo(() => navMode === 'sidebar', [navMode]);
    const bottomMenuVisible = useMediaQuery(theme.breakpoints.down('mobile'));

    if (bottomMenuVisible) {
        return (
            <Drawer open anchor="right">
                <Box
                    sx={{
                        width: '100vw',
                        height: '100vh',
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <RouteHeader {...rest} sticky />
                    <Box sx={{ px: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>{children}</Box>
                </Box>
            </Drawer>
        );
    }

    if (sidebarNav && !hideTabletRouteHeader) {
        return (
            <>
                <RouteHeader {...rest} />
                {children}
            </>
        );
    }

    return <>{children}</>;
};

export default FixedRoute;
