import { FC, useCallback } from 'react';
import CommonBottomDrawer from '../../../../ui-component/common-bottom-drawer';
import { ICompany, IWidgetLinkBuilder } from '../../../../models/ICompany';
import { useFormik } from 'formik';
import StickyFormFooter from '../../../../ui-component/form/sticky-form-footer';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from '@mui/material';
import { getNewMultiServicesValue } from '../../../../utils/services';
import { widgetLinkSchema } from '../../schema';

type WidgetLinkFormProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (formData: IWidgetLinkBuilder) => void;
    defaults?: IWidgetLinkBuilder | null;
    company: Pick<ICompany, 'locations' | 'employees' | 'services'>;
};

const WidgetLinkForm: FC<WidgetLinkFormProps> = ({ open, onClose, onSubmit, defaults, company }) => {
    const { values, errors, touched, setFieldValue, handleSubmit, handleBlur } = useFormik<IWidgetLinkBuilder>({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema: widgetLinkSchema,
        initialValues: defaults ?? { location_id: null, selector: null, employee_id: null, service_ids: [] },
        onSubmit
    });

    const handleChangeServiceValue = useCallback(
        (event: SelectChangeEvent<number[]>) => {
            if (typeof event.target.value !== 'string') {
                const value = getNewMultiServicesValue(event.target.value, company.services ?? []);
                setFieldValue('service_ids', value);
            }
        },
        [company.services, setFieldValue]
    );

    return (
        <CommonBottomDrawer title="Edit Link" open={open} onClose={onClose}>
            <Stack component="form" spacing={2} id="link-form" onSubmit={handleSubmit}>
                <TextField
                    select
                    fullWidth
                    label="Location"
                    value={values.location_id ?? ''}
                    onChange={(event) => {
                        setFieldValue(`location_id`, event.target.value ? event.target.value : null);
                    }}
                >
                    <MenuItem value="">
                        <i>None</i>
                    </MenuItem>
                    {company.locations?.map((location) => (
                        <MenuItem key={location.id} value={location.id}>
                            {location.name}
                        </MenuItem>
                    ))}
                </TextField>

                <FormControl fullWidth variant="filled">
                    <InputLabel id="service_label">Services</InputLabel>
                    <Select
                        variant="filled"
                        labelId="service_label"
                        label="Service"
                        value={values.service_ids ?? []}
                        multiple
                        onChange={handleChangeServiceValue}
                    >
                        {company.services?.map((service) => (
                            <MenuItem key={service.id} value={service.id}>
                                {service.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <TextField
                    fullWidth
                    select
                    label="Staff"
                    value={values.employee_id ?? ''}
                    onChange={(event) => {
                        setFieldValue(`employee_id`, event.target.value ? event.target.value : null);
                    }}
                >
                    <MenuItem value="">
                        <i>None</i>
                    </MenuItem>
                    {company.employees?.map((employee) => (
                        <MenuItem key={employee.id} value={employee.id}>
                            {employee.user.title}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    fullWidth
                    label="Selector"
                    value={values.selector ?? ''}
                    id="selector"
                    name="selector"
                    onChange={(e) => setFieldValue(`selector`, e.target.value)}
                    onBlur={handleBlur}
                    error={!!errors.selector && touched.selector}
                    helperText={touched.selector ? errors.selector : undefined}
                />

                <StickyFormFooter>
                    <Button type="submit" variant="contained" color="primary" form="link-form">
                        Save
                    </Button>
                </StickyFormFooter>
            </Stack>
        </CommonBottomDrawer>
    );
};

export default WidgetLinkForm;
