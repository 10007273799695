import { FC, useCallback } from 'react';
import { FormHelperText, MenuItem, TextField, Switch, Autocomplete, Stack } from '@mui/material';
import { IWebhookSettingsInput } from '../types';
import * as Yup from 'yup';
import useExtendedFormik from '../../../hooks/useExtendedFormik';
import useServiceOptions from '../../../hooks/options/useServiceOptions';
import InputRow from '../../../ui-component/form/InputRow';

interface WebhookSettingsFormProps {
    defaults: IWebhookSettingsInput;
    onSave: (formData: IWebhookSettingsInput) => void;
    triggersData: Record<string, string>;
}

const schema = Yup.object().shape({
    name: Yup.string().trim().required().label('Name'),
    description: Yup.string().trim().nullable(),
    is_enabled: Yup.boolean().required(),
    events: Yup.object()
        .required()
        .test('events', 'At least one event must be selected', (v) => Object.values(v).some((val) => !!val)),
    settings: Yup.object().shape({
        url: Yup.string().trim().url().required().label('Settings Url'),
        method: Yup.string().trim().required().oneOf(['GET', 'POST']).label('Settings Method'),
        secret: Yup.string().trim().nullable()
    }),
    service_ids: Yup.array().of(Yup.number()).nullable()
});

const WebhookSettingsForm: FC<WebhookSettingsFormProps> = ({ defaults, onSave, triggersData }) => {
    const { values, errors, touched, handleBlur, handleChange, setFieldValue, handleSubmit } = useExtendedFormik<IWebhookSettingsInput>({
        enableReinitialize: true,
        initialValues: defaults,
        onSubmit: onSave,
        validationSchema: schema
    });

    const { services, getNameById, isLoading } = useServiceOptions();

    const onServiceChange = useCallback(
        (e: unknown, v: number[] | null) => {
            setFieldValue('service_ids', v ?? []);
        },
        [setFieldValue]
    );

    return (
        <Stack spacing={3} id="webhook-form" component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
                label="Name"
                fullWidth
                id="name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.name && !!errors.name}
                helperText={touched.name ? errors.name : undefined}
            />

            <TextField
                label="Description"
                fullWidth
                id="description"
                name="description"
                value={values.description ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                minRows={4}
                maxRows={4}
                error={!!touched.description && !!errors.description}
                helperText={touched.description ? errors.description : undefined}
            />

            <InputRow label="Enabled">
                <Switch
                    aria-label="Enabled"
                    checked={values.is_enabled}
                    name="is_enabled"
                    onChange={(_, checked) => {
                        setFieldValue('is_enabled', checked);
                    }}
                />
            </InputRow>

            {triggersData &&
                Object.entries(triggersData).map(([key, value]) => (
                    <InputRow label={value} key={key}>
                        <Switch
                            checked={Boolean(values.events[key])}
                            name={`events.${key}`}
                            onChange={(_, checked) => {
                                setFieldValue(`events.${key}`, checked);
                            }}
                        />
                    </InputRow>
                ))}
            {!!errors.events && touched.events ? <FormHelperText error>{errors.events}</FormHelperText> : null}

            <TextField
                label="URL"
                fullWidth
                id="settings.url"
                name="settings.url"
                value={values.settings.url ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.settings?.url && !!errors.settings?.url}
                helperText={touched.settings?.url ? errors.settings?.url : undefined}
            />

            <TextField
                label="Method"
                fullWidth
                id="settings.method"
                name="settings.method"
                value={values.settings.method}
                onChange={(e) => setFieldValue('settings.method', e.target.value)}
                onBlur={handleBlur}
                select
                error={!!touched.settings?.method && !!errors.settings?.method}
                helperText={touched.settings?.method ? errors.settings?.method : undefined}
            >
                <MenuItem value="POST">POST</MenuItem>
                <MenuItem value="GET">GET</MenuItem>
            </TextField>

            <TextField
                label="Secret"
                fullWidth
                id="settings.secret"
                name="settings.secret"
                value={values.settings.secret}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.settings?.secret && !!errors.settings?.secret}
                helperText={touched.settings?.secret ? errors.settings?.secret : undefined}
            />

            <Autocomplete
                multiple
                options={services.map(({ id }) => id)}
                renderInput={(props) => (
                    <TextField {...props} label="Services" name="service_ids" placeholder={isLoading ? 'Loading' : undefined} />
                )}
                fullWidth
                value={isLoading ? [] : values.service_ids ?? []}
                getOptionLabel={(option) => getNameById(option) ?? ''}
                onChange={onServiceChange}
                loading={isLoading}
                disabled={isLoading}
            />
        </Stack>
    );
};

export default WebhookSettingsForm;
