import { useAppDispatch } from '../../../../hooks/redux';
import { useCallback, useState } from 'react';
import { alpha, ButtonBase, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { AppointmentStatuses } from '../../../../models/IAppointment';
import { styled } from '@mui/material/styles';
import { setSelectedEventStatus } from '../../../../store/slices/calendarSlice';
import useDialogFunctions from '../../../../hooks/useDialogFunctions';
import CancellationReasonSelect from './elements/CancellationReasonSelect';
import { CancellationReason } from '../../../../views/calendar/types';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import useGetColorByStatus from '../../../../hooks/useGetColorByStatus';
import { startSubmitting, stopSubmitting } from '../../../../store/slices/SubmittingSlice';
import RebookReminderSelect from './elements/RebookReminderSelect';
import appointmentAPI from '../../../../services/AppointmentService';
import appointmentStatuses from '../../../../constants/appointment-statuses';

const StyledMenuItem = styled(MenuItem)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '5px',

    '& .MuiListItemIcon-root': {
        minWidth: 'auto',

        '& .MuiSvgIcon-root': {
            fontSize: '22px'
        }
    }
}));

interface StatusSelectProps {
    appointmentId: number | string;
    value: AppointmentStatuses;
    onChange: (v: AppointmentStatuses) => void;
    disabled?: boolean;
    useRebookReminder?: boolean;
}

const StatusSelect = ({ appointmentId, value, onChange, disabled, useRebookReminder }: StatusSelectProps) => {
    const [setStatus] = appointmentAPI.useSetAppointmentStatusMutation();
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const { getColorByStatus } = useGetColorByStatus();

    const { isDialogOpen: cancelReasonOpen, openDialog: openCancelReason, closeDialog: closeCancelReason } = useDialogFunctions();
    const { isDialogOpen: reminderOpen, openDialog: openReminder, closeDialog: closeReminder } = useDialogFunctions();

    const statuses = appointmentStatuses;

    const updateStatus = useCallback(
        (newStatus: AppointmentStatuses, reason?: CancellationReason, remind_after_interval?: string) => {
            dispatch(startSubmitting());
            const data = {
                status: newStatus,
                cancel_reason: reason,
                remind_after_interval
            };

            setStatus({ appointmentId, data })
                .unwrap()
                .then(() => {
                    onChange(newStatus);
                    dispatch(setSelectedEventStatus(newStatus));
                    dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
                })
                .catch((err) => {
                    showSnackbar({
                        message: err.message,
                        alertSeverity: SnackBarTypes.Error
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [dispatch, setStatus, appointmentId, onChange, showSnackbar]
    );

    const handleChange = (newStatus: AppointmentStatuses) => {
        setAnchor(null);
        if (newStatus === AppointmentStatuses.Canceled) {
            openCancelReason();
            return;
        }

        if (newStatus === AppointmentStatuses.Completed && useRebookReminder) {
            openReminder();
            return;
        }

        updateStatus(newStatus);
    };

    const setReminder = useCallback(
        (remind_after_interval: string | undefined) => {
            updateStatus(AppointmentStatuses.Completed, undefined, remind_after_interval);
            closeReminder();
        },
        [closeReminder, updateStatus]
    );

    return appointmentId ? (
        <>
            <ButtonBase
                disabled={disabled}
                sx={{
                    paddingX: 1,
                    paddingY: 0.5,
                    fontWeight: 600,
                    borderRadius: '8px',
                    fontSize: '0.75rem',
                    lineHeight: '1rem',
                    color: getColorByStatus(value),
                    backgroundColor: alpha(getColorByStatus(value), 0.15)
                }}
                onClick={(e) => setAnchor(e.currentTarget)}
            >
                {statuses.find((s) => s.value === value)?.label ?? value}
            </ButtonBase>
            {anchor && (
                <Menu open anchorEl={anchor} onClose={() => setAnchor(null)}>
                    {statuses.map((s) => (
                        <StyledMenuItem
                            key={`status-${s.value}`}
                            value={s.value}
                            onClick={() => {
                                handleChange(s.value);
                            }}
                        >
                            <ListItemIcon sx={{ color: getColorByStatus(s.value) }}>{s.icon}</ListItemIcon>
                            <ListItemText>
                                <span
                                    style={{
                                        color: getColorByStatus(s.value),
                                        fontWeight: 'bold',
                                        fill: 'currentColor',
                                        WebkitTextFillColor: 'currentColor'
                                    }}
                                >
                                    {s.label}
                                </span>
                            </ListItemText>
                        </StyledMenuItem>
                    ))}
                </Menu>
            )}

            <CancellationReasonSelect isOpen={cancelReasonOpen} close={closeCancelReason} updateStatus={updateStatus} />
            <RebookReminderSelect isOpen={reminderOpen} action={setReminder} />
        </>
    ) : null;
};

export default StatusSelect;
