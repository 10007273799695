import { FC, useCallback, useMemo } from 'react';
import { Box, ButtonBase, Stack } from '@mui/material';
import { colors } from '../../../store/constant';
import Alpha from '@uiw/react-color-alpha';
import { hsvaToRgba, RgbaColor, hexToRgba, hexToHsva } from '@uiw/color-convert';
import { alphaPickerSx } from '../styles';
import PickerNumberInput from './PickerNumberInput';
import { ColorFormProps } from '../types';

const PredefinedColorsTab: FC<ColorFormProps> = ({ color, onChange, disableAlpha }) => {
    const rgba = useMemo<RgbaColor>(() => hsvaToRgba(color), [color]);

    const selectedColor = useMemo(
        () =>
            Object.values(colors).find((c) => {
                const hexRgba = hexToRgba(c.value);
                return (['r', 'g', 'b'] as const).every((col) => rgba[col] === hexRgba[col]);
            }),
        [rgba]
    );

    const handleSelectSwatch = useCallback(
        (hex: string) => {
            const { a } = color;
            const hsv = hexToHsva(hex);

            onChange({ ...hsv, a });
        },
        [color, onChange]
    );

    const isSelected = useCallback(
        (hex) => {
            const hexHsva = hexToHsva(hex);
            return (['h', 's', 'v'] as const).every((field) => color[field] === hexHsva[field]);
        },
        [color]
    );

    const handleAlphaChange = useCallback(
        (v: number) => {
            const rounded = parseFloat(v.toFixed(2));
            onChange({ ...color, a: rounded });
        },
        [color, onChange]
    );

    return (
        <Stack spacing={1}>
            <Box
                sx={{
                    backgroundColor: 'grey.50',
                    padding: 1,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(4, 1fr)',
                    gridGap: '8px'
                }}
            >
                {Object.values(colors).map((c) => (
                    <ButtonBase
                        key={c}
                        onClick={() => handleSelectSwatch(c.value)}
                        sx={{
                            padding: '2px',
                            border: '1px solid',
                            borderRadius: '4px',
                            borderColor: isSelected(c.value) ? 'newColors.darkBlue' : 'transparent'
                        }}
                    >
                        <Box sx={{ backgroundColor: c.value, width: '32px', height: '32px', borderRadius: '2px' }} />
                    </ButtonBase>
                ))}
            </Box>

            <Box
                sx={{
                    color: 'grey.800',
                    opacity: 0.6,
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    textAlign: 'center'
                }}
            >
                <Box>{selectedColor?.label ?? 'Unknown'}</Box>
                <Box>{`R=${rgba.r} G=${rgba.b} B=${rgba.b}`}</Box>
            </Box>

            {!disableAlpha ? (
                <Stack direction="row" spacing={2} alignItems="center">
                    <Box
                        sx={{
                            width: '134px',
                            flexGrow: 0,
                            flexShrink: 0,
                            '& > .w-color-alpha': {
                                ...alphaPickerSx,
                                marginBottom: '0 !important',

                                '& > .w-color-interactive > div': {
                                    top: '-3px',

                                    '& > div': {
                                        border: '1px solid #fff',
                                        '--alpha-pointer-background-color': `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`
                                    }
                                }
                            }
                        }}
                    >
                        <Alpha width={134} hsva={color} onChange={({ a }) => handleAlphaChange(a)} />
                    </Box>
                    <Box sx={{ width: '42px', flexGrow: 0, flexShrink: 0 }}>
                        <PickerNumberInput
                            value={color.a * 100}
                            onChange={(v) => onChange({ ...color, a: v / 100 })}
                            suffix="%"
                            maxValue={100}
                        />
                    </Box>
                </Stack>
            ) : null}
        </Stack>
    );
};

export default PredefinedColorsTab;
