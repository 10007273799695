import { useMemo } from 'react';
import { IAppointment } from '../../models/IAppointment';
import { IOrderedPayment, IPayment } from '../../models/IPayment';

const usePayments = (selectedEvent?: IAppointment | null) => {
    const rawPayments = useMemo<IPayment[]>(() => selectedEvent?.payments ?? [], [selectedEvent]);
    const orderedPayments = useMemo<IOrderedPayment[]>(
        () =>
            rawPayments.map((payment, index) => ({
                ...payment,
                originalIndex: index
            })),
        [rawPayments]
    );

    const coupon = useMemo<IOrderedPayment | undefined | null>(() => orderedPayments.find((p) => p.reason === 'coupon'), [orderedPayments]);
    const payments = useMemo<IOrderedPayment[]>(() => orderedPayments.filter(({ reason }) => reason !== 'coupon'), [orderedPayments]);

    return { coupon, payments };
};

export default usePayments;
