import { FC, FocusEvent, useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';

const FocusTextField: FC<TextFieldProps> = ({ onFocus, ...rest }) => {
    const handleFocus = useCallback(
        (e: FocusEvent<HTMLInputElement>) => {
            e.target.select();
            onFocus?.(e);
        },
        [onFocus]
    );

    return <TextField {...rest} onFocus={handleFocus} />;
};

export default FocusTextField;
