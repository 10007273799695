import * as Yup from 'yup';

export const widgetLinkSchema = Yup.object().shape({
    selector: Yup.string()
        .trim()
        .nullable()
        .notRequired()
        .test('selector', 'This field must be valid CSS single class or id selector', (val) => {
            if (val) {
                return /^(\.|#)[^\s.#]+$/.test(val);
            }
            return true;
        })
});

export const widgetSchema = Yup.object().shape({
    link_builder: Yup.array().of(widgetLinkSchema),
    slots: Yup.object().shape({
        use_fixed_size: Yup.boolean(),
        size: Yup.number().when('use_fixed_size', {
            is: true,
            then: Yup.number().nullable().required().label('Slot Size'),
            otherwise: Yup.number().nullable().notRequired()
        })
    })
});
