import { FC, useCallback, useState } from 'react';
import { ServiceOption, ServiceOptionPivot } from '../../../../models/IService';
import { Box, Button } from '@mui/material';
import PermanentEntityDrawer from '../../../PermanentEntityDrawer';
import EntityDrawerContent from '../../../entity-drawer-layout/EntityDrawerContent';
import EntityDrawerActions from '../../../entity-drawer-layout/EntityDrawerActions';
import AppointmentServiceOptionsForm from './AppointmentServiceOptionsForm';
import NumberFormat from 'react-number-format';

type AppointmentServiceOptionsProps = {
    serviceOptions: ServiceOption[];
    value: Array<ServiceOption & { pivot: ServiceOptionPivot }>;
    onChange: (v: Array<ServiceOption & { pivot: ServiceOptionPivot }>) => void;
    disabled?: boolean;
};

const AppointmentServiceOptions: FC<AppointmentServiceOptionsProps> = ({ serviceOptions, value, onChange, disabled }) => {
    const [open, setOpen] = useState(false);

    const handleSubmit = useCallback(
        (v: Array<ServiceOption & { pivot: ServiceOptionPivot }>) => {
            onChange(v);
            setOpen(false);
        },
        [onChange]
    );

    if (!serviceOptions.length && !value.length) {
        return null;
    }

    return (
        <Box>
            {value.map((opt) => (
                <Box mb={1} key={opt.id}>
                    <Box component="span">{opt.name}</Box>
                    {', '}
                    <NumberFormat
                        value={opt.pivot.duration}
                        prefix="+"
                        suffix=" min"
                        decimalScale={0}
                        fixedDecimalScale
                        displayType="text"
                    />
                    {', '}
                    <NumberFormat value={opt.pivot.price} prefix="+$" decimalScale={2} fixedDecimalScale displayType="text" />
                </Box>
            ))}
            {!disabled ? (
                <>
                    <Button variant="contained" color="primary" size="small" onClick={() => setOpen(true)}>
                        Edit Options
                    </Button>
                    <PermanentEntityDrawer title="Edit Service Options" open={open} onClose={() => setOpen(false)}>
                        <EntityDrawerContent>
                            {open && <AppointmentServiceOptionsForm options={serviceOptions} defaults={value} onSubmit={handleSubmit} />}
                        </EntityDrawerContent>
                        <EntityDrawerActions>
                            <Button variant="contained" color="primary" type="submit" form="AppointmentServiceOptionsForm">
                                Save
                            </Button>
                        </EntityDrawerActions>
                    </PermanentEntityDrawer>
                </>
            ) : null}
        </Box>
    );
};

export default AppointmentServiceOptions;
