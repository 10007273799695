import CommonHeaderBtn from '../../../../ui-component/CommonHeaderBtn';
import { useState } from 'react';
import SearchFilter from '../../../../ui-component/search-filter/SearchFilter';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import { Box, CircularProgress, IconButton, TextField } from '@mui/material';

const HeaderSearch = () => {
    const [open, setOpen] = useState(false);
    return (
        <Box>
            <CommonHeaderBtn onClick={() => setOpen(true)}>
                <SearchIcon />
            </CommonHeaderBtn>
            {open ? (
                <Box
                    sx={{
                        position: 'absolute',
                        backgroundColor: 'grey.50',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        zIndex: 1,
                        px: '60px',
                        color: 'grey.800'
                    }}
                >
                    <Box sx={{ flexGrow: 1 }}>
                        <SearchFilter
                            fullWidth
                            customRenderInput={(params, props) => (
                                <TextField
                                    {...params}
                                    value={props.value}
                                    onChange={props.onChange}
                                    placeholder="Search"
                                    hiddenLabel
                                    fullWidth
                                    InputProps={{
                                        ...params.InputProps,
                                        disableUnderline: true,
                                        sx: {
                                            fontSize: '24px',
                                            lineHeight: '28px',
                                            fontWeight: 400,
                                            color: 'grey.800',
                                            background: 'transparent !important',

                                            '& input': {
                                                padding: '0 !important'
                                            },

                                            '& input::placeholder': {
                                                fontSize: '24px',
                                                lineHeight: '28px',
                                                fontWeight: 400,
                                                color: 'grey.300',
                                                opacity: 1
                                            }
                                        },
                                        endAdornment: (
                                            <>
                                                {props.loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        )
                                    }}
                                />
                            )}
                        />
                    </Box>
                    <IconButton color="inherit" onClick={() => setOpen(false)}>
                        <ClearIcon />
                    </IconButton>
                </Box>
            ) : null}
        </Box>
    );
};

export default HeaderSearch;
