import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const useRwgTokenOps = () => {
    const [params] = useSearchParams();

    const token = useMemo(() => params.get('rwg_token'), [params]);

    const saveToken = useCallback(
        (locationId: number) => {
            if (token) {
                const { hostname, pathname } = window.location;
                document.cookie = `_rwg_token=${token};_merchant_id=appointments-merchant-${locationId};max-age=2592000;domain=${hostname};path=${pathname}`;
                document.cookie = `_merchant_id=appointments-merchant-${locationId};max-age=2592000;domain=${hostname};path=${pathname}`;
            }
        },
        [token]
    );

    const sendAnalytics = useCallback(() => {
        const url = process.env.REACT_APP_GAC_CONVERSION_ENDPOINT;
        const conversion_partner_id = process.env.REACT_APP_GAC_PARTNER_ID;
        if (token && url && conversion_partner_id) {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify({
                    conversion_partner_id,
                    rwg_token: token,
                    merchant_changed: 2
                })
            }).catch((err) => console.error(err));
        }
    }, [token]);

    return { token, saveToken, sendAnalytics };
};

export default useRwgTokenOps;
