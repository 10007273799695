import { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';

type PaymentSummaryRowProps = PropsWithChildren<{
    label?: string;
    sx?: SxProps;
    isSelected?: boolean;
}>;

const PaymentSummaryRow: FC<PaymentSummaryRowProps> = ({ label, children, sx = {}, isSelected }) => (
    <Box
        px={2}
        py={0.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
            borderTop: '1px solid',
            borderColor: isSelected ? 'grey.300' : 'grey.200',
            minHeight: isSelected ? '64px' : '48px',
            backgroundColor: isSelected ? 'rgba(241, 243, 249, 0.3)' : undefined,
            fontWeight: 600,
            color: 'grey.800',
            ...sx
        }}
    >
        {label ? (
            <Typography variant="subtitle1" sx={{ fontWeight: isSelected ? 600 : 400 }}>
                {label}
            </Typography>
        ) : null}
        {children}
    </Box>
);

export default PaymentSummaryRow;
