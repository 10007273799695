import appointmentAPI from '../../../../../../services/AppointmentService';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useAppSelector } from '../../../../../../hooks/redux';
import { Box, CircularProgress } from '@mui/material';
import useEntityDrawerSpacing from '../../../../../entity-drawer-layout/use-entity-drawer-spacing';
import SectionHeading from '../../../../../SectionHeading';
import AppointmentHistory from '../../../../../appointment-history';

const Activity = () => {
    const { isMobile, spacingX } = useEntityDrawerSpacing();
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const { data, isLoading } = appointmentAPI.useGetAppointmentHistoryQuery(selectedEvent?.id ?? skipToken);

    return (
        <Box px={spacingX}>
            <SectionHeading mb={3}>Activity</SectionHeading>
            <Box>
                {isLoading && (
                    <Box display="flex" alignItems="center" gap={1}>
                        <CircularProgress size={20} />
                        Loading...
                    </Box>
                )}

                {!isLoading && data ? (
                    <Box pl={isMobile ? 0 : 2}>
                        <AppointmentHistory data={data} isMobile={isMobile} />
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};
export default Activity;
