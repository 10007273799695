import { FC } from 'react';
import { Stack } from '@mui/material';
import useNewPaymentOptions from './use-new-payment-options';
import NewPaymentFormOption from './NewPaymentFormOption';

const OtherPaymentMethods: FC<{ onSelect: (v: string) => void }> = ({ onSelect }) => {
    const { otherMethods, getIcon } = useNewPaymentOptions();
    return (
        <Stack spacing={2}>
            {otherMethods.map((method) => (
                <NewPaymentFormOption
                    key={method}
                    icon={getIcon(method)}
                    onClick={() => {
                        onSelect(method);
                    }}
                >
                    {method === 'other' ? 'Other' : null}
                </NewPaymentFormOption>
            ))}
        </Stack>
    );
};

export default OtherPaymentMethods;
