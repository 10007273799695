import { FC, useCallback } from 'react';
import { ICustomerNote, ICustomerNoteCreatePayload } from '../../../../models/ICustomer';
import { Stack, Switch, TextField } from '@mui/material';
import InputRow from '../../../../ui-component/form/InputRow';
import CbImageUpload from '../../../../ui-component/form/cb-image-upload';
import useImagesManagement from '../../../../hooks/use-images-management';
import { useFormik } from 'formik';
import { asyncFilesDeleteAndUpload } from '../../../../utils/functions/uploading-images-helpers';
import UpdatedStyleWrapper from '../../../../ui-component/updated-style-wrapper';
import { useAppDispatch } from '../../../../hooks/redux';
import { startSubmitting } from '../../../../store/slices/SubmittingSlice';
import * as Yup from 'yup';

type CustomerNoteFormType = Omit<ICustomerNoteCreatePayload, 'images'>;
type CustomerNoteFormProps = {
    onSubmit: (formData: ICustomerNoteCreatePayload) => void;
    initialValues?: Pick<ICustomerNote, 'text' | 'images' | 'is_pinned'>;
};

const DEFAULT: Pick<ICustomerNote, 'text' | 'images' | 'is_pinned'> = {
    text: '',
    is_pinned: false,
    images: []
};

const CustomerNoteForm: FC<CustomerNoteFormProps> = ({ onSubmit, initialValues = DEFAULT }) => {
    const dispatch = useAppDispatch();
    const { images, ...rest } = initialValues;
    const { deletedImages, visibleImages, onAddNewImage, onDeleteImage, imagesToUpload } = useImagesManagement(
        images && images.length ? images : undefined
    );

    const onSubmitForm = useCallback(
        (formData: CustomerNoteFormType) => {
            dispatch(startSubmitting());
            asyncFilesDeleteAndUpload(imagesToUpload, deletedImages)
                .then((res) => {
                    const urls = [...res.map(({ link }) => link)];
                    const data = { ...formData, images: urls };

                    onSubmit(data);
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {});
        },
        [deletedImages, dispatch, imagesToUpload, onSubmit]
    );

    const { values, handleSubmit, setFieldValue, handleChange, handleBlur, touched, errors } = useFormik<CustomerNoteFormType>({
        onSubmit: onSubmitForm,
        initialValues: rest,
        validationSchema: Yup.object().shape({
            text: !visibleImages.length
                ? Yup.string().trim().required('Images or text is required')
                : Yup.string().trim().nullable().notRequired()
        })
    });

    const handleDrop = useCallback(
        (files: File[]) => {
            files.forEach((file) => {
                onAddNewImage(file);
            });
        },
        [onAddNewImage]
    );

    return (
        <UpdatedStyleWrapper>
            <Stack id="customer-note-form" component="form" spacing={2} onSubmit={handleSubmit}>
                <InputRow label="Pinned">
                    <Switch
                        value={values.is_pinned}
                        checked={values.is_pinned}
                        onChange={(_, checked) => setFieldValue('is_pinned', checked)}
                    />
                </InputRow>

                <TextField
                    id="text"
                    name="text"
                    label="Note text"
                    value={values.text}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    multiline
                    minRows={4}
                    error={touched.text && !!errors.text}
                    helperText={touched.text ? errors.text : undefined}
                />

                <CbImageUpload value={visibleImages} onDelete={onDeleteImage} onDrop={handleDrop} maxFiles={5} />
            </Stack>
        </UpdatedStyleWrapper>
    );
};

export default CustomerNoteForm;
