import CBModal from '../../../../cb-modal/CBModal';
import { FC, memo, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import useFreeDateInput from '../../../../../hooks/useFreeDateInput';
import DateAutocomplete from '../../../../DateAutocomplete';

interface IRebookReminderSelectProps {
    isOpen: boolean;
    action: (v?: string) => void;
}

const DEFAULT_VALUE = '1 week';

const RebookReminderSelect: FC<IRebookReminderSelectProps> = ({ isOpen, action }) => {
    const [value, setValue] = useState(DEFAULT_VALUE);
    const { suggestion } = useFreeDateInput(value);

    const handleSetReminder = useCallback(() => {
        action(value);
    }, [action, value]);

    return (
        <CBModal
            open={isOpen}
            title="Set Appointment Reminder"
            closeButtonText="Skip"
            okButtonText="Set Reminder"
            onClose={() => action(undefined)}
            onClickOk={handleSetReminder}
            okButtonDisabled={!suggestion.length}
            maxWidth="xs"
            styleOverrides={{
                '& .MuiDialogActions-root': {
                    '& > .MuiButton-root': {
                        width: '50% !important',
                        maxWidth: '50% !important'
                    }
                }
            }}
        >
            <Box sx={{ maxWidth: '300px', margin: '0 auto' }}>
                <Typography sx={{ textAlign: 'center', fontSize: '18px', marginBottom: '0.75em' }}>
                    Remind the client to schedule another appointment in:
                </Typography>
                <DateAutocomplete value={value} onChange={setValue} suggestion={suggestion} />
            </Box>
        </CBModal>
    );
};

export default memo(RebookReminderSelect);
