import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import DateAutocomplete from '../../DateAutocomplete';
import { getSuggestion, getValueInMinutes, parseStringToDate } from '../../../hooks/useFreeDateInput';
import { TextFieldProps } from '@mui/material';

type DurationInputProps = {
    value: number;
    onChange: (v: number) => void;
} & Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'disabled'>;

const getDefaultValueInMinutes = (mins: number) => {
    const parsedObj = parseStringToDate(`${mins} minutes`, true);
    return getSuggestion(parsedObj);
};

const DurationInput: FC<DurationInputProps> = ({ value, onChange, ...rest }) => {
    const [str, setStr] = useState(value ? getDefaultValueInMinutes(value) : '');

    const suggestion = useMemo(() => {
        const parsedObj = parseStringToDate(str, true);
        return getSuggestion(parsedObj);
    }, [str]);

    const handleSearchStringChange = useCallback(
        (v: string) => {
            setStr(v);
            const parsedObj = parseStringToDate(v, true);
            const valueInMinutes = getValueInMinutes(parsedObj);

            if (!!valueInMinutes && valueInMinutes !== value && v === suggestion) {
                onChange(valueInMinutes);
            }
        },
        [onChange, suggestion, value]
    );

    const handleBlur = useCallback(() => {
        if (str) {
            setStr(getDefaultValueInMinutes(value));
        } else {
            setStr('');
            onChange(0);
        }
    }, [onChange, str, value]);

    useEffect(() => {
        setStr(value ? getDefaultValueInMinutes(value) : '');
    }, [value]);

    return <DateAutocomplete value={str} onChange={handleSearchStringChange} suggestion={suggestion} onBlur={handleBlur} {...rest} />;
};

export default DurationInput;
