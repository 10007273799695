import NumberFormat from 'react-number-format';
import PickerInput from './PickerInput';
import { FC, useCallback } from 'react';

type PickerNumberInputProps = {
    value: number;
    onChange: (v: number) => void;
    suffix?: string;
    minValue?: number;
    maxValue?: number;
};

const PickerNumberInput: FC<PickerNumberInputProps> = ({ value, onChange, suffix, minValue = 0, maxValue }) => {
    const handleChange = useCallback(
        (v?: number) => {
            if (typeof v === 'number') {
                let newValue = v;

                if (maxValue && newValue > maxValue) {
                    newValue = maxValue;
                }

                if (newValue < minValue) {
                    newValue = minValue;
                }

                onChange(newValue);
            }
        },
        [maxValue, minValue, onChange]
    );

    return (
        <NumberFormat
            customInput={PickerInput}
            suffix={suffix}
            value={value}
            fixedDecimalScale
            decimalScale={0}
            allowNegative={minValue < 0}
            onValueChange={({ floatValue }) => handleChange(floatValue)}
            fullWidth
        />
    );
};

export default PickerNumberInput;
