import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { useAppSelector } from '../../../../../../../../../hooks/redux';
import ActionsCell from './elements/ActionsCell';
import { IOrderedPayment } from '../../../../../../../../../models/IPayment';
import NoPayments from './elements/NoPayments';
import PaymentAmount from './elements/PaymentAmount';
import PaymentListItem from './elements/PaymentListItem';
import { methodOptions, reasonOptions } from '../PaymentInfoSelectOptions';
import ListRenderer from '../../../../../../../../list-renderer';

interface PaymentsTableProps {
    payments: IOrderedPayment[];
    onClickDelete: (index: number) => void;
    onClickEdit: (index: number) => void;
}

const columns = [
    { id: 'date', label: 'Date' },
    { id: 'method', label: 'Method' },
    { id: 'type', label: 'Type' },
    { id: 'amount', label: 'Amount' },
    { id: 'actions', label: '' }
];

const PaymentsTable = ({ onClickDelete, onClickEdit, payments }: PaymentsTableProps) => {
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);

    if (!payments?.length) {
        return <NoPayments />;
    }

    return (
        <ListRenderer
            compact
            containerSx={{ maxHeight: '100%', maxWidth: '100%', overflowX: 'initial' }}
            rows={payments}
            columns={columns}
            renderRow={(payment) => (
                <TableRow key={`payment-${payment.originalIndex}`}>
                    <TableCell>
                        {moment(payment.datetime)
                            .tz(selectedEvent?.location.time_zone || 'UTC')
                            .format('MMM D, YYYY')}
                    </TableCell>
                    <TableCell>
                        {payment.reason === 'coupon' && payment.code
                            ? payment.code
                            : methodOptions.find((m) => m.value === payment.method)?.label}
                    </TableCell>
                    <TableCell>{reasonOptions.find((r) => r.value === payment.reason)?.label}</TableCell>
                    <TableCell>
                        <PaymentAmount payment={payment} />
                    </TableCell>
                    <TableCell align="right">
                        {!isForeignAppointment ? (
                            <ActionsCell
                                entity="Payment"
                                onDelete={() => onClickDelete(payment.originalIndex)}
                                onEdit={payment.reason !== 'coupon' ? () => onClickEdit(payment.originalIndex) : undefined}
                            />
                        ) : null}
                    </TableCell>
                </TableRow>
            )}
            renderItem={(payment) => (
                <PaymentListItem
                    key={payment.originalIndex}
                    payment={payment}
                    onDelete={() => onClickDelete(payment.originalIndex)}
                    onEdit={payment.reason !== 'coupon' ? () => onClickEdit(payment.originalIndex) : undefined}
                />
            )}
        />
    );
};

export default PaymentsTable;
