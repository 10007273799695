import { FC, useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { ColorFormProps } from '../types';
import PickerInput from './PickerInput';
import { hexToHsva, hsvaToHexa, validHex } from '@uiw/color-convert';

const HexColorInput: FC<ColorFormProps> = ({ color, onChange }) => {
    const [focused, setFocused] = useState(false);
    const [hexa, setHex] = useState(hsvaToHexa(color));

    const handleChange = useCallback(
        (v?: string) => {
            setHex(v ?? '');
            if (v && validHex(v)) {
                onChange(hexToHsva(v));
            }
        },
        [onChange]
    );

    useEffect(() => {
        if (!focused) {
            setHex(hsvaToHexa(color));
        }
    }, [focused, color]);

    return (
        <Box>
            <PickerInput
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                fullWidth
                value={hexa}
                onChange={(e) => handleChange(e.target.value)}
            />
        </Box>
    );
};

export default HexColorInput;
