import useAuth from '../../hooks/useAuth';
import companyAPI from '../../services/CompanyService';
import SettingsForm from './SettingsForm';
import LoadingSpinner from '../../ui-component/LoadingSpinner';
import ErrorCatcher from '../../ui-component/error-catcher';
import FixedRoute from '../../ui-component/fixed-route';

const Settings = () => {
    const { user } = useAuth();
    const { data, isFetching, isLoading, refetch } = companyAPI.useGetCompanyQuery(user?.currentCompany.id.toString() || '', {
        refetchOnMountOrArgChange: false
    });

    return (
        <FixedRoute label="Preferences" backLink="/settings">
            <ErrorCatcher wrappedComponentName="Settings">
                {(isFetching || isLoading) && <LoadingSpinner />}
                {!isFetching && !isLoading && data ? <SettingsForm company={data} saveCb={refetch} /> : null}
            </ErrorCatcher>
        </FixedRoute>
    );
};

export default Settings;
