import { useCallback, useMemo } from 'react';
import { Grid, TextField } from '@mui/material';
import MomentAdapter from '@mui/lab/AdapterMoment';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { Moment } from 'moment-timezone';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import moment from 'moment/moment';
import { isNaN, isNumber } from 'lodash';
import AppTimePicker from '../form/time-picker/AppTimePicker';
import DurationInput from '../appointment-form/elements/DurationInput';

const safeNumber = (num: unknown, fallback = 0): number => (isNumber(num) && !isNaN(num) ? num : fallback);

export type EventTimeSelectData = { start_at: string; end_at?: string; duration?: number };

type EventTimeSelectProps = {
    values: EventTimeSelectData;
    touched: Record<string, boolean | undefined>;
    errors: Record<string, string | undefined>;
    onChange: (v: EventTimeSelectData) => void;
    disabled?: boolean;
};

const EventTimeSelect = ({ values, touched, errors, disabled, onChange }: EventTimeSelectProps) => {
    const startMoment = useMemo(() => moment(values?.start_at), [values]);
    const endMoment = useMemo(
        () =>
            values?.end_at
                ? moment(values?.end_at)
                : moment(values?.start_at)
                      .clone()
                      .add(values?.duration ?? 0, 'minutes'),
        [values]
    );

    const setEndDate = useCallback(
        (endTime: Moment | null) => {
            if (endTime) {
                const endDate = moment(values?.end_at).set('h', endTime.hours()).set('m', endTime.minutes());
                let newDuration = moment.duration(endDate.diff(values.start_at)).asMinutes();
                // add 24 hours to get the time difference excluding calendar date
                if (newDuration < 0) newDuration += 1440;
                onChange({
                    start_at: values.start_at,
                    end_at: moment(endDate).toISOString(),
                    duration: Math.floor(newDuration)
                });
            }
        },
        [values, onChange]
    );

    const setStartDate = useCallback(
        (startDate: Moment | null) => {
            onChange({
                start_at: moment(startDate).toISOString(),
                end_at: moment(startDate).add(values?.duration, 'minutes').toISOString(),
                duration: values.duration
            });
        },
        [values, onChange]
    );

    const setStartTime = useCallback(
        (time: Moment | null) => {
            if (time) {
                setStartDate(moment(values?.start_at).set('h', time.hours()).set('m', time.minutes()));
            }
        },
        [setStartDate, values]
    );

    const handleChangeDuration = useCallback(
        (val) => {
            const time = parseInt(val ?? 0, 10);
            const minutes = safeNumber(time);
            onChange({
                duration: minutes,
                start_at: values.start_at,
                end_at: moment(values.start_at).add(minutes, 'minutes').toISOString()
            });
        },
        [onChange, values]
    );

    return (
        <Grid container item xs={12} rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={MomentAdapter}>
                    <MobileDatePicker<Moment>
                        disabled={disabled}
                        views={['day']}
                        mask="MMMM DD, YYYY"
                        inputFormat="MMM D, YYYY"
                        showTodayButton
                        disableHighlightToday
                        showToolbar={false}
                        value={values?.start_at || null}
                        onChange={(dateArg) => setStartDate(dateArg)}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                {...params}
                                label="Date"
                                InputProps={{
                                    endAdornment: <CalendarMonthOutlinedIcon />
                                }}
                                error={Boolean(touched?.start_at && errors.start_at)}
                                helperText={touched?.start_at ? errors?.start_at : null}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
                <AppTimePicker
                    disabled={disabled}
                    value={startMoment}
                    onChange={(pickerTime) => setStartTime(pickerTime)}
                    label="Start Time"
                />
            </Grid>
            <Grid item xs={6}>
                <AppTimePicker disabled={disabled} value={endMoment} onChange={(pickerTime) => setEndDate(pickerTime)} label="End Time" />
            </Grid>
            <Grid item xs={12}>
                <DurationInput
                    label="Duration"
                    value={values?.duration ?? 0}
                    onChange={(v) => handleChangeDuration(v)}
                    disabled={disabled}
                    error={Boolean(touched?.duration && errors.duration)}
                    helperText={touched?.duration ? errors?.duration : null}
                />
            </Grid>
        </Grid>
    );
};

export default EventTimeSelect;
