import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';

export type TooltipItem = {
    key: string;
    text: string;
};

const tooltipApi = createApi({
    reducerPath: 'tooltipAPI',
    tagTypes: ['Tooltips'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchTooltips: build.query<Array<TooltipItem>, Array<string> | undefined>({
            query: () => ({
                url: `/tooltips`,
                method: 'GET'
            }),
            providesTags: () => ['Tooltips']
        })
    })
});

export default tooltipApi;
