import { FC, useCallback, useMemo, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { LedgerBalancePayload } from '../../../services/LocationService';
import FocusTextField from '../../FocusTextField';

type LedgerBalanceProps = {
    balance?: number;
    isBusy?: boolean;
    onSubmit: (data: LedgerBalancePayload) => void;
};

const LedgerBalance: FC<LedgerBalanceProps> = ({ balance, isBusy, onSubmit }) => {
    const [editing, setEditing] = useState(false);
    const fieldRef = useRef<HTMLInputElement | null>(null);
    const defaults = useMemo(() => ({ new_balance: balance ?? 0, old_balance: balance ?? 0 }), [balance]);

    const handleFormSubmit = useCallback(
        (data: LedgerBalancePayload) => {
            setEditing(false);
            fieldRef?.current?.blur();
            if (data.new_balance !== data.old_balance) {
                onSubmit(data);
            }
        },
        [onSubmit]
    );

    const handleEdit = useCallback(() => {
        setEditing(true);
        fieldRef?.current?.focus();
    }, []);

    const { handleSubmit, values, setFieldValue, handleBlur, handleReset } = useFormik<LedgerBalancePayload>({
        enableReinitialize: true,
        initialValues: { new_balance: balance ?? 0, old_balance: balance ?? 0 },
        onSubmit: handleFormSubmit
    });

    const handleFormReset = useCallback(() => {
        setEditing(false);
        handleReset(defaults);
        fieldRef?.current?.blur();
    }, [defaults, handleReset]);

    const disabled = isBusy || !editing;

    return (
        <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} sx={{ position: 'relative' }}>
            {disabled && (
                <Box
                    sx={{
                        position: 'absolute',
                        left: 0,
                        top: -8,
                        width: '100%',
                        height: 'calc(100% + 8px)',
                        zIndex: 1
                    }}
                />
            )}
            <NumberFormat
                aria-label="Balance"
                value={isBusy ? '' : values.new_balance}
                onValueChange={({ floatValue }, sourceInfo) => {
                    const setToUndefinedWithProps = floatValue === undefined && !sourceInfo.event;
                    if (!setToUndefinedWithProps) {
                        setFieldValue('new_balance', floatValue ?? '');
                    }
                }}
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                customInput={FocusTextField}
                fullWidth
                onBlur={handleBlur}
                inputRef={fieldRef}
                variant="standard"
                InputProps={{
                    endAdornment: (
                        <Box sx={{ display: 'flex', position: 'relative', zIndex: 2 }}>
                            {!!isBusy && <CircularProgress size="1.5rem" />}
                            {!isBusy && !editing ? (
                                <IconButton size="small" color="primary" onClick={handleEdit}>
                                    <EditIcon />
                                </IconButton>
                            ) : null}
                            {!isBusy && editing ? (
                                <>
                                    <IconButton color="primary" type="submit">
                                        <CheckCircleIcon />
                                    </IconButton>
                                    <IconButton color="error" onClick={handleFormReset}>
                                        <CancelIcon />
                                    </IconButton>
                                </>
                            ) : null}
                        </Box>
                    )
                }}
                sx={{
                    '& .MuiInputBase-root': {
                        fontSize: '1.5rem',
                        fontWeight: 600
                    }
                }}
            />
        </Box>
    );
};

export default LedgerBalance;
