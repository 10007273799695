import { FC, useCallback, useMemo, useState } from 'react';
import { Box, Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useAppSelector } from '../../../../../../../../../hooks/redux';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import { IPayment } from '../../../../../../../../../models/IPayment';
import NewPaymentFormStep from './NewPaymentFormStep';
import OtherPaymentMethods from './OtherPaymentMethods';
import validationSchema from './new-payment-schema';
import useNewPaymentOptions from './use-new-payment-options';
import NewPaymentFormOption from './NewPaymentFormOption';
import EntityDrawerContent from '../../../../../../../../entity-drawer-layout/EntityDrawerContent';
import FocusTextField from '../../../../../../../../FocusTextField';
import EntityDrawerActions from '../../../../../../../../entity-drawer-layout/EntityDrawerActions';

type PaymentFormStep = 'method' | 'method_other' | 'method_coupon' | 'reason' | 'amount';

type NewPaymentFormType = {
    datetime: string;
    method: string;
    reason: Array<string>;
    amount_service: number | null;
    amount_deposit: number | null;
    amount_gratuity: number | null;
    code?: string;
};

const NewPaymentForm: FC<{ onSubmit: (id: string, data: IPayment[]) => void; onCancel: () => void }> = ({ onSubmit, onCancel }) => {
    const { coreMethods, coreReasons, getLabel, getIcon } = useNewPaymentOptions();
    const { selectedEvent } = useAppSelector((state) => state.calendar);

    const [step, setStep] = useState<PaymentFormStep>('method');

    const formInitialValues = useMemo(
        () => ({
            datetime: moment()
                .tz(selectedEvent?.location.time_zone || 'utc')
                .toString(),
            method: '',
            reason: [],
            amount_service: null,
            amount_deposit: null,
            amount_gratuity: null,
            code: ''
        }),
        [selectedEvent]
    );

    const {
        values,
        setFieldValue,
        setFieldTouched,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        resetForm
    } = useFormik<NewPaymentFormType>({
        isInitialValid: true,
        initialValues: formInitialValues,
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema,
        onSubmit: (formData) => {
            const payments: IPayment[] = formData.reason.map((reason) => {
                const amountKey: `amount_${'service' | 'deposit' | 'gratuity'}` = `amount_${reason as 'service' | 'deposit' | 'gratuity'}`;
                return {
                    ...formData,
                    datetime: moment(formData.datetime).tz('UTC').toString(),
                    reason,
                    method: formData.method ? formData.method : undefined,
                    code: formData.code ? formData.code : undefined,
                    amount: formData[amountKey] ?? undefined
                };
            });

            onSubmit(String(selectedEvent?.id), payments);
        }
    });

    const handleMethodSelect = useCallback(
        (method: string) => {
            switch (method) {
                case 'other_methods': {
                    setStep('method_other');
                    break;
                }

                case 'discount_code': {
                    setFieldValue('reason', ['coupon']);
                    setStep('method_coupon');
                    break;
                }

                default: {
                    setFieldValue('method', method);
                    setStep('reason');
                }
            }
        },
        [setFieldValue]
    );

    const handleBackToFormStart = useCallback(() => {
        resetForm();
        setStep('method');
    }, [resetForm]);

    const handleReasonSelect = useCallback(
        (reason: string) => {
            const newVal = values.reason.includes(reason) ? [...values.reason].filter((r) => r !== reason) : [...values.reason, reason];
            setFieldValue('reason', newVal);
        },
        [setFieldValue, values.reason]
    );

    const total = useMemo(() => (values.amount_deposit ?? 0) + (values.amount_service ?? 0) + (values.amount_gratuity ?? 0), [
        values.amount_deposit,
        values.amount_gratuity,
        values.amount_service
    ]);

    return (
        <>
            <EntityDrawerContent>
                <form id="new-payment-form" onSubmit={handleSubmit} noValidate autoComplete="off">
                    {step === 'method' && (
                        <NewPaymentFormStep label="Select a Payment Method" handlePrev={onCancel}>
                            <Stack spacing={2}>
                                {coreMethods.map((method) => (
                                    <NewPaymentFormOption
                                        icon={getIcon(method)}
                                        key={method}
                                        onClick={() => {
                                            handleMethodSelect(method);
                                        }}
                                    >
                                        {getLabel(method)}
                                    </NewPaymentFormOption>
                                ))}
                            </Stack>
                        </NewPaymentFormStep>
                    )}

                    {step === 'method_other' && (
                        <NewPaymentFormStep label="Select a Payment Method" handlePrev={handleBackToFormStart}>
                            <Box>
                                <OtherPaymentMethods onSelect={handleMethodSelect} />
                            </Box>
                        </NewPaymentFormStep>
                    )}

                    {step === 'method_coupon' && (
                        <NewPaymentFormStep label="Enter Coupon Code" canSubmit={!!values.code} handlePrev={handleBackToFormStart}>
                            <Box>
                                <TextField
                                    fullWidth
                                    name="code"
                                    value={values.code}
                                    label="Coupon Code"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={Boolean(touched.code && errors.code)}
                                    helperText={touched.code ? errors.code : undefined}
                                />
                            </Box>
                        </NewPaymentFormStep>
                    )}

                    {step === 'reason' && (
                        <NewPaymentFormStep
                            label="Select a Payment Purpose"
                            nextBlocked={values.reason.length < 1}
                            handleNext={() => setStep('amount')}
                            handlePrev={handleBackToFormStart}
                        >
                            <Stack spacing={2}>
                                {coreReasons.map((reason) => (
                                    <NewPaymentFormOption
                                        key={reason}
                                        icon={getIcon(reason)}
                                        active={values.reason.includes(reason)}
                                        onClick={() => {
                                            handleReasonSelect(reason);
                                        }}
                                    >
                                        {getLabel(reason)}
                                    </NewPaymentFormOption>
                                ))}
                            </Stack>
                        </NewPaymentFormStep>
                    )}

                    {step === 'amount' && (
                        <NewPaymentFormStep
                            label="Enter a Payment Amount"
                            canSubmit
                            handleNext={() => {
                                handleSubmit();
                            }}
                            handlePrev={() => {
                                setFieldTouched('amount', false);
                                setFieldValue('amount', { service: null, deposit: null, gratuity: null });
                                setStep('reason');
                            }}
                        >
                            <Stack spacing={3}>
                                {[...coreReasons]
                                    .filter((reason) => values.reason.includes(reason))
                                    .map((res) => (
                                        <NumberFormat
                                            key={res}
                                            fullWidth
                                            customInput={FocusTextField}
                                            error={!!errors[`amount_${res}`] && !!touched[`amount_${res}`]}
                                            helperText={touched[`amount_${res}`] ? errors[`amount_${res}`] : undefined}
                                            id={`amount_${res}`}
                                            name={`amount_${res}`}
                                            value={values[`amount_${res}`] ?? ''}
                                            label={`${getLabel(res)} Amount`}
                                            prefix="$"
                                            decimalScale={2}
                                            fixedDecimalScale
                                            inputProps={{ step: '0.01', min: '0.01' }}
                                            onBlur={handleBlur}
                                            onValueChange={(e) => {
                                                setFieldValue(`amount_${res}`, e.floatValue);
                                            }}
                                        />
                                    ))}
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    spacing={1}
                                    pt={1.5}
                                    sx={{ borderTop: '1px solid', borderColor: 'grey.200' }}
                                >
                                    Total {getLabel(values.method)} Payments:
                                    <Box
                                        sx={{
                                            maxWidth: '150px'
                                        }}
                                    >
                                        <NumberFormat displayType="text" prefix="$" decimalScale={2} fixedDecimalScale value={total} />
                                    </Box>
                                </Stack>
                            </Stack>
                        </NewPaymentFormStep>
                    )}
                </form>
            </EntityDrawerContent>
            <EntityDrawerActions id="new-payment-form-actions" />
        </>
    );
};

export default NewPaymentForm;
