import { useCallback } from 'react';
import moment from 'moment/moment';
import { IService } from '../../models/IService';
import compareServices from '../../ui-component/appointment-form/utils/compare-services';
import { AppointmentFormType, AppointmentServiceRowType, IAppointment, IAppointmentPayload } from '../../models/IAppointment';

const getInitialServices = (services: IService[] | null): AppointmentServiceRowType[] => {
    if (services && services.length) {
        return services?.map((service) => ({
            service,
            price: service.pivot?.price ?? 0,
            prepay: service.pivot?.prepay || null,
            materials_amount: service.use_materials ? service.pivot?.materials?.amount ?? 0 : null,
            options: service.pivot?.options ?? []
        }));
    }

    return [{ service: undefined, price: null, prepay: null, materials_amount: null, options: [] }];
};

const useAppointmentFunctions = () => {
    const calculateDuration = useCallback((startDate?: string | null, endDate?: string | null) => {
        if (startDate && endDate) {
            const diff = Math.round(moment.duration(moment(endDate).diff(startDate)).asMinutes());
            return diff < 1 ? 0 : diff;
        }

        return 0;
    }, []);

    const formatAppointmentPayload = (appointment: IAppointment): IAppointmentPayload => ({
        employee_id: appointment.employee.id,
        location_id: appointment.location.id,
        service_ids: appointment.services.map((service) => ({
            id: service.id,
            price: service.pivot?.price,
            prepay: service.pivot?.prepay,
            options: service.pivot?.options ?? []
        })),
        customer_id: appointment.customer.id,
        start_at: appointment.start_at,
        end_at: moment(appointment.end_at).add(1, 'second').toISOString(),
        note: appointment.note,
        private_note: appointment.private_note,
        images: appointment.images.map((img) => img.link),
        payments: appointment.payments,
        deposit_request: appointment.deposit_request,
        address: appointment.address,
        type: appointment.type
    });

    const appointmentToFormData = useCallback(
        (appointment: IAppointment): AppointmentFormType => {
            const endAt = appointment?.end_at ? moment(appointment.end_at).add(1, 'second').toISOString() : null;

            return {
                ...appointment,
                services: getInitialServices(appointment.services),
                start_at: moment(appointment.start_at).toISOString(),
                duration: calculateDuration(appointment.start_at, endAt),
                end_at: endAt,
                use_reminder: Boolean(appointment.remind_at && appointment.remind_after_interval),
                remind_after_interval: appointment.remind_after_interval ?? ''
            };
        },
        [calculateDuration]
    );

    const formDataToAppointmentPayload = useCallback(
        (formData: AppointmentFormType): IAppointmentPayload => ({
            ...formData,
            service_ids: formData.services.map((item: AppointmentServiceRowType) => ({
                id: item.service?.id ?? 0,
                price: item.price,
                prepay: item.prepay,
                materials_amount: item.service?.use_materials ? item.materials_amount : undefined,
                options: item.options ?? []
            })),
            customer_id: formData?.customer?.id || 0,
            employee_id: formData?.employee?.id || 0,
            location_id: formData?.location?.id || 0,
            start_at: formData?.start_at || '',
            end_at: formData?.end_at ? moment(formData.end_at).subtract(1, 'second').toISOString() : '',
            deposit_request: formData.deposit_request ? formData.deposit_request : undefined,
            remind_after_interval: formData.use_reminder ? formData.remind_after_interval : null,
            remind_at: formData.use_reminder ? formData.remind_at : null,
            images: formData.images.map(({ link }) => link)
        }),
        []
    );

    const isAppointmentFormChanged = useCallback((values: AppointmentFormType, data: AppointmentFormType) => {
        const hasProviderChanged = values.employee?.id !== data.employee?.id;
        const hasChangedCustomer = values.customer?.id !== data.customer?.id;
        const dateFields = ['start_at', 'end_at', 'duration'] as const;
        const hasDatesChanged = dateFields.some((field) => values[field] !== data[field]);
        const hasNotificationsChanged = values.is_notifications_enabled !== data.is_notifications_enabled;
        const useReminderChanged = values.use_reminder !== data.use_reminder;
        const reminderIntervalChanged = values.remind_after_interval !== data.remind_after_interval;
        const hasNoteChanged = (values.note ?? '').trim() !== (data.note ?? '').trim();
        const hasPrivateNoteChanged = (values.private_note ?? '').trim() !== (data.private_note ?? '').trim();
        const servicesChanged = compareServices(data.services, values.services);

        return (
            hasProviderChanged ||
            hasChangedCustomer ||
            hasDatesChanged ||
            hasNotificationsChanged ||
            useReminderChanged ||
            reminderIntervalChanged ||
            hasNoteChanged ||
            hasPrivateNoteChanged ||
            servicesChanged
        );
    }, []);

    const calculateAppointmentServicesDuration = useCallback(
        (services: AppointmentServiceRowType[]) =>
            services.reduce((acc, next) => {
                const options = next.options ?? [];
                return acc + options.reduce((accOptions, current) => accOptions + (current?.duration ?? 0), 0);
            }, 0),
        []
    );

    return {
        formatAppointmentPayload,
        appointmentToFormData,
        calculateDuration,
        formDataToAppointmentPayload,
        isAppointmentFormChanged,
        calculateAppointmentServicesDuration
    };
};

export default useAppointmentFunctions;
