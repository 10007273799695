import companyAPI from '../../../services/CompanyService';
import { Box, Button, Stack, TableCell, TableRow } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useAppDispatch } from '../../../hooks/redux';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import ApiKeyForm from './ApiKeyForm';
import { ICompanyApiKey, ICompanyApiKeyPayload } from '../types';

const ApiKeyTableRow: FC<{ row: ICompanyApiKey; companyId: number }> = ({ row, companyId }) => {
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const [deleteKey, { isLoading: isDeleting }] = companyAPI.useDeleteCompanyApiKeyMutation();
    const [updateKey, { isLoading: isUpdating }] = companyAPI.useUpdateCompanyApiKeyMutation();
    const [edit, setEdit] = useState(false);

    const actionsDisabled = useMemo(() => isDeleting || isUpdating, [isDeleting, isUpdating]);
    const formatDate = useCallback((date: string | null) => {
        if (date) {
            const day = moment(date).format('YYYY-MM-DD');
            const time = moment(date).format('HH:mm');
            return (
                <Box>
                    <Box>{day}</Box>
                    <Box sx={{ opacity: 0.6, fontSize: '0.75rem' }}>{time}</Box>
                </Box>
            );
        }

        return null;
    }, []);

    const onDelete = useCallback(() => {
        dispatch(
            openConfirmPopup({
                title: 'Deleting API Key',
                text: 'Are you sure you want to delete this API key?',
                confirmText: 'Confirm',
                onConfirm: () => {
                    deleteKey({ companyId, keyId: row.id })
                        .unwrap()
                        .then(() => {
                            showSnackbar({
                                alertSeverity: SnackBarTypes.Success,
                                message: 'API Key successfully deleted'
                            });
                        })
                        .catch((err) => {
                            showSnackbar({
                                alertSeverity: SnackBarTypes.Error,
                                message: err.data ?? err.message ?? JSON.stringify(err)
                            });
                        });
                }
            })
        );
    }, [companyId, deleteKey, dispatch, row.id, showSnackbar]);

    const onUpdate = useCallback(
        (formData: ICompanyApiKeyPayload) => {
            dispatch(startSubmitting());
            updateKey({ companyId, keyId: row.id, data: formData })
                .unwrap()
                .then(() => {
                    setEdit(false);
                    showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'API Key successfully updated' });
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.data ?? err.message ?? JSON.stringify(err)
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [companyId, dispatch, row.id, showSnackbar, updateKey]
    );

    return (
        <>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>{formatDate(row.created_at)}</TableCell>
                <TableCell>{formatDate(row.last_used_at) ?? 'Never'}</TableCell>
                <TableCell>
                    <Stack direction="row" spacing={0.5}>
                        <Button
                            className="Cb-Table-Btn forcedBg"
                            color="primary"
                            size="small"
                            disabled={actionsDisabled}
                            onClick={() => setEdit(true)}
                            endIcon={<ModeEditOutlinedIcon />}
                        >
                            Edit
                        </Button>
                        <Button
                            className="Cb-Table-Btn forcedBg"
                            color="error"
                            size="small"
                            onClick={onDelete}
                            disabled={actionsDisabled}
                            endIcon={<DeleteOutlineOutlinedIcon />}
                        >
                            Delete
                        </Button>
                    </Stack>
                </TableCell>
            </TableRow>
            <ApiKeyForm
                open={edit}
                onClose={() => setEdit(false)}
                dialogTitle="Edit API Key"
                onSubmit={onUpdate}
                defaults={{ name: row.name }}
                dialogOkLabel="Save"
                actionsDisabled={isUpdating}
            />
        </>
    );
};

export default ApiKeyTableRow;
