import { FC } from 'react';
import { IPayment } from '../../../../../../../../../../models/IPayment';
import NumberFormat from 'react-number-format';

type PaymentAmountProps = {
    payment: IPayment;
};

const PaymentAmount: FC<PaymentAmountProps> = ({ payment }) => {
    const amount = payment.reason === 'coupon' ? payment.coupon?.amount : payment.amount;
    const prefix = payment.reason !== 'coupon' || payment.coupon?.type === 'fixed' ? '$' : undefined;
    const suffix = payment.coupon?.type === 'percent' ? '%' : undefined;

    return (
        <NumberFormat
            value={payment.coupon?.type === 'percent' ? parseFloat(`${amount}`) : amount}
            prefix={prefix}
            suffix={suffix}
            displayType="text"
            decimalScale={payment.coupon?.type === 'percent' ? undefined : 2}
            fixedDecimalScale
        />
    );
};

export default PaymentAmount;
