import { ILocation } from './ILocation';
import { ICustomer } from './ICustomer';
import { IEmployee } from './IEmployee';

export enum RecurrentPaymentType {
    StartOfMonth = 'start_of_month',
    JoinDate = 'join_date'
}

type RecurringEventDay = {
    id: number;
    enable: boolean;
};

type RecurringEventRules = {
    is_recurring: boolean;
    days: Array<RecurringEventDay> | null;
};

export type RecurringEventCustomerPayload = { id: number; is_paid: boolean };

export interface ICreateRecurringEventPayload {
    location_id: number;
    name: string;
    price: number;
    start_at: string;
    entry_duration: number;
    rules: RecurringEventRules;
    customers: Array<RecurringEventCustomerPayload>;
    employee_id: number;
    payment_type: RecurrentPaymentType;
}

export interface IUpdateRecurringEventPayload extends Omit<ICreateRecurringEventPayload, 'entry_date'> {
    recurring_event_id: number | string;
    entry_date: string;
}

export interface IRecurringEvent extends ICreateRecurringEventPayload {
    id: number | string;
    end_at: string | null;
}

type RecurringEventCustomerPivot = { entered_at: string | null; exited_at: string | null; is_paid: boolean };

export interface IDetailedRecurringEvent extends Omit<IRecurringEvent, 'location_id' | 'customers' | 'employee_id'> {
    location: ILocation;
    customers: Array<ICustomer & { pivot: RecurringEventCustomerPivot }>;
    employee: IEmployee;
}
