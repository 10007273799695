import { FC, useCallback, useState } from 'react';
import { Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

type LinkBuilderItemProps = {
    index: number;
    onEdit: () => void;
    onCopy: () => void;
    onDelete: () => void;
    data: { label: string; value: string }[];
};

const menuItemSx = {
    color: 'grey.800',
    '& .MuiListItemIcon-root, & .MuiListItemText-root > *': {
        color: 'inherit'
    }
} as const;

const LinkBuilderItem: FC<LinkBuilderItemProps> = ({ index, data, onCopy, onEdit, onDelete }) => {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);

    const handleMenuClick = useCallback((cb: () => void) => {
        cb();
        setAnchor(null);
    }, []);

    return (
        <Box
            sx={{
                border: '1px solid',
                borderColor: 'grey.200',
                borderRadius: '16px'
            }}
        >
            <Box
                sx={{
                    padding: '4px 12px',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'grey.800'
                }}
            >
                <Box sx={{ color: 'newColors.darkBlue', display: 'inline-flex', alignItems: 'center' }}>
                    <LinkIcon />
                </Box>
                <Box
                    sx={{
                        flexGrow: 1,
                        color: 'grey.800',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '20px',
                        px: 1
                    }}
                >
                    Link #{index + 1}
                </Box>

                <IconButton color="inherit" onClick={(e) => setAnchor(e.currentTarget)}>
                    <MoreVertIcon />
                </IconButton>
                {anchor && (
                    <Menu anchorEl={anchor} open={!!anchor} onClose={() => setAnchor(null)}>
                        <MenuItem sx={menuItemSx} onClick={() => handleMenuClick(onCopy)}>
                            <ListItemIcon>
                                <FileCopyOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText>Copy Link</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem sx={menuItemSx} onClick={() => handleMenuClick(onEdit)}>
                            <ListItemIcon>
                                <EditOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText>Edit</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem sx={{ ...menuItemSx, color: 'error.main' }} onClick={() => handleMenuClick(onDelete)}>
                            <ListItemIcon sx={{ color: 'error.main' }}>
                                <DeleteOutlineOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText sx={{ color: 'error.main' }}>Delete</ListItemText>
                        </MenuItem>
                    </Menu>
                )}
            </Box>

            <Stack
                sx={{
                    borderTop: '1px solid',
                    borderTopColor: 'grey.200',
                    padding: '12px 16px'
                }}
                spacing={1}
            >
                {data.map(({ label, value }) => (
                    <Stack
                        key={label}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: '100%', color: 'grey.800' }}
                    >
                        <Box component="span">{label}:</Box>
                        <Box component="span" sx={{ textAlign: 'right', opacity: 0.6 }}>
                            {value}
                        </Box>
                    </Stack>
                ))}
            </Stack>
        </Box>
    );
};

export default LinkBuilderItem;
