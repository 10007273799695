import { FC, FocusEvent, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import FocusTextField from '../../ui-component/FocusTextField';
import InfoTooltip from '../../ui-component/InfoTooltip';
import useTooltips from '../../hooks/use-tooltips';

export interface ISettingsFormDelayRowProps {
    label?: string;
    fieldKey: string;
    value: number | null;
    error?: string | null;
    onChange: (field: string, value: unknown) => void;
    onBlur: (e: FocusEvent) => void;
    scale?: 'Hours' | 'Minutes';
    infoText?: string;
    tooltipKey?: string;
    fullWidth?: boolean;
}

const SettingsFormDelayRow: FC<ISettingsFormDelayRowProps> = ({
    label,
    fieldKey,
    value,
    error,
    onChange,
    onBlur,
    infoText,
    scale = 'Hours',
    fullWidth,
    tooltipKey
}) => {
    const { getTooltipTextByKey } = useTooltips();

    const tooltipText = useMemo(() => {
        if (tooltipKey) {
            return getTooltipTextByKey(tooltipKey);
        }

        if (infoText) {
            return infoText;
        }

        return null;
    }, [getTooltipTextByKey, infoText, tooltipKey]);

    return (
        <NumberFormat
            hiddenLabel={!label}
            label={label}
            customInput={FocusTextField}
            fullWidth={fullWidth}
            id={fieldKey}
            name={fieldKey}
            value={value}
            error={!!error}
            helperText={error}
            decimalScale={0}
            suffix={scale ? ` ${scale}` : undefined}
            onValueChange={(val) => onChange(fieldKey, val?.floatValue ?? '')}
            onBlur={onBlur}
            InputProps={{
                endAdornment: tooltipText ? <InfoTooltip label={label} text={tooltipText} /> : undefined
            }}
            allowNegative={false}
        />
    );
};

export default SettingsFormDelayRow;
