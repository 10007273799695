import { useCallback, useState } from 'react';
import { Box } from '@mui/material';
import CBModal from '../cb-modal/CBModal';

const useImageAttachments = () => {
    const [url, setUrl] = useState<string | null>(null);

    const handleOpen = useCallback((imageUrl: string) => {
        setUrl(imageUrl);
    }, []);

    const handleClose = useCallback(() => {
        setUrl(null);
    }, []);

    const dialog = useCallback(
        () => (
            <CBModal open={!!url} onClose={handleClose} maxWidth="lg" fullWidth>
                <Box
                    sx={{
                        width: '100%',
                        '& > img': {
                            width: '100%',
                            height: 'auto',
                            maxHeight: '80vh',
                            objectFit: 'contain',
                            objectPosition: 'center'
                        }
                    }}
                >
                    {url && <img src={url} alt="" />}
                </Box>
            </CBModal>
        ),
        [handleClose, url]
    );

    return { dialog, handleOpen, handleClose };
};

export default useImageAttachments;
