import CBTable, { CBTablePropsType } from '../tables/CBTable';
import { Theme, useMediaQuery } from '@mui/material';
import CbList, { CbListPropsType } from '../cb-list';

type ListRendererPropsType<LT> = CBTablePropsType<LT> & CbListPropsType<LT>;

const ListRenderer = <K,>({ renderItem, rows, isLoading, emptyLabel, ...rest }: ListRendererPropsType<K>) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));

    if (isMobile) {
        return <CbList renderItem={renderItem} rows={rows} isLoading={isLoading} emptyLabel={emptyLabel} />;
    }

    return <CBTable {...rest} rows={rows} isLoading={isLoading} emptyLabel={emptyLabel} />;
};

export default ListRenderer;
