import useAuth from './useAuth';
import { SubscriptionType } from '../models/ICompany';

const TRANSLATIONS: Record<string, string> = {
    [SubscriptionType.SingleUser]: 'single user',
    [SubscriptionType.Organization]: 'organization',
    [SubscriptionType.Agency]: 'agency',
    [SubscriptionType.Deactivated]: 'deactivated',
    [SubscriptionType.SmallBusiness]: 'small business'
};

const usePlanName = () => {
    const { user } = useAuth();

    if (!user?.currentCompany.subscription_type) return null;

    return TRANSLATIONS[user.currentCompany.subscription_type] ?? user.currentCompany.subscription_type;
};

export default usePlanName;
