import { FC, useCallback, useState } from 'react';
import { Box, Button, ButtonBase, Theme, useMediaQuery } from '@mui/material';
import InputRow from '../../../ui-component/form/InputRow';
import TextEditor from '../../../ui-component/TextEditor';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CommonDrawer from '../../../ui-component/CommonSettingsDrawer';
import StickyFormFooter from '../../../ui-component/form/sticky-form-footer';
import { useFormik } from 'formik';

type WidgetTextEditorPropsType = {
    value?: string | null;
    onChange: (v: string) => void;
};

const rowProps = {
    label: 'Deposit Text',
    info: 'Shown in booking widget in case if deposit required',
    forceColumn: true,
    size: 'sm'
} as const;

const WidgetTextEditor: FC<WidgetTextEditorPropsType> = ({ value, onChange }) => {
    const [open, setOpen] = useState(false);
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('mobile'));
    const { values, setFieldValue, handleSubmit, resetForm } = useFormik<{ text: string }>({
        enableReinitialize: true,
        initialValues: { text: value ?? '' },
        onSubmit: ({ text }) => {
            onChange(text);
            setOpen(false);
        }
    });

    const handleCancel = useCallback(() => {
        resetForm();
        setOpen(false);
    }, [resetForm]);

    return !isMobile ? (
        <InputRow {...rowProps}>
            <TextEditor setFieldValue={onChange} value={value ?? ''} />
        </InputRow>
    ) : (
        <>
            <ButtonBase
                onClick={() => setOpen(true)}
                sx={{
                    backgroundColor: 'grey.50',
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: 'grey.300',
                    p: 2,
                    textAlign: 'left',
                    width: '100%'
                }}
            >
                <InputRow {...rowProps} forceColumn={false}>
                    <ChevronRightIcon color="inherit" />
                </InputRow>
            </ButtonBase>
            <CommonDrawer open={open} onClose={handleCancel} title="Deposit Text">
                <Box
                    component="form"
                    sx={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}
                    noValidate
                    onSubmit={handleSubmit}
                >
                    <TextEditor setFieldValue={(v) => setFieldValue('text', v)} value={values.text} />
                    <StickyFormFooter>
                        <Button variant="text" className="forcedBg" color="primary" onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" type="submit">
                            Save
                        </Button>
                    </StickyFormFooter>
                </Box>
            </CommonDrawer>
        </>
    );
};

export default WidgetTextEditor;
