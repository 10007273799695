import { ChangeEvent, FC, useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { AddressAutofill } from '@mapbox/search-js-react';
import { AutofillRetrieveResponse } from '@mapbox/search-js-core/dist/autofill/MapboxAutofill';
import '../assets/css/mapbox-override.css';
import { IAddress } from '../models/IAddress';

const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN;

type AppointmentAddressBoxPropsType = Omit<TextFieldProps, 'onChange' | 'value'> & {
    onChange: (v: IAddress | null) => void;
    value: IAddress | null | undefined;
};
const AppointmentAddressBox: FC<AppointmentAddressBoxPropsType> = ({ onChange, value, ...rest }) => {
    const handleRetrieve = useCallback(
        (res: Pick<AutofillRetrieveResponse, 'features' | 'type'>): void => {
            if (res.type === 'FeatureCollection' && res.features.length === 1) {
                const { place_name, place, region, country_code, postcode, address_line1, address_line2 } = res.features[0].properties;
                onChange({
                    address: place_name,
                    country: country_code ? country_code.toUpperCase() : null,
                    city: place ?? null,
                    state: region ?? null,
                    postal_code: postcode ?? null,
                    l1: address_line1 ?? null,
                    l2: address_line2 ?? null
                });
            }
        },
        [onChange]
    );

    const handleType = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            onChange({
                address: e.target.value,
                country: null,
                city: null,
                state: null,
                postal_code: null,
                l1: null,
                l2: null
            });
        },
        [onChange]
    );

    return (
        <>
            <AddressAutofill
                accessToken={mapboxToken ?? ''}
                options={{
                    country: 'US'
                }}
                onRetrieve={handleRetrieve}
            >
                <TextField {...rest} onChange={handleType} value={value?.address ?? ''} />
            </AddressAutofill>
        </>
    );
};

export default AppointmentAddressBox;
