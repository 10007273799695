import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import ErrorCatcher from '../ui-component/error-catcher';

// sample page routing
const ErrorPage = Loadable(lazy(() => import('../views/Error')));

// ==============================|| MAIN ROUTING ||============================== //

const ErrorRoutes = {
    path: '*',
    element: (
        <ErrorCatcher wrappedComponentName="ErrorPage">
            <ErrorPage />
        </ErrorCatcher>
    )
};

export default ErrorRoutes;
