import { FC, useCallback } from 'react';
import SettingsFormDelayRow from '../SettingsFormDelayRow';
import { Box, Grid, Stack, Switch } from '@mui/material';
import { ICompanySettings } from '../../../models/ICompany';
import { FormikProps } from 'formik';
import LabeledSwitch from '../../../ui-component/form/labeled-switch/LabeledSwitch';
import FormAccordion from '../../../ui-component/form/FormAccordion';
import InputRow from '../../../ui-component/form/InputRow';

type NotificationsTabProps = {
    formInstance: FormikProps<ICompanySettings>;
};

const NotificationsTab: FC<NotificationsTabProps> = ({ formInstance }) => {
    const { values, setFieldValue, errors, handleBlur } = formInstance;
    const handleDelayFieldChange = useCallback(
        (fieldName: string, fieldValue: unknown) => {
            setFieldValue(fieldName, fieldValue);
        },
        [setFieldValue]
    );

    return (
        <Stack spacing={2}>
            <Box>
                <InputRow label="Global Notifications" tooltipKey="settings.notifications.enabled">
                    <Switch
                        aria-label="Enable notifications"
                        checked={values.notifications.enabled}
                        name="notifications.enabled"
                        value={values.notifications.enabled}
                        onChange={(_, checked) => {
                            setFieldValue('notifications.enabled', checked);
                        }}
                    />
                </InputRow>
            </Box>
            {values.notifications.enabled ? (
                <Box>
                    <FormAccordion label="Appointments">
                        <Stack spacing={3} sx={{ width: '100%', maxWidth: '482px' }}>
                            <InputRow label="Notification Delay" tooltipKey="settings.notifications.delay" forceColumn forceTooltips>
                                <Box sx={{ width: '100%' }}>
                                    <SettingsFormDelayRow
                                        aria-label="Notification Delay"
                                        fieldKey="notifications.delay"
                                        value={values.notifications.delay}
                                        error={errors.notifications?.delay}
                                        onBlur={handleBlur}
                                        onChange={handleDelayFieldChange}
                                        scale="Minutes"
                                        fullWidth
                                    />
                                </Box>
                            </InputRow>
                            <Box>
                                <LabeledSwitch
                                    value={values.notifications.immediately_sms_notify}
                                    label="Provider Notification SMS"
                                    onChange={(_, checked) => setFieldValue('notifications.immediately_sms_notify', checked)}
                                    tooltipKey="settings.notifications.immediately_sms_notify"
                                    fullWidth
                                />
                            </Box>
                            <Box>
                                <LabeledSwitch
                                    onChange={(_, checked) => setFieldValue('appointments.completed_notify_customers', checked)}
                                    value={values.appointments.completed_notify_customers}
                                    label="Customer Appointment Closed SMS"
                                    tooltipKey="settings.appointments.completed_notify_customers"
                                    hint="Customer gets notification when appointment is closed either automatically or by staff member."
                                    fullWidth
                                />
                            </Box>
                            <Box>
                                <LabeledSwitch
                                    onChange={(_, checked) => setFieldValue('notifications.check_in_sms_notify', checked)}
                                    value={Boolean(values.notifications.check_in_sms_notify)}
                                    label="Customer Arrival SMS"
                                    fullWidth
                                />
                            </Box>
                        </Stack>
                    </FormAccordion>

                    <FormAccordion label="Reminders">
                        <Box sx={{ width: '100%', maxWidth: '710px' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <SettingsFormDelayRow
                                        fullWidth
                                        label="Service Reminder"
                                        fieldKey="notifications.customer.appointment_reminder.service"
                                        value={values.notifications.customer.appointment_reminder.service}
                                        error={errors.notifications?.customer?.appointment_reminder?.service}
                                        onChange={handleDelayFieldChange}
                                        onBlur={handleBlur}
                                        tooltipKey="settings.notifications.customer.appointment_reminder.service"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <SettingsFormDelayRow
                                        fullWidth
                                        label="Virtual Service Reminder"
                                        fieldKey="notifications.customer.appointment_reminder.virtual_service"
                                        value={values.notifications.customer.appointment_reminder.virtual_service}
                                        error={errors.notifications?.customer?.appointment_reminder?.virtual_service}
                                        onChange={handleDelayFieldChange}
                                        onBlur={handleBlur}
                                        tooltipKey="settings.notifications.customer.appointment_reminder.virtual_service"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <SettingsFormDelayRow
                                        fullWidth
                                        label="Rebook Reminder Follow-up Request"
                                        fieldKey="notifications.customer.rebook_reminder_follow_up"
                                        value={values.notifications.customer.rebook_reminder_follow_up}
                                        error={errors.notifications?.customer?.rebook_reminder_follow_up}
                                        onChange={handleDelayFieldChange}
                                        onBlur={handleBlur}
                                        tooltipKey="settings.notifications.customer.rebook_reminder_follow_up"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </FormAccordion>

                    <FormAccordion label="Requests">
                        <Box sx={{ width: '100%', maxWidth: '710px' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <SettingsFormDelayRow
                                        fullWidth
                                        label="Appointment Deposit Request"
                                        fieldKey="notifications.customer.appointment_deposit_request_reminder"
                                        value={values.notifications.customer.appointment_deposit_request_reminder}
                                        error={errors.notifications?.customer?.appointment_deposit_request_reminder}
                                        onChange={handleDelayFieldChange}
                                        onBlur={handleBlur}
                                        tooltipKey="settings.notifications.customer.appointment_deposit_request_reminder"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SettingsFormDelayRow
                                        fullWidth
                                        label="Appointment Request Reminder"
                                        fieldKey="notifications.customer.appointment_invite_reminder.first"
                                        value={values.notifications.customer.appointment_invite_reminder.first}
                                        error={errors.notifications?.customer?.appointment_invite_reminder?.first}
                                        onChange={handleDelayFieldChange}
                                        onBlur={handleBlur}
                                        tooltipKey="settings.notifications.customer.appointment_invite_reminder.first"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SettingsFormDelayRow
                                        fullWidth
                                        label="Appointment Request Final"
                                        fieldKey="notifications.customer.appointment_invite_reminder.final"
                                        value={values.notifications.customer.appointment_invite_reminder.final}
                                        error={errors.notifications?.customer?.appointment_invite_reminder?.final}
                                        onChange={handleDelayFieldChange}
                                        onBlur={handleBlur}
                                        tooltipKey="settings.notifications.customer.appointment_invite_reminder.final"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </FormAccordion>
                </Box>
            ) : null}
        </Stack>
    );
};

export default NotificationsTab;
