import { FC } from 'react';
import { Chip } from '@mui/material';
import { startCase, toLower } from 'lodash';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { UserRole } from '../models/IEmployee';
import { SxProps } from '@mui/system';

type EmployeeRoleChipProps = {
    role: UserRole;
    size?: 'small' | 'medium';
    sx?: SxProps;
};

const mapping = {
    admin: 'secondary',
    owner: 'orange',
    provider: 'primary',
    frontdesk: 'success',
    manager: 'error',
    read_only_limited: null
} as const;

const EmployeeRoleChip: FC<EmployeeRoleChipProps> = ({ role, sx, size = 'small' }) => {
    const colorSchema = role in mapping ? mapping[`${role}`] : null;
    return (
        <Chip
            icon={<AccountCircleOutlinedIcon />}
            size={size}
            label={startCase(toLower(role))}
            sx={{
                color: 'grey.800',
                fontSize: size === 'medium' ? '14px' : '12px',
                lineHeight: '18px',
                px: 1,
                py: size === 'medium' ? 1 : 0.5,
                borderRadius: '8px',
                fontWeight: 600,
                backgroundColor: colorSchema ? `${colorSchema}.light` : undefined,
                '& .MuiSvgIcon-root': { ml: 0, color: colorSchema ? `${colorSchema}.main` : undefined },
                '& .MuiChip-label': { pr: 0 },
                ...sx
            }}
        />
    );
};

export default EmployeeRoleChip;
