import { AppointmentStatuses } from '../models/IAppointment';
import { useTheme } from '@mui/material';

const useGetColorByStatus = () => {
    const theme = useTheme();

    const getColorByStatus = (s?: string) => {
        switch (s) {
            case AppointmentStatuses.Completed:
                return '#00a651';
            case AppointmentStatuses.Canceled:
                return '#f37a7c';
            case AppointmentStatuses.Pending:
                return '#fcaf07';
            case AppointmentStatuses.Active:
            default:
                return theme.palette.primary.main;
        }
    };

    return { getColorByStatus };
};

export default useGetColorByStatus;
