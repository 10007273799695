import { useMemo } from 'react';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { Theme, useMediaQuery } from '@mui/material';

export const routeNames: Record<string, string> = {
    '/settings': 'Settings',
    '/settings/preferences': 'Preferences',
    '/settings/employee': 'Staff',
    '/settings/service': 'Services',
    '/settings/service-categories': 'Service Categories',
    '/settings/organization': 'Organization',
    '/settings/location': 'Locations',
    '/settings/widget-settings': 'Booking Widget',
    '/settings/forms': 'Form Builder',
    '/settings/products': 'Products'
};

const useAppBreadcrumbs = () => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
    const allBreadcrumbs = useBreadcrumbs();
    const breadcrumbs = useMemo(() => allBreadcrumbs.filter((b) => !!routeNames[b.match.pathname]), [allBreadcrumbs]);
    const showBreadcrumbs = useMemo(() => breadcrumbs.length >= 2 && !isMobile, [breadcrumbs.length, isMobile]);

    return { breadcrumbs, showBreadcrumbs };
};

export default useAppBreadcrumbs;
