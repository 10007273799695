import useAuth from '../../../hooks/useAuth';
import { SubscriptionType } from '../../../models/ICompany';

const SubsWithSiteBuilder = [SubscriptionType.Organization, SubscriptionType.Agency];

const useCanSeeSiteBuilder = () => {
    const { user } = useAuth();
    const sub = user?.currentCompany.subscription_type;

    return sub === null || SubsWithSiteBuilder.some((type) => type === sub);
};

export default useCanSeeSiteBuilder;
