import { FC, useCallback, useMemo } from 'react';
import { Button, ButtonProps } from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import useShowSnackbar from '../../../hooks/useShowSnackbar';

const CopyUrlButton: FC<{ url: string }> = ({ url }) => {
    const { showSnackbar } = useShowSnackbar();

    const handleCopyUrl = useCallback(() => {
        navigator.clipboard.writeText(url).then(() => {
            showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Link copied to clipboard!' });
        });
    }, [url, showSnackbar]);

    const copyBtnProps = useMemo<ButtonProps>(
        () => ({
            onClick: handleCopyUrl,
            className: 'forcedBg',
            color: 'primary',
            size: 'large',
            sx: {
                padding: '8px 16px',
                width: '100%',
                alignItems: 'center',
                display: 'flex',
                textTransform: 'none',
                fontSize: '1rem',
                lineHeight: 1.5
            }
        }),
        [handleCopyUrl]
    );

    return (
        <Button {...copyBtnProps}>
            {url} <FileCopyOutlinedIcon sx={{ fontSize: '1em', ml: 1 }} />
        </Button>
    );
};

export default CopyUrlButton;
