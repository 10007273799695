import { FC } from 'react';
import { Box, CircularProgress } from '@mui/material';

const LoadingSpinner: FC = () => (
    <Box
        sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexGrow: 1
        }}
    >
        <CircularProgress />
    </Box>
);

export default LoadingSpinner;
