import { axiosBaseQuery } from '../utils/axios';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { LeadData, SaveLeadPayload } from '../views/authentication/types';
import { IRegisterPayloadCompany, RegisterPayload } from '../contexts/SanctumContext';

export type ExtendedLeadPayloadData = Omit<RegisterPayload, 'stripe_price_id' | 'company' | 'with_trial'> & {
    company: Omit<IRegisterPayloadCompany, 'time_zone' | 'lead_uuid'>;
};

type ExtendedLeadPayload = {
    companyId: number | string;
    data: ExtendedLeadPayloadData;
};

const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: axiosBaseQuery(),

    endpoints: (build) => ({
        getLead: build.query<LeadData, string>({
            query: (id) => ({
                method: 'GET',
                url: `get-lead/${id}`
            })
        }),

        saveLead: build.mutation<LeadData, SaveLeadPayload>({
            query: (payload) => ({
                url: '/save-lead',
                method: 'POST',
                data: payload
            })
        }),

        saveExtendedLead: build.mutation<{ uuid: string; url: string }, ExtendedLeadPayload>({
            query: ({ companyId, data }) => ({
                url: `companies/${companyId}/register-leads`,
                method: 'POST',
                data
            })
        })
    })
});

export default authAPI;
