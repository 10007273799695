import { IConversationDetails } from '../models/IAppointment';
import appointmentAPI from '../services/AppointmentService';
import { useAppDispatch } from './redux';
import { useCallback } from 'react';

const useMessagesCache = () => {
    const dispatch = useAppDispatch();

    const updateMessagesCache = useCallback(
        (appointmentId: number, res: IConversationDetails) => {
            dispatch(
                appointmentAPI.util.updateQueryData(
                    'getSingleConversation',
                    {
                        appointmentId,
                        conversationId: res.id
                    },
                    () => res
                )
            );

            dispatch(
                appointmentAPI.util.updateQueryData('getConversationsList', appointmentId, (prev) =>
                    prev.some((conv) => conv.id === res.id) ? prev.map((c) => (c.id === res.id ? res : c)) : [...prev, res]
                )
            );
        },
        [dispatch]
    );

    return { updateMessagesCache };
};

export default useMessagesCache;
