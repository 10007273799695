import { FC } from 'react';
import { IAppointment } from '../models/IAppointment';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip } from '@mui/material';

const WaiverSignIndicator: FC<{ waiver: IAppointment['has_waiver']; forceMonochrome?: boolean }> = ({
    waiver,
    forceMonochrome = false
}) => {
    if (waiver) {
        return waiver === 'not_signed' ? (
            <Tooltip title="Waiver form not signed">
                <CancelIcon color={forceMonochrome ? 'inherit' : 'error'} />
            </Tooltip>
        ) : (
            <Tooltip title="Waiver form signed">
                <CheckCircleIcon color={forceMonochrome ? 'inherit' : 'success'} />
            </Tooltip>
        );
    }

    return null;
};

export default WaiverSignIndicator;
