import { FC } from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Box } from '@mui/material';

type HighlightTextRenderProps = {
    text: string;
    query?: string | null;
};

const HighlightTextRender: FC<HighlightTextRenderProps> = ({ text, query }) => {
    if (query) {
        const matches = match(text, query ?? '', { insideWords: true });
        const parts = parse(text, matches);

        return (
            <>
                {parts.map((part, index) => (
                    <Box component="span" sx={{ fontWeight: part.highlight ? 600 : 400 }} key={index}>
                        {part.text}
                    </Box>
                ))}
            </>
        );
    }

    return <>{text}</>;
};

export default HighlightTextRender;
