import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import { ICustomer, ICustomerNote, ICustomerNoteCreatePayload, ICustomerNoteUpdatePayload, ICustomerPayload } from 'models/ICustomer';
import { IBaseParams, IPaginateResponse } from 'models/IPaginateResponse';

const customerAPI = createApi({
    reducerPath: 'customerAPI',
    tagTypes: ['Customer', 'CustomerNotes'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchAllCustomers: build.query<IPaginateResponse<ICustomer[]>, IBaseParams>({
            query: ({ per_page = null, page = 1, search = null, sort = null, order = null, trashed = null }) => ({
                url: `/customers`,
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order,
                    trashed
                }
            }),
            providesTags: (result) => ['Customer']
        }),
        getCustomer: build.query<ICustomer, string>({
            query: (id) => ({ url: `/customers/${id}`, method: 'GET' }),
            providesTags: ['Customer']
        }),
        createCustomer: build.mutation<ICustomer, ICustomerPayload>({
            query: (customer) => ({
                url: `/customers`,
                method: 'POST',
                data: customer
            }),
            invalidatesTags: ['Customer']
        }),
        updateCustomer: build.mutation<ICustomer, ICustomerPayload & { id: number }>({
            query: (customer) => ({
                url: `/customers/${customer.id}`,
                method: 'PUT',
                data: customer
            }),
            invalidatesTags: ['Customer']
        }),
        deleteCustomer: build.mutation<ICustomer, ICustomer>({
            query: (customer) => ({
                url: `/customers/${customer.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Customer']
        }),

        // Notes
        getCustomerNotes: build.query<IPaginateResponse<ICustomerNote[]>, number | string>({
            query: (customerId) => ({
                url: `/customers/${customerId}/notes`,
                method: 'GET',
                params: {
                    per_page: -1
                }
            }),
            providesTags: ['CustomerNotes']
        }),

        getCustomerNoteById: build.query<ICustomerNote, { customerId: number | string; noteId: number | string }>({
            query: ({ customerId, noteId }) => ({
                url: `/customers/${customerId}/notes/${noteId}`,
                method: 'GET'
            }),
            providesTags: ['CustomerNotes']
        }),

        createCustomerNote: build.mutation<
            ICustomerNote,
            {
                customerId: string | number;
                data: ICustomerNoteCreatePayload;
            }
        >({
            query: ({ customerId, data }) => ({
                url: `/customers/${customerId}/notes`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['CustomerNotes']
        }),

        updateCustomerNote: build.mutation<
            ICustomerNote,
            {
                customerId: string | number;
                data: ICustomerNoteUpdatePayload;
            }
        >({
            query: ({ customerId, data: { id, ...rest } }) => ({
                url: `/customers/${customerId}/notes/${id}`,
                method: 'PUT',
                data: rest
            }),
            invalidatesTags: ['CustomerNotes']
        }),

        deleteCustomerNote: build.mutation<unknown, { customerId: number | string; noteId: number | string }>({
            query: ({ customerId, noteId }) => ({
                url: `/customers/${customerId}/notes/${noteId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['CustomerNotes']
        })
    })
});

export default customerAPI;
