import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    reason: Yup.array().of(Yup.string()),
    amount_service: Yup.mixed().when('reason', {
        is: (value: string[]) => value.includes('service'),
        then: Yup.number().typeError('Service Amount is required').required('Service Amount is required').min(0.01).label('Service Amount'),
        otherwise: Yup.mixed().notRequired().nullable()
    }),
    amount_deposit: Yup.mixed().when('reason', {
        is: (value: string[]) => value.includes('deposit'),
        then: Yup.number().typeError('Deposit Amount is required').required('Deposit Amount is required').min(0.01).label('Deposit Amount'),
        otherwise: Yup.mixed().notRequired().nullable()
    }),
    amount_gratuity: Yup.mixed().when('reason', {
        is: (value: string[]) => value.includes('gratuity'),
        then: Yup.number()
            .typeError('Gratuity Amount is required')
            .required('Gratuity Amount is required')
            .min(0.01)
            .label('Gratuity Amount'),
        otherwise: Yup.mixed().notRequired().nullable()
    })
});

export default validationSchema;
