import { useCallback, useMemo } from 'react';
import productsAPI from '../../services/ProductsService';
import { IProduct } from '../../models/IProduct';
import { skipToken } from '@reduxjs/toolkit/query';

const useProductsOptions = (excludedValues: number[] = [], skip = false) => {
    const { data, isLoading } = productsAPI.useFetchProductsQuery(!skip ? { per_page: -1 } : skipToken);

    const options = useMemo<IProduct[]>(() => {
        const arr = data?.data ?? [];
        return arr.filter((opt) => !excludedValues.includes(opt.id));
    }, [data, excludedValues]);

    const getOptionById = useCallback((id: number) => options.find((option) => option.id === id), [options]);

    return { options, isLoading, getOptionById };
};

export default useProductsOptions;
