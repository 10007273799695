import { CBTablePropsType } from '../tables/CBTable';
import { ReactNode } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import { Box, Stack } from '@mui/material';

export type CbListPropsType<T> = Pick<CBTablePropsType<T>, 'rows' | 'isLoading' | 'emptyLabel'> & {
    renderItem: (row: T, index: number) => ReactNode;
};

const CbList = <K,>({ rows, isLoading, emptyLabel, renderItem }: CbListPropsType<K>) => {
    if (isLoading) {
        return <LoadingSpinner />;
    }

    if (!isLoading && !rows.length) {
        return <Box sx={{ textAlign: 'center' }}>{emptyLabel ?? 'Nothing to display'}</Box>;
    }

    return <Stack spacing={2}>{rows.map(renderItem)}</Stack>;
};

export default CbList;
