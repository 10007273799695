const StartConversationIcon = () => (
    <svg width={100} height={101} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1609_15548)">
            <rect y="0.0302734" width="100" height="100" fill="white" />
            <circle cx="50" cy="50.0303" r="50" fill="#708B9E" fillOpacity="0.3" />
            <path
                d="M33.8965 35.6778V23.9854C33.8965 22.2595 35.2956 20.8604 37.0215 20.8604H79.1634C80.8892 20.8604 82.2884 22.2595 82.2884 23.9854V49.9966C82.2884 51.7225 80.8892 53.1216 79.1634 53.1216H74.5449"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
            />
            <path
                d="M66.2356 41.3057C67.9615 41.3057 69.3606 42.7048 69.3606 44.4307V70.4419C69.3606 72.1678 67.9615 73.5669 66.2356 73.5669H36.8851L26.5961 80.9875C25.5627 81.7328 24.1196 80.9944 24.1196 79.7202L24.1196 73.5669H24.0938C22.3679 73.5669 20.9688 72.1678 20.9688 70.4419V44.4307C20.9688 42.7048 22.3679 41.3057 24.0937 41.3057H66.2356Z"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2.5"
                strokeLinecap="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1609_15548">
                <rect width="100" height="100" fill="white" transform="translate(0 0.0302734)" />
            </clipPath>
        </defs>
    </svg>
);

export default StartConversationIcon;
