import { FC } from 'react';

// material-ui
import { Box, LinearProgress } from '@mui/material';

const Loader: FC<{ isBlocker?: boolean }> = ({ isBlocker }) => (
    <Box
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1301,
            width: '100%',
            height: isBlocker ? '100vh' : 'auto',
            backgroundColor: isBlocker ? '#fff' : undefined
        }}
    >
        <LinearProgress color="primary" />
    </Box>
);

export default Loader;
