import useAuth from './useAuth';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { useCallback } from 'react';

const useChangeCompany = () => {
    const { changeCompanyContext } = useAuth();
    const navigate = useNavigate();

    const handleChangeCompany = useCallback(
        async (companyId: number, isNew?: boolean) => {
            await changeCompanyContext(companyId);

            if (isNew) {
                window.location.href = config.defaultPath;
            } else {
                navigate(0);
            }
        },
        [changeCompanyContext, navigate]
    );

    return { handleChangeCompany };
};

export default useChangeCompany;
