import { Box, Drawer } from '@mui/material';
import { SIDEBAR_WIDTH } from './entity-dialogs/EntityDialogs';
import { FC, PropsWithChildren, ReactNode } from 'react';
import EntityDrawerContainer from './entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from './entity-drawer-layout/EntityDrawerHeader';
import CbPortal from './cb-portal';

type PermanentEntityDrawerProps = PropsWithChildren<{
    open: boolean;
    onClose: () => void;
    title: ReactNode;
}>;

const PermanentEntityDrawer: FC<PermanentEntityDrawerProps> = ({ open, onClose, title, children }) => (
    <CbPortal query="#permanent-drawer-contained">
        <Box sx={{ height: 0, overflow: 'hidden' }}>
            <Drawer
                open={open}
                anchor="right"
                variant="persistent"
                ModalProps={{
                    keepMounted: false
                }}
                sx={{
                    height: '100vh',
                    // @ts-ignore
                    // eslint-disable-next-line no-dupe-keys
                    height: '100svh',
                    '& .MuiDrawer-paper': {
                        top: 0,
                        width: SIDEBAR_WIDTH,
                        maxWidth: '100vw',
                        borderLeft: '2px solid',
                        borderLeftColor: 'grey.300'
                    }
                }}
            >
                <EntityDrawerContainer>
                    <EntityDrawerHeader title={title} onClose={onClose} />
                    {open && <>{children}</>}
                </EntityDrawerContainer>
            </Drawer>
        </Box>
    </CbPortal>
);

export default PermanentEntityDrawer;
