import { Box, MenuItem, Stack, TextField, TextFieldProps } from '@mui/material';
import { FC, Fragment, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BookingWidgetAnswer, BookingWidgetQuestionAnswerType } from '../../views/scheduling-widget/widget-wizard/types';

type WidgetSurveyFormType = BookingWidgetAnswer[];

type WidgetSurveyFormProps = {
    formId: string;
    data: WidgetSurveyFormType;
    onSubmit: (data: WidgetSurveyFormType) => void;
};

const schema = Yup.array().of(
    Yup.object().shape({
        answers: Yup.array().of(
            Yup.object().shape({
                answer: Yup.string().trim().required('This field is required')
            })
        )
    })
);

const WidgetSurveyForm: FC<WidgetSurveyFormProps> = ({ formId, data, onSubmit }) => {
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik<WidgetSurveyFormType>({
        initialValues: data,
        onSubmit,
        validationSchema: schema
    });

    const getError = useCallback(
        (serviceIndex: number, questionIndex: number) => {
            const isTouched = !!touched?.[serviceIndex]?.answers?.[questionIndex];
            const err = errors?.[serviceIndex]?.answers?.[questionIndex];

            return isTouched && typeof err !== 'string' ? err?.answer : undefined;
        },
        [touched, errors]
    );

    const renderQuestion = useCallback(
        (serviceIndex: number, element: BookingWidgetQuestionAnswerType, index: number) => {
            const id = `${serviceIndex}.answers.${index}.answer`;
            const commonInputProps: Partial<TextFieldProps> = {
                id,
                name: id,
                'aria-label': element.title,
                value: element.answer ?? '',
                onBlur: handleBlur,
                fullWidth: true,
                error: !!getError(serviceIndex, index),
                helperText: getError(serviceIndex, index)
            };

            if (element.type === 'comment' || element.type === 'text') {
                const props = {
                    ...commonInputProps,
                    onChange: handleChange,
                    multiline: element.type === 'comment' ? true : undefined,
                    rows: element.type === 'comment' ? 4 : undefined
                };
                return <TextField {...props} />;
            }

            if (element.type === 'dropdown') {
                return (
                    <TextField {...commonInputProps} select onChange={(e) => setFieldValue(id, e.target.value)}>
                        {element.show_none_item ? (
                            <MenuItem key="None" value="None">
                                None
                            </MenuItem>
                        ) : null}

                        {(element.choices ?? []).map((choice) => (
                            <MenuItem key={choice} value={choice}>
                                {choice}
                            </MenuItem>
                        ))}
                        {element.show_other_item ? (
                            <MenuItem key="Other" value="Other">
                                Other
                            </MenuItem>
                        ) : null}
                    </TextField>
                );
            }

            return null;
        },
        [getError, handleBlur, handleChange, setFieldValue]
    );

    return (
        <Stack spacing={2} id={formId} component="form" onSubmit={handleSubmit}>
            {values.map((service, serviceIndex) => (
                <Fragment key={service.service_id}>
                    {service.answers.map((element, index) => (
                        <Box key={`${service.service_id}_${element.title}_${index}`}>
                            <Box sx={{ fontWeight: 600, mb: 0.5 }}>{element.title}</Box>
                            {renderQuestion(serviceIndex, element, index)}
                        </Box>
                    ))}
                </Fragment>
            ))}
        </Stack>
    );
};

export default WidgetSurveyForm;
