import { Tabs, Tab, Box } from '@mui/material';
import Segment from '@mui/icons-material/Segment';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import HistoryOutlined from '@mui/icons-material/HistoryOutlined';
import MailOutlined from '@mui/icons-material/MailOutlined';
import PaymentOutlined from '@mui/icons-material/PaymentOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import { IAppointment } from '../../../../../models/IAppointment';
import WaiverSignIndicator from '../../../../WaiverSignIndicator';
import AppointmentDepositOpenedIndicator from '../../../../AppointmentDepositOpenedIndicator';
import useAuth from '../../../../../hooks/useAuth';
import useEntityDrawerSpacing from '../../../../entity-drawer-layout/use-entity-drawer-spacing';

interface MenuProps {
    activeTab: string;
    handleClickTab: (val: string) => void;
    has_waiver?: IAppointment['has_waiver'];
    deposit_request?: IAppointment['deposit_request'];
    disabled?: boolean;
}

const Menu = ({ activeTab, handleClickTab, has_waiver, deposit_request, disabled }: MenuProps) => {
    const { isMobile } = useEntityDrawerSpacing();
    const { user } = useAuth();
    const productsDisabled = !user?.currentCompany.settings?.appointments.use_products;

    const handleChange = (_: unknown, newValue: string) => {
        handleClickTab(newValue);
    };

    return (
        <Box
            sx={{
                width: '100%',
                px: isMobile ? 0 : 3,
                '& .MuiTab-root': { minWidth: '73px', minHeight: '49px', py: 1, px: 2, flexGrow: 1 },
                '& .MuiTabs-root': {
                    minHeight: '50px'
                }
            }}
        >
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                orientation="horizontal"
                onChange={handleChange}
                value={activeTab}
                indicatorColor="secondary"
                textColor="secondary"
            >
                <Tab value="details" icon={<Segment />} iconPosition="start" disabled={disabled} />
                <Tab
                    value="customer-info"
                    icon={
                        <>
                            <PersonOutlineOutlined />
                            <WaiverSignIndicator waiver={has_waiver} />
                        </>
                    }
                    iconPosition="start"
                    disabled={disabled}
                />
                <Tab value="activity" icon={<HistoryOutlined />} iconPosition="start" disabled={disabled} />
                <Tab value="messages" icon={<MailOutlined />} iconPosition="start" disabled={disabled} />
                {!productsDisabled && <Tab value="products" icon={<LocalMallOutlinedIcon />} iconPosition="start" disabled={disabled} />}
                <Tab
                    value="payments"
                    icon={
                        <>
                            <PaymentOutlined />
                            <AppointmentDepositOpenedIndicator depositRequest={deposit_request} />
                        </>
                    }
                    iconPosition="start"
                    disabled={disabled}
                />
            </Tabs>
        </Box>
    );
};

export default Menu;
