import { useCallback } from 'react';
import { Box, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import useAuth from '../../../../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

const LogoutBtn = () => {
    const { logout } = useAuth();
    const location = useLocation();

    const handleLogout = useCallback(async () => {
        try {
            await logout();
        } catch (err) {
            console.error(err);
        }
        location.state = null;
    }, [location, logout]);

    return (
        <Box sx={{ position: 'sticky', bottom: 0, zIndex: 1, backgroundColor: 'background.default', marginTop: 'auto' }}>
            <ListItemButton
                sx={{
                    color: 'error.main',
                    py: 2,
                    px: 1.5,
                    borderRadius: '8px',
                    '&:hover': { backgroundColor: 'error.light' }
                }}
                onClick={handleLogout}
            >
                <ListItemIcon>
                    <LogoutOutlinedIcon sx={{ color: 'error.main' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography sx={{ color: 'error.main' }}>Logout</Typography>} />
            </ListItemButton>
        </Box>
    );
};

export default LogoutBtn;
