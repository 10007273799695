import { FC, ReactNode } from 'react';
import { Grid } from '@mui/material';
import { Theme, useMediaQuery } from '@material-ui/core';

interface IWidgetColumnsLayoutProps {
    Data: ReactNode;
    Summary: ReactNode;
    matchDownMd?: boolean;
    Error?: ReactNode | null;
}

const WidgetColumnsLayout: FC<IWidgetColumnsLayoutProps> = ({ Data, Summary, matchDownMd, Error }) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    return (
        <Grid
            container
            sx={{ flexDirection: matchDownMd ? 'column' : 'row' }}
            spacing={isMobile ? 1 : 3}
            px={isMobile ? 1 : 0}
            py={isMobile ? 1 : 0}
        >
            {Error ? (
                <Grid item xs={12}>
                    {Error}
                </Grid>
            ) : (
                <>
                    <Grid item xs={matchDownMd ? 12 : 6}>
                        {Data}
                    </Grid>
                    <Grid item xs={matchDownMd ? 12 : 6}>
                        {Summary}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default WidgetColumnsLayout;
