import { AccordionSummary, AccordionSummaryProps } from '@mui/material';
import { FC } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FormAccordionSummary: FC<AccordionSummaryProps> = ({ children, expandIcon, ...rest }) => (
    <AccordionSummary {...rest} expandIcon={expandIcon ?? <ExpandMoreIcon />}>
        {children}
    </AccordionSummary>
);

export default FormAccordionSummary;
