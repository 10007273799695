import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { setupStore } from './store/store';
import 'assets/scss/style.scss';
import interceptors from './utils/axios';

const store = setupStore();
interceptors.setupInterceptors(store);

const rootElement = document.getElementById('root');

if (!rootElement) throw new Error('Failed to find the root element');

const root = createRoot(rootElement);
const router = createBrowserRouter([
    {
        path: '/*',
        element: <App />
    }
]);

root.render(
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
