import { FC, PropsWithChildren, useMemo, FocusEvent } from 'react';

import { ICustomerPayload } from '../models/ICustomer';
import { Stack, IconButton, Link, TextField, Divider } from '@mui/material';
import CustomPhoneInput from './form/CustomPhoneInput';
import useShouldSubmitDetailsForm from '../hooks/useShouldSubmitDetailsForm';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import useCanSeeCustomerDetails from '../hooks/use-can-see-customer-details';
import useCustomerFormValidation from '../views/customer/hooks/use-customer-form-validation';
import EmployeeIdSelect from './form/EmployeeIdSelect';
import useExtendedFormik from '../hooks/useExtendedFormik';
import SectionHeading from './SectionHeading';
import FormAccordion from './form/FormAccordion';

interface CustomerFormProps {
    customer: ICustomerPayload;
    onSubmit: (data: ICustomerPayload) => void;
    formId: string;
    hideTitles?: boolean;
    isReadOnly?: boolean;
    skipChangesCheck?: boolean;
    isNew?: boolean;
    showContactDetailsInAccordion?: boolean;
}

type RenderCustomerFormGroupProps = PropsWithChildren<{ label: string; mode?: 'section' | 'accordion' }>;

const RenderCustomerFormGroup: FC<RenderCustomerFormGroupProps> = ({ label, mode, children }) => {
    if (mode === 'accordion') {
        return (
            <FormAccordion label={label}>
                <Stack spacing={2}>{children}</Stack>
            </FormAccordion>
        );
    }
    return (
        <Stack spacing={2}>
            {mode === 'section' && <SectionHeading>{label}</SectionHeading>}
            <>{children}</>
        </Stack>
    );
};

const CustomerForm = ({
    customer,
    onSubmit,
    formId,
    hideTitles = false,
    isReadOnly = false,
    skipChangesCheck = false,
    showContactDetailsInAccordion = false,
    isNew
}: CustomerFormProps) => {
    const { canEditCustomerDetails, canEditCustomerOwner, canSeeCustomerCampaignId } = useCanSeeCustomerDetails();
    const { schema } = useCustomerFormValidation();

    const canSeeDetails = useMemo(() => (!customer ? true : canEditCustomerDetails(customer.employee_owner_id)), [
        canEditCustomerDetails,
        customer
    ]);

    const {
        handleSubmit,
        values,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldTouched,
        setFieldValue,
        initialValues
    } = useExtendedFormik<ICustomerPayload>({
        enableReinitialize: true,
        initialValues: customer,
        validationSchema: schema,
        onSubmit: (formData) => {
            if (canSeeDetails) {
                onSubmit(formData);
            }
        }
    });

    const showDivider = useMemo(() => !hideTitles && !showContactDetailsInAccordion, [hideTitles, showContactDetailsInAccordion]);
    const detailsSectionMode = useMemo(() => {
        if (showContactDetailsInAccordion) {
            return 'accordion';
        }

        if (!hideTitles) {
            return 'section';
        }
        return undefined;
    }, [hideTitles, showContactDetailsInAccordion]);

    const isDirty = useMemo(() => {
        if (canSeeDetails) {
            const simpleFields = ['firstname', 'lastname', 'email', 'employee_owner_id', 'campaign_identifier'] as const;
            const simpleFieldsChanged = simpleFields.some((field) => values[field] !== initialValues[field]);

            // need to remove all non-numeric symbols from the phone fields because phone formats on FE and BE are different
            const phoneChanged = (values.phone ?? '').replace(/\D/g, '') !== (initialValues.phone ?? '')?.replace(/\D/g, '');

            return simpleFieldsChanged || phoneChanged;
        }

        return false;
    }, [canSeeDetails, values, initialValues]);

    useShouldSubmitDetailsForm(isDirty, skipChangesCheck);

    return (
        <Stack
            spacing={!hideTitles ? 3 : 2}
            component="form"
            noValidate
            onSubmit={handleSubmit}
            id={formId}
            divider={showDivider ? <Divider /> : undefined}
        >
            <RenderCustomerFormGroup label="Personal Info" mode={hideTitles ? undefined : 'section'}>
                <TextField
                    fullWidth
                    label="First Name"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.firstname && errors?.firstname)}
                    helperText={touched?.firstname ? errors?.firstname : undefined}
                    disabled={isReadOnly || !canSeeDetails}
                />
                <TextField
                    fullWidth
                    label="Last Name"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched?.lastname && errors?.lastname)}
                    helperText={touched?.lastname ? errors?.lastname : undefined}
                    disabled={isReadOnly || !canSeeDetails}
                />
            </RenderCustomerFormGroup>

            {canSeeDetails ? (
                <RenderCustomerFormGroup label="Contact Details" mode={detailsSectionMode}>
                    <CustomPhoneInput
                        fullWidth
                        id="phone"
                        name="phone"
                        label="Phone"
                        placeholder="Phone"
                        value={values.phone ?? ''}
                        onChange={(v) => {
                            setFieldTouched('phone', false);
                            setFieldValue('phone', v);
                        }}
                        onBlur={(e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            handleBlur(e);
                            if (!e.target.value) {
                                setFieldTouched('phone', false);
                            }
                        }}
                        error={Boolean(touched?.phone && errors?.phone)}
                        helperText={touched?.phone ? errors?.phone : undefined}
                        hasActions
                        disabled={isReadOnly}
                    />
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        value={values.email ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched?.email && errors?.email)}
                        helperText={touched?.email ? errors?.email : undefined}
                        disabled={isReadOnly}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    size="small"
                                    disabled={!!errors?.email || !values.email}
                                    component={Link}
                                    href={`mailto:${values.email}`}
                                >
                                    <MailOutlineIcon />
                                </IconButton>
                            )
                        }}
                    />

                    {canEditCustomerOwner && !isNew ? (
                        <EmployeeIdSelect
                            value={values.employee_owner_id || null}
                            onChange={(v) => setFieldValue('employee_owner_id', v)}
                            id="employee_owner_id"
                            name="employee_owner_id"
                            label="Contact Owner"
                            disabled={isReadOnly}
                            onBlur={handleBlur}
                            error={Boolean(touched?.employee_owner_id && errors?.employee_owner_id)}
                            helperText={touched?.employee_owner_id ? errors?.employee_owner_id : undefined}
                        />
                    ) : null}

                    {canSeeCustomerCampaignId ? (
                        <TextField
                            id="campaign_identifier"
                            name="campaign_identifier"
                            label="Campaign Identifier"
                            disabled={isReadOnly}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.campaign_identifier ?? ''}
                            fullWidth
                        />
                    ) : null}
                </RenderCustomerFormGroup>
            ) : null}
        </Stack>
    );
};

export default CustomerForm;
