import { useEffect } from 'react';
import { setAppointmentDetailsFormId, setAppointmentDetailsSaveButtonDisabled } from '../store/slices/calendarSlice';
import { useAppDispatch } from './redux';

const useAppointmentDetailsFormFunctions = (formId: string, isSubmitting: boolean) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setAppointmentDetailsFormId(formId));
        dispatch(setAppointmentDetailsSaveButtonDisabled(isSubmitting));

        return () => {
            dispatch(setAppointmentDetailsSaveButtonDisabled(false));
            dispatch(setAppointmentDetailsFormId(undefined));
        };
    }, [dispatch, formId, isSubmitting]);
};

export default useAppointmentDetailsFormFunctions;
