const CloseSvg = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1141_7064)">
            <path
                d="M12.9393 12L18.466 6.47329C18.5752 6.34576 18.6323 6.18171 18.6258 6.01393C18.6193 5.84614 18.5498 5.68698 18.431 5.56825C18.3123 5.44952 18.1532 5.37997 17.9854 5.37349C17.8176 5.36701 17.6535 5.42408 17.526 5.53329L11.9993 11.06L6.47266 5.52663C6.34713 5.40109 6.17687 5.33057 5.99933 5.33057C5.8218 5.33057 5.65153 5.40109 5.526 5.52663C5.40046 5.65216 5.32994 5.82243 5.32994 5.99996C5.32994 6.1775 5.40046 6.34776 5.526 6.47329L11.0593 12L5.526 17.5266C5.45621 17.5864 5.39953 17.6599 5.35951 17.7426C5.3195 17.8254 5.29701 17.9154 5.29347 18.0073C5.28992 18.0991 5.30539 18.1906 5.33891 18.2762C5.37242 18.3617 5.42326 18.4394 5.48823 18.5044C5.5532 18.5694 5.6309 18.6202 5.71645 18.6537C5.802 18.6872 5.89355 18.7027 5.98537 18.6992C6.07718 18.6956 6.16727 18.6731 6.24998 18.6331C6.33269 18.5931 6.40623 18.5364 6.466 18.4666L11.9993 12.94L17.526 18.4666C17.6535 18.5758 17.8176 18.6329 17.9854 18.6264C18.1532 18.62 18.3123 18.5504 18.431 18.4317C18.5498 18.3129 18.6193 18.1538 18.6258 17.986C18.6323 17.8182 18.5752 17.6542 18.466 17.5266L12.9393 12Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1141_7064">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default CloseSvg;
