import { FC, PropsWithChildren, useEffect } from 'react';
import { StyledEngineProvider, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../themes/v5theme';

const UpdatedStyleWrapper: FC<PropsWithChildren<unknown>> = ({ children }) => {
    useEffect(() => {
        console.log('Outer V5 theme', theme);
    }, []);
    return (
        <StyledEngineProvider injectFirst>
            <CssBaseline />
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </StyledEngineProvider>
    );
};

export default UpdatedStyleWrapper;
