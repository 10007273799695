import { FC } from 'react';
import { ServiceOption } from '../../models/IService';
import { ButtonBase } from '@mui/material';
import NumberFormat from 'react-number-format';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

type WidgetServiceOptionProps = {
    option: ServiceOption;
    isSelected?: boolean;
    onClick?: () => void;
};

const WidgetServiceOption: FC<WidgetServiceOptionProps> = ({ option, isSelected, onClick }) => {
    const theme = useTheme();
    return (
        <ButtonBase
            onClick={onClick}
            sx={{
                cursor: 'pointer',
                padding: 1,
                background: isSelected
                    ? `linear-gradient(45deg, ${theme.palette.widget.darkGreen}, ${theme.palette.widget.lightGreen})`
                    : theme.palette.grey[100],
                borderRadius: '8px',
                color: theme.palette.widget.text,
                fontSize: '0.875rem',
                fontWeight: 700,
                '& .MuiSvgIcon-root': {
                    mr: 0.5
                }
            }}
        >
            {isSelected ? <CheckBoxIcon color="inherit" /> : <CheckBoxOutlineBlankIcon color="inherit" />}
            <Typography
                sx={{
                    color: 'inherit',
                    fontSize: 'inherit',
                    fontWeight: 'inherit'
                }}
                component="span"
            >
                {option.name}
                {', '}
                <NumberFormat value={option.price} prefix="+$" decimalScale={2} fixedDecimalScale displayType="text" />
            </Typography>
        </ButtonBase>
    );
};

export default WidgetServiceOption;
