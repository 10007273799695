import { FC, useCallback } from 'react';
import { useFormik } from 'formik';
import EntityDrawerContent from '../../entity-drawer-layout/EntityDrawerContent';
import { Autocomplete, Box, Button, MenuItem, Stack, Switch, TextField } from '@mui/material';
import NumberFormat from 'react-number-format';
import FocusTextField from '../../FocusTextField';
import EventTimeSelect from '../../event-time-select';
import InputRow from '../../form/InputRow';
import WeekDaysSchedule from '../../WeekDaysSchedule';
import RecurringEventCustomers from './RecurringEventCustomers';
import EntityDrawerActions from '../../entity-drawer-layout/EntityDrawerActions';
import * as Yup from 'yup';
import { ICreateRecurringEventPayload, RecurrentPaymentType, RecurringEventCustomerPayload } from '../../../models/IRecurringEvent';
import getDefaultDays from '../utils/get-default-days';
import FormAccordion from '../../form/FormAccordion';
import useEmployeeOptions from '../../../hooks/options/useEmployeeOptions';

const schema = Yup.object().shape({
    name: Yup.string().trim().required().label('Name'),
    employee_id: Yup.number().required().label('Provider'),
    payment_type: Yup.string().trim().required().label('Payment Type'),
    start_at: Yup.string().required('Start date is required').typeError('Start date is invalid'),
    entry_duration: Yup.number()
        .min(10, 'Minimal allowed duration is 10 minutes')
        .max(600, 'Maximal allowed duration is 10 hours (600 minutes)')
});

export type RecurringEventFormType = Omit<ICreateRecurringEventPayload, 'employee_id' | 'customers'> & {
    employee_id: number | null;
    customers: Array<RecurringEventCustomerPayload & { title: string }>;
};

type RecurringEventFormProps = {
    defaults: RecurringEventFormType;
    onSubmit: (formData: RecurringEventFormType) => void;
    hideCustomers?: boolean;
    blockEditPaymentType?: boolean;
};

const RecurringEventForm: FC<RecurringEventFormProps> = ({ defaults, onSubmit, hideCustomers, blockEditPaymentType }) => {
    const { handleSubmit, values, touched, errors, setFieldValue, handleChange, handleBlur, resetForm } = useFormik<RecurringEventFormType>(
        {
            enableReinitialize: true,
            onSubmit,
            initialValues: defaults,
            validationSchema: schema
        }
    );

    const { employees, isEmployeesLoading, getEmployeeById } = useEmployeeOptions();

    const handleTimeChange = useCallback(
        (v: { start_at: string; duration?: number }) => {
            setFieldValue('start_at', v.start_at);
            setFieldValue('entry_duration', v.duration);
        },
        [setFieldValue]
    );

    const toggleRecurring = useCallback(
        (v: boolean) => {
            setFieldValue('rules.is_recurring', v);
            setFieldValue('rules.days', v ? getDefaultDays() : null);
        },
        [setFieldValue]
    );

    return (
        <>
            <EntityDrawerContent>
                <Stack
                    onSubmit={handleSubmit}
                    component="form"
                    spacing={2}
                    mb={1}
                    noValidate
                    autoComplete="off"
                    id="new-recurring-event=form"
                >
                    <TextField
                        label="Name"
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.name && !!errors.name}
                        helperText={touched.name ? errors.name : null}
                    />

                    <NumberFormat
                        value={values.price}
                        prefix="$"
                        decimalScale={2}
                        fixedDecimalScale
                        allowNegative={false}
                        onValueChange={({ floatValue }) => setFieldValue('price', floatValue)}
                        customInput={FocusTextField}
                        label="Price"
                        id="price"
                        name="price"
                        onBlur={handleBlur}
                        error={!!touched.price && !!errors.price}
                        helperText={touched.price ? errors.price : null}
                    />

                    <Autocomplete
                        disabled={isEmployeesLoading}
                        value={values.employee_id}
                        options={employees.map(({ id }) => id)}
                        getOptionLabel={(opt) => getEmployeeById(opt)?.title ?? 'Unknown Employee'}
                        onBlur={handleBlur}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id="employee_id"
                                name="employee_id"
                                fullWidth
                                error={!!touched.employee_id && !!errors.employee_id}
                                helperText={touched.employee_id ? errors.employee_id : undefined}
                                label="Provider"
                            />
                        )}
                        onChange={(_e, v) => {
                            if (v) {
                                setFieldValue('employee_id', v);
                            }
                        }}
                    />

                    <TextField
                        id="payment_type"
                        name="payment_type"
                        label="Payment Type"
                        select
                        value={values.payment_type}
                        onBlur={handleBlur}
                        onChange={(e) => {
                            setFieldValue('payment_type', e.target.value);
                        }}
                        fullWidth
                        error={!!touched.payment_type && !!errors.payment_type}
                        helperText={touched.payment_type ? errors.payment_type : undefined}
                        disabled={blockEditPaymentType}
                    >
                        <MenuItem value={RecurrentPaymentType.StartOfMonth}>Start of Month</MenuItem>
                        <MenuItem value={RecurrentPaymentType.JoinDate}>Join Date</MenuItem>
                    </TextField>
                </Stack>

                <FormAccordion label="Date">
                    <Box>
                        <EventTimeSelect
                            values={{ start_at: values.start_at, duration: values.entry_duration }}
                            errors={{ start_at: errors.start_at, duration: errors.entry_duration }}
                            touched={{ start_at: touched.start_at, duration: touched.entry_duration }}
                            onChange={handleTimeChange}
                        />
                    </Box>
                </FormAccordion>

                <FormAccordion label="Rules">
                    <Stack spacing={2}>
                        <InputRow label="Is Recurring" size="sm">
                            <Switch
                                value={values.rules.is_recurring}
                                checked={values.rules.is_recurring}
                                onChange={(_v, checked) => toggleRecurring(checked)}
                            />
                        </InputRow>
                        {values.rules.days ? (
                            <WeekDaysSchedule schedule={values.rules.days} onChange={(v) => setFieldValue('rules.days', v)} />
                        ) : null}
                    </Stack>
                </FormAccordion>

                {!hideCustomers && (
                    <FormAccordion label="Attendees">
                        <RecurringEventCustomers value={values.customers} onChange={(v) => setFieldValue('customers', v)} />
                    </FormAccordion>
                )}
            </EntityDrawerContent>
            <EntityDrawerActions>
                <Button color="primary" variant="text" className="forcedBg" onClick={() => resetForm()}>
                    Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit" form="new-recurring-event=form">
                    Save
                </Button>
            </EntityDrawerActions>
            <Box id="newCustomerFormContainer" />
        </>
    );
};

export default RecurringEventForm;
