import { Autocomplete, TextField, TextFieldProps } from '@mui/material';
import employeeService from '../../services/EmployeeService';
import { FC, useCallback, useMemo } from 'react';

type EmployeeSelectPropsType = {
    value: number | null;
    onChange: (v: number | null) => void;
} & Pick<TextFieldProps, 'id' | 'name' | 'placeholder' | 'disabled' | 'error' | 'helperText' | 'onBlur' | 'label'>;

const EmployeeIdSelect: FC<EmployeeSelectPropsType> = ({
    value,
    onChange,
    id,
    name,
    disabled,
    error,
    onBlur,
    helperText,
    placeholder,
    label
}) => {
    const { data, isLoading } = employeeService.useFetchAllEmployeesQuery({});

    const options = useMemo(() => {
        const arr = data?.data ?? [];

        const opts = arr.map((employee) => ({
            value: employee.id,
            label: employee.title
        }));

        return [{ value: null, label: 'Not Selected' }, ...opts];
    }, [data]);

    const getOptionLabel = useCallback((v: number | null) => options.find((option) => option.value === v)?.label || 'Unknown Employee', [
        options
    ]);

    return (
        <Autocomplete
            id={id}
            onChange={(_event, v) => onChange(v)}
            value={value}
            options={options.map((o) => o.value)}
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    name={name}
                    error={error}
                    onBlur={onBlur}
                    helperText={helperText}
                    disabled={disabled || isLoading}
                    placeholder={placeholder}
                />
            )}
        />
    );
};

export default EmployeeIdSelect;
