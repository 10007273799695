// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
import { useMemo } from 'react';
import useAvailableMenuItems from '../../../../menu-items/use-available-menu-items';
import NavItem from './NavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { filteredItems } = useAvailableMenuItems();

    const navItems = useMemo(
        () =>
            filteredItems.map((item) => {
                switch (item.type) {
                    case 'item':
                        return <NavItem key={item.id} item={item} level={1} />;
                    case 'group':
                        return <NavGroup key={item.id} item={item} />;
                    default:
                        return (
                            <Typography key={item.id} variant="h6" color="error" align="center">
                                Menu Items Error
                            </Typography>
                        );
                }
            }),
        [filteredItems]
    );

    return <>{navItems}</>;
};

export default MenuList;
