import { useCallback } from 'react';
import { openConfirmPopup } from '../store/confirmPopupSlice';
import { closeDialog } from '../store/slices/entityDialogsSlice';
import { useAppDispatch, useAppSelector } from './redux';

const useAppointmentCloseConfirm = () => {
    const dispatch = useAppDispatch();
    const { entityId, entityType } = useAppSelector((state) => state.entityDialogs);
    const { shouldSubmitFormOnTabChange } = useAppSelector((state) => state.calendar);

    const confirmAppointmentClose = useCallback(
        (cb: () => void) => {
            if (entityType === 'appointment' && !!entityId && shouldSubmitFormOnTabChange) {
                dispatch(
                    openConfirmPopup({
                        text: 'Discard unsaved changes?',
                        confirmText: 'Discard',
                        onConfirm: () => {
                            dispatch(closeDialog());
                            cb();
                        }
                    })
                );
            } else {
                cb();
            }
        },
        [dispatch, entityId, entityType, shouldSubmitFormOnTabChange]
    );

    return { confirmAppointmentClose };
};

export default useAppointmentCloseConfirm;
