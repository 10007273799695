import * as Yup from 'yup';

const salesTaxSchema = Yup.object().shape({
    enabled: Yup.boolean().required().label('Sales Tax'),
    included_in_price: Yup.mixed()
        .when('enabled', {
            is: true,
            then: Yup.boolean().required()
        })
        .label('Included In Price'),
    use_for_services: Yup.mixed()
        .when('enabled', {
            is: true,
            then: Yup.boolean().required()
            // .test('use_for_services', 'The services sales tax must be accepted when products sales tax is false.', (v, ctx) => {
            //     if (!ctx.parent.use_for_products) {
            //         return Boolean(v);
            //     }
            //
            //     return true;
            // })
        })
        .label('Services Sales Tax'),
    use_for_products: Yup.mixed()
        .when('enabled', {
            is: true,
            then: Yup.boolean().required()
            // .test('use_for_products', 'The products sales tax must be accepted when services sales tax is false.', (v, ctx) => {
            //     if (!ctx.parent.use_for_services) {
            //         return Boolean(v);
            //     }
            //
            //     return true;
            // })
        })
        .label('Products Sales Tax'),
    override_allowed: Yup.boolean().required('Allow override on location level')
});

export default salesTaxSchema;
