import { lazy } from 'react';

import AuthGuard from 'utils/route-guard/AuthGuard';
import { WebsocketProvider } from '../contexts/WebsocketContext';
import Loadable from 'ui-component/Loadable';
import SupportLayout from '../layout/SupportLayout';
import ErrorCatcher from '../ui-component/error-catcher';

const SelectOrganization = Loadable(lazy(() => import('../views/support/SelectOrganization')));

const SupportRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <WebsocketProvider>
                <SupportLayout />
            </WebsocketProvider>
        </AuthGuard>
    ),
    children: [
        {
            path: '/select-organization',
            element: (
                <ErrorCatcher wrappedComponentName="SelectOrganization">
                    <SelectOrganization />
                </ErrorCatcher>
            )
        }
    ]
};

export default SupportRoutes;
