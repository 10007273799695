import React, { SyntheticEvent, useMemo } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { Fade, Grow, Slide, SlideProps } from '@material-ui/core';
import MuiSnackbar from '@material-ui/core/Snackbar';
import { Alert, Button, IconButton } from '@mui/material';

// assets
import CloseIcon from '@mui/icons-material/Close';

import { DefaultRootStateProps, KeyedObject } from 'types';

// animation function
function TransitionSlideLeft(props: SlideProps) {
    return <Slide {...props} direction="left" />;
}

function TransitionSlideUp(props: SlideProps) {
    return <Slide {...props} direction="up" />;
}

function TransitionSlideRight(props: SlideProps) {
    return <Slide {...props} direction="right" />;
}

function TransitionSlideDown(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}

function GrowTransition(props: SlideProps) {
    return <Grow {...props} />;
}

// animation options
const transition: KeyedObject = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade
};

// ==============================|| SNACKBAR ||============================== //

const Snackbar = () => {
    const [open, setOpen] = React.useState(false);
    const snackbarInitial = useSelector((state: DefaultRootStateProps) => state.snackbar);

    const autoHideDuration = 3000;

    const handleClose = (reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const severity = snackbarInitial.alertSeverity;
    const color = useMemo(() => {
        if (severity === 'warning') {
            return `${severity}.dark`;
        }

        return severity ? `${severity}.main` : undefined;
    }, [severity]);

    const alertSx = useMemo(
        () => ({
            color: '#909092',
            fontSize: '1rem',
            background: '#fff',
            border: 'none',
            borderLeft: '5px solid',
            borderColor: color,
            fontWeight: 500,
            padding: '10px 20px',
            borderRadius: '3px',
            boxShadow: `rgba(149, 157, 165, 0.2) 0px 8px 24px`,
            minHeight: '120px',
            minWidth: '300px',
            maxWidth: '100vw',
            '& > .MuiAlert-message': {
                padding: 0
            },

            '& .MuiAlert-icon': {
                opacity: 1,
                color,
                fontSize: '28px'
            }
        }),
        [color]
    );

    React.useEffect(() => {
        setOpen(snackbarInitial.open);
    }, [snackbarInitial.action, snackbarInitial.open]);

    return (
        <>
            {/* default snackbar */}
            {snackbarInitial.variant === 'default' && (
                <MuiSnackbar
                    anchorOrigin={snackbarInitial.anchorOrigin}
                    open={open}
                    autoHideDuration={autoHideDuration}
                    onClose={(e: SyntheticEvent, v: string) => handleClose(v)}
                    message={snackbarInitial.message}
                    TransitionComponent={transition[snackbarInitial.transition]}
                    action={
                        <>
                            <Button color="secondary" size="small" onClick={() => handleClose()}>
                                UNDO
                            </Button>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={() => handleClose()}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                />
            )}

            {/* alert snackbar */}
            {snackbarInitial.variant === 'alert' && (
                <MuiSnackbar
                    TransitionComponent={transition[snackbarInitial.transition]}
                    anchorOrigin={snackbarInitial.anchorOrigin}
                    open={open}
                    autoHideDuration={autoHideDuration}
                    onClose={(e: SyntheticEvent, v: string) => handleClose(v)}
                >
                    <Alert
                        severity={snackbarInitial.alertSeverity}
                        action={
                            <>
                                {snackbarInitial.actionButton !== false && (
                                    <Button color="inherit" onClick={() => handleClose()}>
                                        UNDO
                                    </Button>
                                )}
                                {snackbarInitial.close !== false && (
                                    <IconButton sx={{ fontSize: '28px' }} aria-label="close" color="inherit" onClick={() => handleClose()}>
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                )}
                            </>
                        }
                        sx={alertSx}
                    >
                        {/* <AlertTitle */}
                        {/*    sx={{ */}
                        {/*        fontSize: '1.17em', */}
                        {/*        color: '#000', */}
                        {/*        marginBottom: 0.5 */}
                        {/*    }} */}
                        {/* > */}
                        {/*    {title} */}
                        {/* </AlertTitle> */}
                        {snackbarInitial.message}
                    </Alert>
                </MuiSnackbar>
            )}
        </>
    );
};

export default Snackbar;
