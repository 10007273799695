import { useCallback, useMemo, useState } from 'react';
import { IAppointment } from '../../../models/IAppointment';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Chip, Stack, alpha, Divider, ButtonBase } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import moment from 'moment/moment';
import { styled } from '@mui/material/styles';
import useGetColorByStatus from '../../../hooks/useGetColorByStatus';
import { getServiceArrayPivotPrice, getServiceName, isAllServicesFree } from '../../../utils/services';
import NumberFormat from 'react-number-format';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
    margin: '10px 10px 10px 0 !important',
    backgroundColor: theme.palette.grey[50],
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '16px !important',
    overflow: 'hidden',

    '&:first-of-type': {
        marginTop: '0 !important'
    },

    '&:before': {
        display: 'none'
    },

    '& .MuiTypography-root': {
        color: theme.palette.grey[800]
    },

    '& .MuiAccordionSummary-root': {
        padding: theme.spacing(2),
        alignItems: 'flex-start'
    },

    '& .MuiAccordionSummary-content': {
        '&, &.Mui-expanded': {
            margin: '0'
        }
    },

    '& .MuiAccordionDetails-root': {
        '& .MuiTypography-root': {
            color: alpha(theme.palette.grey[800], 0.6),
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
        }
    }
}));

interface AppointmentRowProps {
    appointment: IAppointment;
    onViewAppointment?: () => void;
}

const AppointmentRow = ({ appointment, onViewAppointment }: AppointmentRowProps) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const { getColorByStatus } = useGetColorByStatus();

    const toggleIsOpened = useCallback(() => setIsOpened(!isOpened), [isOpened]);
    const { fullValue, counter, firstItemValue } = getServiceName(appointment.services);
    const counterTitle = counter ? `${firstItemValue} + ${counter}` : firstItemValue;

    const price = useMemo(() => getServiceArrayPivotPrice(appointment.services), [appointment.services]);
    const isFree = useMemo(() => isAllServicesFree(appointment.services), [appointment.services]);

    return (
        <StyledAccordion expanded={isOpened} onChange={toggleIsOpened}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box width="100%">
                    <Typography sx={{ fontSize: '1rem', lineHeight: 1.5, fontWeight: 600, mb: 1 }}>
                        {counterTitle}

                        <Chip
                            size="small"
                            label={appointment.status}
                            variant="filled"
                            sx={{
                                textTransform: 'capitalize',
                                borderRadius: '8px',
                                ml: 1,
                                color: getColorByStatus(appointment.status),
                                backgroundColor: alpha(getColorByStatus(appointment.status), 0.15)
                            }}
                        />
                    </Typography>
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <AccountCircleOutlinedIcon />
                        <span>{appointment.employee.title}</span>
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <Divider sx={{ mb: 1, mt: -1.5 }} />
                <Stack direction="row">
                    <Stack spacing={1} flexGrow={1}>
                        {counter ? (
                            <Typography>
                                <span>{`Services: ${fullValue}`}</span>
                            </Typography>
                        ) : null}

                        <Typography>
                            <CalendarMonthOutlinedIcon />
                            {moment(appointment.start_at).format('MMM D, YYYY')}
                        </Typography>
                        <Typography>
                            <AccessTimeIcon />
                            {moment(appointment.start_at).format('hh:mm A')} -{' '}
                            {moment(appointment.end_at).clone().add(1, 'second').format('hh:mm A')}{' '}
                        </Typography>
                        {price && !isFree ? (
                            <Typography>
                                <LocalAtmIcon />
                                <NumberFormat displayType="text" value={price} prefix="$" decimalScale={2} fixedDecimalScale />
                            </Typography>
                        ) : null}

                        {isFree ? (
                            <Typography>
                                <LocalAtmIcon />
                                Free
                            </Typography>
                        ) : null}
                    </Stack>
                    {onViewAppointment ? (
                        <Box flexGrow={0} flexShrink={0}>
                            <ButtonBase
                                sx={{ color: 'primary.200', textDecoration: 'underline', fontWeight: 600 }}
                                onClick={onViewAppointment}
                            >
                                View
                            </ButtonBase>
                        </Box>
                    ) : null}
                </Stack>
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default AppointmentRow;
