import { FC } from 'react';

const PayPalColoredIcon: FC<{ scale?: number }> = ({ scale = 1 }) => (
    <svg width={47 * scale} height={12 * scale} viewBox="0 0 47 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M36.3319 3.13043H33.7954C33.6462 3.13043 33.497 3.2795 33.4224 3.42857L32.3779 9.98757C32.3779 10.1366 32.4525 10.2112 32.6017 10.2112H33.9446C34.0938 10.2112 34.1684 10.1366 34.1684 9.98757L34.4668 8.12422C34.4668 7.97515 34.616 7.82609 34.8398 7.82609H35.6605C37.3763 7.82609 38.3462 7.00621 38.57 5.36646C38.7192 4.69565 38.57 4.09938 38.2716 3.72671C37.824 3.35404 37.1525 3.13043 36.3319 3.13043ZM36.6303 5.59006C36.4811 6.48447 35.8097 6.48447 35.1382 6.48447H34.6906L34.989 4.77018C34.989 4.69565 35.0636 4.62112 35.2128 4.62112H35.3621C35.8097 4.62112 36.2573 4.62112 36.4811 4.91925C36.6303 4.99379 36.6303 5.21739 36.6303 5.59006Z"
            fill="#139AD6"
        />
        <path
            d="M18.0538 3.13043H15.5173C15.3681 3.13043 15.2189 3.2795 15.1443 3.42857L14.0999 9.98757C14.0999 10.1366 14.1745 10.2112 14.3237 10.2112H15.5173C15.6665 10.2112 15.8157 10.0621 15.8903 9.91304L16.1887 8.12422C16.1887 7.97515 16.3379 7.82609 16.5618 7.82609H17.3824C19.0983 7.82609 20.0681 7.00621 20.2919 5.36646C20.4411 4.69565 20.2919 4.09938 19.9935 3.72671C19.5459 3.35404 18.9491 3.13043 18.0538 3.13043ZM18.3522 5.59006C18.203 6.48447 17.5316 6.48447 16.8602 6.48447H16.4872L16.7856 4.77018C16.7856 4.69565 16.8602 4.62112 17.0094 4.62112H17.1586C17.6062 4.62112 18.0538 4.62112 18.2776 4.91925C18.3522 4.99379 18.4268 5.21739 18.3522 5.59006ZM25.7379 5.51553H24.5443C24.4697 5.51553 24.3205 5.59006 24.3205 5.66459L24.2459 6.03726L24.1713 5.8882C23.8729 5.51553 23.3506 5.36646 22.7538 5.36646C21.411 5.36646 20.2173 6.40994 19.9935 7.82609C19.8443 8.57143 20.0681 9.24223 20.4411 9.68944C20.8141 10.1366 21.3364 10.2857 22.0078 10.2857C23.1268 10.2857 23.7237 9.6149 23.7237 9.6149L23.6491 9.98757C23.6491 10.1366 23.7237 10.2112 23.8729 10.2112H24.9919C25.1411 10.2112 25.2903 10.0621 25.3649 9.91304L26.0364 5.73913C25.9618 5.66459 25.8126 5.51553 25.7379 5.51553ZM24.0221 7.90062C23.8729 8.57143 23.3506 9.09317 22.6046 9.09317C22.2316 9.09317 21.9332 8.9441 21.784 8.79503C21.6348 8.57143 21.5602 8.27329 21.5602 7.90062C21.6348 7.22981 22.2316 6.70807 22.903 6.70807C23.276 6.70807 23.4999 6.85714 23.7237 7.00621C23.9475 7.22981 24.0221 7.60248 24.0221 7.90062Z"
            fill="#263B80"
        />
        <path
            d="M43.9413 5.51552H42.7477C42.6731 5.51552 42.5239 5.59006 42.5239 5.66459L42.4493 6.03726L42.3747 5.88819C42.0762 5.51552 41.554 5.36646 40.9572 5.36646C39.6143 5.36646 38.4207 6.40993 38.1969 7.82608C38.0477 8.57142 38.2715 9.24223 38.6445 9.68944C39.0175 10.1366 39.5397 10.2857 40.2112 10.2857C41.3302 10.2857 41.927 9.6149 41.927 9.6149L41.8524 9.98757C41.8524 10.1366 41.927 10.2112 42.0762 10.2112H43.1953C43.3445 10.2112 43.4937 10.0621 43.5683 9.91304L44.2397 5.73913C44.1651 5.66459 44.0905 5.51552 43.9413 5.51552ZM42.2255 7.90062C42.0762 8.57142 41.554 9.09316 40.808 9.09316C40.435 9.09316 40.1366 8.9441 39.9874 8.79503C39.8382 8.57142 39.7635 8.27329 39.7635 7.90062C39.8382 7.22981 40.435 6.70807 41.1064 6.70807C41.4794 6.70807 41.7032 6.85714 41.927 7.00621C42.2255 7.22981 42.3001 7.60248 42.2255 7.90062Z"
            fill="#139AD6"
        />
        <path
            d="M32.2286 5.51553H30.9603C30.8111 5.51553 30.7365 5.59007 30.6619 5.6646L29.0206 8.19876L28.2746 5.81367C28.2 5.6646 28.1254 5.59007 27.9016 5.59007H26.7079C26.5587 5.59007 26.4841 5.73914 26.4841 5.8882L27.827 9.83852L26.5587 11.6273C26.4841 11.7764 26.5587 12 26.7079 12H27.9016C28.0508 12 28.1254 11.9255 28.2 11.8509L32.3032 5.96274C32.527 5.73914 32.3778 5.51553 32.2286 5.51553Z"
            fill="#263B80"
        />
        <path
            d="M45.3587 3.35404L44.3142 10.0621C44.3142 10.2112 44.3888 10.2857 44.538 10.2857H45.5825C45.7317 10.2857 45.8809 10.1367 45.9555 9.98758L46.9999 3.42858C46.9999 3.27951 46.9253 3.20498 46.7761 3.20498H45.5825C45.5079 3.13044 45.4333 3.20498 45.3587 3.35404Z"
            fill="#139AD6"
        />
        <path
            d="M8.57936 0.894409C8.05714 0.298136 7.0873 0 5.74444 0H2.01429C1.79048 0 1.56667 0.223601 1.49206 0.447204L0 10.2112C0 10.4348 0.149206 10.5839 0.298413 10.5839H2.61111L3.20794 6.93168V7.08075C3.28254 6.85714 3.50635 6.63354 3.73016 6.63354H4.84921C7.0127 6.63354 8.65397 5.73913 9.17619 3.2795V3.0559C9.10159 3.0559 9.10159 3.0559 9.17619 3.0559C9.25079 2.08696 9.10159 1.49068 8.57936 0.894409Z"
            fill="#263B80"
        />
        <path
            d="M9.10149 3.05589V3.2795C8.57927 5.81366 6.938 6.63353 4.77451 6.63353H3.65546C3.43165 6.63353 3.20784 6.85714 3.13324 7.08074L2.38721 11.6273C2.38721 11.7764 2.46181 11.9255 2.68562 11.9255H4.6253C4.84911 11.9255 5.07292 11.7764 5.07292 11.5528V11.4783L5.44594 9.16769V9.01863C5.44594 8.79502 5.66975 8.64596 5.89356 8.64596H6.19197C8.05705 8.64596 9.54911 7.90061 9.92213 5.66459C10.0713 4.77018 9.99673 3.9503 9.54911 3.42856C9.47451 3.2795 9.3253 3.13043 9.10149 3.05589Z"
            fill="#139AD6"
        />
        <path
            d="M8.57919 2.83231C8.50459 2.83231 8.42999 2.75777 8.35538 2.75777C8.28078 2.75777 8.20618 2.75777 8.13157 2.68324C7.83316 2.6087 7.53475 2.6087 7.16173 2.6087H4.25221C4.1776 2.6087 4.103 2.6087 4.0284 2.68324C3.87919 2.75777 3.80459 2.90684 3.80459 3.05591L3.20776 6.93168V7.08075C3.28237 6.85715 3.50618 6.63355 3.72999 6.63355H4.84903C7.01253 6.63355 8.6538 5.73914 9.17602 3.27951C9.17602 3.20498 9.17602 3.13044 9.25062 3.05591C9.10141 2.98137 9.02681 2.90684 8.8776 2.90684C8.65379 2.83231 8.65379 2.83231 8.57919 2.83231Z"
            fill="#232C65"
        />
    </svg>
);

export default PayPalColoredIcon;
