import { FC, useCallback, useEffect, useState } from 'react';
import { IAppointment } from '../../../models/IAppointment';
import { FormControlLabel, Switch, Box, Stack } from '@mui/material';
import ResendConfirmationButton from './ResendConfirmationButton';
import ResendWaiverButton from './ResendWaiverButton';
import useAuth from '../../../hooks/useAuth';
import { useAppointmentFunctions, useSaveAppointment } from '../../../hooks/appointments';

const AppointmentNotifications: FC<{ appointment: IAppointment; isReadOnly?: boolean }> = ({ appointment, isReadOnly }) => {
    const [checked, setChecked] = useState(Boolean(appointment.is_notifications_enabled));
    const { user } = useAuth();
    const { saveAppointment } = useSaveAppointment();
    const { formatAppointmentPayload } = useAppointmentFunctions();

    const isReSendBtnDisabled = !user?.currentCompany.settings?.notifications?.enabled;

    const handleToggle = useCallback(
        (e: unknown, v: boolean) => {
            setChecked(v);
            const data = formatAppointmentPayload(appointment);
            data.is_notifications_enabled = v;
            saveAppointment(data, undefined, appointment.id.toString());
        },
        [appointment, formatAppointmentPayload, saveAppointment]
    );

    useEffect(() => {
        setChecked((prev) => {
            const incomingValue = Boolean(appointment.is_notifications_enabled);
            return prev !== incomingValue ? incomingValue : prev;
        });
    }, [appointment.is_notifications_enabled]);

    return (
        <Stack spacing={2} direction="row" alignItems="center" sx={{ width: '100%' }}>
            <Box sx={{ flexGrow: 1 }}>
                <FormControlLabel
                    control={<Switch checked={checked} onChange={handleToggle} disabled={isReadOnly} />}
                    label="Send Notifications"
                    labelPlacement="start"
                    sx={{ margin: 0, width: '100%', justifyContent: 'space-between' }}
                />
            </Box>

            {!isReadOnly && (
                <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
                    <ResendConfirmationButton appointmentId={appointment.id} disabled={isReSendBtnDisabled} />
                </Box>
            )}

            {appointment.has_waiver === 'not_signed' && !isReadOnly && (
                <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
                    <ResendWaiverButton appointment_id={appointment.id ?? 0} />
                </Box>
            )}
        </Stack>
    );
};

export default AppointmentNotifications;
