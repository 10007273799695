import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Box } from '@mui/material';
import 'assets/scss/textEditor.scss';

interface ITextEditorProps {
    value: string | null;
    setFieldValue: (val: string) => void;
}

const TextEditor = ({ value, setFieldValue }: ITextEditorProps) => {
    const prepareDraft = (htmlValue: string) => {
        const draft = htmlToDraft(htmlValue);
        const contentState = ContentState.createFromBlockArray(draft.contentBlocks);
        return EditorState.createWithContent(contentState);
    };

    const [editorState, setEditorState] = React.useState(value ? prepareDraft(value) : EditorState.createEmpty());

    const onEditorStateChange = (newEditorState: EditorState) => {
        const forFormik = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
        setFieldValue(forFormik);
        setEditorState(newEditorState);
    };
    return (
        <Box
            sx={{
                '& .rdw-editor-wrapper': {
                    bgcolor: 'background.paper',
                    border: '1px solid',
                    borderColor: 'newColors.darkBlue',
                    borderRadius: '8px',
                    padding: 1,
                    '& .rdw-editor-main': {
                        p: 0,
                        border: 'none',
                        minHeight: '150px'
                    },
                    '& .rdw-editor-toolbar': {
                        pt: 1.25,
                        border: 'none',
                        borderBottom: '1px solid',
                        borderColor: '#F1F3F9',
                        bgcolor: '#F1F3F9',
                        borderRadius: '4px',
                        '& .rdw-option-wrapper': {
                            bgcolor: '#F1F3F9',
                            borderColor: '#F1F3F9'
                        },
                        '& .rdw-dropdown-wrapper': {
                            bgcolor: '#F1F3F9',
                            borderColor: '#F1F3F9',
                            '& .rdw-dropdown-selectedtext': {
                                color: 'grey.900'
                            },

                            '&:hover': {
                                boxShadow: 'none'
                            }
                        }
                    }
                }
            }}
        >
            <Editor
                editorState={editorState}
                wrapperClassName="custom-wrapper"
                editorClassName="custom-editor"
                onEditorStateChange={onEditorStateChange}
                toolbar={{
                    options: [
                        'inline',
                        'blockType',
                        'fontSize',
                        'fontFamily',
                        'list',
                        'textAlign',
                        'colorPicker',
                        'link',
                        'embedded',
                        'emoji',
                        'image',
                        'remove',
                        'history'
                    ],
                    inline: {
                        inDropdown: true
                    },
                    blockType: {
                        inDropdown: true
                    },
                    fontSize: {
                        inDropdown: true
                    },
                    list: {
                        inDropdown: true
                    },
                    textAlign: {
                        inDropdown: true
                    },
                    link: {
                        inDropdown: true
                    }
                }}
            />
        </Box>
    );
};

export default TextEditor;
