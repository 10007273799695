import { Box, Breadcrumbs, useMediaQuery, Theme } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import UpdatedStyleWrapper from '../../ui-component/updated-style-wrapper';
import useAppBreadcrumbs, { routeNames } from '../../hooks/use-app-breadcrumbs';

const CBBreadcrumbs = () => {
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const { breadcrumbs, showBreadcrumbs } = useAppBreadcrumbs();

    if (!showBreadcrumbs) {
        return null;
    }

    return (
        <UpdatedStyleWrapper>
            <Box
                sx={{
                    borderBottom: '1px solid',
                    borderTop: '1px solid',
                    borderBottomColor: 'grey.300',
                    borderTopColor: 'grey.300',
                    px: matchSm ? '16px' : '20px',
                    py: matchSm ? '16px' : '20px'
                }}
            >
                <Breadcrumbs separator={<ChevronRightIcon />}>
                    {breadcrumbs.map(({ match, breadcrumb }, index) =>
                        index === breadcrumbs.length - 1 ? (
                            <Box key={match.pathname} component="span">
                                {routeNames[match.pathname] ?? breadcrumb}
                            </Box>
                        ) : (
                            <Link key={match.pathname} to={match.pathname}>
                                {routeNames[match.pathname] ?? breadcrumb}
                            </Link>
                        )
                    )}
                </Breadcrumbs>
            </Box>
        </UpdatedStyleWrapper>
    );
};

export default CBBreadcrumbs;
