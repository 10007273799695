import { FC, RefObject } from 'react';

import { IButtonProps } from './interfaces/IButtonProps';
import { Button, useTheme } from '@material-ui/core';
import getSizeButtons from './GetSizeButtons';
import { useMediaQuery } from '@mui/material';

interface FilledButtonProps extends IButtonProps {
    buttonRef?: RefObject<HTMLButtonElement>;
}

const FilledButton: FC<FilledButtonProps> = ({ text, onClick, type, size = 'md', buttonRef, ...props }) => {
    const theme = useTheme();
    const matchSm = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Button
            {...props}
            variant="contained"
            onClick={onClick}
            type={type}
            sx={{
                minWidth: matchSm ? undefined : getSizeButtons(size),
                backgroundColor: theme.palette.primary?.main,
                justifyContent: 'center',
                alignItems: 'center',
                color: theme.palette.background?.paper,
                borderRadius: '8px',
                fontSize: '14px',
                fontWeight: 700,
                padding: '8px',
                boxShadow: 'none',
                border: `1px solid ${theme.palette.primary?.main}`,
                '&:hover': {
                    '@media (hover: none)': {
                        backgroundColor: theme.palette.primary?.main,
                        color: theme.palette.background?.paper,
                        boxShadow: 'none'
                    },
                    backgroundColor: theme.palette.background?.paper,
                    color: theme.palette.primary?.main
                },
                '&:disabled': {
                    borderColor: 'currentColor'
                }
            }}
            ref={buttonRef}
        >
            {text}
        </Button>
    );
};

export default FilledButton;
