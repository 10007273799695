import { ForwardedRef, HTMLAttributes, useMemo } from 'react';
import { Box, Chip, Link, Theme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { SxProps } from '@mui/system';

interface ISearchPaperProps {
    count: number;
    query: string;
    listProps: HTMLAttributes<HTMLElement>;
    innerRef: ForwardedRef<HTMLElement>;
    reversed?: boolean;
    onSeeAllCb?: () => void;
}

const SearchListBox = ({ count, query, listProps, innerRef, reversed, onSeeAllCb }: ISearchPaperProps) => {
    const MAX_RESULTS = 10;
    const { children, ...rest } = listProps;

    const showFooter = useMemo(() => count > MAX_RESULTS, [count]);

    const footerSxProps = useMemo<SxProps<Theme>>(
        () => ({
            textTransform: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: reversed ? '40px' : '38px',
            position: reversed ? 'absolute' : 'sticky',
            bottom: reversed ? 'initial' : '-2px',
            borderBottom: reversed ? 'none !important' : undefined,
            zIndex: 10,
            width: reversed ? '100%' : undefined,
            borderTopLeftRadius: reversed ? '16px' : undefined,
            borderTopRightRadius: reversed ? '16px' : undefined
        }),
        [reversed]
    );

    return (
        <Box
            {...rest}
            component="ul"
            ref={innerRef}
            sx={{
                '&.MuiAutocomplete-listbox': {
                    padding: 0,
                    paddingTop: reversed && showFooter ? '38px' : 0,
                    display: 'flex',
                    flexDirection: reversed ? 'column-reverse' : 'column',
                    position: 'static'
                },
                '& .MuiListSubheader-root': {
                    fontWeight: 700,
                    color: 'grey.300',
                    fontSize: '0.625rem',
                    lineHeight: '1.75',
                    background: '#fff',
                    borderTop: 'none',
                    borderBottom: 'none',
                    top: 0,
                    textTransform: 'uppercase'
                }
            }}
        >
            {children}
            {showFooter ? (
                <Box component="li" className="MuiListSubheader-root" paddingX={2} sx={footerSxProps}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                            mr={0.5}
                            sx={{
                                fontSize: '0.75rem',
                                color: 'newColors.darkBlue',
                                fontWeight: 500
                            }}
                        >
                            Result
                        </Box>
                        <Chip
                            label={count}
                            size="small"
                            variant="outlined"
                            sx={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                color: 'newColors.darkBlue',
                                minWidth: '28px',
                                border: '1px solid transparent',
                                backgroundColor: 'grey.200',
                                height: 'auto',

                                '& .MuiChip-label': {
                                    paddingX: 0.5,
                                    fontSize: '12px',
                                    lineHeight: '16px'
                                }
                            }}
                        />
                    </Box>
                    <Link
                        sx={{ fontSize: '0.75rem' }}
                        to={`/search?query=${query}`}
                        onClick={onSeeAllCb}
                        underline="none"
                        component={RouterLink}
                    >
                        See All
                    </Link>
                </Box>
            ) : null}
        </Box>
    );
};

export default SearchListBox;
