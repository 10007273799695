import { FC } from 'react';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { Button, IconButton, TextField, TextFieldProps, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';

interface IOverrideInputProps {
    overrideValue: number | null;
    hasOverride: boolean;
    onOverrideChange?: (v: boolean) => void;
    onChange: (v: NumberFormatValues) => void;
    toggleLabel: string;
    fieldProps: NumberFormatProps<TextFieldProps>;
    fullWidth?: boolean;
}

const OverrideInput: FC<IOverrideInputProps> = ({
    overrideValue,
    hasOverride,
    onOverrideChange,
    onChange,
    fieldProps,
    toggleLabel,
    fullWidth
}) => {
    if (hasOverride) {
        const { helperText, InputProps, ...restFieldProps } = fieldProps;
        return (
            <NumberFormat
                value={overrideValue ?? ''}
                customInput={TextField}
                onValueChange={onChange}
                {...restFieldProps}
                fullWidth
                decimalScale={2}
                InputProps={{
                    ...InputProps,
                    sx: { pr: 1, ...InputProps?.sx },
                    endAdornment: (
                        <>
                            {helperText ? (
                                <Tooltip title={helperText}>
                                    <InfoIcon color="error" />
                                </Tooltip>
                            ) : null}
                            {onOverrideChange ? (
                                <IconButton color="inherit" size="small" onClick={() => onOverrideChange(false)}>
                                    <ClearIcon />
                                </IconButton>
                            ) : undefined}
                        </>
                    )
                }}
                sx={!fullWidth ? { width: '140px' } : undefined}
            />
        );
    }

    return (
        <Button
            sx={{ width: fullWidth ? '100%' : '140px', height: '40px', p: 1 }}
            variant="contained"
            onClick={() => onOverrideChange?.(true)}
            disableElevation
        >
            {toggleLabel}
        </Button>
    );
};

export default OverrideInput;
