import { Box, Drawer, IconButton, Typography } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { FC, PropsWithChildren } from 'react';

export type CommonBottomDrawerProps = {
    open: boolean;
    onClose: () => void;
    title: string;
};

const CommonBottomDrawer: FC<PropsWithChildren<CommonBottomDrawerProps>> = ({ open, onClose, title, children }) => (
    <Drawer open={open} onClose={onClose} anchor="bottom" PaperProps={{ sx: { borderRadius: '20px 20px 0 0' } }}>
        <Box p={2}>
            <Box sx={{ color: 'grey.800', textAlign: 'right' }}>
                <IconButton onClick={onClose} color="inherit">
                    <ClearOutlinedIcon />
                </IconButton>
            </Box>
            <Typography
                variant="h5"
                sx={{
                    fontWeight: 600,
                    fontSize: '1.5rem',
                    lineHeight: '1.15',
                    mb: 2
                }}
            >
                {title}
            </Typography>
            {children}
        </Box>
    </Drawer>
);

export default CommonBottomDrawer;
