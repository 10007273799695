import { FC } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';

const SelfCheckoutTrigger: FC<{
    available: boolean;
    disabled?: boolean;
    onClick: () => void;
    isMobile?: boolean;
}> = ({ available, disabled, onClick, isMobile }) => (
    <Tooltip title={!available ? 'Please set up Twillio and payment integration to use this feature.' : ''}>
        <Box component="span" sx={{ alignSelf: 'flex-start', flexShrink: 0 }}>
            <Button
                onClick={onClick}
                variant="text"
                color="primary"
                className="forcedBg"
                disabled={!available || disabled}
                endIcon={isMobile ? undefined : <KeyboardArrowDownOutlinedIcon />}
                size={isMobile ? 'small' : undefined}
            >
                Self Checkout
            </Button>
        </Box>
    </Tooltip>
);

export default SelfCheckoutTrigger;
