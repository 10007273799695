import { ImageData } from '../models/IImage';

const preferredImageSize = (img?: string | ImageData | null, size?: 'small' | 'medium' | null): string | undefined => {
    if (!img || typeof img === 'string') {
        return img ?? undefined;
    }
    const key: 'url' | 'url_small' | 'url_medium' = size ? `url_${size}` : `url`;

    return img[key] ? img[key] : img.url;
};

export default preferredImageSize;
