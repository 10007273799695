import { useCallback, useEffect, useMemo, useState } from 'react';
import { IFileUploaderData, ImageData as ModifiedImageData } from '../models/IImage';
import { v4 } from 'uuid';

const DEFAULT: Array<IFileUploaderData | ModifiedImageData> = [];

const useImagesManagement = (defaults?: Array<IFileUploaderData | ModifiedImageData>) => {
    const [images, setImages] = useState<Array<IFileUploaderData | ModifiedImageData>>(defaults ?? DEFAULT);
    const [deletedImages, setDeletedImages] = useState<Array<number>>([]);

    const visibleImages = useMemo(() => images.filter(({ id }) => !deletedImages.some((deletedId) => deletedId === id)), [
        deletedImages,
        images
    ]);

    const imagesToUpload = useMemo<Array<File | Blob>>(() => {
        const files = images.map((image) => ('file' in image ? image.file : undefined));
        return files.filter((f) => !!f) as File[];
    }, [images]);

    const onAddNewImage = useCallback(
        (file: File) =>
            setImages((prev) => [
                ...prev,
                {
                    id: v4(),
                    file,
                    filename: file.name
                }
            ]),
        []
    );

    const onDeleteImage = useCallback((id: number | string | undefined) => {
        if (typeof id === 'number') {
            setDeletedImages((prev) => [...prev, id]);
        }

        if (typeof id === 'string') {
            setImages((prev) => prev.filter((img) => img.id !== id));
        }
    }, []);

    const onUpdateImage = useCallback(
        (data: IFileUploaderData, oldId?: number) => {
            setImages((prev) => {
                const imageExists = prev.some((img) => img.id === data.id);
                return imageExists ? prev.map((img) => (img.id === data.id ? data : img)) : [...prev, data];
            });

            if (oldId) {
                onDeleteImage(oldId);
            }
        },
        [onDeleteImage]
    );

    const clearState = useCallback(() => {
        setImages(defaults ?? []);
        setDeletedImages([]);
    }, [defaults]);

    useEffect(() => {
        if (defaults) {
            setImages(defaults);
            setDeletedImages([]);
        }
    }, [defaults]);

    return { images, visibleImages, deletedImages, onAddNewImage, onDeleteImage, onUpdateImage, imagesToUpload, clearState };
};

export default useImagesManagement;
