import { Box, Divider, Link, Stack } from '@mui/material';
import AppointmentCustomerForm from './elements/AppointmentCustomerForm';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks/redux';
import { useCallback, useMemo } from 'react';
import { UserRole } from '../../../../../../../models/IEmployee';
import useAuth from '../../../../../../../hooks/useAuth';
import moment from 'moment-timezone';
import { appDateFormat } from '../../../../../../../store/constant';
import { closeDialog, DialogTypes, openDialog } from '../../../../../../../store/slices/entityDialogsSlice';
import DeleteWaiverButton from '../../../../components/DeleteWaiverButton';
import useEntityDrawerSpacing from '../../../../../../entity-drawer-layout/use-entity-drawer-spacing';
import AppointmentNotifications from '../../../../../../appointment-form/elements/AppointmentNotifications';
import CustomerAppointments from '../../../../../../customer-appointments';
import SectionHeading from '../../../../../../SectionHeading';

interface ICustomerInfoProps {
    onResetTabs: () => void;
}

const CustomerInfo = ({ onResetTabs }: ICustomerInfoProps) => {
    const { spacingX } = useEntityDrawerSpacing();
    const { user } = useAuth();
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);

    const dispatch = useAppDispatch();

    const handleViewAppointment = useCallback(
        (id: number) => {
            dispatch(closeDialog());
            dispatch(openDialog({ type: DialogTypes.Appointment, id }));
            onResetTabs();
        },
        [dispatch, onResetTabs]
    );

    const hasWaiver = selectedEvent?.has_waiver;
    const eventSignedWaivers = selectedEvent?.signed_waiver;
    const role = user?.employee.role.name;
    const currentUserId = user?.employee.id;
    const eventEmployeeId = selectedEvent?.employee.id;

    const isWaiverVisibleToUser = useMemo(() => {
        if (role === UserRole.Provider) {
            return eventEmployeeId === currentUserId;
        }
        return true;
    }, [currentUserId, eventEmployeeId, role]);

    return (
        <Stack px={spacingX} spacing={3} display="flex" flexDirection="column" divider={<Divider />}>
            <AppointmentCustomerForm />
            {!!hasWaiver && !!eventSignedWaivers && eventSignedWaivers.length > 0 && isWaiverVisibleToUser && (
                <Stack spacing={2}>
                    <SectionHeading>Forms</SectionHeading>
                    {eventSignedWaivers.map((waiver) => (
                        <Box key={waiver.service_id}>
                            <Link href={waiver.url ?? ''} type="download" target="_blank" sx={{ mr: 0.5 }}>
                                {`${waiver.waiver_name ?? 'Untitled'} (${moment(waiver.waiver_signed_at).format(appDateFormat)})`}
                            </Link>
                            <DeleteWaiverButton serviceId={waiver.service_id} appointmentId={selectedEvent?.id ?? ''} />
                        </Box>
                    ))}
                </Stack>
            )}

            {selectedEvent ? (
                <Stack spacing={2}>
                    <SectionHeading>Appointment Notifications</SectionHeading>
                    <Stack direction="row" spacing={2}>
                        <AppointmentNotifications appointment={selectedEvent} isReadOnly={isForeignAppointment} />
                    </Stack>
                </Stack>
            ) : null}
            <Stack spacing={2}>
                <SectionHeading mt={3} mb={0.5}>
                    Customer Appointments
                </SectionHeading>
                {selectedEvent && <CustomerAppointments customerId={selectedEvent.customer.id} onViewAppointment={handleViewAppointment} />}
            </Stack>
        </Stack>
    );
};

export default CustomerInfo;
