import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { Button, Link, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom';
import AuthCardWrapper from './AuthCardWrapper';
import Logo from '../../ui-component/Logo';
import AuthWrapper from './AuthWrapper';
import { useTheme } from '@material-ui/core/styles';
import { useCallback } from 'react';
import { SET_SUB_RENEW_DATA } from '../../store/account/actions';
import { useAppDispatch } from '../../hooks/redux';

const subtitle = 'Subscription activation required';
const cancelBtnLabel = 'Cancel';
const okBtnLabel = 'Purchase';

const SubRequired = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { stripe_url, required_subscription } = useSelector((AppState: RootState) => AppState.account);

    const onCancel = useCallback(() => {
        dispatch({ type: SET_SUB_RENEW_DATA, payload: { stripe_url: null, required_subscription: null } });
        navigate('/login');
    }, [dispatch, navigate]);

    if (!stripe_url || !required_subscription) {
        return <Navigate to="/login" />;
    }
    return (
        <AuthWrapper>
            <Grid container justifyContent="center" alignItems={matchDownSM ? 'flex-start' : 'center'} sx={{ minHeight: '100vh' }}>
                <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                    <AuthCardWrapper>
                        <Stack spacing={3} alignItems="center">
                            <RouterLink to="/">
                                <Logo />
                            </RouterLink>
                            <Typography variant="caption" fontSize="16px" textAlign="center" component="div">
                                {subtitle}
                            </Typography>
                            <Stack direction="row" spacing={3} justifyContent="center">
                                <Button variant="outlined" onClick={onCancel}>
                                    {cancelBtnLabel}
                                </Button>
                                <Link href={stripe_url}>
                                    <Button disableElevation variant="contained">
                                        {okBtnLabel}
                                    </Button>
                                </Link>
                            </Stack>
                        </Stack>
                    </AuthCardWrapper>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
};

export default SubRequired;
