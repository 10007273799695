import { FC, useMemo, useState } from 'react';
import companyAPI from '../../../services/CompanyService';
import { Box, Stack, Typography, Link, Alert, useMediaQuery, Theme } from '@mui/material';
import AppPagePagination from '../../../ui-component/PagePagination';
import ApiKeyCreate from './ApiKeyCreate';
import ApiKeyTableRow from './ApiKeyTableRow';
import CBTable, { CbTableColumn } from '../../../ui-component/tables/CBTable';
import config from '../../../config';

const columns: CbTableColumn[] = [
    { id: 'name', label: 'Name' },
    { id: 'created_at', label: 'Created At' },
    { id: 'last_used_at', label: 'Last Used At' },
    { id: 'actions', label: 'Actions' }
];

const ApiKeysList: FC<{ companyId: number }> = ({ companyId }) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('md'));

    const [page, setPage] = useState<number>(1);
    const { data, isLoading } = companyAPI.useGetCompanyApiKeysQuery({ companyId, params: { page, per_page: 5 } });

    const rows = useMemo(() => (data ? data.data : []), [data]);

    return (
        <Box>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2} mb={0.5} pb={1} alignItems={!isMobile ? 'center' : 'flex-start'}>
                <Typography variant="h4" sx={{ flexGrow: 1, flexShrink: 1 }}>
                    API Keys for External Requests
                </Typography>

                <Alert
                    severity="info"
                    sx={{
                        padding: '0px 12px',
                        '& .MuiAlert-message': { padding: '4px 0' },
                        '& .MuiAlert-icon': { padding: '4px 0' }
                    }}
                >
                    Your <strong>companyId</strong> param is: &quot;<strong>{companyId}</strong>&quot;. See&nbsp;
                    <Link href={`${config.apiUrl}/docs/api`} target="_blank">
                        API Docs
                    </Link>
                </Alert>

                <ApiKeyCreate companyId={companyId} />
            </Stack>

            <Box sx={{ p: 1.5, border: '1px solid', borderColor: 'grey.200', borderRadius: '8px' }}>
                <CBTable
                    rows={rows}
                    columns={columns}
                    renderRow={(row) => <ApiKeyTableRow row={row} companyId={companyId} key={row.id} />}
                    isLoading={isLoading}
                />
                <AppPagePagination data={data} perPage={5} page={page} setPage={setPage} />
            </Box>
        </Box>
    );
};

export default ApiKeysList;
