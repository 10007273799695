import { useCallback, useState, useEffect, useMemo, ReactElement } from 'react';
import { Grid, Skeleton, useMediaQuery } from '@material-ui/core';
import { Box, Button, ButtonBase } from '@mui/material';
import { IService } from '../../../../../../../models/IService';
import { TimeSlot } from '../../../../types';
import appointmentWidgetAPI from '../../../../../../../services/WidgetService';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import StripeCheckoutForm from './StripeCheckoutForm';
import StripeIcon from '../../../../../../../assets/images/icons/stripe.svg';
import { styled, Theme, useTheme } from '@material-ui/core/styles';
import CBModal from '../../../../../../../ui-component/cb-modal/CBModal';
import usePayButtonText from '../../../../../hooks/usePayButtonText';
import { IStripeAdditionalData, IStripePaymentDetails } from '../../../../../../../models/ICompany';

interface StripeFormProps {
    services: IService[];
    date: Pick<TimeSlot, 'start_at'> | null;
    submitBooking: (details?: object) => void;
    stripePublicKey: string;
    amount?: number;
    entity?: IStripePaymentDetails;
    savedData?: IStripeAdditionalData;
    ToggleBtn?: ReactElement;
    disabled?: boolean;
    companySlug?: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
    '& .MuiButton-startIcon img': {
        width: '20px'
    },

    '&.Mui-disabled .MuiButton-startIcon': {
        filter: 'grayscale(1)'
    }
}));

loadStripe.setLoadParameters({ advancedFraudSignals: false });

const StripePayment = ({
    services,
    date,
    submitBooking,
    stripePublicKey,
    amount,
    entity,
    ToggleBtn,
    savedData,
    disabled,
    companySlug
}: StripeFormProps) => {
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const theme = useTheme();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
    const { company_slug } = useParams();
    const { payButtonTitle } = usePayButtonText({
        predefinedAmount: amount
    });

    const payload = useMemo(() => {
        const slug = company_slug ?? companySlug;
        if (slug) {
            return {
                slug,
                coupon_code: savedData?.coupon_code ?? undefined,
                location_id: savedData?.location_id ?? undefined,
                ...entity
            };
        }

        return skipToken;
    }, [companySlug, company_slug, entity, savedData]);

    const { data, isLoading, refetch } = appointmentWidgetAPI.useGetStripePaymentIntentQuery(payload);

    const openModal = useCallback(() => {
        setShowModal(true);
    }, [setShowModal]);

    const closeModal = useCallback(() => {
        setShowModal(false);
    }, [setShowModal]);

    useEffect(() => {
        if (!stripePromise && stripePublicKey) {
            setStripePromise(loadStripe(stripePublicKey));
        }
    }, [stripePromise, stripePublicKey]);

    useEffect(() => {
        refetch();
    }, [refetch, services]);

    const appearance = {
        variables: {
            colorPrimary: theme.palette.widget.text || '#32325d',
            fontSizeBase: '16px',
            colorText: theme.palette.widget.text || '#32325d',
            colorDanger: theme.palette.error.main || '#ff0000',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif'
        }
    };

    return (
        <>
            {ToggleBtn ? (
                <ButtonBase sx={{ mb: 1.5, width: '100%' }} onClick={openModal} disabled={disabled}>
                    {ToggleBtn}
                </ButtonBase>
            ) : (
                <StyledButton
                    onClick={openModal}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{ height: '45px', mb: 1.5 }}
                    startIcon={<img src={StripeIcon} alt="" />}
                    disabled={disabled}
                >
                    Stripe
                </StyledButton>
            )}

            <CBModal
                title={<Box px={3}>Payment Details</Box>}
                open={showModal}
                onClose={closeModal}
                okButtonText={payButtonTitle()}
                okButtonFormId="widget-stripe-checkout-form"
                fullWidth
                maxWidth="sm"
                fullScreen={matchSm}
            >
                {date && data?.result && data.client_secret && !isLoading ? (
                    <Elements stripe={stripePromise} options={{ clientSecret: data.client_secret, appearance }}>
                        <StripeCheckoutForm
                            submitBooking={submitBooking}
                            client_secret={data.client_secret}
                            savedData={savedData}
                            stripePublicKey={stripePublicKey}
                        />
                    </Elements>
                ) : (
                    <>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Skeleton animation="wave" height={30} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Skeleton animation="wave" height={30} />
                                <Skeleton animation="wave" height={30} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Skeleton animation="wave" height={30} />
                                <Skeleton animation="wave" height={30} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton animation="wave" height={50} />
                            </Grid>
                        </Grid>
                    </>
                )}
            </CBModal>
        </>
    );
};

export default StripePayment;
