import { FC, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@material-ui/core/styles';
import Badge from '@mui/material/Badge';
import { Box, useMediaQuery } from '@material-ui/core';

// assets
import { IconBell } from '@tabler/icons';
import useNotifications from '../../../../hooks/useNotifications';
import NotificationsPopper from './NotificationsPopper';
import CommonHeaderBtn from '../../../../ui-component/CommonHeaderBtn';
import NotificationPreview from './NotificationPreview';
import useNotificationsListener from '../../../../hooks/useNotificationsListener';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection: FC = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = useState(false);

    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<any>(null);

    const { data, markReadAll, notificationsLength, unreadNotificationsLength } = useNotifications();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const onPressReadAll = () => {
        markReadAll(null);
    };

    const { current, dismissNotification } = useNotificationsListener();

    return (
        <Box sx={{ display: 'flex' }} component="span" ref={anchorRef}>
            <CommonHeaderBtn onClick={handleToggle} aria-controls={open ? 'menu-list-grow' : undefined} aria-haspopup="true">
                <Badge
                    color="error"
                    overlap="circular"
                    variant="dot"
                    invisible={!unreadNotificationsLength}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <IconBell stroke={1.5} size="1.3rem" />
                </Badge>
            </CommonHeaderBtn>
            {/* Notifications Preview */}
            <Box sx={{ position: 'relative' }}>
                {!!current && (
                    <Box sx={{ position: 'absolute', width: '500px', right: 0, top: 0, bottom: 0, zIndex: 2 }}>
                        {current && <NotificationPreview notification={current} onDismiss={dismissNotification} />}
                    </Box>
                )}
            </Box>

            <NotificationsPopper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorRef={anchorRef}
                data={data}
                setOpen={setOpen}
                onReadAll={onPressReadAll}
                notificationsLength={notificationsLength}
                unreadNotificationsLength={unreadNotificationsLength}
            />
        </Box>
    );
};

export default NotificationSection;
