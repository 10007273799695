import { FC } from 'react';
import { Stack, TextField } from '@mui/material';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';
import FocusTextField from '../../FocusTextField';
import * as Yup from 'yup';

type LedgerTransactionFormType = { amount: number; description: string };

type LedgerTransactionFormProps = {
    onSubmit: (formData: LedgerTransactionFormType) => void;
    defaults?: LedgerTransactionFormType;
};

const LedgerTransactionForm: FC<LedgerTransactionFormProps> = ({ onSubmit, defaults }) => {
    const { values, setFieldValue, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik<LedgerTransactionFormType>({
        initialValues: defaults ?? { amount: 0, description: '' },
        onSubmit,
        validationSchema: Yup.object().shape({
            amount: Yup.number().required().label('Amount').not([0], 'Amount must be grater or lower than 0'),
            description: Yup.string().trim().required().label('Description')
        })
    });

    return (
        <Stack component="form" spacing={2} onSubmit={handleSubmit} noValidate autoComplete="off" id="ledger_transaction_form">
            <NumberFormat
                id="amount"
                name="amount"
                label="Amount"
                value={values.amount}
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
                fullWidth
                customInput={FocusTextField}
                onBlur={handleBlur}
                onValueChange={({ floatValue }) => setFieldValue('amount', floatValue)}
                error={touched.amount && !!errors.amount}
                helperText={touched.amount ? errors.amount : undefined}
            />
            <TextField
                id="description"
                name="description"
                label="Description"
                multiline
                rows={4}
                value={values.description ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.description && !!errors.description}
                helperText={touched.description ? errors.description : undefined}
            />
        </Stack>
    );
};

export default LedgerTransactionForm;
