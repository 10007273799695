const CouponIcon = () => (
    <svg width="1.5rem" height="1.5rem" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g stroke="currentColor">
            <path
                d="M7.74858 16.6159L16.32 8.0445M0.857147 19.1874C0.857146 19.6414 1.03729 20.0769 1.35804 20.3983C1.67879 20.7197 2.11394 20.9007 2.568 20.9016H21.432C21.8861 20.9007 22.3212 20.7197 22.642 20.3983C22.9627 20.0769 23.1429 19.6414 23.1429 19.1874V15.7005C22.4027 15.4998 21.7492 15.061 21.2832 14.4519C20.8172 13.8427 20.5648 13.0971 20.5648 12.3302C20.5648 11.5633 20.8172 10.8177 21.2832 10.2086C21.7492 9.59945 22.4027 9.16065 23.1429 8.95993V5.47307C23.1429 5.01901 22.9627 4.58349 22.642 4.2621C22.3212 3.94071 21.8861 3.7597 21.432 3.75879H2.568C2.11394 3.7597 1.67879 3.94071 1.35804 4.2621C1.03729 4.58349 0.857146 5.01901 0.857147 5.47307V8.95307C1.60324 9.14931 2.26334 9.58708 2.73439 10.198C3.20544 10.809 3.46091 11.5588 3.46091 12.3302C3.46091 13.1017 3.20544 13.8514 2.73439 14.4624C2.26334 15.0734 1.60324 15.5111 0.857147 15.7074V19.1874Z"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.60572 9.75872C8.83305 9.75872 9.05107 9.66841 9.21182 9.50767C9.37256 9.34692 9.46287 9.1289 9.46287 8.90158C9.46287 8.67425 9.37256 8.45623 9.21182 8.29549C9.05107 8.13474 8.83305 8.04443 8.60572 8.04443C8.3784 8.04443 8.16038 8.13474 7.99963 8.29549C7.83889 8.45623 7.74858 8.67425 7.74858 8.90158C7.74858 9.1289 7.83889 9.34692 7.99963 9.50767C8.16038 9.66841 8.3784 9.75872 8.60572 9.75872ZM15.4629 16.6159C15.6902 16.6159 15.9082 16.5256 16.069 16.3648C16.2297 16.2041 16.32 15.986 16.32 15.7587C16.32 15.5314 16.2297 15.3134 16.069 15.1526C15.9082 14.9919 15.6902 14.9016 15.4629 14.9016C15.2355 14.9016 15.0175 14.9919 14.8568 15.1526C14.696 15.3134 14.6057 15.5314 14.6057 15.7587C14.6057 15.986 14.696 16.2041 14.8568 16.3648C15.0175 16.5256 15.2355 16.6159 15.4629 16.6159Z"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
    </svg>
);

export default CouponIcon;
