import { useCallback, useMemo } from 'react';
import moment from 'moment-timezone';

// mui
import { Box, Button } from '@mui/material';
import AddBoxOutlined from '@mui/icons-material/AddBoxOutlined';

// project imports
import { AppointmentType, IAppointmentPayload } from '../../../models/IAppointment';
import { UserRole } from '../../../models/IEmployee';
import BlockTimeForm, { BlockTimeFormType } from './BlockTimeForm';
import { ILocation } from '../../../models/ILocation';
import useAuth from '../../../hooks/useAuth';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { startSubmitting, stopSubmitting } from 'store/slices/SubmittingSlice';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { apiTimeFormat } from '../../../store/constant';
import appointmentAPI from '../../../services/AppointmentService';
import BlockSkeleton from './BlockSkeleton';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { isEventDateValid } from '../../../utils/functions/time-zones-helpers';
import BlockTimeHistory from './history/BlockTimeHistory';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import appointmentWidgetAPI from '../../../services/WidgetService';
import EntityDrawerContainer from '../../entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../../entity-drawer-layout/EntityDrawerHeader';
import EntityDrawerActions from '../../entity-drawer-layout/EntityDrawerActions';
import EntityDrawerContent from '../../entity-drawer-layout/EntityDrawerContent';
import UpdatedStyleWrapper from '../../updated-style-wrapper';
import useEntityDrawerSpacing from '../../entity-drawer-layout/use-entity-drawer-spacing';
import { DateRange } from '../../../views/calendar/types';

interface BlockTimeDialogProps {
    onClose: () => void;
    location: ILocation;
    eventId: number | undefined;
    range: DateRange | null;
    selectedEmployeeId: number | null;
}

const BlockTimeDialog = ({ onClose, range, eventId, location, selectedEmployeeId }: BlockTimeDialogProps) => {
    const { isMobile } = useEntityDrawerSpacing();
    const { allEmployees: employees } = useAppSelector((state) => state.calendarFilter);
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const { user } = useAuth();
    const { data: block, isLoading: isLoadingBlock, isFetching } = appointmentAPI.useGetAppointmentQuery(eventId ?? skipToken);
    const [createAppointment, { isLoading: isCreating }] = appointmentAPI.useCreateAppointmentMutation();
    const [updateAppointment, { isLoading: isUpdating }] = appointmentAPI.useUpdateAppointmentMutation();
    const [deleteAppointment, { isLoading: isDeleting }] = appointmentAPI.useDeleteAppointmentMutation();

    const invalidateAppointmentTag = useCallback(() => {
        dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
        dispatch(appointmentWidgetAPI.util.invalidateTags(['AppointmentWidget']));
    }, [dispatch]);

    const { isSubmitting } = useAppSelector((store) => store.submitting);
    const { isForeignAppointment } = useAppSelector((state) => state.calendar);

    const isEdit = useMemo(() => !!eventId, [eventId]);

    const handleEventCreate = (data: IAppointmentPayload) => {
        createAppointment(data)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Time Block Created',
                    alertSeverity: SnackBarTypes.Success
                });
                invalidateAppointmentTag();
                onClose();
                dispatch(stopSubmitting());
            })
            .catch((e) => {
                if (e.data) {
                    showSnackbar({
                        message: e.data,
                        alertSeverity: SnackBarTypes.Error
                    });
                } else {
                    showSnackbar({
                        message:
                            data.type === AppointmentType.Appointment
                                ? "Appointment hasn't been created"
                                : "Time Block hasn't been Created",
                        alertSeverity: SnackBarTypes.Error
                    });
                }
                dispatch(stopSubmitting());
            });
    };

    const handleUpdateEvent = (id: string, data: IAppointmentPayload) => {
        updateAppointment({ appointmentId: id, data })
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Time Block Updated',
                    alertSeverity: SnackBarTypes.Success
                });
                invalidateAppointmentTag();
                onClose();
                dispatch(stopSubmitting());
            })
            .catch((e) => {
                if (e.data) {
                    showSnackbar({
                        message: e.data,
                        alertSeverity: SnackBarTypes.Error
                    });
                } else {
                    showSnackbar({
                        message: `Time Block hasn't been Updated`,
                        alertSeverity: SnackBarTypes.Error
                    });
                }
                dispatch(stopSubmitting());
            });
    };

    const handleEventDelete = useCallback(
        (id: string | number) => {
            deleteAppointment(id)
                .unwrap()
                .then(() => {
                    showSnackbar({
                        message: 'Time Block Deleted',
                        alertSeverity: SnackBarTypes.Success
                    });
                    onClose();
                    invalidateAppointmentTag();
                })
                .catch(() => {
                    showSnackbar({
                        message: `Time Block hasn't been Deleted`,
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        },
        [deleteAppointment, invalidateAppointmentTag, onClose, showSnackbar]
    );

    const deleteEventConfirm = useCallback(
        (id: string | number) => {
            dispatch(
                openConfirmPopup({
                    onConfirm: () => handleEventDelete(id),
                    confirmText: `Delete`,
                    text: `Are you sure you want to delete this time block?`
                })
            );
        },
        [dispatch, handleEventDelete]
    );

    const handleSubmit = (formData: BlockTimeFormType) => {
        const sendData = (data: IAppointmentPayload) => {
            dispatch(startSubmitting());
            if (block && eventId) {
                handleUpdateEvent(String(block.id), data);
            } else {
                handleEventCreate(data);
            }
        };

        if (formData.employee && formData.start && formData.end) {
            const data = ({
                employee_id: formData.employee.id,
                start_at: formData.start.toISOString(true),
                end_at: formData.end.toISOString(true),
                note: formData.title,
                location_id: location.id,
                type: AppointmentType.Blocked_Time
            } as unknown) as IAppointmentPayload;
            // check pasttime
            if (!isEventDateValid(data, location.time_zone)) {
                dispatch(
                    openConfirmPopup({
                        onConfirm: () => {
                            sendData(data);
                        },
                        onClose: () => {
                            dispatch(stopSubmitting());
                        },
                        confirmText: `${block ? 'Update' : 'Create'}`,
                        text: `Are you sure you want to ${block ? 'update' : 'create'} Time Block for the past date?`
                    })
                );
            } else {
                sendData(data);
            }
        }
    };

    const modalActions = useMemo(
        () =>
            !isLoadingBlock && eventId ? (
                <Button
                    disabled={isSubmitting}
                    onClick={() => deleteEventConfirm(eventId)}
                    color="error"
                    variant="text"
                    className="forcedBg"
                >
                    Delete
                </Button>
            ) : undefined,
        [isLoadingBlock, eventId, isSubmitting, deleteEventConfirm]
    );

    const okButtonText = useMemo(() => (isEdit ? 'Save' : 'Create'), [isEdit]);

    const employeeId = user && user.employee.role.name === UserRole.Provider ? user.employee.id : selectedEmployeeId;

    const initialValues = useMemo<BlockTimeFormType>(() => {
        if (eventId) {
            return {
                title: block?.note || '',
                employee: block?.employee || null,
                start: moment(block?.start_at),
                end: moment(block?.end_at)
            };
        }

        return {
            title: '',
            employee: employees?.find((staff) => staff.id === employeeId) || null,
            start: moment(range?.start || null, apiTimeFormat),
            end: moment(range?.end || null, apiTimeFormat)
        };
    }, [block, employees, eventId, range, employeeId]);

    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <EntityDrawerHeader title="Block Time" onClose={onClose} />
                <EntityDrawerContent>
                    <Box>
                        {!isLoadingBlock && !isFetching ? (
                            <BlockTimeForm
                                userRole={user?.employee.role.name}
                                employees={employees}
                                initialValues={initialValues}
                                onSubmit={handleSubmit}
                                isBlocked={isCreating || isUpdating || isDeleting}
                            />
                        ) : null}
                        {eventId ? <BlockTimeHistory eventId={eventId} /> : null}
                        {eventId && (isLoadingBlock || isFetching) ? <BlockSkeleton /> : null}
                    </Box>
                </EntityDrawerContent>
                <EntityDrawerActions>
                    {modalActions}
                    {isMobile ? null : <Box sx={{ mr: 'auto', pr: 1 }} />}
                    <Button color="primary" variant="text" className="forcedBg" onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={block ? undefined : <AddBoxOutlined />}
                        type="submit"
                        form="block_time_form"
                        disabled={isSubmitting || isForeignAppointment}
                    >
                        {okButtonText}
                    </Button>
                </EntityDrawerActions>
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default BlockTimeDialog;
