import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Moment } from 'moment-timezone';

interface AppTimePickerProps {
    value: Moment;
    onChange: (time: Moment | null) => void;
    disabled?: boolean;
    label?: string;
    size?: 'small' | 'medium';
}

const AppTimePicker = (props: AppTimePickerProps) => {
    const { value, onChange, disabled, label, size } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimeField value={value} onChange={onChange} disabled={disabled} label={label} size={size} fullWidth />
        </LocalizationProvider>
    );
};

export default AppTimePicker;
