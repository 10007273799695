import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import { IBaseParams, IPaginateResponse } from '../models/IPaginateResponse';
import { ICoupon, ICouponCreatePayload } from '../models/ICoupon';

const couponApi = createApi({
    reducerPath: 'couponAPI',
    tagTypes: ['Coupons'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        getCouponsList: build.query<IPaginateResponse<ICoupon[]>, IBaseParams>({
            query: ({ per_page = null, page = 1, search = null, sort = null, order = null }) => ({
                url: '/coupons',
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order
                }
            }),
            providesTags: () => ['Coupons']
        }),

        getSingleCoupon: build.query<ICoupon, string | number>({
            query: (id) => ({
                url: `/coupons/${id}`,
                method: 'GET'
            }),
            providesTags: () => ['Coupons']
        }),

        addCoupon: build.mutation<ICoupon, ICouponCreatePayload>({
            query: (data) => ({
                url: `/coupons`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['Coupons']
        }),

        editCoupon: build.mutation<ICoupon, ICouponCreatePayload & { id: number }>({
            query: (data) => ({
                url: `/coupons/${data.id}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['Coupons']
        }),

        deleteCoupon: build.mutation<unknown, number>({
            query: (id: number) => ({
                url: `/coupons/${id}`,
                method: 'DELETE'
            }),

            invalidatesTags: ['Coupons']
        }),

        addRedemptionCoupon: build.mutation<ICoupon, { customer_id: number; amount: number }>({
            query: (data) => ({
                url: '/coupons/create-redemption',
                method: 'POST',
                data
            })
        })
    })
});

export default couponApi;
