import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import useEntityDrawerSpacing from './use-entity-drawer-spacing';

const EntityDrawerActions: FC<PropsWithChildren<{ id?: string }>> = ({ id, children }) => {
    const { isMobile, spacingX } = useEntityDrawerSpacing();

    return (
        <Box
            id={id}
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                py: 2,
                px: spacingX,
                borderTop: isMobile ? undefined : '1px solid',
                borderTopColor: 'grey.200',
                boxShadow: isMobile ? '0px -1px 8px 0px rgba(53, 53, 53, 0.25)' : undefined,
                gap: '8px',
                flexGrow: 0,
                flexShrink: 0,
                justifyContent: 'flex-end',
                '&:empty': {
                    display: 'none'
                }
            }}
        >
            {children}
        </Box>
    );
};

export default EntityDrawerActions;
