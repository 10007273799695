import { FC, ReactNode } from 'react';
import { alpha, Box, Stack } from '@mui/material';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

type AppointmentHistoryItemProps = {
    text: string;
    icon?: ReactNode;
    color?: string;
    createdAt?: string;
    createdBy?: string;
    isMobile?: boolean;
};

const AppointmentHistoryItem: FC<AppointmentHistoryItemProps> = ({ text, icon, createdBy, createdAt, color, isMobile }) => (
    <Stack direction="row" spacing={1} alignItems="center">
        <Box
            sx={{
                width: isMobile ? '36px' : '48px',
                height: isMobile ? '36px' : '48px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                color,
                backgroundColor: color ? alpha(color, 0.15) : undefined,

                '& .MuiSvgIcon-root': isMobile
                    ? {
                          fontSize: '1rem'
                      }
                    : undefined
            }}
        >
            {icon ?? <SettingsSuggestIcon />}
        </Box>
        <Box>
            <Box
                sx={{
                    color: 'grey.800',
                    fontWeight: 600,
                    fontSize: '1rem',
                    lineHeight: 1.5,
                    textTransform: 'capitalize'
                }}
            >
                {text}
            </Box>
            <Box sx={{ fontSize: '0.875rem', lineHeight: '1.5', opacity: 0.6, '& strong': { fontWeight: 600 } }}>
                {createdAt} <strong>{createdBy}</strong>
            </Box>
        </Box>
    </Stack>
);

export default AppointmentHistoryItem;
