import { FC, useCallback, useState } from 'react';
import { Box, Button, IconButton, Stack } from '@mui/material';
import { IAppointmentProduct } from '../../../../../../../models/IProduct';
import NumberFormat from 'react-number-format';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonBottomDrawer from '../../../../../../common-bottom-drawer';
import CardImage from '../../../../../../card-image';

type AppointmentProductListItemProps = {
    product: IAppointmentProduct;
    onUpdate: (product: IAppointmentProduct) => void;
    onDelete: (id: number) => void;
};

const AppointmentProductListItem: FC<AppointmentProductListItemProps> = ({ product, onUpdate, onDelete }) => {
    const [open, setOpen] = useState(false);

    const handleUpdate = useCallback(() => {
        onUpdate(product);
        setOpen(false);
    }, [onUpdate, product]);

    const handleDelete = useCallback(() => {
        onDelete(product.id);
        setOpen(false);
    }, [onDelete, product.id]);

    return (
        <>
            <Box
                sx={{
                    border: '1px solid',
                    borderColor: 'grey.200',
                    p: 2,
                    borderRadius: '16px',
                    color: 'grey.800',
                    lineHeight: 1.5
                }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        mb: 1,
                        fontWeight: 600,
                        fontSize: '1rem',
                        alignItems: 'flex-start',
                        mr: '-12px'
                    }}
                >
                    <CardImage src={product.images?.[0]?.url_medium} />
                    <Box sx={{ flexGrow: 1, flexShrink: 1 }}>
                        <Box>{product.name}</Box>
                        <NumberFormat value={product.pivot.price} prefix="$" decimalScale={2} fixedDecimalScale displayType="text" />
                    </Box>
                    <IconButton onClick={() => setOpen(true)} color="inherit">
                        <MoreVertIcon />
                    </IconButton>
                </Stack>
                <Stack spacing={0.5}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ fontSize: '0.875rem' }}>
                        <Box>Amount</Box>
                        <Box sx={{ opacity: 0.6, textAlign: 'right' }}>{product.pivot.amount}</Box>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ fontSize: '0.875rem' }}>
                        <Box>Units</Box>
                        <Box sx={{ opacity: 0.6, textAlign: 'right' }}>{product.pivot.units}</Box>
                    </Stack>
                </Stack>
            </Box>
            <CommonBottomDrawer open={open} onClose={() => setOpen(false)} title="Select option">
                <Stack spacing={1}>
                    <Button variant="text" color="primary" className="forcedBg" onClick={handleUpdate}>
                        Edit
                    </Button>
                    <Button variant="text" color="error" className="forcedBg" onClick={handleDelete}>
                        Delete
                    </Button>
                </Stack>
            </CommonBottomDrawer>
        </>
    );
};

export default AppointmentProductListItem;
