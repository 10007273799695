import { FC, ReactNode } from 'react';
import { Box, ButtonBase, ButtonBaseProps } from '@mui/material';

type NewPaymentFormOptionProps = Pick<ButtonBaseProps, 'onClick' | 'children'> & {
    active?: boolean;
    icon?: ReactNode;
};

const NewPaymentFormOption: FC<NewPaymentFormOptionProps> = ({ onClick, children, icon, active }) => {
    const sx = {
        borderStyle: 'solid',
        borderWidth: active ? '2px' : '1px',
        borderColor: active ? 'secondary.main' : 'grey.200',
        fontWeight: active ? 600 : 400,
        paddingX: `calc(0.75rem - ${active ? 1 : 0}px)`,
        paddingY: `calc(1.5rem - ${active ? 1 : 0}px)`,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderRadius: 1.5,
        fontSize: '1rem',
        lineHeight: 1.5,
        color: 'grey.800',
        gap: '10px'
    };

    return (
        <ButtonBase onClick={onClick} sx={sx}>
            {icon && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: active ? 'secondary.main' : 'newColors.darkBlue'
                    }}
                >
                    {icon}
                </Box>
            )}
            {children}
        </ButtonBase>
    );
};

export default NewPaymentFormOption;
