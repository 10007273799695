import { FC } from 'react';
import { Box, Button, TextField } from '@mui/material';
import * as Yup from 'yup';
import useExtendedFormik from '../../../hooks/useExtendedFormik';
import CommonDrawer, { useCommonSettingsDrawerPx } from '../../../ui-component/CommonSettingsDrawer';
import StickyFormFooter from '../../../ui-component/form/sticky-form-footer';

type ApiKeyFormProps = {
    open: boolean;
    onClose: () => void;
    dialogTitle: string;
    dialogOkLabel: string;
    actionsDisabled: boolean;
    defaults: { name: string };
    onSubmit: (data: { name: string }) => void;
};

const ApiKeyForm: FC<ApiKeyFormProps> = ({ defaults, onSubmit, open, onClose, dialogTitle, dialogOkLabel, actionsDisabled }) => {
    const containerXSpacing = useCommonSettingsDrawerPx();
    const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useExtendedFormik<{ name: string }>({
        initialValues: defaults,
        onSubmit,
        validationSchema: Yup.object().shape({
            name: Yup.string().trim().required().label('Name')
        })
    });

    return (
        <CommonDrawer open={open} onClose={onClose} title={dialogTitle}>
            <Box
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                component="form"
                id="api-key-form"
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
            >
                <TextField
                    label="Name"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    error={!!errors.name && touched.name}
                    helperText={touched.name ? errors.name : undefined}
                />

                <StickyFormFooter containerXSpacing={containerXSpacing}>
                    <Button className="forcedBg" color="primary" onClick={onClose} disabled={actionsDisabled}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit" disabled={actionsDisabled}>
                        {dialogOkLabel ?? 'Save'}
                    </Button>
                </StickyFormFooter>
            </Box>
        </CommonDrawer>
    );
};

export default ApiKeyForm;
