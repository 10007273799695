import { FC, useEffect, useMemo } from 'react';
import { CircularProgress, Link, Stack, Box, Grid, IconButton, Typography, Avatar } from '@mui/material';
import { IConversationDetails } from '../../models/IAppointment';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ChartHistory from './ChartHistory';
import { format } from 'date-fns';
import { useAppSelector } from '../../hooks/redux';
import ChatInput from './ChatInput';
import useMessagesCache from '../../hooks/use-messages-cache';
import { stringToColor } from '../../store/constant';
import useEntityDrawerSpacing from '../entity-drawer-layout/use-entity-drawer-spacing';

type ChatConversationProps = {
    conversation?: IConversationDetails | null;
    isLoading?: boolean;
};

const ChatConversation: FC<ChatConversationProps> = ({ conversation, isLoading }) => {
    const { isMobile, spacingX } = useEntityDrawerSpacing();
    const { selectedEvent } = useAppSelector((store) => store.calendar);
    const { updateMessagesCache } = useMessagesCache();

    const threads = useMemo(() => {
        if (conversation) {
            return [...conversation.threads].sort((prev, next) => {
                const prevTime = new Date(prev.created_at).valueOf();
                const nextTime = new Date(next.created_at).valueOf();

                return prevTime - nextTime;
            });
        }
        return [];
    }, [conversation]);

    const customerPhone = useMemo(() => {
        if (selectedEvent) {
            return selectedEvent.customer.phone;
        }

        return null;
    }, [selectedEvent]);

    const appointmentId = selectedEvent?.id;
    const conversationId = conversation?.id;

    useEffect(() => {
        const callback = (
            payload: {
                conversation?: IConversationDetails | null;
            } | null
        ) => {
            if (payload?.conversation && appointmentId) {
                updateMessagesCache(appointmentId, payload.conversation);
            }
        };

        // Subscribe to conversation updated event from Inbox
        window.Echo.private(`App.Appointment.Messaging.Conversation.${conversationId}`).listen(
            '.appointment.messaging.conversation.updated',
            callback
        );

        return () => {
            // Unsubscribe from conversation updated event
            window.Echo.private(`App.Appointment.Messaging.Conversation.${conversationId}`).stopListening(
                '.appointment.messaging.conversation.updated'
            );
            window.Echo.leave(`App.Appointment.Messaging.Conversation.${conversationId}`);
        };
    }, [appointmentId, conversationId, updateMessagesCache]);

    if (isLoading) {
        return (
            <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (conversation) {
        return (
            <Stack
                sx={{
                    flexGrow: 1,
                    '& .ScrollHeight': {
                        width: '100%',
                        flexGrow: 1,
                        overflowX: 'hidden'
                    }
                }}
            >
                <Box sx={{ px: isMobile ? 0 : spacingX }}>
                    <Box px={2} py={2} mt={-2} sx={{ backgroundColor: 'grey.50', borderBottom: '1px solid', borderColor: 'grey.200' }}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Avatar
                                    sx={{
                                        height: '48px',
                                        width: '48px',
                                        bgcolor: stringToColor(`${conversation.customer.firstname} ${conversation.customer.lastname}`)
                                    }}
                                >
                                    <Typography
                                        color="#fff"
                                        sx={{
                                            fontSize: '14px',
                                            fontWeight: 600
                                        }}
                                    >
                                        {conversation.customer.firstname.slice(0, 1)}
                                        {conversation.customer.lastname?.slice(0, 1) ?? ''}
                                    </Typography>
                                </Avatar>
                            </Grid>

                            <Grid item>
                                <Typography
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        fontSize: '1.5rem',
                                        lineHeight: 1.15,
                                        fontWeight: 400,
                                        mb: 0.5,
                                        color: 'grey.800'
                                    }}
                                >
                                    {conversation.customer.firstname} {conversation.customer.lastname}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: 'grey.800',
                                        opacity: 0.6,
                                        fontWeight: 600
                                    }}
                                >{`Last message: ${format(
                                    new Date(conversation.customer_waiting_since.time),
                                    'h:mm a, MM/dd/yyyy'
                                )}`}</Typography>
                            </Grid>

                            <Grid item sm zeroMinWidth />
                            {customerPhone && (
                                <Grid item sx={{ color: 'grey.800' }}>
                                    <IconButton color="inherit" component={Link} href={`tel:${customerPhone}`}>
                                        <CallTwoToneIcon />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Box>

                <PerfectScrollbar className="ScrollHeight">
                    <Box py={2} px={isMobile ? spacingX : 0}>
                        <ChartHistory data={threads} />
                    </Box>
                </PerfectScrollbar>

                <ChatInput conversationId={conversation.id} />
            </Stack>
        );
    }

    return null;
};

export default ChatConversation;
