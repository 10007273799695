import { createContext, ReactNode, useContext } from 'react';
import { Steps } from '../widget-wizard/types';

interface IStepsContextProps {
    steps: Steps;
    stepsLength: number;
}

interface IStepsProviderProps extends IStepsContextProps {
    children: ReactNode;
}

const StepsContext = createContext<IStepsContextProps | undefined>(undefined);

const useStepContext = () => {
    const ctx = useContext(StepsContext);

    if (ctx === undefined) {
        throw new Error('useStepContext must be used within StepsContextProvider');
    }

    return ctx;
};

const StepsContextProvider = ({ children, ...props }: IStepsProviderProps) => (
    <StepsContext.Provider value={props}>{children}</StepsContext.Provider>
);

export { useStepContext, StepsContextProvider };
