import * as Yup from 'yup';
import { isValidPhone } from '../../../utils/phone-helpers';
import { useMemo } from 'react';
import moment from 'moment-timezone';
import regExps from '../../../utils/formValidators';

const useCustomerFormValidation = () => {
    const schema = useMemo(
        () =>
            Yup.object().shape(
                {
                    firstname: Yup.string().trim().max(255, 'First name must be at most 255 characters').required('First name is required'),
                    lastname: Yup.string().trim().max(255, 'Last name must be at most 255 characters').required('Last name is required'),
                    email: Yup.string()
                        .trim()
                        .nullable()
                        .max(255, 'Email must be at most 255 characters')
                        .email('Must be a valid email')
                        .when('phone', {
                            is: (phone: string) => !phone || phone.length === 0,
                            then: Yup.string().trim().nullable().required('Email or phone is required')
                        }),
                    phone: Yup.string()
                        .trim()
                        .nullable()
                        .test({
                            name: 'phone',
                            test: (v) => (v ? isValidPhone(v) : true),
                            message: 'Phone number is not valid'
                        })
                        .when('email', {
                            is: (email: string) => !email || email.length === 0,
                            then: Yup.string().trim().nullable().required('Phone or email is required')
                        }),
                    birth_date: Yup.date()
                        .nullable()
                        .typeError('Invalid Date')
                        .min(moment('01-01-1900', 'MM-DD-YYYY').toDate(), 'Minimum date is 01.01.1900')
                        .max(moment().add(1, 'hour').toDate(), `Maximum date is ${moment().format('MM/DD/YYYY')}`),
                    address: Yup.object()
                        .shape({
                            address: Yup.string().trim().nullable().max(255, 'The limit is 255 symbols'),
                            city: Yup.string().trim().nullable().max(255, 'The limit is 255 symbols'),
                            state: Yup.string().trim().nullable().max(255, 'The limit is 255 symbols'),
                            postal_code: Yup.string()
                                .trim()
                                .nullable()
                                .max(255, 'The limit is 255 symbols')
                                .matches(regExps.postal_code, 'Must be a valid postal code')
                        })
                        .nullable(),
                    note: Yup.string().trim().nullable(),
                    employee_owner_id: Yup.number().nullable(),
                    campaign_identifier: Yup.string().trim().nullable().notRequired()
                },
                [['email', 'phone']]
            ),
        []
    );

    return { schema };
};

export default useCustomerFormValidation;
