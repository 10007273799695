const DinersIcon = () => (
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1895_10653)">
            <path
                d="M28.5956 20.9741L28.5984 20.9741C29.84 20.9848 30.8587 19.9696 30.8742 18.7046L30.8742 3.10741C30.8693 2.49561 30.6258 1.91124 30.1979 1.48306C29.7711 1.05597 29.1958 0.819212 28.5956 0.824311L2.60005 0.824299C2.00264 0.819212 1.42737 1.05597 1.00058 1.48306C0.572707 1.91124 0.329208 2.49561 0.32423 3.10476L0.324219 18.691C0.329208 19.3028 0.572707 19.8872 1.00058 20.3154C1.42737 20.7425 2.00264 20.9792 2.60282 20.9741H28.5956ZM28.5943 21.6241L28.5929 21.6241L28.5956 21.6241H28.5943Z"
                fill="white"
                stroke="black"
                strokeOpacity="0.2"
                strokeWidth="0.65"
            />
            <path
                d="M13.0003 3.16631V3.14551H18.2003V3.16631C22.2763 3.46458 25.431 6.85855 25.431 10.9455C25.431 15.0325 22.2763 18.4264 18.2003 18.7247V18.7455H13.0003V18.7247C8.92422 18.4264 5.76953 15.0325 5.76953 10.9455C5.76953 6.85855 8.92422 3.46458 13.0003 3.16631Z"
                fill="#0165AC"
            />
            <path
                d="M15.0817 15.3551C16.9476 14.6918 18.194 12.9258 18.194 10.9455C18.194 8.96526 16.9476 7.19932 15.0817 6.53595V15.3551ZM11.9617 6.53595C10.0958 7.19932 8.84946 8.96526 8.84946 10.9455C8.84946 12.9258 10.0958 14.6918 11.9617 15.3551V6.53595ZM13.5217 17.7055C9.78827 17.7055 6.76172 14.679 6.76172 10.9455C6.76172 7.2121 9.78827 4.18555 13.5217 4.18555C17.2552 4.18555 20.2817 7.2121 20.2817 10.9455C20.2817 14.679 17.2552 17.7055 13.5217 17.7055Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1895_10653">
                <rect width="31.2" height="20.8" fill="white" transform="translate(0 0.499023)" />
            </clipPath>
        </defs>
    </svg>
);

export default DinersIcon;
