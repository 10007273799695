import { useMemo } from 'react';
import { ICompany } from '../../models/ICompany';
import companyAPI from '../../services/CompanyService';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { Box, Stack, Switch, TextField, FormHelperText, useMediaQuery, Theme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import WidgetEmbedCode from './WidgetEmbedCode';
import WidgetStyleSettings from './WidgetStyleSettings';
import WidgetLinkBuilder from './WidgetLinkBuilder';
import useGenerateLink from './useGenerateLink';
import useExtendedFormik from '../../hooks/useExtendedFormik';
import FormAccordion from '../../ui-component/form/FormAccordion';
import StickyFormFooter from '../../ui-component/form/sticky-form-footer';
import InputRow from '../../ui-component/form/InputRow';
import NumberFormat from 'react-number-format';
import WidgetTextEditor from './components/WidgetTextEditor';
import { MenuItem } from '@material-ui/core';
import { widgetSchema } from './schema';

interface WidgetSettingsFormProps {
    company: ICompany;
}

const WidgetSettingsForm = ({ company }: WidgetSettingsFormProps) => {
    const isMobile = useMediaQuery((params: Theme) => params.breakpoints.down('mobile'));
    const [updateCompanySettings, { isLoading }] = companyAPI.useUpdateCompanySettingsMutation();
    const { generateLink } = useGenerateLink(company);

    const { showSnackbar } = useShowSnackbar();

    const { handleSubmit, values, setFieldValue, handleChange, handleBlur, touched, errors } = useExtendedFormik({
        enableReinitialize: true,
        validateOnChange: true,
        initialValues: company.settings.widget,
        onSubmit: (formData) => {
            updateCompanySettings({ settings: { ...company.settings, widget: formData }, companyId: company.id })
                .unwrap()
                .then(() => {
                    showSnackbar({
                        message: 'Settings updated successfully',
                        alertSeverity: SnackBarTypes.Success
                    });
                })
                .catch((e) => {
                    showSnackbar({
                        message: e.data || "Settings wasn't updated",
                        alertSeverity: SnackBarTypes.Error
                    });
                });
        },
        validationSchema: widgetSchema
    });

    const triggers = useMemo<Record<string, string> | undefined>(() => {
        if (values.link_builder) {
            const result: Record<string, string> = {};
            values.link_builder.forEach((v) => {
                if (v.selector) {
                    result[v.selector] = generateLink(v);
                }
            }, []);

            return Object.keys(result).length ? result : undefined;
        }
        return undefined;
    }, [generateLink, values.link_builder]);

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ height: '100%', display: 'flex', flexDirection: 'column', flexGrow: 1 }}
        >
            <FormAccordion defaultExpanded label="Widget Configuration">
                <Stack spacing={3} mb={6}>
                    <WidgetEmbedCode slug={company.slug} triggers={triggers} />

                    <InputRow
                        label="Attachments in Widget"
                        tooltipKey="settings.widget.is_attachments_enabled"
                        // info="Enable to allow customers to upload attachments for their appointment via booking widget."
                        size="sm"
                    >
                        <Switch
                            value={values.is_attachments_enabled}
                            checked={values.is_attachments_enabled}
                            onChange={(_, v) => setFieldValue('is_attachments_enabled', v)}
                        />
                    </InputRow>

                    <InputRow
                        forceColumn
                        label="Confirmation Note"
                        tooltipKey="settings.widget.confirmation_note"
                        // info="A global confirmation note that is send in the confirmation email to customers upon booking. Can be overridden for specific services on edit service page."
                        size="sm"
                    >
                        <TextField
                            multiline
                            id="confirmation_note"
                            name="confirmation_note"
                            value={values?.confirmation_note ?? ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            placeholder="Enter confirmation note"
                            fullWidth
                            InputProps={{
                                className: 'input-blue'
                            }}
                        />
                    </InputRow>

                    <InputRow
                        forceColumn
                        label="Max Advance Booking, days"
                        tooltipKey="settings.widget.max_advance_booking"
                        // info="How far in advance can a booking be made via booking widget."
                        size="sm"
                    >
                        <>
                            <NumberFormat
                                customInput={TextField}
                                decimalScale={0}
                                fixedDecimalScale
                                id="max_advance_booking"
                                name="max_advance_booking"
                                value={values?.max_advance_booking}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    className: 'input-blue'
                                }}
                            />
                            <FormHelperText sx={{ mx: 0, color: 'grey.800' }}>Use &quot;0&quot; for no limit</FormHelperText>
                        </>
                    </InputRow>

                    <InputRow label="Fixed Availability Slot Size" size="sm">
                        <Switch
                            value={values.slots.use_fixed_size}
                            checked={values.slots.use_fixed_size}
                            onChange={(_, checked) => setFieldValue('slots.use_fixed_size', checked)}
                        />
                    </InputRow>

                    {values.slots.use_fixed_size ? (
                        <InputRow label="Slot Size" size="sm" forceColumn>
                            <TextField
                                id="slots.size"
                                name="slots.size"
                                aria-label="Slot Size"
                                hiddenLabel
                                select
                                value={values.slots.size ?? ''}
                                fullWidth
                                onBlur={handleBlur}
                                onChange={(e) => setFieldValue('slots.size', e.target.value)}
                                error={Boolean(errors.slots?.size && touched.slots?.size)}
                                helperText={touched.slots?.size ? errors.slots?.size : undefined}
                            >
                                {[15, 30, 45, 60].map((v) => (
                                    <MenuItem key={v} value={v}>{`${v} Minutes`}</MenuItem>
                                ))}
                            </TextField>
                        </InputRow>
                    ) : null}
                </Stack>

                <WidgetTextEditor onChange={(val) => setFieldValue('deposit_text', val)} value={values?.deposit_text ?? ''} />
            </FormAccordion>
            <FormAccordion label="Link Builder">
                <WidgetLinkBuilder
                    links={values.link_builder}
                    setFieldValue={setFieldValue}
                    company={company}
                    errors={errors.link_builder}
                />
            </FormAccordion>
            <FormAccordion label="Style Settings">
                <WidgetStyleSettings
                    values={values}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    touched={touched}
                    errors={errors}
                    setFieldValue={setFieldValue}
                />
            </FormAccordion>

            <StickyFormFooter containerXSpacing={isMobile ? 2 : undefined}>
                <LoadingButton loading={isLoading} type="submit" color="primary" variant="contained">
                    Save
                </LoadingButton>
            </StickyFormFooter>
        </Box>
    );
};

export default WidgetSettingsForm;
