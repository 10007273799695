import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LayoutSliceProps {
    showSupportWidget: boolean;
    headerHeight: number;
    toolbarHeight: number;
}

const initialState: LayoutSliceProps = {
    showSupportWidget: false,
    headerHeight: 0,
    toolbarHeight: 0
};

export const layoutSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setShowSupportWidget: (state, action: PayloadAction<boolean>) => {
            state.showSupportWidget = action.payload;
        },
        setHeaderHeight: (state, action: PayloadAction<number>) => {
            state.headerHeight = action.payload;
        },
        setToolbarHeight: (state, action: PayloadAction<number>) => {
            state.toolbarHeight = action.payload;
        }
    }
});

export const { setShowSupportWidget, setHeaderHeight, setToolbarHeight } = layoutSlice.actions;
