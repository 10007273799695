import tooltipsService, { TooltipItem } from '../services/TooltipsService';
import { useCallback, useMemo } from 'react';

const useTooltips = () => {
    const { data, isLoading } = tooltipsService.useFetchTooltipsQuery(undefined);
    const tooltips = useMemo<TooltipItem[]>(() => data ?? [], [data]);
    const getTooltipTextByKey = useCallback((key: string) => tooltips.find((tooltip) => tooltip.key === key)?.text, [tooltips]);

    return { tooltips, tooltipsLoading: isLoading, getTooltipTextByKey };
};

export default useTooltips;
