import { useCallback, useContext, useMemo } from 'react';
import { useAppDispatch } from '../../../../hooks/redux';
import { Box, Button, Grid, Skeleton } from '@mui/material';

import customerAPI from '../../../../services/CustomerService';
import appointmentAPI from '../../../../services/AppointmentService';
import useCustomerFormDefaults from '../../hooks/use-customer-form-defaults';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { AbilityContext } from '../../../../utils/roles/Can';
import useCanSeeCustomerDetails from '../../../../hooks/use-can-see-customer-details';
import { ICustomerPayload } from '../../../../models/ICustomer';
import { startSubmitting, stopSubmitting } from '../../../../store/slices/SubmittingSlice';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import getErrorMessage from '../../../../utils/get-error-message';
import { DialogTypes, openDialog } from '../../../../store/slices/entityDialogsSlice';
import EntityDrawerContent from '../../../../ui-component/entity-drawer-layout/EntityDrawerContent';
import CustomerForm from '../../../../ui-component/CustomerForm';
import SectionHeading from '../../../../ui-component/SectionHeading';
import CustomerAppointments from '../../../../ui-component/customer-appointments';
import CustomerLoyaltyProgram from '../../../../ui-component/customer-loyalty-program';
import EntityDrawerActions from '../../../../ui-component/entity-drawer-layout/EntityDrawerActions';
import useAuth from '../../../../hooks/useAuth';

interface CustomerInfoModalProps {
    customerId: string;
    onClose: () => void;
}

const CustomerDetails = ({ customerId, onClose }: CustomerInfoModalProps) => {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const { data, isFetching } = customerAPI.useGetCustomerQuery(customerId, {
        refetchOnMountOrArgChange: true
    });

    const defaults = useCustomerFormDefaults(data);

    const { showSnackbar } = useShowSnackbar();
    const ability = useContext(AbilityContext);

    const [updateCustomer, { isLoading: saving }] = customerAPI.useUpdateCustomerMutation();

    const loyaltyProgramEnabled = user?.currentCompany.settings?.loyalty_program?.enabled;
    const { canEditCustomerDetails } = useCanSeeCustomerDetails();

    const canEdit = useMemo(() => ability.can('update', 'customer') && canEditCustomerDetails(data?.employee_owner?.id), [
        ability,
        canEditCustomerDetails,
        data
    ]);

    const handleUpdate = useCallback(
        (customer: ICustomerPayload) => {
            if (data && canEdit && !saving) {
                dispatch(startSubmitting());
                updateCustomer({ id: data.id, ...customer })
                    .unwrap()
                    .then(() => {
                        showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Customer updated' });
                        dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
                        onClose();
                    })
                    .catch((err) => {
                        const message = getErrorMessage(err);
                        showSnackbar({ alertSeverity: SnackBarTypes.Error, message });
                    })
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [data, canEdit, saving, dispatch, updateCustomer, showSnackbar, onClose]
    );

    const onViewAppointment = useCallback(
        (id: number) => {
            dispatch(openDialog({ type: DialogTypes.Appointment, id }));
        },
        [dispatch]
    );

    return (
        <>
            <EntityDrawerContent>
                <Box>
                    {data && !isFetching ? (
                        <Box>
                            <CustomerForm
                                customer={defaults}
                                onSubmit={handleUpdate}
                                formId="CustomerDetailsForm"
                                isReadOnly={saving || !canEdit}
                                skipChangesCheck
                            />
                            <SectionHeading mt={2} mb={1}>
                                Customer Appointments
                            </SectionHeading>
                            <CustomerAppointments customerId={parseInt(customerId, 10)} onViewAppointment={onViewAppointment} />

                            {loyaltyProgramEnabled ? (
                                <>
                                    <SectionHeading mt={2} mb={1}>
                                        Loyalty Program
                                    </SectionHeading>
                                    <CustomerLoyaltyProgram customer={data} />
                                </>
                            ) : null}
                        </Box>
                    ) : (
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                                <Skeleton animation="wave" height={30} />
                                <Skeleton animation="wave" height={30} />
                                <Skeleton animation="wave" height={30} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Skeleton animation="wave" height={30} />
                                <Skeleton animation="wave" height={30} />
                                <Skeleton animation="wave" height={30} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton animation="wave" height={50} />
                                <Skeleton animation="wave" height={50} />
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </EntityDrawerContent>

            <EntityDrawerActions>
                <Button color="primary" variant="text" className="forcedBg" onClick={onClose}>
                    Close
                </Button>
                {canEdit ? (
                    <Button
                        variant="contained"
                        color="primary"
                        form="CustomerDetailsForm"
                        disabled={isFetching || saving || !canEdit}
                        type="submit"
                    >
                        Save
                    </Button>
                ) : null}
            </EntityDrawerActions>
        </>
    );
};

export default CustomerDetails;
