const VisaIcon = () => (
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1895_10623)">
            <path
                d="M29.075 0.824219H3.725C2.28906 0.824219 1.125 1.98828 1.125 3.42422V18.3742C1.125 19.8102 2.28906 20.9742 3.725 20.9742H29.075C30.5109 20.9742 31.675 19.8102 31.675 18.3742V3.42422C31.675 1.98828 30.5109 0.824219 29.075 0.824219Z"
                fill="white"
                stroke="black"
                strokeOpacity="0.2"
                strokeWidth="0.65"
            />
            <path
                d="M4.42561 8.18779C3.74553 7.81478 2.96936 7.51478 2.10156 7.30659L2.13796 7.14447H5.69604C6.17831 7.16137 6.56965 7.30648 6.69699 7.81895L7.47026 11.5056L7.70714 12.616L9.87292 7.14447H12.2114L8.73531 15.1598H6.39672L4.42561 8.18779ZM13.9315 15.1683H11.72L13.1033 7.14447H15.3146L13.9315 15.1683ZM21.9484 7.34063L21.6476 9.07328L21.4476 8.98808C21.0474 8.82584 20.5192 8.66372 19.8003 8.68085C18.9271 8.68085 18.5355 9.03063 18.5265 9.37212C18.5265 9.74776 19.0002 9.99532 19.7737 10.3625C21.0478 10.9344 21.6388 11.6343 21.63 12.5477C21.6121 14.2123 20.1014 15.2879 17.7809 15.2879C16.7888 15.2793 15.8332 15.0825 15.3145 14.8609L15.624 13.0597L15.9152 13.1879C16.6341 13.4869 17.1072 13.6147 17.9901 13.6147C18.6269 13.6147 19.3095 13.3669 19.3182 12.8294C19.3182 12.4794 19.0274 12.2231 18.1718 11.8304C17.3347 11.4462 16.2155 10.8061 16.2336 9.6536C16.243 8.09158 17.7809 6.99902 19.9648 6.99902C20.8203 6.99902 21.5122 7.17828 21.9484 7.34063ZM24.8876 12.3258H26.7258C26.6349 11.9246 26.2161 10.0039 26.2161 10.0039L26.0615 9.31256C25.9523 9.61128 25.7614 10.0979 25.7706 10.0807C25.7706 10.0807 25.0698 11.8648 24.8876 12.3258ZM27.6174 7.14447L29.4016 15.1682H27.3539C27.3539 15.1682 27.1535 14.2463 27.0901 13.9646H24.2507C24.1686 14.1779 23.7866 15.1682 23.7866 15.1682H21.4661L24.751 7.81021C24.9786 7.28946 25.3794 7.14447 25.9069 7.14447H27.6174Z"
                fill="#1434CB"
            />
        </g>
        <defs>
            <clipPath id="clip0_1895_10623">
                <rect width="31.2" height="20.8" fill="white" transform="translate(0.800781 0.499023)" />
            </clipPath>
        </defs>
    </svg>
);

export default VisaIcon;
