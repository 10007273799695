import { FC, ChangeEvent, useCallback } from 'react';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';

type TaxTreatmentSelectPropsType = Omit<TextFieldProps, 'onChange' | 'select' | 'children'> & {
    onChange: (v: boolean) => void;
};

const TaxTreatmentSelect: FC<TaxTreatmentSelectPropsType> = ({ onChange, value, ...rest }) => {
    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const v = parseInt(e.target.value, 10);
            onChange(!!v);
        },
        [onChange]
    );
    return (
        <TextField {...rest} select value={value ? 1 : 0} onChange={handleChange}>
            <MenuItem value={0}>Not Included in Price</MenuItem>
            <MenuItem value={1}>Included in Price</MenuItem>
        </TextField>
    );
};

export default TaxTreatmentSelect;
