const CommonNavBtnSx = {
    borderRadius: 2,
    padding: 1,
    color: 'grey.800',

    '& .MuiListItemIcon-root, & .MuiListItemText-root': {
        color: 'inherit !important',
        fontWeight: 'inherit !important',

        '& .MuiTypography-root.MuiListItemText-primary': {
            color: 'inherit !important',
            fontWeight: 'inherit !important'
        }
    },

    '&:hover': {
        color: 'secondary.dark',
        fontWeight: 500
    }
};

export default CommonNavBtnSx;
