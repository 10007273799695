import { FC, useCallback, useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import appointmentService from '../../../../services/AppointmentService';
import useShowSnackbar from '../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../store/snackbarReducer';
import { UserRole } from '../../../../models/IEmployee';
import useAuth from '../../../../hooks/useAuth';

type DeleteWaiverButtonProps = {
    appointmentId: string | number;
    serviceId: string | number;
};
const DeleteWaiverButton: FC<DeleteWaiverButtonProps> = ({ appointmentId, serviceId }) => {
    const [remove, { isLoading }] = appointmentService.useRemoveWaiverMutation();
    const { showSnackbar } = useShowSnackbar();
    const { user } = useAuth();

    const userRole = user?.employee.role.name;
    const canRemove = useMemo(() => {
        const roles = [UserRole.Owner, UserRole.Admin, UserRole.Manager];
        return userRole && roles.includes(userRole);
    }, [userRole]);

    const handleRemove = useCallback(() => {
        remove({ appointmentId, serviceId })
            .unwrap()
            .catch((err) => {
                showSnackbar({
                    alertSeverity: SnackBarTypes.Error,
                    message: err.data || err.message || JSON.stringify(err)
                });
            });
    }, [appointmentId, remove, serviceId, showSnackbar]);

    return canRemove ? (
        <LoadingButton loading={isLoading} color="error" size="small" onClick={handleRemove}>
            Remove
        </LoadingButton>
    ) : null;
};

export default DeleteWaiverButton;
