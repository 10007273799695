import serviceAPI from '../../services/ServiceService';
import { useCallback, useMemo } from 'react';

const useServiceOptions = () => {
    const { data, isLoading } = serviceAPI.useFetchAllServicesQuery({});

    const services = useMemo(() => data?.data ?? [], [data]);
    const getNameById = useCallback((id: number) => services.find((s) => s.id === id)?.name, [services]);

    return { services, isLoading, getNameById };
};

export default useServiceOptions;
