import { FC } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { SxProps } from '@mui/system';

const WidgetTextField: FC<TextFieldProps> = ({ sx, ...rest }) => {
    const override: SxProps = {
        fontFamily: 'PT Sans',
        '& *': {
            fontFamily: 'PT Sans'
        },

        '& legend > span': {
            display: 'none'
        },

        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e6e6e6',
            boxShadow: `0px 3px 6px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)`,
            borderRadius: '5px'
        },

        '& .MuiInputBase-root': {
            borderRadius: '5px',
            color: '#4C636C',
            fontSize: '16px'
        }
    };

    const labelSx: SxProps = {
        fontFamily: 'PT Sans',
        position: 'static',
        transform: 'none !important',
        fontSize: '14px !important',
        marginBottom: '4px',
        color: '#0B2D3B'
    };

    return (
        <>
            <TextField
                {...rest}
                variant="outlined"
                sx={{ ...sx, ...override }}
                InputLabelProps={{ shrink: true, disableAnimation: true, sx: labelSx }}
            />
        </>
    );
};

export default WidgetTextField;
