import { FC, memo, useMemo } from 'react';
import useHistoryItemValue from './use-history-item-value';

const mappings: Record<string, string> = {
    employee_id: 'provider',
    note: 'reason',
    start_at: 'start time',
    end_at: 'end time'
};

const BlockTimeHistoryField: FC<{ label: string; oldValue?: string; newValue: string }> = ({ label, oldValue, newValue }) => {
    const usedLabel = useMemo(() => mappings[label] || label, [label]);
    const newVal = useHistoryItemValue(label, newValue);
    const oldVal = useHistoryItemValue(label, oldValue);

    return <li>{oldVal ? `Changed ${usedLabel} from ${oldVal} to ${newVal}` : `Set ${usedLabel} to ${newVal}`}</li>;
};

export default memo(BlockTimeHistoryField);
