import useAuth from '../../../../../../hooks/useAuth';
import { useMemo } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import AppointmentMessages from '../../../../../AppointmentMessages';

const Messages = () => {
    const { user } = useAuth();

    const inboxStatus = user?.currentCompany?.inbox_status;
    const isMessagingEnabled = useMemo(() => inboxStatus?.sms && inboxStatus?.inbox, [inboxStatus]);

    if (!user) {
        return (
            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }
    return isMessagingEnabled ? (
        <AppointmentMessages />
    ) : (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
            <Typography variant="h3" align="center" sx={{ my: 'auto', px: 3 }}>
                <div>Messaging is not enabled for this organization.</div>
                <div>Please contact support.</div>
            </Typography>
        </Box>
    );
};

export default Messages;
