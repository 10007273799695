import { FC, useCallback } from 'react';
import PaymentSummaryRow from './payments-summary/PaymentSummaryRow';
import { IconButton, TextField } from '@mui/material';
import { IOrderedPayment } from '../../../../../../../../models/IPayment';
import { useFormik } from 'formik';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export type IPaymentCouponProps = {
    coupon?: IOrderedPayment | null;
    onCreateCoupon?: (code: string) => void;
    onDeleteCoupon?: (index: number) => void;
};

const PaymentCoupon: FC<IPaymentCouponProps> = ({ coupon, onCreateCoupon, onDeleteCoupon }) => {
    const couponCode = coupon?.code ?? '';
    const { values, handleSubmit, handleChange, errors } = useFormik<{ code: string }>({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: { code: couponCode },
        onSubmit: ({ code }) => {
            onCreateCoupon?.(code);
        }
    });

    const handleDelete = useCallback(() => {
        if (coupon && onDeleteCoupon) {
            onDeleteCoupon(coupon.originalIndex);
        }
    }, [coupon, onDeleteCoupon]);

    return (
        <PaymentSummaryRow label="Discount code" sx={{ backgroundColor: 'grey.50', borderColor: 'grey.300' }}>
            <form id="payment_coupon_form" onSubmit={handleSubmit}>
                <TextField
                    id="code"
                    name="code"
                    value={values.code}
                    aria-label="Discount code"
                    onChange={handleChange}
                    disabled={!!coupon}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <>
                                {coupon ? (
                                    <IconButton color="error" size="small" onClick={handleDelete}>
                                        <CancelOutlinedIcon />
                                    </IconButton>
                                ) : null}

                                {!coupon && values.code ? (
                                    <IconButton color="primary" type="submit" size="small">
                                        <CheckCircleOutlineOutlinedIcon />
                                    </IconButton>
                                ) : null}
                            </>
                        )
                    }}
                    error={!!errors.code}
                    helperText={errors.code}
                    sx={{ maxWidth: '170px' }}
                />
            </form>
        </PaymentSummaryRow>
    );
};

export default PaymentCoupon;
