import CommonDrawer, { useCommonSettingsDrawerPx } from '../../../ui-component/CommonSettingsDrawer';
import { useFormik } from 'formik';
import { FC, useCallback, useMemo } from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';
import StickyFormFooter from '../../../ui-component/form/sticky-form-footer';

type GradeUsFormType = {
    api_key?: string;
    profile_id?: string;
};

type GradeUsFormPropsType = {
    open: boolean;
    onClose: () => void;
    data?: GradeUsFormType | null;
    onSave: (data: GradeUsFormType) => void;
};

const GradeUsForm: FC<GradeUsFormPropsType> = ({ open, onClose, onSave, data }) => {
    const containerXSpacing = useCommonSettingsDrawerPx();
    const initialValues = useMemo(() => ({ api_key: data?.api_key ?? '', profile_id: data?.profile_id ?? '' }), [data]);
    const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm } = useFormik<GradeUsFormType>({
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: true,
        initialValues,
        validationSchema: Yup.object().shape(
            {
                api_key: Yup.string()
                    .trim()
                    .nullable()
                    .when('profile_id', {
                        is: (v?: string | null) => !!v,
                        then: Yup.string()
                            .trim()
                            .nullable()
                            .required('The GradeUs API Key field is required when GradeUs Profile ID is present.')
                    }),
                profile_id: Yup.string()
                    .trim()
                    .nullable()
                    .when('api_key', {
                        is: (v?: string | null) => !!v,
                        then: Yup.string()
                            .trim()
                            .nullable()
                            .required('The GradeUs Profile ID field is required when GradeUs API Key is present.')
                    })
            },
            [['api_key', 'profile_id']]
        ),
        onSubmit: (formData) => {
            onClose();
            onSave(formData);
        }
    });

    const handleClose = useCallback(() => {
        onClose();
        resetForm();
    }, [onClose, resetForm]);

    return (
        <CommonDrawer open={open} title="Grade US Details" onClose={handleClose}>
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%'
                }}
            >
                <Stack spacing={3}>
                    <TextField
                        id="api_key"
                        name="api_key"
                        label="API key"
                        value={values?.api_key ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.api_key && !!errors.api_key}
                        helperText={touched.api_key ? errors.api_key : undefined}
                    />

                    <TextField
                        id="profile_id"
                        name="profile_id"
                        label="Profile ID"
                        value={values?.profile_id ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!touched.profile_id && !!errors.profile_id}
                        helperText={touched.profile_id ? errors.profile_id : undefined}
                    />
                </Stack>
                <StickyFormFooter containerXSpacing={containerXSpacing}>
                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </StickyFormFooter>
            </Box>
        </CommonDrawer>
    );
};

export default GradeUsForm;
