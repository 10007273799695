import companyAPI from '../../../services/CompanyService';
import { useMemo } from 'react';
import { IWebhookItem, IWebhookSettingsInput } from '../types';

const NEW_WEBHOOK: IWebhookSettingsInput = {
    name: '',
    description: '',
    is_enabled: false,
    events: {},
    settings: {
        url: '',
        method: 'POST',
        secret: ''
    },
    service_ids: []
};

const useWebhookDefaults = (companyId: number, webhook?: IWebhookItem) => {
    const { data: triggerData } = companyAPI.useGetAvailableCompanyTriggersQuery(companyId);

    const getEvents = useMemo(() => {
        const entries = Object.keys(triggerData ?? {}).map((key) => [key, Boolean(webhook?.events?.[key])]);
        return Object.fromEntries(entries);
    }, [triggerData, webhook]);

    const defaultValue: IWebhookSettingsInput = useMemo(() => {
        const events = getEvents;
        const data = webhook ?? NEW_WEBHOOK;
        return { ...data, events };
    }, [getEvents, webhook]);

    return { defaultValue, triggerData };
};

export default useWebhookDefaults;
