import { useCallback, useMemo } from 'react';
import { Box, Button, Stack, useMediaQuery, Theme } from '@mui/material';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import InputRow from '../../ui-component/form/InputRow';

interface WidgetEmbedCodeProps {
    slug: string;
    triggers?: Record<string, string>;
}

const WidgetEmbedCode = ({ slug, triggers }: WidgetEmbedCodeProps) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const { showSnackbar } = useShowSnackbar();

    const triggersString = useMemo(() => {
        if (triggers) {
            try {
                return btoa(JSON.stringify(triggers));
            } catch (err) {
                console.error(err);
            }
        }

        return '';
    }, [triggers]);

    const code = useMemo(
        () =>
            `<script src='${window.location.origin}/widget.js?v=${Math.floor(Math.random() * 1000000000)}' company='${slug}' url='${
                window.location.origin
            }' trigger-button-class='cb-widget-btn' ${triggersString ? `data-triggers="${triggersString}"` : ''}></script>`,
        [slug, triggersString]
    );

    const copyCode = useCallback(() => {
        navigator.clipboard.writeText(code).then(() => {
            showSnackbar({
                message: 'Copied!',
                alertSeverity: SnackBarTypes.Success
            });
        });
    }, [code, showSnackbar]);

    return (
        <InputRow
            label="Embedded Widget Code"
            info='Use this code to embed ChilledButter booking widget in your website. Copy paste to the very end of you html <body> tag. Use html element class "cb-widget-btn" for the element on your site that you want to use to trigger the widget.'
            forceColumn
            size="sm"
        >
            <Stack spacing={1}>
                {!isMobile ? (
                    <Box
                        sx={{
                            backgroundColor: 'grey.200',
                            border: '1px solid',
                            borderColor: 'grey.300',
                            px: 2,
                            py: 0.5,
                            fontSize: '1rem',
                            lineHeight: 1.5,
                            fontFamily: '"Roboto Mono", monospace',
                            borderRadius: '4px',
                            color: 'grey.800',
                            wordBreak: 'break-word'
                        }}
                    >
                        {code}
                    </Box>
                ) : null}

                <Button onClick={copyCode} className="forcedBg" sx={{ alignSelf: isMobile ? 'flex-start' : 'flex-end' }}>
                    Copy code
                </Button>
            </Stack>
        </InputRow>
    );
};

export default WidgetEmbedCode;
