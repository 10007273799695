import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import {
    IService,
    IServiceCategory,
    ServiceCategoryOrdering,
    ServiceCategoryPayload,
    ServiceOption,
    ServiceOptionPayload,
    ServiceOrderPayload,
    ServicePayloadType,
    ServiceQuestionType
} from '../models/IService';
import { IBaseParams, IPaginateResponse } from '../models/IPaginateResponse';

const serviceAPI = createApi({
    reducerPath: 'serviceAPI',
    tagTypes: ['Service', 'ServiceDetails', 'ServiceCategories', 'ServiceOptions'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchAllServices: build.query<IPaginateResponse<IService[]>, IBaseParams>({
            query: ({ per_page = -1, page = 1, search = null, sort = null, order = null, trashed = null }) => ({
                url: `/services`,
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order,
                    trashed
                }
            }),
            providesTags: (result) => ['Service']
        }),
        getService: build.query<IService, string>({
            query: (id) => ({ url: `/services/${id}`, method: 'GET' }),
            providesTags: () => ['ServiceDetails']
        }),
        getAccountServices: build.query<IService[], null>({
            query: () => ({ url: '/account/services/', method: 'GET' }),
            providesTags: (result) => ['Service']
        }),
        createService: build.mutation<IService, ServicePayloadType>({
            query: (service) => ({
                url: `/services`,
                method: 'POST',
                data: service
            }),
            invalidatesTags: ['Service']
        }),
        updateService: build.mutation<IService, ServicePayloadType & { id: number | string }>({
            query: (service) => ({
                url: `/services/${service.id}`,
                method: 'PUT',
                data: service
            }),
            invalidatesTags: ['Service']
        }),
        deleteService: build.mutation<IService, Pick<IService, 'id'>>({
            query: (service) => ({
                url: `/services/${service.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Service']
        }),
        updateSortingOrders: build.mutation<IService[], ServiceOrderPayload>({
            query: (data) => ({
                url: '/services/updateSortingOrders',
                method: 'PUT',
                data
            }),
            invalidatesTags: ['Service']
        }),
        simpleCreateService: build.mutation<IService, string>({
            query: (name) => ({
                url: '/services/simplified-store',
                method: 'POST',
                data: { name }
            }),
            invalidatesTags: ['Service']
        }),

        updateServiceQuestions: build.mutation<unknown, { serviceId: number; data: ServiceQuestionType[] }>({
            query: ({ serviceId, data }) => ({
                url: `/services/${serviceId}/widget-form-questions`,
                method: 'PUT',
                data: { widget_questions: data }
            }),
            invalidatesTags: ['ServiceDetails', 'Service']
        }),

        getCategories: build.query<IPaginateResponse<IServiceCategory[]>, IBaseParams>({
            query: ({ per_page = -1, page = 1 }) => ({
                url: '/service-categories',
                method: 'GET',
                params: {
                    page,
                    per_page
                }
            }),
            providesTags: ['ServiceCategories']
        }),

        createCategory: build.mutation<IServiceCategory, ServiceCategoryPayload>({
            query: (data) => ({
                url: '/service-categories',
                method: 'POST',
                data
            }),
            invalidatesTags: ['ServiceCategories']
        }),

        updateCategory: build.mutation<IServiceCategory, ServiceCategoryPayload & { id: number }>({
            query: ({ id, ...data }) => ({
                url: `/service-categories/${id}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['ServiceCategories', 'Service']
        }),

        deleteCategory: build.mutation<unknown, number>({
            query: (id) => ({
                url: `/service-categories/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['ServiceCategories', 'Service']
        }),

        reorderCategories: build.mutation<unknown, ServiceCategoryOrdering[]>({
            query: (ordering) => ({
                url: `/service-categories/update-sorting-orders`,
                method: 'PUT',
                data: { ordering }
            }),
            invalidatesTags: ['ServiceCategories']
        }),

        // Service Options
        getServiceOptions: build.query<
            IPaginateResponse<ServiceOption[]>,
            {
                serviceId: string | number;
                params: IBaseParams;
            }
        >({
            query: ({ serviceId, params }) => ({ url: `services/${serviceId}/options`, method: 'GET', params }),
            providesTags: ['ServiceOptions']
        }),

        getSingleServiceOptions: build.query<
            unknown,
            {
                serviceId: string | number;
                serviceOptionId: string | number;
            }
        >({
            query: ({ serviceId, serviceOptionId }) => ({
                url: `/services/${serviceId}/options/${serviceOptionId}`,
                method: 'GET'
            }),
            providesTags: ['ServiceOptions']
        }),

        createServiceOption: build.mutation<unknown, { serviceId: number | string; data: ServiceOptionPayload }>({
            query: ({ serviceId, data }) => ({
                url: `/services/${serviceId}/options`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['ServiceOptions', 'ServiceDetails']
        }),

        updateServiceOption: build.mutation<
            unknown,
            {
                serviceId: number | string;
                serviceOptionId: string | number;
                data: ServiceOptionPayload;
            }
        >({
            query: ({ serviceId, serviceOptionId, data }) => ({
                url: `/services/${serviceId}/options/${serviceOptionId}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['ServiceOptions', 'ServiceDetails']
        }),

        deleteServiceOption: build.mutation<
            unknown,
            {
                serviceId: number | string;
                serviceOptionId: string | number;
            }
        >({
            query: ({ serviceId, serviceOptionId }) => ({
                url: `/services/${serviceId}/options/${serviceOptionId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['ServiceOptions', 'ServiceDetails']
        })
    })
});

export default serviceAPI;
