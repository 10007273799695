import SettingsMenuLink from './SettingsMenuLink';
import useAvailableMenuItems from '../../../menu-items/use-available-menu-items';
import FixedRoute from '../../../ui-component/fixed-route';
import { Stack } from '@mui/material';

const SettingsMenu = () => {
    const { availableSettings } = useAvailableMenuItems();

    return (
        <FixedRoute label="Settings" backLink="/">
            <Stack component="nav" spacing={1}>
                {availableSettings.map((setting) => (
                    <SettingsMenuLink key={setting.id} url={`/settings${setting.url}`} Icon={setting.icon} title={setting.title ?? ''} />
                ))}
            </Stack>
        </FixedRoute>
    );
};

export default SettingsMenu;
