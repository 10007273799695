import { ColorFormProps } from '../types';
import { FC, useCallback, useMemo } from 'react';
import { HslaColor, hslaToHsva, hsvaToHsla } from '@uiw/color-convert';
import { Box } from '@mui/material';
import PickerNumberInput from './PickerNumberInput';

const HslaColorForm: FC<ColorFormProps> = ({ color, onChange, disableAlpha }) => {
    const hsla = useMemo(() => hsvaToHsla(color), [color]);

    const handleChange = useCallback(
        (data: Partial<HslaColor>) => {
            const newColor: HslaColor = { ...hsla, ...data };
            const hsva = hslaToHsva(newColor);

            onChange(hsva);
        },
        [hsla, onChange]
    );

    return (
        <Box sx={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'repeat(4, 1fr)' }}>
            <Box>
                <PickerNumberInput value={hsla.h} minValue={0} maxValue={360} onChange={(h) => handleChange({ h })} />
            </Box>
            {(['s', 'l'] as const).map((key) => (
                <Box key={key}>
                    <PickerNumberInput
                        value={hsla[key]}
                        minValue={0}
                        maxValue={100}
                        suffix="%"
                        onChange={(v) => handleChange({ [key]: v })}
                    />
                </Box>
            ))}
            <Box>
                {!disableAlpha ? (
                    <PickerNumberInput
                        value={hsla.a * 100}
                        minValue={0}
                        maxValue={100}
                        suffix="%"
                        onChange={(v) => handleChange({ a: v / 100 })}
                    />
                ) : null}
            </Box>
        </Box>
    );
};

export default HslaColorForm;
