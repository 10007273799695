import { FC, useCallback, useMemo } from 'react';
import CommonDrawer, { useCommonSettingsDrawerPx } from '../../../ui-component/CommonSettingsDrawer';
import { Box, Button, Stack, TextField } from '@mui/material';
import StickyFormFooter from '../../../ui-component/form/sticky-form-footer';
import { useFormik } from 'formik';
import * as Yup from 'yup';

type ReviewshakeFormType = {
    api_key?: string;
    subdomain?: string;
    custom_domain?: string;
    campaign?: string;
    client?: string;
    location_slug?: string;
};

type ReviewshakeFormProps = {
    open: boolean;
    onClose: () => void;
    onSave: (data: ReviewshakeFormType) => void;
    data?: ReviewshakeFormType | null;
};

const defaults: ReviewshakeFormType = {
    api_key: '',
    subdomain: '',
    custom_domain: '',
    campaign: '',
    client: '',
    location_slug: ''
};

const keys = ['api_key', 'subdomain', 'custom_domain', 'campaign', 'client', 'location_slug'] as const;

const labels: Record<string, string> = {
    api_key: 'API Key',
    subdomain: 'Subdomain',
    custom_domain: 'Custom Domain',
    campaign: 'Campaign',
    client: 'Client',
    location_slug: 'Location Slug'
} as const;

const placeholders = {
    api_key: '6be02cc7c55b789f290e',
    subdomain: 'chilledbutter',
    custom_domain: 'https://reviews.cbtr.me',
    campaign: 'Default',
    client: 'Chilled Butter',
    location_slug: 'location-slug'
} as const;

const getFieldSchema = (key: string) =>
    Yup.string()
        .trim()
        .nullable()
        .when('api_key', {
            is: (v?: string | null) => !!v,
            then: Yup.string()
                .trim()
                .nullable()
                .required(`The ${labels[key] ?? key} field is required when API Key is present.`)
        });

const getUrlSchema = (oppositeKey: string, label: string) =>
    Yup.string()
        .trim('required')
        .nullable()
        .when(['api_key', oppositeKey], {
            is: (apiKey?: string | null, domain?: string | null) => !!apiKey && !domain,
            then: Yup.string().trim().nullable().url().required().label(`The ${label} field is required when API Key is present.`)
        });

const ReviewshakeForm: FC<ReviewshakeFormProps> = ({ open, onClose, data, onSave }) => {
    const initialValues = useMemo<ReviewshakeFormType>(() => ({ ...defaults, ...data }), [data]);
    const containerXSpacing = useCommonSettingsDrawerPx();

    const { values, errors, touched, handleSubmit, handleChange, handleBlur, resetForm } = useFormik<ReviewshakeFormType>({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        initialValues,
        onSubmit: (formData) => {
            onSave(formData);
            onClose();
        },
        validationSchema: Yup.object().shape(
            {
                api_key: Yup.string()
                    .trim()
                    .nullable()
                    .when([...keys], {
                        is: (...args: Array<string | null | undefined>) => [...args].some((arg) => !!arg),
                        then: Yup.string().trim().nullable().required(`The API Key field is required when other fields are present.`)
                    }),
                subdomain: getUrlSchema('custom_domain', labels.subdomain),
                custom_domain: getUrlSchema('subdomain', labels.custom_domain),
                campaign: getFieldSchema('campaign'),
                client: getFieldSchema('client'),
                location_slug: getFieldSchema('location_slug')
            },
            [
                ['api_key', 'api_key'],
                ['api_key', 'subdomain'],
                ['api_key', 'custom_domain'],
                ['api_key', 'campaign'],
                ['api_key', 'client'],
                ['api_key', 'location_slug'],
                ['custom_domain', 'subdomain']
            ]
        )
    });

    const handleClose = useCallback(() => {
        onClose();
        resetForm();
    }, [onClose, resetForm]);

    const isFieldDisabled = useCallback(
        (key: string) => {
            switch (key) {
                case 'custom_domain': {
                    return !!values.subdomain;
                }
                case 'subdomain': {
                    return !!values.custom_domain;
                }

                default: {
                    return false;
                }
            }
        },
        [values.custom_domain, values.subdomain]
    );

    return (
        <CommonDrawer open={open} title="Reviewshake Details " onClose={handleClose}>
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%'
                }}
            >
                <Stack spacing={3}>
                    {keys.map((key) => (
                        <TextField
                            key={key}
                            id={key}
                            name={key}
                            label={labels[key] ?? key}
                            placeholder={placeholders[key] ?? undefined}
                            value={values[key]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={!!errors?.[key] && !!touched?.[key]}
                            helperText={touched?.[key] ? errors?.[key] : undefined}
                            disabled={isFieldDisabled(key)}
                        />
                    ))}
                </Stack>
                <StickyFormFooter containerXSpacing={containerXSpacing}>
                    <Button variant="outlined" color="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </StickyFormFooter>
            </Box>
        </CommonDrawer>
    );
};

export default ReviewshakeForm;
