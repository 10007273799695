import { FC, PropsWithChildren } from 'react';
import { Stack, Theme, useMediaQuery } from '@mui/material';
import { SxProps } from '@mui/system';

const StickyFormFooter: FC<
    PropsWithChildren<{
        sx?: SxProps;
        offset?: number;
        containerXSpacing?: number;
    }>
> = ({ children, containerXSpacing, sx, offset = 0 }) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    return (
        <Stack
            direction={!isMobile ? 'row' : 'column'}
            spacing={!isMobile ? 3 : 1}
            py={2}
            mt="auto"
            justifyContent="flex-end"
            sx={{
                mx: containerXSpacing ? containerXSpacing * -1 : undefined,
                px: containerXSpacing ?? undefined,
                backgroundColor: 'background.paper',
                position: 'sticky',
                bottom: `${offset}px`,
                zIndex: 1,
                boxShadow: containerXSpacing ? '0px -1px 8px 0px rgba(53, 53, 53, 0.25)' : undefined,
                ...sx
            }}
        >
            {children}
        </Stack>
    );
};

export default StickyFormFooter;
