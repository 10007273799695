import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';

type VideoCallResponse = {
    roomUrl?: string;
    join_url?: string;
};

const callApi = createApi({
    reducerPath: 'callAPI',
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        getZoomHostMeetingUrl: build.query<{ start_url: string }, null>({
            query: () => ({
                url: '/account/cbvc',
                method: 'GET'
            })
        }),

        getZoomHostAppointmentMeetingUrl: build.query<{ start_url?: string; roomUrl?: string }, { appointmentId: number }>({
            query: ({ appointmentId }) => ({
                url: `/appointments/${appointmentId}/cbvc`,
                method: 'GET'
            })
        }),

        getAppointmentCallUrl: build.query<VideoCallResponse, { company_slug: string; appointment_uuid: string }>({
            query: ({ company_slug, appointment_uuid }) => ({
                url: `/public/company/${company_slug}/cbvc/${appointment_uuid}`,
                method: 'GET'
            })
        }),

        getEmployeeCallUrl: build.query<VideoCallResponse, { company_slug: string; employee_slug: string }>({
            query: ({ company_slug, employee_slug }) => ({
                url: `/public/company/${company_slug}/${employee_slug}/cbvc`,
                method: 'GET'
            })
        })
    })
});

export default callApi;
