import { ListItemIcon } from '@mui/material';
import { OverrideIcon } from '../../../types';
import { FC } from 'react';

const HeaderNavItemIcon: FC<{ Icon?: OverrideIcon; position?: 'start' | 'end' }> = ({ Icon, position = 'start' }) => {
    const sx = position === 'end' ? { minWidth: 0, ml: 1 } : { minWidth: '28px' };

    return Icon ? (
        <ListItemIcon sx={sx}>
            <Icon sx={{ fontSize: '1.25rem', color: 'inherit' }} />
        </ListItemIcon>
    ) : null;
};

export default HeaderNavItemIcon;
