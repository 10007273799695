import { FC, useMemo } from 'react';
import { IThreadAttachment } from '../../models/IAppointment';
import { Box, ButtonBase, Link } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import useImageAttachments from './use-image-attachments';

const ChatAttachment: FC<{ attachment: IThreadAttachment }> = ({ attachment }) => {
    const { handleOpen, dialog } = useImageAttachments();
    const isImage = useMemo(() => attachment.mime_type.startsWith('image/'), [attachment.mime_type]);

    return (
        <Box>
            {isImage ? (
                <>
                    <ButtonBase
                        onClick={() => handleOpen(attachment.file_url)}
                        sx={{
                            background: '#fff',
                            p: 1,
                            borderRadius: '8px',
                            cursor: 'pointer',
                            position: 'relative',
                            '& > img': {
                                width: '250px',
                                height: '250px',
                                objectPosition: 'center',
                                objectFit: 'cover',
                                borderRadius: '4px'
                            }
                        }}
                    >
                        <img src={attachment.file_url} alt="" />
                    </ButtonBase>
                    {dialog()}
                </>
            ) : (
                <Link
                    href={attachment.file_url}
                    download
                    target="_blank"
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        wordBreak: 'break-all',
                        '& .MuiSvgIcon-root': {
                            fontSize: '1.2em'
                        }
                    }}
                >
                    <AttachFileIcon /> {attachment.file_name}
                </Link>
            )}
        </Box>
    );
};

export default ChatAttachment;
