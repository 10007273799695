import { Button } from '@mui/material';
import RebookReminderSelect from '../appointment-form/elements/status-select/elements/RebookReminderSelect';
import useDialogFunctions from '../../hooks/useDialogFunctions';
import { FC, useCallback } from 'react';
import { AppointmentStatuses } from '../../models/IAppointment';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import { setSelectedEventStatus } from '../../store/slices/calendarSlice';
import appointmentAPI from '../../services/AppointmentService';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { useAppDispatch } from '../../hooks/redux';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SxProps } from '@mui/system';

const CompleteAppointmentBtn: FC<{ appointmentId: string | number; sx?: SxProps }> = ({ appointmentId, sx }) => {
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const { isDialogOpen: reminderOpen, openDialog: openReminder, closeDialog: closeReminder } = useDialogFunctions();
    const [setStatus] = appointmentAPI.useSetAppointmentStatusMutation();

    const updateStatus = useCallback(
        (remind_after_interval?: string) => {
            dispatch(startSubmitting());
            const data = {
                status: AppointmentStatuses.Completed,
                remind_after_interval
            };

            setStatus({ appointmentId, data })
                .unwrap()
                .then(() => {
                    dispatch(setSelectedEventStatus(AppointmentStatuses.Completed));
                    dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
                })
                .catch((err) => {
                    showSnackbar({
                        message: err.message,
                        alertSeverity: SnackBarTypes.Error
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                    closeReminder();
                });
        },
        [dispatch, setStatus, appointmentId, showSnackbar, closeReminder]
    );

    return (
        <>
            <Button sx={sx} color="primary" variant="text" className="forcedBg" onClick={openReminder}>
                Complete Appointment
            </Button>

            <RebookReminderSelect isOpen={reminderOpen} action={updateStatus} />
        </>
    );
};

export default CompleteAppointmentBtn;
