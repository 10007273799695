import { FC, useMemo } from 'react';
import { CardMedia, IconButton, Stack, Theme, Tooltip, useTheme } from '@mui/material';
import AspectRatio from '@mui/icons-material/AspectRatio';
import DeleteForever from '@mui/icons-material/DeleteForever';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const cardMediaSx = (theme: Theme) => ({
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100px',
    width: '120px',
    padding: theme.spacing(0.5),
    border: '1px solid',
    borderColor: 'grey.300',
    borderRadius: theme.spacing(0.5),

    '&:hover': {
        '& > img.hoverable': {
            backdropFilter: 'brightness(70%)',
            filter: 'brightness(70%) blur(2px)'
        },
        '& > div': {
            opacity: 1,
            visibility: 'visible',
            transform: 'translateY(0)'
        }
    },

    '& .cardImage': {
        borderRadius: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'contain',
        objectPosition: 'center',
        background: theme.palette.background.default,
        outline: 'none',
        transition: '0.3s'
    }
});

const cardButtonsSx = (theme: Theme) => ({
    position: 'absolute',
    background: 'rgba(255, 255, 255, 0.7)',
    borderRadius: theme.spacing(0.5),
    padding: '4px',
    opacity: 0,
    transform: 'translateY(-10px)',
    visibility: 'hidden',
    transition: '0.3s'
});

const ImagePreview: FC<{
    file?: File | Blob;
    url?: string;
    onResize?: () => void;
    onDelete?: () => void;
    onPreview?: () => void;
}> = ({ file, url, onResize, onDelete, onPreview }) => {
    const theme = useTheme();
    const preview = useMemo(() => {
        if (url) {
            return url;
        }

        if (file) {
            return URL.createObjectURL(file);
        }

        return '';
    }, [file, url]);

    return (
        <CardMedia sx={cardMediaSx(theme)}>
            <img src={preview} className={`cardImage ${onResize || onDelete ? 'hoverable' : ''}`} alt="" />
            {onResize || onDelete || onPreview ? (
                <Stack sx={cardButtonsSx(theme)} direction="row" alignItems="center" justifyContent="start">
                    {onResize && (
                        <Tooltip title="Edit image">
                            <IconButton color="primary" aria-label="Edit image" onClick={onResize}>
                                <AspectRatio />
                            </IconButton>
                        </Tooltip>
                    )}
                    {onPreview && (
                        <Tooltip title="Zoom In">
                            <IconButton color="primary" aria-label="Delete image" onClick={onPreview}>
                                <VisibilityOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {onDelete && (
                        <Tooltip title="Delete image">
                            <IconButton color="error" aria-label="Delete image" onClick={onDelete}>
                                <DeleteForever />
                            </IconButton>
                        </Tooltip>
                    )}
                </Stack>
            ) : null}
        </CardMedia>
    );
};

export default ImagePreview;
