import { useMemo } from 'react';
import useAuth from './useAuth';
import { UserRole } from '../models/IEmployee';

const LEDGER_ROLES = [UserRole.Owner, UserRole.Admin, UserRole.Manager];

const useCashLedger = () => {
    const { user } = useAuth();

    const isLedgerVisible = useMemo(() => {
        if (user) {
            const hasRights = LEDGER_ROLES.includes(user.employee?.role.name);
            const enabledInSettings = !!user.currentCompany.settings?.payments.use_cash_ledger;

            return hasRights && enabledInSettings;
        }
        return false;
    }, [user]);

    return { isLedgerVisible };
};

export default useCashLedger;
