import { AbilityContext } from '../utils/roles/Can';
import useAuth from '../hooks/useAuth';
import { buildAbilityFor } from '../utils/roles/ability';
import { useMemo } from 'react';

export const UserRoleProvider = ({ children }: { children: React.ReactElement | null }) => {
    const { user } = useAuth();
    const value = useMemo(() => {
        if (user) {
            return buildAbilityFor(user);
        }

        return undefined;
    }, [user]);

    return user && value ? <AbilityContext.Provider value={value}>{children}</AbilityContext.Provider> : null;
};

export default AbilityContext;
