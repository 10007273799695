import CommonDrawer, { CommonDrawerProps } from './CommonSettingsDrawer';
import { FC, useCallback, useEffect, useState } from 'react';

const CommonDrawerRoute: FC<Omit<CommonDrawerProps, 'open'>> = ({ children, onClose, ...rest }) => {
    const [open, setOpen] = useState(false);

    const handleClose = useCallback(() => {
        setOpen(false);
        if (onClose) {
            setTimeout(onClose, 150);
        }
    }, [onClose]);

    useEffect(() => {
        setOpen(true);
        return () => {
            setOpen(false);
        };
    }, []);

    return (
        <CommonDrawer {...rest} open={open} onClose={handleClose}>
            {children}
        </CommonDrawer>
    );
};

export default CommonDrawerRoute;
