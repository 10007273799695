import { useCallback, useEffect, useMemo } from 'react';

// project imports
import { IAppointment } from '../../../models/IAppointment';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import appointmentAPI from '../../../services/AppointmentService';
import { skipToken } from '@reduxjs/toolkit/query/react';
import AppointmentDetails from './appointment-details';
import { setSelectedEvent as setStoreEvent } from '../../../store/slices/calendarSlice';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import EntityDrawerHeader from '../../entity-drawer-layout/EntityDrawerHeader';
import EntityWrapperContainer from '../../entity-drawer-layout/EntityDrawerContainer';
import UpdatedStyleWrapper from '../../updated-style-wrapper';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import { UserRole } from '../../../models/IEmployee';
import useAuth from '../../../hooks/useAuth';

export interface AppointmentCardProps {
    cardEvent: IAppointment | string | undefined;
    onCancel: () => void;
}

const AppointmentCard = ({ cardEvent, onCancel }: AppointmentCardProps) => {
    const { user } = useAuth();
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();

    const { selectedEvent: storeEvent, shouldSubmitFormOnTabChange, isForeignAppointment } = useAppSelector((state) => state.calendar);
    // event from calendar -> get his id -> fetch full appointment info
    const eventId = typeof cardEvent === 'string' && !isNaN(Number(cardEvent)) ? Number(cardEvent) : undefined;
    const { data: appointment, isLoading, isFetching } = appointmentAPI.useGetAppointmentQuery(eventId ?? skipToken, {
        refetchOnMountOrArgChange: true
    });

    const onCloseEventCard = () => {
        if (shouldSubmitFormOnTabChange) {
            dispatch(
                openConfirmPopup({
                    text: 'Discard unsaved changes?',
                    confirmText: 'Discard',
                    onConfirm: onCancel
                })
            );
        } else {
            onCancel();
        }
    };

    const [deleteAppointment] = appointmentAPI.useDeleteAppointmentMutation();

    const roleName = user?.employee.role.name;
    const canDelete = useMemo(() => {
        const isProvider = roleName && roleName === UserRole.Provider;
        return !isForeignAppointment && !isProvider;
    }, [isForeignAppointment, roleName]);

    const handleDelete = useCallback(() => {
        if (!storeEvent) return;

        dispatch(startSubmitting());

        deleteAppointment(storeEvent.id)
            .unwrap()
            .then(() => {
                showSnackbar({
                    message: 'Appointment deleted.',
                    alertSeverity: SnackBarTypes.Success
                });
                onCancel();
            })
            .catch((e) => {
                showSnackbar({
                    message: e.message || 'Appointment was not deleted.',
                    alertSeverity: SnackBarTypes.Error
                });
            })
            .finally(() => dispatch(stopSubmitting()));
    }, [storeEvent, dispatch, deleteAppointment, showSnackbar, onCancel]);

    const onDelete = useCallback(() => {
        dispatch(
            openConfirmPopup({
                onConfirm: handleDelete,
                confirmText: `Delete`,
                text: 'Are you sure you want to delete this appointment?'
            })
        );
    }, [dispatch, handleDelete]);

    const appointmentLoading = isLoading || isFetching;

    useEffect(() => {
        if (appointment && (!storeEvent || storeEvent.id !== eventId)) {
            dispatch(setStoreEvent(appointment));
        }
    }, [appointment, dispatch, eventId, storeEvent]);

    return (
        <UpdatedStyleWrapper>
            <EntityWrapperContainer>
                <EntityDrawerHeader
                    title="Appointment Details"
                    onClose={onCloseEventCard}
                    onDelete={canDelete ? () => onDelete() : undefined}
                />
                <AppointmentDetails isLoading={appointmentLoading} onClose={onCloseEventCard} />
            </EntityWrapperContainer>
        </UpdatedStyleWrapper>
    );
};

export default AppointmentCard;
