import { useState, FormEvent } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Typography } from '@material-ui/core';
import { PaymentGateways } from '../../../../../../../types';
import { useAppDispatch } from '../../../../../../../hooks/redux';
import { startSubmitting, stopSubmitting } from 'store/slices/SubmittingSlice';
import { IStripeAdditionalData } from '../../../../../../../models/ICompany';

interface StripeCheckoutFormProps {
    submitBooking: (details?: object) => void;
    stripePublicKey: string;
    client_secret: string;
    return_url?: string;
    savedData?: IStripeAdditionalData;
}

const StripeCheckoutForm = ({ submitBooking, return_url, client_secret, savedData, stripePublicKey }: StripeCheckoutFormProps) => {
    const [error, setError] = useState<string | null>(null);

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useAppDispatch();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!stripe || !elements) return;
        setError(null);
        dispatch(startSubmitting());
        localStorage.setItem(client_secret, JSON.stringify({ ...savedData, _key: stripePublicKey } ?? {}));

        const result = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: return_url ?? window.location.href
            }
        });

        if (result.error?.message) {
            dispatch(stopSubmitting());
            setError(result.error.message);
            return;
        }

        if (result?.paymentIntent?.status === 'requires_capture') {
            localStorage.removeItem(client_secret);
            submitBooking({ ...result.paymentIntent, gateway: PaymentGateways.Stripe });
        }
    };

    return (
        <form onSubmit={handleSubmit} id="widget-stripe-checkout-form">
            <PaymentElement id="payment-element" />
            {error && (
                <Typography ml={2} color="error">
                    {error}
                </Typography>
            )}
        </form>
    );
};

export default StripeCheckoutForm;
