import { FC } from 'react';
import { Typography, TypographyProps } from '@mui/material';

const SectionHeading: FC<TypographyProps> = ({ children, sx, ...rest }) => (
    <Typography
        sx={{
            ...sx,
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '1.5',
            color: 'grey.800'
        }}
        {...rest}
    >
        {children}
    </Typography>
);

export default SectionHeading;
