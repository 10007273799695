import { FC, useMemo, useState } from 'react';
import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import companyAPI from '../../../services/CompanyService';
import AppPagePagination from '../../../ui-component/PagePagination';
import NewWebhook from './NewWebhook';
import WebhookTableRow from './WebhookTableRow';
import CBTable, { CbTableColumn } from '../../../ui-component/tables/CBTable';
import { IWebhookItem } from '../types';

const columns: CbTableColumn[] = [
    { id: 'name', label: 'Name' },
    { id: 'enabled', label: 'Enabled' },
    { id: 'url', label: 'URL' },
    { id: 'actions', label: 'Actions' }
];

const WebhooksSettingsTable: FC<{ companyId: number }> = ({ companyId }) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));
    const [page, setPage] = useState<number>(1);
    const { data, isLoading } = companyAPI.useGetCompanyWebhooksQuery({ companyId, params: { page, per_page: 5 } });
    const companyWebhooksData = data?.data;

    const rows = useMemo<IWebhookItem[]>(() => {
        const arr: IWebhookItem[] = [];
        return companyWebhooksData ?? arr;
    }, [companyWebhooksData]);

    return (
        <Box>
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2} pb={1} mb={0.5} alignItems={isMobile ? 'flex-start' : 'center'}>
                <Typography variant="h4" sx={{ flexGrow: 1, flexShrink: 1 }}>
                    Web Hooks
                </Typography>
                <NewWebhook companyId={companyId} />
            </Stack>

            <Box>
                <CBTable
                    columns={columns}
                    rows={rows}
                    isLoading={isLoading}
                    renderRow={(row) => <WebhookTableRow companyId={companyId} row={row} key={row.id} />}
                />
                <AppPagePagination data={data} perPage={5} page={page} setPage={setPage} />
            </Box>
        </Box>
    );
};

export default WebhooksSettingsTable;
