import * as DOMPurify from 'dompurify';
import { Typography } from '@material-ui/core';
import { FC, useCallback, MouseEvent } from 'react';
import useImageAttachments from './use-image-attachments';

const wrapperSx = {
    '& img': {
        width: '250px',
        height: '250px',
        objectPosition: 'center',
        objectFit: 'cover',
        borderRadius: '4px',
        border: '8px solid #fff',
        cursor: 'pointer'
    },

    '& br:first-child, & br + br': {
        display: 'none'
    }
} as const;

const ChatText: FC<{ text: string }> = ({ text }) => {
    const { dialog, handleOpen } = useImageAttachments();
    const handleImgClick = useCallback(
        (e: MouseEvent<HTMLElement>) => {
            // @ts-ignore
            const { src, tagName } = e.target;
            if (!!src && tagName === 'IMG') {
                handleOpen(src);
            }
        },
        [handleOpen]
    );

    return (
        <>
            <Typography sx={wrapperSx} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }} onClick={handleImgClick} />
            {dialog()}
        </>
    );
};

export default ChatText;
