import { FC, useCallback, useMemo } from 'react';
import { Box, FormControl, FormHelperText, Grid, InputAdornment, TextField } from '@mui/material';
import * as Yup from 'yup';
import { IDepositRequestPayload } from '../../../models/IAppointment';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AppTimePicker from '../time-picker/AppTimePicker';
import moment from 'moment';
import DepositRequestReminder from './DepositRequestReminder';
import useExtendedFormik from '../../../hooks/useExtendedFormik';
import useAuth from '../../../hooks/useAuth';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

export const depositRequestSchema = Yup.object()
    .shape({
        expires_at: Yup.string().trim().required().label('Exp. Date'),
        amount: Yup.number().positive('Amount must be greater than 0').required().label('Amount'),
        reminder: Yup.object({
            unit: Yup.string().trim().oneOf(['minutes', 'hours', 'days', 'months']).required().label('Exp. Reminder Unit'),
            value: Yup.number().min(1).integer().required().label('Exp. Reminder Value')
        })
            .required()
            .label('Exp. Reminder')
    })
    .nullable();

interface IDepositRequestFormProps {
    onSubmit: (data: IDepositRequestPayload) => void;
    defaults?: Partial<IDepositRequestPayload>;
}

const DepositRequestForm: FC<IDepositRequestFormProps> = ({ onSubmit, defaults = {} }) => {
    const { user } = useAuth();

    const reminderValue = user?.currentCompany.settings?.notifications?.customer.appointment_deposit_request_reminder ?? 1;
    const defaultValues = useMemo<IDepositRequestPayload>(
        () => ({
            expires_at: '',
            amount: 0,
            reminder: { value: reminderValue, unit: 'hours' }
        }),
        [reminderValue]
    );

    const { values, handleChange, setFieldValue, handleSubmit, errors } = useExtendedFormik<IDepositRequestPayload>({
        initialValues: { ...defaultValues, ...defaults },
        onSubmit,
        validationSchema: depositRequestSchema,
        validateOnChange: true,
        validateOnBlur: true
    });

    const reminderErrors = useMemo<string[]>(() => (errors.reminder ? Object.values(errors.reminder).filter((v) => !!v) : []), [
        errors.reminder
    ]);

    const handleDateOnlyUpdate = useCallback(
        (date: Date | null) => {
            setFieldValue('expires_at', moment(date).toISOString());
        },
        [setFieldValue]
    );

    const handleTimeOnlyUpdate = useCallback(
        (date: Date | null) => {
            const hours = moment(date).hours();
            const minutes = moment(date).minutes();
            setFieldValue('expires_at', moment(values.expires_at).set({ hours, minutes }).toISOString());
        },
        [setFieldValue, values.expires_at]
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box paddingX={0} component="form" onSubmit={handleSubmit} id="deposit-request-form">
                <Grid container spacing={1.5}>
                    <Grid item xs={6}>
                        <MobileDatePicker
                            label="Exp. Date"
                            onChange={(date) => handleDateOnlyUpdate(date)}
                            value={values.expires_at ? new Date(values.expires_at) : new Date()}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !!errors.expires_at,
                                    helperText: errors.expires_at,
                                    InputProps: {
                                        endAdornment: <CalendarMonthOutlinedIcon />
                                    }
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl error={!!errors.expires_at} fullWidth>
                            <AppTimePicker
                                label="Exp. Time"
                                value={values.expires_at ? moment(values.expires_at) : moment(new Date()).hours(9).minutes(0)}
                                onChange={(date) => handleTimeOnlyUpdate(date ? date.toDate() : date)}
                            />
                            <FormHelperText>{errors.expires_at}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            id="amount"
                            name="amount"
                            label="Amount"
                            value={values.amount}
                            onChange={handleChange}
                            fullWidth
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                            error={!!errors.amount}
                            helperText={errors.amount}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <DepositRequestReminder
                            reminderValue={values.reminder.value}
                            reminderUnit={values.reminder.unit}
                            onChangeValue={(v) => setFieldValue('reminder.value', v)}
                            onChangeAmount={(v) => setFieldValue('reminder.unit', v)}
                            errors={reminderErrors}
                        />
                    </Grid>
                </Grid>
            </Box>
        </LocalizationProvider>
    );
};

export default DepositRequestForm;
