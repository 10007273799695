const alphaPickerSx = {
    marginBottom: '12px !important',
    height: '10px !important',
    paddingTop: '3px',
    paddingBottom: '3px',

    '& > div': {
        height: '10px !important',
        borderRadius: '5px !important'
    }
};

const saturationPickerSx = {
    marginBottom: '12px !important',
    borderRadius: '4px !important',

    '& > div': {
        borderWidth: '1px !important',
        width: '16px !important',
        height: '16px !important',
        transform: 'translate(-8px, -8px) !important'
    }
};

const borderlessSelectSx = {
    '& .MuiOutlinedInput-notchedOutline': {
        opacity: '0 !important'
    },

    '& .MuiInputBase-input': {
        paddingLeft: '0 !important',
        paddingY: '4px !important'
    }
};

export { alphaPickerSx, saturationPickerSx, borderlessSelectSx };
