import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Box, Button, ButtonGroup, InputAdornment, Popover, Stack, TextField } from '@mui/material';
import ColorizeOutlinedIcon from '@mui/icons-material/ColorizeOutlined';
import PredefinedColorsTab from './components/PredefinedColorsTab';
import CustomColorsTab from './components/CustomColorsTab';
import { hexToHsva, hsvaToHexa, HsvaColor, hsvaToHex } from '@uiw/color-convert';
import { IColorPickerProps } from './types';

const ColorPicker: FC<IColorPickerProps> = ({ value, onChange, disableAlphaOutput, InputProps, ...rest }) => {
    const [tab, setTab] = useState<'colors' | 'custom'>('colors');
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);

    const hsvaColor = useMemo(() => {
        const { h, s, v, a } = hexToHsva(value ?? '#ffffff');
        return { h, s, v, a: parseFloat(a.toFixed(2)) };
    }, [value]);

    const handleChange = useCallback(
        (newColor: HsvaColor) => {
            const hex = disableAlphaOutput ? hsvaToHex(newColor) : hsvaToHexa(newColor);
            onChange(hex);
        },
        [disableAlphaOutput, onChange]
    );

    return (
        <>
            <TextField
                {...rest}
                ref={ref}
                onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                }}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                type="color"
                sx={{
                    '& .MuiInputBase-input': {
                        width: '1px',
                        flexGrow: 0,
                        flexShrink: 0,
                        visibility: 'hidden'
                    }
                }}
                InputProps={{
                    ...InputProps,
                    startAdornment: (
                        <InputAdornment
                            position="start"
                            sx={{
                                flexGrow: 1,
                                height: 'auto'
                            }}
                        >
                            <Box
                                sx={{
                                    minHeight: '1.5rem',
                                    width: '100%',
                                    maxWidth: '80px',
                                    borderRadius: '2px',
                                    backgroundColor: value ?? 'transparent'
                                }}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <ColorizeOutlinedIcon sx={{ color: 'grey.900' }} />
                        </InputAdornment>
                    )
                }}
            />
            <Popover
                id="colorpicker"
                open={open}
                anchorEl={ref.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Stack p={1.5} spacing={1.5} sx={{ width: '216px', maxWidth: '100vw' }}>
                    <ButtonGroup color="secondary" sx={{ width: '100%', '& .MuiButton-root': { width: '50%' } }}>
                        <Button variant={tab === 'colors' ? 'contained' : 'outlined'} onClick={() => setTab('colors')}>
                            Colors
                        </Button>
                        <Button variant={tab === 'custom' ? 'contained' : 'outlined'} onClick={() => setTab('custom')}>
                            Custom
                        </Button>
                    </ButtonGroup>

                    {tab === 'colors' ? (
                        <PredefinedColorsTab color={hsvaColor} onChange={handleChange} disableAlpha={disableAlphaOutput} />
                    ) : null}
                    {tab === 'custom' ? (
                        <CustomColorsTab color={hsvaColor} onChange={handleChange} disableAlpha={disableAlphaOutput} />
                    ) : null}
                </Stack>
            </Popover>
        </>
    );
};

export default ColorPicker;
