import { Theme, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';

const useEntityDrawerSpacing = () => {
    const isMobile = useMediaQuery((themeParams: Theme) => themeParams.breakpoints.down('mobile'));
    const spacingX = useMemo(() => (isMobile ? 2 : 3), [isMobile]);

    return { isMobile, spacingX };
};

export default useEntityDrawerSpacing;
