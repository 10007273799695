import { FC, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import { Alert, AlertTitle, Box, Button, Collapse, IconButton } from '@mui/material';

type ErrorFallbackProps = {
    title: string;
    text?: any;
    onReset: () => void;
    error: any;
    wrappedComponentName?: string;
};

const ErrorFallback: FC<ErrorFallbackProps> = ({ title, text, onReset, error, wrappedComponentName }) => {
    const [open, setOpen] = useState(false);

    return (
        <Alert
            severity="error"
            action={
                <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={
                        onReset
                            ? () => {
                                  onReset();
                              }
                            : undefined
                    }
                >
                    <CachedIcon fontSize="inherit" />
                </IconButton>
            }
        >
            <AlertTitle>{title}</AlertTitle>
            {text ? <Box mb={1}>{text}</Box> : null}
            <Button color="error" size="small" onClick={() => setOpen(!open)}>
                Technical Details
            </Button>
            <Collapse in={open}>
                <div>
                    {wrappedComponentName ? <div>Component: {wrappedComponentName}</div> : null}
                    <div>{JSON.stringify(error?.message)}</div>
                </div>
            </Collapse>
        </Alert>
    );
};

export default ErrorFallback;
