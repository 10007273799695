import { AppointmentServiceRowType } from '../../../models/IAppointment';

const convertedPrice = (v?: unknown) => {
    if (typeof v === 'string' || typeof v === 'number') {
        return parseFloat(String(v));
    }

    return null;
};

const compareServices = (oldValues: AppointmentServiceRowType[], newValues: AppointmentServiceRowType[]): boolean => {
    if (oldValues.length !== newValues.length) {
        return true;
    }

    return newValues.some((service, index) => {
        const oldService = oldValues[index];
        const differentId = service.service?.id !== oldService?.service?.id;
        const differentPrice = convertedPrice(service.price) !== convertedPrice(oldService?.price);
        const differentPrepay = convertedPrice(service.prepay) !== convertedPrice(oldService?.prepay);

        const oldServiceOptionIds = oldService?.options.map((option) => option.id);
        const newServiceOptionIds = service.options.map((option) => option.id);
        const differentServiceOptions =
            [
                ...newServiceOptionIds.filter((id) => !oldServiceOptionIds.includes(id)),
                ...oldServiceOptionIds.filter((id) => !newServiceOptionIds.includes(id))
            ].length > 0;

        return differentId || differentPrice || differentPrepay || differentServiceOptions;
    });
};

export default compareServices;
