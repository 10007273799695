import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Theme,
    Typography,
    useMediaQuery
} from '@mui/material';
import authService, { ExtendedLeadPayloadData } from '../../services/authService';
import { useFormik } from 'formik';
import AppointmentAddressBox from '../../ui-component/AppointmentAddressBox';
import StickyFormFooter from '../../ui-component/form/sticky-form-footer';
import UpdatedStyleWrapper from '../../ui-component/updated-style-wrapper';
import { useCallback, useMemo, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import InitAPI from '../../services/InitService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SnackBarTypes } from '../../store/snackbarReducer';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import * as Yup from 'yup';
import { useAppDispatch } from '../../hooks/redux';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';

const initialValues: ExtendedLeadPayloadData = {
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    address: {
        address: '',
        city: '',
        state: '',
        postal_code: '',
        country: ''
    },
    company: {
        industry: '',
        name: '',
        subscription_type: ''
    }
};

const labels: Record<string, string> = {
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'Email'
};

const userFields = ['firstname', 'lastname', 'email'] as const;

const CreateLead = () => {
    const dispatch = useAppDispatch();
    const [url, setUrl] = useState<string | null>(null);
    const { user } = useAuth();
    const { showSnackbar } = useShowSnackbar();
    const companyId = user?.currentCompany.id;
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));

    const [generateLead] = authService.useSaveExtendedLeadMutation();
    const { data: initData } = InitAPI.useInitQuery({ params: {} });

    const planOptions = useMemo(() => {
        if (initData) {
            return initData.stripe.products.map((product) => ({
                value: product.metadata.limitName,
                label: product.name
            }));
        }

        return [];
    }, [initData]);

    const handleCopyUrl = useCallback(() => {
        if (url) {
            navigator.clipboard.writeText(url).then(() => {
                showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Link copied!' });
            });
        }
    }, [showSnackbar, url]);

    const onSubmit = useCallback(
        (data: ExtendedLeadPayloadData) => {
            if (companyId) {
                dispatch(startSubmitting());
                generateLead({ companyId, data })
                    .unwrap()
                    .then((res) => {
                        setUrl(res.url);
                    })
                    .catch((err) => {
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Error,
                            message: err.message ?? err.data ?? JSON.stringify(err)
                        });
                    })
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [companyId, dispatch, generateLead, showSnackbar]
    );

    const form = useFormik<ExtendedLeadPayloadData>({
        enableReinitialize: true,
        onSubmit,
        initialValues,
        validationSchema: Yup.object().shape({
            email: Yup.string().trim().required().email().label(labels.email),
            firstname: Yup.string().trim().required().label(labels.firstname),
            lastname: Yup.string().trim().required().label(labels.lastname),
            address: Yup.object().shape({
                address: Yup.string().trim().required().label('Address')
            }),
            company: Yup.object().shape({
                industry: Yup.string().trim().required().label('Industry'),
                name: Yup.string().trim().required().label('Business Name'),
                subscription_type: Yup.string().trim().required().label('Subscription Type')
            })
        })
    });

    const { values, touched, handleChange, handleBlur, handleSubmit, errors, setFieldValue, resetForm } = form;

    const onGenerateNewLabel = useCallback(() => {
        setUrl(null);
        resetForm();
    }, [resetForm]);

    return (
        <UpdatedStyleWrapper>
            <Box
                sx={{
                    display: 'grid',
                    minHeight: '100%',
                    gridTemplateRows: '100%',
                    gridTemplateColumns: '100%',
                    pb: isMobile ? 0 : '20px',

                    '& > .MuiPaper-root:not(.MuiAlert-root)': {
                        px: isMobile ? 2 : 3,
                        pt: isMobile ? 0 : 3,
                        pb: isMobile ? 0 : 1,
                        borderRadius: isMobile ? 0 : '12px',
                        width: '1320px',
                        maxWidth: '100%',
                        margin: '0 auto'
                    }
                }}
            >
                <Paper elevation={0}>
                    <Typography variant="h3" mb={2}>
                        Lead URL Generator
                    </Typography>

                    {url ? (
                        <Stack spacing={2}>
                            <Alert
                                severity="info"
                                action={
                                    <IconButton color="inherit" onClick={handleCopyUrl}>
                                        <ContentCopyIcon />
                                    </IconButton>
                                }
                            >
                                <AlertTitle>URL for client: </AlertTitle>
                                {url}
                            </Alert>
                            <Box>
                                <Button onClick={onGenerateNewLabel} variant="contained" color="error">
                                    Generate Another Lead
                                </Button>
                            </Box>
                        </Stack>
                    ) : (
                        <Grid container spacing={2} component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
                            {userFields.map((item) => (
                                <Grid item xs={12} sm={item !== 'email' ? 6 : undefined} key={item}>
                                    <TextField
                                        fullWidth
                                        id={item}
                                        name={item}
                                        label={labels[item]}
                                        value={values[item]}
                                        error={!!errors[item] && !!touched[item]}
                                        helperText={touched[item] ? errors[item] : undefined}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                            ))}

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="company.name"
                                    name="company.name"
                                    label="Business Name"
                                    value={values.company.name}
                                    error={!!errors.company?.name && !!touched.company?.name}
                                    helperText={touched.company?.name ? errors.company?.name : undefined}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="company.industry"
                                    name="company.industry"
                                    label="Industry"
                                    select
                                    value={values.company.industry}
                                    onBlur={handleBlur}
                                    error={!!errors.company?.industry && !!touched.company?.industry}
                                    helperText={touched.company?.industry ? errors.company?.industry : undefined}
                                    onChange={(e) => setFieldValue('company.industry', e.target.value)}
                                >
                                    <MenuItem value="barber">Barber</MenuItem>
                                    <MenuItem value="med_spa">Med Spa</MenuItem>
                                    <MenuItem value="tattoo">Tattoo</MenuItem>
                                    <MenuItem value="fitness">Fitness</MenuItem>
                                    <MenuItem value="other">Other</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <AppointmentAddressBox
                                    fullWidth
                                    id="address"
                                    name="address"
                                    label="Address"
                                    value={values.address}
                                    onBlur={handleBlur}
                                    onChange={(v) => setFieldValue('address', v)}
                                    error={!!touched.address && !!errors.address?.address}
                                    helperText={touched.address ? errors.address?.address : undefined}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="company.subscription_type"
                                    name="company.subscription_type"
                                    label="Subscription Type"
                                    select
                                    value={values.company.subscription_type}
                                    onBlur={handleBlur}
                                    error={!!errors.company?.subscription_type && !!touched.company?.subscription_type}
                                    helperText={touched.company?.subscription_type ? errors.company?.subscription_type : undefined}
                                    onChange={(e) => setFieldValue('company.subscription_type', e.target.value)}
                                >
                                    {planOptions.map((opt) => (
                                        <MenuItem value={opt.value} key={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <StickyFormFooter>
                                    <Button color="primary" variant="text" className="forcedBg" onClick={() => resetForm()}>
                                        Cancel
                                    </Button>
                                    <Button type="submit" color="primary" variant="contained">
                                        Generate Lead
                                    </Button>
                                </StickyFormFooter>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </Box>
        </UpdatedStyleWrapper>
    );
};

export default CreateLead;
