import { Fragment, memo, useCallback, useEffect, useRef } from 'react';

// material-ui
import { Box, Stack } from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import { IConversationThread } from '../../models/IAppointment';
import ChatMessage from './ChatMessage';
import { format, isSameDay } from 'date-fns';

// ==============================|| CHAT MESSAGE HISTORY ||============================== //

export interface ChartHistoryProps {
    data: IConversationThread[];
}

const ChartHistory = ({ data }: ChartHistoryProps) => {
    const wrapper = useRef<HTMLDivElement | null>(null);

    const compareDates = useCallback((dateA?: string, dateB?: string) => {
        if (dateA && dateB) {
            return isSameDay(new Date(dateA), new Date(dateB));
        }

        return false;
    }, []);

    useEffect(() => {
        if (wrapper.current) {
            wrapper.current.scrollIntoView(false);
        }
    }, [data.length]);

    return (
        <Stack alignItems="flex-start" width="100%" spacing={gridSpacing} ref={wrapper}>
            {data.map((history, index, arr) => {
                const prev = arr[index - 1];
                const timestamp = compareDates(prev?.created_at, history.created_at)
                    ? null
                    : format(new Date(history.created_at), 'MMM d, yyyy');
                return (
                    <Fragment key={history.id}>
                        {timestamp ? (
                            <Box
                                sx={{
                                    position: 'sticky',
                                    top: '16px',
                                    paddingX: 0.5,
                                    paddingY: 0.25,
                                    backgroundColor: 'newColors.darkBlue',
                                    color: '#fff',
                                    borderRadius: '4px',
                                    marginX: 'auto',
                                    fontSize: '0.75rem',
                                    lineHeight: '1rem',
                                    fontWeight: 600,
                                    alignSelf: 'center'
                                }}
                            >
                                {timestamp}
                            </Box>
                        ) : null}
                        <ChatMessage thread={history} />
                    </Fragment>
                );
            })}
        </Stack>
    );
};

export default memo(ChartHistory);
