import { useCallback } from 'react';
import { IFileUploaderData, ImageData } from '../../../../models/IImage';

const useImagePreview = () =>
    useCallback((v: ImageData | IFileUploaderData) => {
        if ('file' in v && !!v.file) {
            return URL.createObjectURL(v.file);
        }

        if ('preview' in v) {
            return v.preview;
        }

        if ('url' in v) {
            return v.url;
        }

        return undefined;
    }, []);

export default useImagePreview;
