import { useCallback, useMemo, useState } from 'react';
import locationAPI from '../services/LocationService';
import { LedgerTransaction } from '../models/ILocation';
import { useAppDispatch } from './redux';

const PER_PAGE = 20;
const useCashLedgerTransactions = (locationId: number) => {
    const dispatch = useAppDispatch();
    const [cursor, setCursor] = useState<string | null>(null);
    const [prevTransactions, setPrevTransactions] = useState<LedgerTransaction[]>([]);
    const { data: transactions, refetch, isLoading, isFetching } = locationAPI.useFetchLocationLedgerQuery({
        locationId,
        params: { cursor, per_page: PER_PAGE }
    });

    const transactionsData = transactions?.data ?? [];
    const nextCursor = transactions?.meta?.next_cursor;

    const allTransactions = useMemo(() => {
        const actualData = prevTransactions.filter((item) => !transactionsData.some(({ id }) => id === item.id));
        return [...actualData, ...transactionsData];
    }, [prevTransactions, transactionsData]);

    const onLoadMore = useCallback(() => {
        if (nextCursor) {
            setPrevTransactions((prev) => [...prev, ...(transactions?.data || [])]);
            setCursor(nextCursor);
            refetch();
        }
    }, [nextCursor, refetch, transactions]);

    const fullReload = useCallback(() => {
        setPrevTransactions([]);
        setCursor(null);
        refetch();
    }, [refetch]);

    const onDeleteTransaction = useCallback(
        (transactionId: number) => {
            setPrevTransactions((prev) => prev.filter(({ id }) => id !== transactionId));
            dispatch(
                locationAPI.util.updateQueryData(
                    'fetchLocationLedger',
                    {
                        locationId,
                        params: { cursor, per_page: PER_PAGE }
                    },
                    (prev) => ({ ...prev, data: prev.data.filter(({ id }) => id !== transactionId) })
                )
            );
        },
        [cursor, dispatch, locationId]
    );

    const onUpdateTransaction = useCallback(
        (transaction: LedgerTransaction) => {
            setPrevTransactions((prev) => prev.map((t) => (t.id === transaction.id ? transaction : t)));
            dispatch(
                locationAPI.util.updateQueryData(
                    'fetchLocationLedger',
                    {
                        locationId,
                        params: { cursor, per_page: PER_PAGE }
                    },
                    (prev) => ({ ...prev, data: prev.data.map((t) => (t.id === transaction.id ? transaction : t)) })
                )
            );
        },
        [cursor, dispatch, locationId]
    );

    const addTransactionToArray = useCallback((newTransaction: LedgerTransaction, arr: LedgerTransaction[]) => {
        if (!arr.some(({ id }) => id === newTransaction.id)) {
            return [newTransaction, ...arr];
        }

        return arr;
    }, []);

    const onAddTransaction = useCallback(
        (transaction: LedgerTransaction) => {
            if (prevTransactions.length) {
                setPrevTransactions((prev) => addTransactionToArray(transaction, prev));
            } else {
                dispatch(
                    locationAPI.util.updateQueryData(
                        'fetchLocationLedger',
                        {
                            locationId,
                            params: { cursor, per_page: PER_PAGE }
                        },
                        (prev) => ({ ...prev, data: addTransactionToArray(transaction, prev.data) })
                    )
                );
            }
        },
        [addTransactionToArray, cursor, dispatch, locationId, prevTransactions.length]
    );

    return {
        data: allTransactions,
        onLoadMore,
        isLoading,
        isFetching,
        fullReload,
        hasMore: !!nextCursor,
        onDeleteTransaction,
        onUpdateTransaction,
        onAddTransaction
    };
};

export default useCashLedgerTransactions;
