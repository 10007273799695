import companyAPI from '../../../services/CompanyService';
import { FC, useCallback, useMemo, useState } from 'react';
import { Button, Chip, Stack, TableCell, TableRow } from '@mui/material';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { useAppDispatch } from '../../../hooks/redux';
import useWebhookDefaults from './use-webhook-defaults';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import { IWebhookItem, IWebhookSettingsInput } from '../types';
import WebhookDialog from './WebhookDialog';

const WebhookTableRow: FC<{ companyId: number; row: IWebhookItem }> = ({ companyId, row }) => {
    const dispatch = useAppDispatch();
    const [edit, setEdit] = useState(false);
    const { defaultValue, triggerData } = useWebhookDefaults(companyId, row);
    const [deleteHook, { isLoading: isDeleting }] = companyAPI.useDeleteCompanyWebhookMutation();
    const [updateHook, { isLoading: isUpdating }] = companyAPI.useUpdateCompanyWebhookMutation();

    const { showSnackbar } = useShowSnackbar();

    const onUpdate = useCallback(
        (formData: IWebhookSettingsInput) => {
            dispatch(startSubmitting());
            updateHook({ companyId, hookId: row.id, data: formData })
                .unwrap()
                .then(() => {
                    setEdit(false);
                    showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'Webhook successfully updated' });
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.data ?? err.message ?? JSON.stringify(err)
                    });
                })
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [companyId, dispatch, row.id, showSnackbar, updateHook]
    );

    const onDelete = useCallback(() => {
        dispatch(
            openConfirmPopup({
                title: 'Deleting Webhook',
                text: 'Are you sure you want to delete this web hook?',
                confirmText: 'Confirm',
                onConfirm: () => {
                    deleteHook({ companyId, hookId: row.id })
                        .unwrap()
                        .then(() => {
                            showSnackbar({
                                alertSeverity: SnackBarTypes.Success,
                                message: 'Webhook successfully deleted'
                            });
                        })
                        .catch((err) => {
                            showSnackbar({
                                alertSeverity: SnackBarTypes.Error,
                                message: err.data ?? err.message ?? JSON.stringify(err)
                            });
                        });
                }
            })
        );
    }, [companyId, deleteHook, dispatch, row.id, showSnackbar]);

    const actionsDisabled = useMemo(() => isDeleting || isUpdating, [isDeleting, isUpdating]);

    const chipSx = useCallback(
        (color: 'success' | 'error') => ({
            fontWeight: 500,
            borderRadius: '8px',
            color: `${color}.dark`,
            backgroundColor: `${color}.light`
        }),
        []
    );

    return (
        <>
            <TableRow>
                <TableCell>{row.name}</TableCell>
                <TableCell>
                    <Chip
                        sx={chipSx(row.is_enabled ? 'success' : 'error')}
                        size="small"
                        color={row.is_enabled ? 'success' : 'error'}
                        label={row.is_enabled ? 'Enabled' : 'Disabled'}
                    />
                </TableCell>
                <TableCell>{row.settings.url}</TableCell>
                <TableCell>
                    <Stack direction="row" spacing={0.5}>
                        {!!triggerData && (
                            <Button
                                className="Cb-Table-Btn forcedBg"
                                color="primary"
                                size="small"
                                disabled={actionsDisabled}
                                onClick={() => setEdit(true)}
                                endIcon={<ModeEditOutlinedIcon />}
                            >
                                Edit
                            </Button>
                        )}
                        <Button
                            className="Cb-Table-Btn forcedBg"
                            color="error"
                            size="small"
                            onClick={onDelete}
                            disabled={actionsDisabled}
                            endIcon={<DeleteOutlineOutlinedIcon />}
                        >
                            Delete
                        </Button>
                    </Stack>
                </TableCell>
            </TableRow>
            {!!triggerData && (
                <WebhookDialog
                    open={edit}
                    onClose={() => setEdit(false)}
                    dialogTitle="Edit Web Hook"
                    defaults={defaultValue}
                    onSubmit={onUpdate}
                    triggerData={triggerData}
                    dialogActionsDisabled={isUpdating}
                    dialogOkButtonLabel="Save"
                />
            )}
        </>
    );
};

export default WebhookTableRow;
