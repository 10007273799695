import { FC } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { IAppointmentProduct } from '../../../../../../../models/IProduct';
import ActionsCell from '../payments/elements/payments-table/elements/ActionsCell';
import NumberFormat from 'react-number-format';
import { useAppSelector } from '../../../../../../../hooks/redux';
import NoProductsIllustration from './NoProductsIllustration';
import AppointmentProductListItem from './AppointmentProductListItem';
import ListRenderer from '../../../../../../list-renderer';

interface IAppointmentProductsTableProps {
    products: IAppointmentProduct[];
    isLoading?: boolean;
    onDelete: (id: number) => void;
    onUpdate: (data: IAppointmentProduct) => void;
}

const columns = [
    { id: 'product', label: 'Product' },
    { id: 'amount', label: 'Amount' },
    { id: 'price', label: 'Price' },
    { id: 'actions', label: '' }
];

const AppointmentProductsTable: FC<IAppointmentProductsTableProps> = ({ products, isLoading, onDelete, onUpdate }) => {
    const { isForeignAppointment } = useAppSelector((state) => state.calendar);

    if (!products.length && !isLoading) {
        return <NoProductsIllustration />;
    }

    return (
        <ListRenderer
            columns={columns}
            rows={products}
            isLoading={isLoading}
            compact
            renderRow={(product) => (
                <TableRow key={product.id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.pivot.amount}</TableCell>
                    <TableCell>
                        <NumberFormat displayType="text" value={product.pivot.price} prefix="$" decimalScale={2} fixedDecimalScale />
                    </TableCell>
                    <TableCell align="right">
                        {isForeignAppointment ? null : (
                            <ActionsCell entity="Product" onEdit={() => onUpdate(product)} onDelete={() => onDelete(product.id)} />
                        )}
                    </TableCell>
                </TableRow>
            )}
            renderItem={(product) => (
                <AppointmentProductListItem key={product.id} product={product} onUpdate={onUpdate} onDelete={onDelete} />
            )}
        />
    );
};

export default AppointmentProductsTable;
