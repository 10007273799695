import { TabsProps } from '../types';
import { Box } from '@mui/material';

export function a11yProps(index: number, prefix: string) {
    return {
        id: `${prefix}-tab-${index}`,
        'aria-controls': `${prefix}-tabpanel-${index}`
    };
}

function TabPanel({ children, value, index, prefix, fullHeight, ...other }: TabsProps & { prefix: string; fullHeight?: boolean }) {
    return (
        <div
            className="tabpanel"
            role="tabpanel"
            hidden={value !== index}
            id={`${prefix}-tabpanel-${index}`}
            aria-labelledby={`${prefix}-tab-${index}`}
            style={fullHeight ? { height: '100%' } : undefined}
            {...other}
        >
            {value === index && <Box sx={{ p: 0, height: fullHeight ? '100%' : undefined }}>{children}</Box>}
        </div>
    );
}

export default TabPanel;
