const AmexIcon = () => (
    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1895_10641)">
            <path
                d="M29.2016 0.499023H3.20156C1.76562 0.499023 0.601562 1.66308 0.601562 3.09902V18.699C0.601562 20.135 1.76562 21.299 3.20156 21.299H29.2016C30.6375 21.299 31.8016 20.135 31.8016 18.699V3.09902C31.8016 1.66308 30.6375 0.499023 29.2016 0.499023Z"
                fill="#016FD0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.4961 17.9108V10.499L31.6879 10.5109V12.5582L30.1632 14.1876L31.6879 15.832V17.9228H29.2535L27.9597 16.4952L26.6752 17.9282L18.4961 17.9108Z"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.375 17.098V11.3145H24.279V12.6468H20.9628V13.5512H24.1999V14.8614H20.9628V15.7497H24.279V17.098H19.375Z"
                fill="#016FD0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.254 17.0989L26.9676 14.2038L24.2539 11.3154H26.3542L28.0124 13.1485L29.6752 11.3154H31.6853V11.3609L29.0295 14.2038L31.6853 17.0168V17.0989H29.655L27.9675 15.2474L26.2972 17.0989H24.254Z"
                fill="#016FD0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.1097 3.9209H22.2896L23.4064 6.457V3.9209H27.3322L28.0092 5.82094L28.6885 3.9209H31.6862V11.3327H15.8438L19.1097 3.9209Z"
                fill="#FFFFFE"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7107 4.72559L17.1445 10.5043H18.9046L19.3887 9.34709H22.0121L22.4959 10.5043H24.2997L21.7442 4.72559H19.7107ZM19.9313 8.05025L20.7009 6.21066L21.4699 8.05025H19.9313Z"
                fill="#016FD0"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.2773 10.5032V4.72461L26.7514 4.73311L28.0244 8.28566L29.3058 4.72461H31.6869V10.5032L30.1547 10.5168V6.54742L28.7083 10.5032H27.3098L25.8334 6.53386V10.5032H24.2773Z"
                fill="#016FD0"
            />
        </g>
        <defs>
            <clipPath id="clip0_1895_10641">
                <rect width="31.2" height="20.8" fill="white" transform="translate(0.601562 0.499023)" />
            </clipPath>
        </defs>
    </svg>
);

export default AmexIcon;
