import { FormikValues } from 'formik/dist/types';
import { FormikConfig, useFormik } from 'formik';

const useExtendedFormik = <Values extends FormikValues = FormikValues>(
    config: FormikConfig<Values> & {
        isBlocked?: boolean;
    }
) =>
    useFormik<Values>({
        ...config,
        onSubmit: (data, helpers) => {
            const el = document.activeElement;
            el && 'blur' in el && (el as HTMLElement).blur();
            !config.isBlocked && config.onSubmit(data, helpers);
        }
    });

export default useExtendedFormik;
