import { FC, useCallback, useMemo } from 'react';
import { Stack, Switch } from '@mui/material';
import InputRow from '../../../form/InputRow';
import { ServiceOption, ServiceOptionPivot } from '../../../../models/IService';
import { useFormik } from 'formik';
import NumberFormat from 'react-number-format';

type AppointmentServiceOptionsFormType = {
    options: Array<ServiceOption & { enabled: boolean }>;
};

type AppointmentServiceOptionsFormProps = {
    defaults: Array<ServiceOption & { pivot: ServiceOptionPivot }>;
    onSubmit: (v: Array<ServiceOption & { pivot: ServiceOptionPivot }>) => void;
    options: Array<ServiceOption>;
};

const AppointmentServiceOptionsForm: FC<AppointmentServiceOptionsFormProps> = ({ defaults, onSubmit, options }) => {
    const getLinkedPivot = useCallback((id: number | string) => defaults.find((opt) => opt.id === id)?.pivot, [defaults]);

    const allowedOptions = useMemo(() => {
        const deletedButSelectedOptions = defaults.reduce<ServiceOption[]>((acc, current) => {
            const { id, name, price, duration } = current;
            const isDeleted = !options.some((option) => option.id === id);
            return isDeleted ? [...acc, { id, name, price, duration }] : acc;
        }, []);

        return [...options, ...deletedButSelectedOptions];
    }, [defaults, options]);

    const { values, setFieldValue, handleSubmit } = useFormik<AppointmentServiceOptionsFormType>({
        initialValues: {
            options: allowedOptions.map((option) => ({
                ...option,
                enabled: defaults.some(({ id }) => id === option.id)
            }))
        },
        onSubmit: (formData) => {
            const newValue = formData.options
                .filter(({ enabled }) => !!enabled)
                .map((option) => {
                    const pivot = getLinkedPivot(option.id);
                    return {
                        ...option,
                        pivot: pivot ?? { id: option.id, duration: option.duration, price: option.price }
                    };
                });
            onSubmit(newValue);
        }
    });

    return (
        <Stack component="form" spacing={2} noValidate id="AppointmentServiceOptionsForm" onSubmit={handleSubmit}>
            {values.options.map((option, index) => {
                const pivot = getLinkedPivot(option.id);
                return (
                    <InputRow
                        key={option.id}
                        label={option.name}
                        info={
                            <>
                                <NumberFormat
                                    value={pivot?.duration ?? option.duration}
                                    suffix=" min"
                                    decimalScale={0}
                                    fixedDecimalScale
                                    displayType="text"
                                />
                                {', '}
                                <NumberFormat
                                    value={pivot?.price ?? option.price}
                                    prefix="$"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    displayType="text"
                                />
                            </>
                        }
                    >
                        <Switch
                            value={option.enabled}
                            checked={option.enabled}
                            onChange={(_, checked) => setFieldValue(`options[${index}].enabled`, checked)}
                        />
                    </InputRow>
                );
            })}
        </Stack>
    );
};

export default AppointmentServiceOptionsForm;
