import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import locationAPI, { LedgerBalanceData, LedgerBalancePayload } from '../../../services/LocationService';
import { useAppDispatch } from '../../../hooks/redux';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import ConditionalDialog from '../../conditional-dialog/ConditionalDialog';
import LedgerTransactionForm from './LedgerTransactionForm';
import LedgerTransactionItem from './LedgerTransactionItem';
import useCashLedgerTransactions from '../../../hooks/use-cash-ledger-transactions';
import { LoadingButton } from '@mui/lab';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import AddIcon from '@mui/icons-material/Add';
import { LedgerTransaction } from '../../../models/ILocation';
import LedgerBalance from './LedgerBalance';

const LocationLedger: FC<{ locationId: number }> = ({ locationId }) => {
    const dispatch = useAppDispatch();
    const [create, setCreate] = useState(false);
    const [editingTransaction, setEditingTransaction] = useState<LedgerTransaction | null>(null);
    const {
        data,
        onLoadMore,
        fullReload,
        isLoading,
        isFetching,
        hasMore,
        onDeleteTransaction,
        onUpdateTransaction,
        onAddTransaction
    } = useCashLedgerTransactions(locationId);
    const { data: balanceData, isLoading: balanceDataLoading, isFetching: isBalanceDataFetching } = locationAPI.useGetLocationBalanceQuery(
        locationId
    );
    const { showSnackbar } = useShowSnackbar();
    const [addTransaction] = locationAPI.useCreateLedgerTransactionMutation();
    const [deleteTransaction] = locationAPI.useDeleteLedgerTransactionMutation();
    const [editTransaction] = locationAPI.useUpdateLedgerTransactionMutation();
    const [updateBalance, { isLoading: isUpdatingBalance }] = locationAPI.useUpdateLocationBalanceMutation();

    const defaults = useMemo(() => {
        if (editingTransaction) {
            const { amount, description } = editingTransaction;
            return { amount, description };
        }

        return undefined;
    }, [editingTransaction]);

    const handleCancel = useCallback(() => {
        setCreate(false);
        setEditingTransaction(null);
    }, []);

    const onError = useCallback(
        (err: any) => {
            showSnackbar({
                alertSeverity: SnackBarTypes.Error,
                message: err.data || err.message || JSON.stringify(err)
            });
        },
        [showSnackbar]
    );

    const onFinally = useCallback(() => {
        dispatch(stopSubmitting());
    }, [dispatch]);

    const handleAddTransaction = useCallback(
        (formData: { amount: number; description: string }) => {
            dispatch(startSubmitting());
            addTransaction({
                locationId,
                data: formData
            })
                .unwrap()
                .then((res) => {
                    handleCancel();
                    onAddTransaction(res);
                })
                .catch(onError)
                .finally(onFinally);
        },
        [addTransaction, dispatch, handleCancel, locationId, onAddTransaction, onError, onFinally]
    );

    const handleEditTransaction = useCallback(
        (formData: { amount: number; description: string }) => {
            if (editingTransaction) {
                dispatch(startSubmitting());
                editTransaction({ locationId, itemId: editingTransaction.id, data: formData })
                    .unwrap()
                    .then((res) => {
                        handleCancel();
                        onUpdateTransaction(res);
                    })
                    .catch(onError)
                    .finally(onFinally);
            }
        },
        [dispatch, editTransaction, editingTransaction, handleCancel, locationId, onError, onFinally, onUpdateTransaction]
    );

    const handleDeleteTransaction = useCallback(
        (itemId: number) => {
            dispatch(startSubmitting());
            deleteTransaction({ locationId, itemId })
                .unwrap()
                .then(() => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Success,
                        message: 'Transaction Deleted'
                    });
                    onDeleteTransaction(itemId);
                })
                .catch(onError)
                .finally(onFinally);
        },
        [deleteTransaction, dispatch, locationId, onDeleteTransaction, onError, onFinally, showSnackbar]
    );

    const updateBalanceQuery = useCallback(
        (res: LedgerBalanceData) => {
            dispatch(locationAPI.util.updateQueryData('getLocationBalance', locationId, () => res));
        },
        [dispatch, locationId]
    );

    const handleUpdateBalance = useCallback(
        (formData: LedgerBalancePayload) => {
            updateBalance({ locationId, payload: formData })
                .unwrap()
                .then((res) => {
                    updateBalanceQuery(res);
                    fullReload();
                })
                .catch(onError)
                .finally(onFinally);
        },
        [fullReload, locationId, onError, onFinally, updateBalance, updateBalanceQuery]
    );

    useEffect(() => {
        const postDeleteTransaction = (payload: { id: number }) => {
            onDeleteTransaction(payload.id);
        };

        window.Echo.private(`App.Models.Location.${locationId}`).listen('.ledger.balance.updated', updateBalanceQuery);
        window.Echo.private(`App.Models.Location.${locationId}`).listen('.ledger.item.created', onAddTransaction);
        window.Echo.private(`App.Models.Location.${locationId}`).listen('.ledger.item.updated', onUpdateTransaction);
        window.Echo.private(`App.Models.Location.${locationId}`).listen('.ledger.item.deleted', postDeleteTransaction);

        return () => {
            window.Echo.private(`App.Models.Location.${locationId}`).stopListening('.ledger.balance.updated');
            window.Echo.private(`App.Models.Location.${locationId}`).stopListening('.ledger.item.created');
            window.Echo.private(`App.Models.Location.${locationId}`).stopListening('.ledger.item.updated');
            window.Echo.private(`App.Models.Location.${locationId}`).stopListening('.ledger.item.deleted');
            window.Echo.leave(`App.Models.Location.${locationId}`);
        };
    }, [locationId, onAddTransaction, onDeleteTransaction, onUpdateTransaction, updateBalanceQuery]);

    return (
        <Stack spacing={2} sx={{ height: '100%' }}>
            {!create && !editingTransaction ? (
                <Stack spacing={2} sx={{ height: '100%' }}>
                    <Stack spacing={2} sx={{ flexGrow: 0, flexShrink: 0 }}>
                        <Typography variant="h4">Balance</Typography>
                        <LedgerBalance
                            balance={balanceData?.balance}
                            isBusy={balanceDataLoading || isUpdatingBalance || isBalanceDataFetching}
                            onSubmit={handleUpdateBalance}
                        />

                        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h4">Transactions</Typography>
                            <IconButton size="small" color="primary" onClick={() => setCreate(true)}>
                                <AddIcon />
                            </IconButton>
                        </Stack>
                    </Stack>

                    <Stack spacing={2} sx={{ flexGrow: 1, overflow: 'auto' }}>
                        {data.map((transaction) => (
                            <LedgerTransactionItem
                                onDelete={handleDeleteTransaction}
                                onEdit={setEditingTransaction}
                                key={transaction.id}
                                transaction={transaction}
                            />
                        ))}
                        {hasMore ? (
                            <LoadingButton
                                sx={{ alignSelf: 'flex-start' }}
                                loading={isLoading || isFetching}
                                disabled={isLoading || isFetching}
                                onClick={onLoadMore}
                            >
                                Load More
                            </LoadingButton>
                        ) : null}
                    </Stack>
                </Stack>
            ) : null}

            <ConditionalDialog open={create || !!editingTransaction} onCancel={handleCancel} formId="ledger_transaction_form">
                <LedgerTransactionForm onSubmit={defaults ? handleEditTransaction : handleAddTransaction} defaults={defaults} />
            </ConditionalDialog>
        </Stack>
    );
};

export default LocationLedger;
