import { useCallback, useEffect, useState } from 'react';
import 'assets/css/support-widget.css';
import { Box, Tooltip } from '@mui/material';
import ContactSupportOutlined from '@mui/icons-material/ContactSupportOutlined';
import CommonHeaderBtn from '../../../ui-component/CommonHeaderBtn';
import useAuth from '../../../hooks/useAuth';
import config, { Envs } from '../../../config';

declare global {
    interface Window {
        loadwidget: any;
        _salesmate_widget_script_url: any;
        Widget: any;
        salesmateSettings: Record<string, string>;
        SALESMATE: any;
    }
}

const LAUNCHER_BTN_ID = 'sm-widget-launcher-btn';
const MESSENGER_ID = 'salesmate-messenger';

const SETTINGS = {
    workspace_id: 'f4ba7a83-07ad-4e19-a6ed-e05d92c1b3b9',
    app_key: '2fdcb040-6561-11ef-8e8a-e5048cc8c88c',
    tenant_id: 'chilled.salesmate.io'
};

window.salesmateSettings = SETTINGS;

function init() {
    if (config.env === Envs.Local) return;

    try {
        const i = 'loadwidget';
        const d = `https://${SETTINGS.tenant_id}/messenger-platform/messenger-platform-main.js`;
        const n = document.createElement('script');
        const o = document.getElementsByTagName('script')[0];
        window.Widget = i;
        window[i] =
            window[i] ||
            // eslint-disable-next-line func-names
            function () {
                // eslint-disable-next-line prefer-rest-params
                (window[i].q = window[i].q || []).push(arguments);
            };
        n.id = i;
        n.src = d;
        n.async = true;
        o.parentNode?.insertBefore(n, o);
        // eslint-disable-next-line no-underscore-dangle
        window._salesmate_widget_script_url = d;
        window.loadwidget('init', {});
        window.loadwidget('load_widget', 'Widget Loading...!');
    } catch (err) {
        console.error(err);
    }
}

function deinit() {
    window.SALESMATE?.hideChatWidget();
    window.SALESMATE?.logout();

    // delete window.SALESMATE;
    // // eslint-disable-next-line no-underscore-dangle
    // delete window._salesmate_widget_script_url;
    // delete window.Widget;

    const btn = document.getElementById(LAUNCHER_BTN_ID);
    const messenger = document.getElementById(MESSENGER_ID);

    btn?.remove();
    messenger?.remove();
}

const SupportWidget = () => {
    const chatInitialized = Boolean(window.SALESMATE);
    const { user } = useAuth();
    const [open, setOpen] = useState(false);
    const handleOpenWidget = useCallback(() => {
        window.SALESMATE?.showChatWidget();
    }, []);

    const handleCloseWidget = useCallback(() => {
        window.SALESMATE?.hideChatWidget();
    }, []);

    const handleClick = useCallback(() => {
        open ? handleCloseWidget() : handleOpenWidget();
        setOpen(!open);
    }, [handleCloseWidget, handleOpenWidget, open]);

    const userId = user?.id;
    const handleLogin = useCallback(() => {
        if (user && chatInitialized) {
            const { email, firstname, lastname } = user;
            window.SALESMATE?.login({ user_id: userId, email, first_name: firstname, last_name: lastname });
        }
        // We don't need to check everything, just user id is enough to detect change
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        init();

        return () => {
            deinit();
        };
    }, []);

    useEffect(() => {
        handleLogin();
    }, [handleLogin]);

    return (
        <Tooltip title="Support" placement="top" arrow>
            <Box component="span">
                <CommonHeaderBtn onClick={handleClick}>
                    <ContactSupportOutlined />
                </CommonHeaderBtn>
            </Box>
        </Tooltip>
    );
};

export default SupportWidget;
