import { FC, useMemo } from 'react';
import { FormikProps } from 'formik';
import { RegistrationFormType } from '../types';
import { Grid, MenuItem, TextField } from '@mui/material';
import AppointmentAddressBox from '../../../../ui-component/AppointmentAddressBox';

type CompanyDataSubformPropsType = {
    formInstance: FormikProps<RegistrationFormType>;
};

const CompanyDataSubform: FC<CompanyDataSubformPropsType> = ({ formInstance }) => {
    const { values, errors, touched, handleChange, handleBlur, setFieldValue } = formInstance;

    const addressError = useMemo(() => {
        if (errors.address && typeof errors.address === 'object') {
            // Because Formik can't recognize correct error type
            // @ts-ignore
            return errors.address.address;
        }

        return errors.address;
    }, [errors.address]);

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="company_name"
                    name="company_name"
                    label="Business Name"
                    value={values.company_name}
                    error={!!errors.company_name && !!touched.company_name}
                    helperText={touched.company_name ? errors.company_name : undefined}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="industry"
                    name="industry"
                    label="Industry"
                    select
                    value={values.industry}
                    onBlur={handleBlur}
                    error={!!errors.industry && !!touched.industry}
                    helperText={touched.industry ? errors.industry : undefined}
                    onChange={(e) => setFieldValue('industry', e.target.value)}
                >
                    <MenuItem value="barber">Barber</MenuItem>
                    <MenuItem value="med_spa">Med Spa</MenuItem>
                    <MenuItem value="tattoo">Tattoo</MenuItem>
                    <MenuItem value="fitness">Fitness</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <AppointmentAddressBox
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    value={values.address}
                    onBlur={handleBlur}
                    onChange={(v) => setFieldValue('address', v)}
                    error={!!touched.address && !!errors.address}
                    helperText={touched.address ? addressError : undefined}
                />
            </Grid>
        </>
    );
};

export default CompanyDataSubform;
