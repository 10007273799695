import { FC } from 'react';
import { Input, InputProps } from '@mui/material';

const PickerInput: FC<InputProps> = ({ sx, ...rest }) => (
    <Input
        {...rest}
        sx={{
            ...sx,
            backgroundColor: 'primary.light',
            px: 0.5,
            borderRadius: '2px'
        }}
        disableUnderline
    />
);

export default PickerInput;
