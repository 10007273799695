import { Box, Button, Stack } from '@mui/material';
import { FC, PropsWithChildren, useCallback } from 'react';
import useSwipe from '../../../../../../../../../hooks/useSwipe';
import CbPortal from '../../../../../../../../cb-portal';

interface INewPaymentFormStepProps {
    label: string;
    handleNext?: () => void;
    handlePrev?: () => void;
    nextBlocked?: boolean;
    canSubmit?: boolean;
}

const NewPaymentFormStep: FC<PropsWithChildren<INewPaymentFormStepProps>> = ({
    label,
    children,
    handleNext,
    handlePrev,
    canSubmit,
    nextBlocked
}) => {
    const onSwipeRight = useCallback(() => {
        handlePrev?.();
    }, [handlePrev]);

    const onSwipeLeft = useCallback(() => {
        if (!nextBlocked) {
            handleNext?.();
        }
    }, [handleNext, nextBlocked]);

    const swipeProps = useSwipe({ onSwipeLeft, onSwipeRight });

    return (
        <Box {...swipeProps}>
            <Stack spacing={2}>
                <Box sx={{ color: 'grey.800', opacity: 0.6, fontSize: '1rem', lineHeight: 1.5 }}>{label}</Box>
                <Box>{children}</Box>
                {handleNext || handlePrev || canSubmit ? (
                    <CbPortal query="#new-payment-form-actions">
                        <Box sx={{ width: '100%', justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
                            {handlePrev ? (
                                <Button onClick={handlePrev} variant="text" className="forcedBg">
                                    Back
                                </Button>
                            ) : null}
                            {handleNext && !canSubmit ? (
                                <Button color="primary" variant="contained" onClick={handleNext} disabled={!!nextBlocked}>
                                    Next
                                </Button>
                            ) : null}
                            {canSubmit ? (
                                <Button type="submit" color="primary" variant="contained" form="new-payment-form">
                                    Save
                                </Button>
                            ) : null}
                        </Box>
                    </CbPortal>
                ) : null}
            </Stack>
        </Box>
    );
};

export default NewPaymentFormStep;
