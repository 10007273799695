import { useMemo } from 'react';
import moment from 'moment/moment';
import employeeAPI from '../../../../services/EmployeeService';
import { skipToken } from '@reduxjs/toolkit/query';

const useHistoryItemValue = (key: string, value: unknown) => {
    const { data, isLoading } = employeeAPI.useGetEmployeeQuery(
        key === 'employee_id' && value && parseFloat(String(value)) ? String(value) : skipToken
    );
    const employeeName = useMemo(() => {
        if (isLoading) {
            return 'Loading...';
        }

        if (data && !isLoading) {
            return data.user.title;
        }
        return `Unknown Employee`;
    }, [data, isLoading]);

    return useMemo(() => {
        if (value) {
            switch (key) {
                case 'start_at':
                case 'end_at': {
                    return moment(String(value))
                        .clone()
                        .add(key === 'end_at' ? 1 : 0, 'second')
                        .format('MM/DD/YY h:mm a (z)');
                }

                case 'employee_id': {
                    return employeeName;
                }

                default: {
                    return String(value);
                }
            }
        }

        return null;
    }, [employeeName, key, value]);
};

export default useHistoryItemValue;
