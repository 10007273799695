import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import { SiteBuilderResponse, SiteBuilderSaveInput } from '../models/SiteBuilder';
import convertRecordtoFormData from '../utils/functions/build-form-data';

const siteBuilderAPI = createApi({
    reducerPath: 'steBuilderAPI',
    tagTypes: ['SiteBuilder'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        getBuilderData: build.query<SiteBuilderResponse, number | string>({
            query: (id) => ({
                method: 'GET',
                url: `/companies/${id}/site-builder`
            }),
            providesTags: () => ['SiteBuilder']
        }),

        saveBuilderData: build.mutation<unknown, SiteBuilderSaveInput>({
            query: ({ id, section, data }) => {
                const sendAsForm = ['portfolio', 'services', 'employees'].includes(section);
                return {
                    url: `/companies/${id}/site-builder/${section}`,
                    method: 'POST',
                    data: sendAsForm ? convertRecordtoFormData(data) : data,
                    headers: sendAsForm
                        ? {
                              'Content-Type': 'multipart/form-data'
                          }
                        : undefined
                };
            },
            invalidatesTags: ['SiteBuilder']
        })
    })
});

export default siteBuilderAPI;
