import { LedgerTransaction } from '../../../models/ILocation';
import { Box, IconButton, Stack } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import NumberFormat from 'react-number-format';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAppDispatch } from '../../../hooks/redux';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';

type LedgerTransactionItemProps = {
    transaction: LedgerTransaction;
    onEdit: (t: LedgerTransaction) => void;
    onDelete: (id: number) => void;
};

const LedgerTransactionItem: FC<LedgerTransactionItemProps> = ({ transaction, onDelete, onEdit }) => {
    const dispatch = useAppDispatch();
    const username = useMemo(() => transaction.employee.title ?? '', [transaction.employee]);

    const priceColor = useMemo(() => {
        if (transaction.amount > 0) {
            return 'success.main';
        }

        if (transaction.amount < 0) {
            return 'error.main';
        }

        return 'currentColor';
    }, [transaction.amount]);

    const handleDelete = useCallback(() => {
        dispatch(
            openConfirmPopup({
                onConfirm: () => onDelete(transaction.id),
                confirmText: 'Delete Transaction',
                text: 'Are you sure you want to delete this transaction?'
            })
        );
    }, [dispatch, onDelete, transaction.id]);

    return (
        <Stack spacing={0.5} sx={{ borderBottom: '1px solid #c7c7c7', pb: 1 }}>
            <Stack spacing={1} direction="row" alignItems="center">
                <Box sx={{ mr: 'auto', color: priceColor }}>
                    <NumberFormat displayType="text" prefix="$" decimalScale={2} fixedDecimalScale value={transaction.amount} />
                </Box>

                <Box>
                    <IconButton size="small" color="primary" onClick={() => onEdit(transaction)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton size="small" color="error" onClick={handleDelete}>
                        <DeleteIcon />
                    </IconButton>
                </Box>
            </Stack>
            {transaction.description ? <Box>{transaction.description}</Box> : null}

            <Box sx={{ fontSize: '0.75rem' }}>
                {username} at {moment(transaction.created_at).format('MM/DD/YYYY, hh:mm a (z)')}
            </Box>
        </Stack>
    );
};

export default LedgerTransactionItem;
