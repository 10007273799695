import Adjust from '@mui/icons-material/Adjust';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AppointmentStatuses } from '../models/IAppointment';

const appointmentStatuses = [
    {
        value: AppointmentStatuses.Pending,
        label: 'Pending',
        icon: <AccessTimeIcon fontSize="small" />
    },
    {
        value: AppointmentStatuses.Active,
        label: 'Confirmed',
        icon: <Adjust fontSize="small" />
    },
    {
        value: AppointmentStatuses.Completed,
        label: 'Completed',
        icon: <CheckCircleOutline fontSize="small" />
    },
    {
        value: AppointmentStatuses.Canceled,
        label: 'Cancelled',
        icon: <CancelOutlined fontSize="small" />
    }
];

export default appointmentStatuses;
