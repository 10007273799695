import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

const EntityWrapperContainer: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <Box
        sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}
    >
        {children}
    </Box>
);

export default EntityWrapperContainer;
