import { FC, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import PickerNumberInput from './PickerNumberInput';
import { hsvaToRgba, RgbaColor, rgbaToHsva } from '@uiw/color-convert';
import { ColorFormProps } from '../types';

const RgbaColorForm: FC<ColorFormProps> = ({ color, onChange, disableAlpha }) => {
    const rgba = useMemo<RgbaColor>(() => hsvaToRgba(color), [color]);

    const handleChange = useCallback(
        (data: Partial<RgbaColor>) => {
            const newColor: RgbaColor = { ...rgba, ...data };
            const hsva = rgbaToHsva(newColor);
            onChange(hsva);
        },
        [rgba, onChange]
    );

    return (
        <Box sx={{ display: 'grid', gridGap: '8px', gridTemplateColumns: 'repeat(4, 1fr)' }}>
            {(['r', 'g', 'b'] as const).map((key) => (
                <Box key={key}>
                    <PickerNumberInput value={rgba[key]} minValue={0} maxValue={255} onChange={(v) => handleChange({ [key]: v })} />
                </Box>
            ))}
            <Box>
                {!disableAlpha ? (
                    <PickerNumberInput
                        value={rgba.a * 100}
                        minValue={0}
                        maxValue={100}
                        suffix="%"
                        onChange={(v) => handleChange({ a: v / 100 })}
                    />
                ) : null}
            </Box>
        </Box>
    );
};

export default RgbaColorForm;
