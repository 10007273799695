const CvcIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.9389 5.59961C18.9997 6.25888 18.2333 7.13476 17.7044 8.15307C17.1756 9.17139 16.8999 10.3022 16.9008 11.4496C16.9008 13.1786 17.5144 14.7646 18.5349 15.9996H5.20078C4.856 15.9996 4.52534 16.1366 4.28154 16.3804C4.03774 16.6242 3.90078 16.9548 3.90078 17.2996V18.5996C3.90078 18.9444 4.03774 19.2751 4.28154 19.5188C4.52534 19.7626 4.856 19.8996 5.20078 19.8996H26.0008C26.3456 19.8996 26.6762 19.7626 26.92 19.5188C27.1638 19.2751 27.3008 18.9444 27.3008 18.5996V17.8196C28.3377 17.2888 29.23 16.5139 29.9008 15.5615V23.7996C29.9008 24.4892 29.6269 25.1505 29.1393 25.6381C28.6517 26.1257 27.9903 26.3996 27.3008 26.3996H3.90078C3.21122 26.3996 2.5499 26.1257 2.0623 25.6381C1.57471 25.1505 1.30078 24.4892 1.30078 23.7996V8.19961C1.30078 7.51005 1.57471 6.84873 2.0623 6.36113C2.5499 5.87354 3.21122 5.59961 3.90078 5.59961H19.9389ZM28.658 5.98051C28.9687 6.24311 29.256 6.53171 29.5186 6.84241C29.3036 6.49133 29.0087 6.19601 28.658 5.98051Z"
            fill="#436775"
        />
        <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.6808 8.19922C17.0655 9.40163 16.8049 10.7543 16.9294 12.0992H1.30078V8.19922H17.6808Z"
            fill="#436775"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.0484 18.5998C22.1521 18.5998 20.3335 17.8465 18.9926 16.5056C17.6517 15.1647 16.8984 13.3461 16.8984 11.4498C16.8984 9.55351 17.6517 7.73488 18.9926 6.39399C20.3335 5.05311 22.1521 4.2998 24.0484 4.2998C25.9447 4.2998 27.7634 5.05311 29.1043 6.39399C30.4451 7.73488 31.1984 9.55351 31.1984 11.4498C31.1984 13.3461 30.4451 15.1647 29.1043 16.5056C27.7634 17.8465 25.9447 18.5998 24.0484 18.5998ZM21.2092 8.4871H20.4019L18.4311 9.4881V10.5099L19.9937 9.6935V14.4125H21.2196V8.4858H21.2092V8.4871ZM23.5583 9.3048C24.1407 9.3048 24.5489 9.6311 24.5489 10.1017C24.5489 10.6126 24.0679 10.9701 23.3737 10.9701H23.0682V11.8385H23.4361C24.1706 11.8385 24.6711 12.2051 24.6711 12.7368C24.6711 13.2477 24.181 13.5948 23.4868 13.5948C22.9759 13.5948 22.465 13.431 21.9346 13.1138V14.1356C22.5066 14.3813 23.0786 14.5139 23.6402 14.5139C24.9779 14.5139 25.897 13.8301 25.897 12.8395C25.897 12.144 25.468 11.5824 24.753 11.3575C25.3666 11.1534 25.7644 10.6126 25.7644 9.999C25.7644 9.0396 24.9168 8.3857 23.6909 8.3857C23.1382 8.39171 22.5925 8.50979 22.0867 8.7328V9.7338C22.5781 9.4582 23.0786 9.3048 23.5583 9.3048ZM27.9705 11.5317C28.7167 11.5317 29.2367 11.9711 29.2367 12.5431C29.2367 13.145 28.7167 13.5636 27.9705 13.5636C27.5207 13.5636 27.0514 13.4206 26.5717 13.1255V14.1772C27.0722 14.4021 27.5831 14.5152 28.0836 14.5152C28.3488 14.5152 28.5932 14.4736 28.8285 14.4112C29.3746 13.5275 29.6645 12.5094 29.6657 11.4706L29.6462 11.0416C29.2663 10.8164 28.8306 10.7029 28.3891 10.714C28.1942 10.7133 27.9996 10.7272 27.8067 10.7556V9.427H29.2783C29.1505 9.09999 28.993 8.78538 28.8077 8.4871H26.7446V11.7046C27.1447 11.5971 27.5564 11.539 27.9705 11.5317Z"
            fill="#436775"
        />
    </svg>
);

export default CvcIcon;
