import { Box } from '@mui/material';
import Check from '@mui/icons-material/Check';

interface BgPatternBoxProps {
    isActive: boolean;
    start: string;
    end: string;
    onClick: () => void;
}

const BgPatternBox = ({ isActive, start, end, onClick }: BgPatternBoxProps) => (
    <Box
        onClick={onClick}
        sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '60px',
            height: '60px',
            cursor: 'pointer',
            borderRadius: '2px',
            border: '1px solid',
            borderColor: isActive ? 'grey.800' : 'transparent',
            background: `linear-gradient(135deg, ${start}, ${end})`,
            backgroundColor: '#fff',
            backgroundPosition: 'center',
            backgroundSize: '54px 54px',
            backgroundRepeat: 'no-repeat',
            '& .MuiSvgIcon-root': {
                fontSize: '30px',
                color: '#fff'
            }
        }}
    >
        {isActive && <Check />}
    </Box>
);

export default BgPatternBox;
