import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import { IWaiver } from 'models/ICompany';
import { IBaseParams, IPaginateResponse } from '../models/IPaginateResponse';

const waiverAPI = createApi({
    reducerPath: 'waiverAPI',
    tagTypes: ['Waiver'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchAllWaivers: build.query<IPaginateResponse<IWaiver[]>, IBaseParams>({
            query: ({ per_page = null, page = 1, search = null, sort = null, order = null, trashed = null }) => ({
                url: `/waivers`,
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order,
                    trashed
                }
            }),
            providesTags: (result) => ['Waiver']
        }),
        createWaiver: build.mutation<IWaiver, IWaiver>({
            query: (waiver) => ({
                url: `/waivers`,
                method: 'POST',
                data: waiver
            }),
            invalidatesTags: ['Waiver']
        }),
        updateWaiver: build.mutation<IWaiver, IWaiver>({
            query: (waiver) => ({
                url: `/waivers/${waiver.id}`,
                method: 'PUT',
                data: waiver
            }),
            invalidatesTags: ['Waiver']
        }),
        getWaiver: build.query<IWaiver, string>({
            query: (id) => ({ url: `/waivers/${id}`, method: 'GET' }),
            providesTags: (result) => ['Waiver']
        }),
        deleteWaiver: build.mutation<IWaiver, IWaiver>({
            query: (company) => ({
                url: `/waivers/${company.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Waiver']
        })
    })
});

export default waiverAPI;
