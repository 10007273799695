import { useCallback } from 'react';
import { BookingWidgetAnswer, IWidgetDeposit, IWidgetUser } from '../../views/scheduling-widget/widget-wizard/types';
import { axiosServices } from '../../utils/axios';
import { setDeposit, setNoShowDeposit } from '../../store/slices/widgetSlice';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { useAppDispatch } from '../redux';
import useShowSnackbar from '../useShowSnackbar';

interface IValidateUserInput {
    location_id?: number;
    employee_id?: number;
    service_ids: number[];
    company_slug: string;
    coupon_code?: string;
    images: string[];
    widget_answers?: BookingWidgetAnswer[] | null;
}

const useValidateUserData = () => {
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();

    const handleValidateUserData = useCallback(
        (formData: IWidgetUser, input: IValidateUserInput) => {
            const { company_slug, ...rest } = input;
            return new Promise<{ deposit: IWidgetDeposit; no_show_deposit: IWidgetDeposit }>((resolve, reject) => {
                axiosServices
                    .post(`/public/company/${company_slug}/appointment/validate-customer-info`, {
                        user: formData,
                        ...rest
                    })
                    .then((response: { data: { deposit: IWidgetDeposit; no_show_deposit: IWidgetDeposit } }) => {
                        dispatch(setDeposit(response.data.deposit));
                        dispatch(setNoShowDeposit(response.data.no_show_deposit));
                        resolve(response.data);
                    })
                    .catch((err) => {
                        reject(err);
                        showSnackbar({ message: err.message, alertSeverity: SnackBarTypes.Error });
                    });
            });
        },
        [dispatch, showSnackbar]
    );

    return { handleValidateUserData };
};

export default useValidateUserData;
