import CommonDrawerRoute from '../../../ui-component/CommonDrawerRoute';
import ProfileCardLink from './ProfileCardLink';
import { useNavigate } from 'react-router-dom';

const ProfileCardRoute = () => {
    const navigate = useNavigate();

    return (
        <CommonDrawerRoute onClose={() => navigate(-1)} title="Profile Card Link">
            <ProfileCardLink />
        </CommonDrawerRoute>
    );
};

export default ProfileCardRoute;
