import { useCallback, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { AppBar, CssBaseline, Toolbar, useMediaQuery, Alert, Link, Box, useTheme, Theme } from '@mui/material';

// third-party
import { hotjar } from 'react-hotjar';

// project imports
import Breadcrumbs from 'ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import navigation from 'menu-items';
import { SET_MENU } from 'store/actions';
import { DefaultRootStateProps } from 'types';

// assets
import { IconChevronRight } from '@tabler/icons';
import useAuth from '../../hooks/useAuth';

// casl
import { AbilityContext } from '../../utils/roles/Can';
import { Ability, AbilityBuilder } from '@casl/ability';
import { baseUserActions } from '../../utils/roles/ability';
import { IUser, UserPermission } from '../../models/IUser';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch } from '../../hooks/redux';
import config from '../../config';
import EntityDialogs, { SIDEBAR_WIDTH } from '../../ui-component/entity-dialogs/EntityDialogs';
import useNavigationMode from '../use-navigation-mode';
import { setHeaderHeight } from '../../store/slices/layoutSlice';
import CBBottomNav from '../CBBottomNav';
import CBBreadcrumbs from '../CBBreadcrumbs';
import useAppHeaderHeight from '../../hooks/use-header-height';
import useEntityDialogs from '../use-entity-dialogs';

const updateAbility = (ability: Ability, user: IUser) => {
    const {
        employee: {
            role: { permissions }
        }
    } = user;
    const { can, rules } = new AbilityBuilder(Ability);

    permissions.forEach(({ action, subject }: UserPermission) => {
        if (action === '*') {
            baseUserActions.forEach((baseAction) => {
                can(baseAction, subject);
            });
        } else {
            can(action, subject);
        }
    });

    ability.update(rules);
};

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const { showAsNormalDrawer, open } = useEntityDialogs();
    const height = useAppHeaderHeight();
    const navMode = useNavigationMode();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useAuth();
    const matchSm = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('sm'));

    useEffect(() => {
        if (location.pathname === '/') {
            navigate(config.defaultPath, { replace: true });
        }
    }, [location, navigate]);

    // Handle left drawer
    const leftDrawerOpened = useSelector((state: DefaultRootStateProps) => state.customization.opened);
    const handleLeftDrawerToggle = useCallback(() => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    }, [dispatch, leftDrawerOpened]);

    const ability = useContext(AbilityContext);

    const toolbarRef = useRef<HTMLDivElement | null>(null);
    const element = toolbarRef?.current;

    useEffect(() => {
        const observer = new ResizeObserver((data) => {
            const elemHeight = data[0]?.contentRect.height ?? 0;
            dispatch(setHeaderHeight(elemHeight));
        });

        if (element) {
            observer.observe(element);
        }

        return () => {
            // Cleanup the observer by unobserving all elements
            dispatch(setHeaderHeight(0));
            observer.disconnect();
        };
    }, [dispatch, element]);

    const roleName = user?.employee.role.name;

    useEffect(() => {
        if (user) {
            updateAbility(ability, user);
        }
    }, [user, roleName, ability]);

    useEffect(() => {
        if (process.env.NODE_ENV !== 'production') return;
        hotjar.initialize(3409396, 6);
    }, [user]);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* header */}
            <AppBar
                id="app-header"
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                ref={toolbarRef}
                sx={{
                    backgroundColor: 'background.default',
                    right: open && !showAsNormalDrawer ? SIDEBAR_WIDTH : undefined,
                    width: open && !showAsNormalDrawer ? `calc(100% - ${SIDEBAR_WIDTH})` : '100%'
                }}
            >
                <Toolbar
                    sx={{
                        px: matchSm ? '16px' : '20px !important',
                        py: matchSm ? '10px !important' : undefined,
                        minHeight: matchSm ? '60px !important' : '80px !important'
                    }}
                >
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} matchSm={matchSm} />
                </Toolbar>
                <CBBreadcrumbs />
            </AppBar>

            {/* drawer */}
            {navMode === 'sidebar' ? <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} /> : null}

            {/* main content */}
            <Box
                component="main"
                sx={{
                    backgroundColor: 'grey.200',
                    marginTop: `${height} !important`,
                    minHeight: `calc(100vh - ${height}) !important`,
                    width: open && !showAsNormalDrawer ? 'calc(100% - 400px)' : '100%',

                    borderRadius: 0,
                    marginLeft: 0,
                    marginRight: 0,
                    padding: '20px',

                    '& > div': {
                        border: 'none'
                    },
                    paddingBottom: 0,
                    [theme.breakpoints.down('mobile')]: {
                        padding: '0 0 56px 0'
                    },

                    [theme.breakpoints.down('sm')]: {
                        marginTop: '60px'
                    }
                }}
            >
                {user?.is_impersonated && (
                    <Alert severity="error" sx={{ marginBottom: theme.spacing(1), borderRadius: '8px' }}>
                        You&apos;re in the impersonate mode, <Link href="/select-organization">select another user.</Link>
                    </Alert>
                )}
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
                <CBBottomNav />
            </Box>
            <EntityDialogs />
        </Box>
    );
};

export default MainLayout;
