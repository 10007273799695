import { Button, ButtonProps, IconButton, Theme, useMediaQuery } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FC } from 'react';

type DetailsBtnProps = Pick<ButtonProps, 'onClick'>;

const DetailsBtn: FC<DetailsBtnProps> = ({ onClick }) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('mobile'));
    return isMobile ? (
        <IconButton size="small" color="primary" onClick={onClick}>
            <ChevronRightIcon />
        </IconButton>
    ) : (
        <Button size="small" variant="outlined" color="primary" onClick={onClick} endIcon={<ChevronRightIcon />}>
            See Details
        </Button>
    );
};

export default DetailsBtn;
