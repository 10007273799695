// material-ui
import { Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

// assets
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// project import
import useAuth from 'hooks/useAuth';
import CommonHeaderBtn from '../../../../ui-component/CommonHeaderBtn';

const WidgetLink = () => {
    const { user } = useAuth();

    return (
        <Tooltip title="to Widget" placement="top" arrow>
            <Box component="span">
                {/* @ts-ignore */}
                <CommonHeaderBtn component={Link} to={`/cal/${user?.currentCompany.slug}`} target="_blank">
                    <OpenInNewIcon />
                </CommonHeaderBtn>
            </Box>
        </Tooltip>
    );
};

export default WidgetLink;
