import { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../hooks/redux';
import customerAPI from '../../../../../../../../services/CustomerService';
import useShowSnackbar from '../../../../../../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../../../../../../store/snackbarReducer';
import { setSelectedEventCustomer } from '../../../../../../../../store/slices/calendarSlice';
import { startSubmitting, stopSubmitting } from '../../../../../../../../store/slices/SubmittingSlice';
import useAppointmentDetailsFormFunctions from '../../../../../../../../hooks/useAppointmentDetailsFormFunctions';
import appointmentAPI from '../../../../../../../../services/AppointmentService';
import useCanSeeCustomerDetails from '../../../../../../../../hooks/use-can-see-customer-details';
import getErrorMessage from '../../../../../../../../utils/get-error-message';
import useCustomerFormDefaults from '../../../../../../../../views/customer/hooks/use-customer-form-defaults';
import CustomerForm from '../../../../../../../CustomerForm';

const AppointmentCustomerForm = () => {
    const { showSnackbar } = useShowSnackbar();
    const dispatch = useAppDispatch();
    const { selectedEvent, isForeignAppointment } = useAppSelector((state) => state.calendar);
    const id = selectedEvent?.customer.id;
    const customer = useCustomerFormDefaults(selectedEvent?.customer);
    const [isSubmitting, setSubmitting] = useState<boolean>(false);
    const { canEditCustomerDetails, canEditCustomerOwner } = useCanSeeCustomerDetails();
    const [updateCustomer] = customerAPI.useUpdateCustomerMutation();

    const save = useCallback(
        (formData) => {
            if (!id || !customer || isSubmitting) return;

            setSubmitting(true);

            dispatch(startSubmitting());

            const customerData = {
                id,
                ...formData,
                employee_owner_id: canEditCustomerOwner ? formData.employee_owner_id : undefined
            };

            updateCustomer(customerData)
                .unwrap()
                .then((data) => {
                    dispatch(setSelectedEventCustomer(data));
                    dispatch(appointmentAPI.util.invalidateTags(['Appointment']));
                    showSnackbar({
                        message: 'Customer updated successfully.',
                        alertSeverity: SnackBarTypes.Success
                    });
                })
                .catch((err) => {
                    const message = getErrorMessage(err);
                    showSnackbar({
                        message,
                        alertSeverity: SnackBarTypes.Error
                    });
                })
                .finally(() => {
                    setSubmitting(false);
                    dispatch(stopSubmitting());
                });
        },
        [canEditCustomerOwner, customer, dispatch, id, isSubmitting, showSnackbar, updateCustomer]
    );

    useAppointmentDetailsFormFunctions(
        'appointment-details-customer-form',
        canEditCustomerDetails(customer.employee_owner_id) ? isSubmitting : true
    );

    return (
        <CustomerForm
            customer={customer}
            onSubmit={save}
            formId="appointment-details-customer-form"
            isReadOnly={isForeignAppointment}
            showContactDetailsInAccordion
        />
    );
};

export default AppointmentCustomerForm;
