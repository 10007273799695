import { FC, useCallback } from 'react';
import { Box, Divider, Stack, Switch, TextField, Theme, useMediaQuery } from '@mui/material';
import { FormikProps } from 'formik';
import { ICompanySettings } from '../../../models/ICompany';
import SettingsFormDelayRow from '../SettingsFormDelayRow';
import NumberFormat from 'react-number-format';
import InputRow from '../../../ui-component/form/InputRow';

const AppointmentsTab: FC<{ formInstance: FormikProps<ICompanySettings> }> = ({ formInstance }) => {
    const isMobile = useMediaQuery((themeParam: Theme) => themeParam.breakpoints.down('md'));
    const { values, setFieldValue, touched, errors, handleBlur } = formInstance;

    const handleDelayFieldChange = useCallback(
        (fieldName: string, fieldValue: unknown) => {
            setFieldValue(fieldName, fieldValue);
        },
        [setFieldValue]
    );

    const handleToggleWhitelist = useCallback(
        (checked: boolean) => {
            setFieldValue('appointments.waitlist.enabled', checked);
            if (!checked) {
                setFieldValue('appointments.waitlist.any_employee', false);
            }
        },
        [setFieldValue]
    );

    return (
        <Stack spacing={1.5}>
            <InputRow forceTooltips label="Automatically Close Appointments" tooltipKey="settings.appointments.autocomplete.enabled">
                <Switch
                    value={values.appointments.autocomplete.enabled}
                    checked={values.appointments.autocomplete.enabled}
                    onChange={(_, checked) => setFieldValue('appointments.autocomplete.enabled', checked)}
                />
            </InputRow>
            {values.appointments.autocomplete.enabled && (
                <>
                    <InputRow
                        size="sm"
                        forceTooltips
                        label="Appointment closure interval, hours"
                        tooltipKey="settings.appointments.autocomplete.interval"
                        forceColumn={isMobile}
                    >
                        <Box sx={{ width: isMobile ? '100%' : '280px' }}>
                            <SettingsFormDelayRow
                                fullWidth
                                fieldKey="appointments.autocomplete.interval"
                                value={values.appointments.autocomplete.interval}
                                onChange={handleDelayFieldChange}
                                onBlur={handleBlur}
                                error={
                                    touched.appointments?.autocomplete?.interval ? errors.appointments?.autocomplete?.interval : undefined
                                }
                            />
                        </Box>
                    </InputRow>
                </>
            )}

            <Divider />

            <InputRow forceTooltips label="Require No-Show Deposit" tooltipKey="settings.appointments.no_show_deposit.enabled">
                <Switch
                    value={Boolean(values.appointments.no_show_deposit.enabled)}
                    checked={Boolean(values.appointments.no_show_deposit.enabled)}
                    onChange={(_, checked) => setFieldValue('appointments.no_show_deposit.enabled', checked)}
                />
            </InputRow>

            {values.appointments.no_show_deposit.enabled && (
                <InputRow label="No show deposit" size="sm">
                    <Box sx={{ width: '280px' }}>
                        <NumberFormat
                            fullWidth
                            hiddenLabel
                            id="appointments.no_show_deposit.percent"
                            name="appointments.no_show_deposit.percent"
                            value={values.appointments.no_show_deposit.percent}
                            customInput={TextField}
                            suffix="%"
                            decimalScale={0}
                            onValueChange={(v) => setFieldValue('appointments.no_show_deposit.percent', v.floatValue)}
                            onBlur={handleBlur}
                        />
                    </Box>
                </InputRow>
            )}

            <Divider />

            <InputRow forceTooltips label="Contact Privacy" tooltipKey="settings.appointments.use_contact_privacy">
                <Switch
                    value={Boolean(values.appointments.use_contact_privacy)}
                    checked={Boolean(values.appointments.use_contact_privacy)}
                    onChange={(_, checked) => setFieldValue('appointments.use_contact_privacy', checked)}
                />
            </InputRow>

            <Divider />

            <InputRow forceTooltips label="Enable Waitlist" tooltipKey="settings.appointments.waitlist.enabled">
                <Switch
                    value={Boolean(values.appointments.waitlist.enabled)}
                    checked={Boolean(values.appointments.waitlist.enabled)}
                    onChange={(_, checked) => handleToggleWhitelist(checked)}
                />
            </InputRow>

            {values.appointments.waitlist.enabled ? (
                <InputRow label="Enable Alternate Provider Options" size="sm">
                    <Switch
                        value={Boolean(values.appointments.waitlist.any_employee)}
                        checked={Boolean(values.appointments.waitlist.any_employee)}
                        onChange={(_, checked) => setFieldValue('appointments.waitlist.any_employee', checked)}
                    />
                </InputRow>
            ) : null}
        </Stack>
    );
};

export default AppointmentsTab;
