import { styled } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const StyledRestartButton = styled(Button)(({ theme }) => ({
    color: theme.palette.widget.buttonDetails,
    width: '100%',

    '&:hover': {
        backgroundColor: 'transparent'
    },

    '& .MuiSvgIcon-root': {
        marginRight: theme.spacing(1)
    }
}));

export default StyledRestartButton;
