import * as React from 'react';
import NumberFormat from 'react-number-format';

interface PriceInputProps {
    onChange: (event: { target: { name: string; value: number } }) => void;
    name: string;
}

const PriceInput = React.forwardRef<NumberFormat<any>, PriceInputProps>((props, ref) => {
    const { onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            decimalScale={2}
            prefix="$"
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: parseFloat(values.value)
                    }
                });
            }}
        />
    );
});

export default PriceInput;
