import { useEffect } from 'react';
import { useAppDispatch } from './redux';
import { setShouldSubmitFormOnTabChange } from '../store/slices/calendarSlice';

const useShouldSubmitDetailsForm = (shouldSubmit: boolean, ignoreChanges = false) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!ignoreChanges) {
            dispatch(setShouldSubmitFormOnTabChange(shouldSubmit));
        }
    }, [dispatch, ignoreChanges, shouldSubmit]);
};

export default useShouldSubmitDetailsForm;
