import { FC } from 'react';
import logo from 'assets/images/logo_horizontal_title.png';
import squareLogo from 'assets/images/logo_square.svg';

// ==============================|| LOGO ||============================== //

const Logo: FC<{ square?: boolean }> = ({ square }) => (
    <img style={{ width: square ? '35px' : '150px' }} src={square ? squareLogo : logo} alt="Chilled Butter" />
);

export default Logo;
