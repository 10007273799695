import { FC, useCallback, useState } from 'react';
import { Box, Select, SelectChangeEvent } from '@mui/material';
import Colorful from '@uiw/react-color-colorful';
import { saturationPickerSx, alphaPickerSx, borderlessSelectSx } from '../styles';
import RgbaColorForm from './RgbaColorForm';
import { ColorFormProps } from '../types';
import HslaColorForm from './HslaColorForm';
import HexColorInput from './HexColorInput';
import { MenuItem } from '@material-ui/core';

const CustomColorsTab: FC<ColorFormProps> = (props) => {
    const [form, setForm] = useState<'rgba' | 'hsla' | 'hex'>('rgba');
    const handleSetForm = useCallback((e: SelectChangeEvent<'rgba' | 'hsla' | 'hex'>) => {
        setForm(e.target.value as 'rgba' | 'hsla' | 'hex');
    }, []);

    const { color, onChange, disableAlpha } = props;

    return (
        <Box
            sx={{
                '& .w-color-colorful': {
                    '& > .w-color-saturation': saturationPickerSx,
                    '& > .w-color-alpha': {
                        ...alphaPickerSx,

                        '& > .w-color-interactive > div': {
                            borderWidth: '1px !important',
                            width: '16px !important',
                            height: '16px !important',
                            transform: 'translate(-8px, -3px) !important'
                        }
                    }
                }
            }}
        >
            <Colorful style={{ width: 192 }} color={color} onChange={({ hsva }) => onChange(hsva)} disableAlpha={disableAlpha} />

            <Box pb={2}>
                <Select size="small" value={form} onChange={handleSetForm} sx={borderlessSelectSx}>
                    <MenuItem value="rgba">RGB</MenuItem>
                    <MenuItem value="hsla">HSL</MenuItem>
                    <MenuItem value="hex">HEX</MenuItem>
                </Select>
            </Box>
            {form === 'rgba' ? <RgbaColorForm {...props} /> : null}
            {form === 'hsla' ? <HslaColorForm {...props} /> : null}
            {form === 'hex' ? <HexColorInput {...props} /> : null}
        </Box>
    );
};

export default CustomColorsTab;
