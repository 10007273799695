import { FC } from 'react';
import { CircularProgress, TableCell, TableRow } from '@material-ui/core';

const TableLoader: FC<{ colSpan?: number }> = ({ colSpan = 1 }) => (
    <TableRow>
        <TableCell colSpan={colSpan} align="center">
            <CircularProgress />
        </TableCell>
    </TableRow>
);

export default TableLoader;
