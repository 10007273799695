import { FC } from 'react';
import { IconButton, IconButtonProps, useMediaQuery } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';

const CommonHeaderBtn: FC<IconButtonProps> = ({ sx, children, ...rest }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const baseSx = {
        ...sx,

        '& svg': !matchDownMd
            ? {
                  width: '1.5rem',
                  height: '1.5rem'
              }
            : {}
    };

    return (
        <IconButton sx={baseSx} {...rest} color="secondary" size={!matchDownMd ? 'large' : undefined}>
            {children}
        </IconButton>
    );
};

export default CommonHeaderBtn;
