import { useCallback, useEffect, useMemo, useState } from 'react';
import { ICustomer } from '../models/ICustomer';
import customerService from '../services/CustomerService';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppDispatch } from './redux';
import { stopSubmitting } from '../store/slices/SubmittingSlice';

const useDuplicateCustomerConfirm = () => {
    const dispatch = useAppDispatch();
    const [data, setData] = useState<{ email?: string | null; phone?: string | null } | null>(null);

    const search = useMemo(() => data?.phone || data?.email || null, [data]);

    const { data: customersData } = customerService.useFetchAllCustomersQuery(search ? { search } : skipToken);

    const existingCustomer = useMemo<ICustomer | null>(() => {
        if (data) {
            const customers = customersData?.data ?? [];

            return customers[0] ?? null;
        }

        return null;
    }, [data, customersData]);

    const handleCustomerError = useCallback((inputData: { email?: string | null; phone?: string | null }) => {
        setData(inputData);
    }, []);

    const clearError = useCallback(() => {
        setData(null);
    }, []);

    useEffect(() => {
        if (!!data && !!existingCustomer) {
            dispatch(stopSubmitting());
        }
    }, [dispatch, data, existingCustomer]);

    return { data, handleCustomerError, existingCustomer, clearError };
};

export default useDuplicateCustomerConfirm;
