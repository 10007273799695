import { FC } from 'react';
import CommonDrawer, { useCommonSettingsDrawerPx } from '../../../ui-component/CommonSettingsDrawer';
import { Box, Button } from '@mui/material';
import WebhookSettingsForm from './WebhookSettingsForm';
import StickyFormFooter from '../../../ui-component/form/sticky-form-footer';
import { IWebhookSettingsInput } from '../types';

type WebhookDialogProps = {
    open: boolean;
    onClose: () => void;
    dialogTitle: string;
    dialogOkButtonLabel?: string;
    dialogActionsDisabled?: boolean;
    defaults: IWebhookSettingsInput;
    onSubmit: (formData: IWebhookSettingsInput) => void;
    triggerData: Record<string, string>;
};

const WebhookDialog: FC<WebhookDialogProps> = ({
    open,
    onClose,
    dialogTitle,
    dialogOkButtonLabel,
    dialogActionsDisabled,
    defaults,
    onSubmit,
    triggerData
}) => {
    const containerXSpacing = useCommonSettingsDrawerPx();
    return (
        <CommonDrawer open={open} onClose={onClose} title={dialogTitle}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%'
                }}
            >
                <WebhookSettingsForm defaults={defaults} triggersData={triggerData} onSave={onSubmit} />
                <StickyFormFooter containerXSpacing={containerXSpacing}>
                    <Button className="forcedBg" color="primary" onClick={onClose} disabled={dialogActionsDisabled}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" type="submit" form="webhook-form" disabled={dialogActionsDisabled}>
                        {dialogOkButtonLabel ?? 'Save'}
                    </Button>
                </StickyFormFooter>
            </Box>
        </CommonDrawer>
    );
};

export default WebhookDialog;
