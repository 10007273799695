import { Box, Stack } from '@mui/material';

const Illustration = () => (
    <svg width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1694_100772)">
            <rect y="0.740234" width="100" height="100" />
            <circle cx="50" cy="50.7402" r="50" fill="#708B9E" fillOpacity="0.3" />
            <path
                d="M72.3441 56.9739V38.7659C72.3441 37.04 70.945 35.6409 69.2191 35.6409H30.7793C29.0534 35.6409 27.6543 37.04 27.6543 38.7659V79.6884C27.6543 81.4143 29.0534 82.8134 30.7793 82.8134H49.9992M59.634 41.4929V28.3018C59.634 22.9806 55.3204 18.667 49.9992 18.667C44.6781 18.667 40.3644 22.9806 40.3644 28.3018V41.4929"
                stroke="#0A3A5B"
                strokeWidth="2.5"
                strokeLinecap="round"
            />
            <path
                d="M64.1356 78.9962L71.0519 72.0799M71.0519 78.9963L64.1356 72.08M80.0938 75.5381C80.0938 82.4416 74.4973 88.0381 67.5938 88.0381C60.6902 88.0381 55.0938 82.4416 55.0938 75.5381C55.0938 68.6345 60.6902 63.0381 67.5938 63.0381C74.4973 63.0381 80.0938 68.6345 80.0938 75.5381Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                fill="#F86F60"
            />
        </g>
        <defs>
            <clipPath id="clip0_1694_100772">
                <rect width="100" height="100" fill="white" transform="translate(0 0.740234)" />
            </clipPath>
        </defs>
    </svg>
);

const NoProductsIllustration = () => (
    <Stack
        sx={{
            p: 2,
            minHeight: '260px',
            backgroundColor: 'grey.50',
            border: '1px solid',
            borderColor: 'grey.200',
            borderRadius: '8px'
        }}
        spacing={3}
        alignItems="center"
        justifyContent="center"
    >
        <Illustration />
        <Box sx={{ fontSize: '1.5rem', lineHeight: 1.15, fontWeight: 600, color: 'primary.900' }}>No products added</Box>
    </Stack>
);

export default NoProductsIllustration;
