import { CustomerCoupon } from '../../../models/ICoupon';
import { FC } from 'react';
import { Box } from '@material-ui/system';
import { Stack } from '@mui/material';
import NumberFormat from 'react-number-format';

type CustomerRedemptionCouponProps = {
    coupon: CustomerCoupon;
};
const CustomerRedemptionCoupon: FC<CustomerRedemptionCouponProps> = ({ coupon }) => {
    const { code, amount, is_used } = coupon;

    return (
        <Box
            sx={{
                p: 1,
                borderRadius: 1,
                color: is_used ? 'grey.400' : 'grey.800',
                backgroundColor: is_used ? 'grey.50' : undefined,
                border: '1px solid',
                borderColor: 'grey.200'
            }}
        >
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" sx={{ fontWeight: 600 }}>
                <Box>{code}</Box>
                <Box sx={{ color: is_used ? 'inherit' : 'success.dark' }}>
                    <NumberFormat decimalScale={2} fixedDecimalScale prefix="$" displayType="text" value={amount} />
                </Box>
            </Stack>
        </Box>
    );
};

export default CustomerRedemptionCoupon;
