import { useEffect, useMemo, useState } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { ChatDrawer, ChatConversation, ChatInput } from './chat';
import { useAppSelector } from '../hooks/redux';
import appointmentAPI from '../services/AppointmentService';
import { IConversationDetails } from '../models/IAppointment';
import useMessagesCache from '../hooks/use-messages-cache';
import StartConversationIcon from './icons/StartConversationIcon';

const CONVERSATION_SELECTION_ENABLED = false;

const AppointmentMessages = () => {
    const { selectedEvent } = useAppSelector((store) => store.calendar);
    const { updateMessagesCache } = useMessagesCache();
    const [selectedConversation, setSelectedConversation] = useState<number | null>(null);
    const { data: conversationsList, isLoading: listLoading } = appointmentAPI.useGetConversationsListQuery(
        selectedEvent ? selectedEvent.id : skipToken
    );

    const conversationId = useMemo(() => (conversationsList ? conversationsList[0]?.id : undefined), [conversationsList]);

    const {
        data: conversation,
        isLoading: conversationLoading,
        isFetching: conversationFetching
    } = appointmentAPI.useGetSingleConversationQuery(
        selectedEvent && conversationId
            ? {
                  appointmentId: selectedEvent.id,
                  conversationId
              }
            : skipToken
    );

    const customerPhone = selectedEvent?.customer.phone;
    const customerId = selectedEvent?.customer.id;
    const appointmentId = selectedEvent?.id;

    useEffect(() => {
        const handleUpdate = (payload?: { conversation?: IConversationDetails | null } | null) => {
            if (payload?.conversation && appointmentId) {
                updateMessagesCache(appointmentId, payload.conversation);
            }
        };
        window.Echo.private(`App.Appointment.Messaging.Customer.${customerId}`).listen(
            '.appointment.messaging.conversation.created',
            handleUpdate
        );

        return () => {
            window.Echo.private(`App.Appointment.Messaging.Customer.${customerId}`).stopListening(
                '.appointment.messaging.conversation.created'
            );
            window.Echo.leave(`App.Appointment.Messaging.Customer.${customerId}`);
        };
    }, [appointmentId, customerId, updateMessagesCache]);

    if (listLoading || !selectedEvent) {
        return (
            <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (selectedEvent && !listLoading && !customerPhone) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <Typography variant="h3" align="center" sx={{ my: 'auto', px: 3, color: 'primary.900' }}>
                    Customer has no phone number.
                </Typography>
            </Box>
        );
    }

    if (conversationsList?.length && !listLoading && selectedEvent) {
        return (
            <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                {CONVERSATION_SELECTION_ENABLED ? (
                    <>
                        <ChatDrawer
                            conversations={conversationsList}
                            selectedId={selectedConversation}
                            onSelectConversation={setSelectedConversation}
                        />
                        <Divider orientation="vertical" sx={{ height: '100%' }} />
                    </>
                ) : null}

                {conversationId ? (
                    <ChatConversation conversation={conversation} isLoading={conversationLoading || conversationFetching} />
                ) : (
                    <Box>Please select conversation</Box>
                )}
            </Box>
        );
    }

    if (conversationsList && !conversationsList.length && !listLoading && selectedEvent) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
                <Stack spacing={3} sx={{ my: 'auto', color: 'primary.900', alignItems: 'center' }}>
                    <StartConversationIcon />
                    <Typography variant="h3" align="center" sx={{ px: 3, color: 'primary.900' }}>
                        Start a conversation!
                    </Typography>
                </Stack>
                <ChatInput />
            </Box>
        );
    }

    return null;
};

export default AppointmentMessages;
