import useAvailableMenuItems from '../../menu-items/use-available-menu-items';
import { Navigate } from 'react-router-dom';
import useNavigationMode from '../../layout/use-navigation-mode';
import SettingsMenu from './components/SettingsMenu';

const SettingsDefaultScreen = () => {
    const { availableSettings } = useAvailableMenuItems();
    const navMode = useNavigationMode();

    if (availableSettings.length) {
        return navMode === 'sidebar' ? <SettingsMenu /> : <Navigate to={`/settings${availableSettings[0].url}`} />;
    }

    return <Navigate to="/calendar" />;
};

export default SettingsDefaultScreen;
