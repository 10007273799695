import { Box, ButtonBase, Typography } from '@mui/material';
import { FC } from 'react';

type OrganizationSizeButtonProps = {
    type: string;
    selected: boolean;
    onSelect: (type: string) => void;
};

const SIZE_TYPE_DATA: Record<string, { label: string; l1: string; l2?: string; url: string }> = {
    single_user: {
        label: 'Just Me',
        l1: '1',
        l2: 'Solo Edition',
        url: 'https://chilledbutter.com/wp-content/uploads/static/signup_soloed.jpg'
    },
    small: {
        label: 'Small',
        l1: '2-10',
        l2: 'Growth Edition',
        url: 'https://chilledbutter.com/wp-content/uploads/static/signup_growthed.jpg'
    },
    large: {
        label: 'Large',
        l1: '11+',
        l2: 'Growth Edition',
        url: 'https://chilledbutter.com/wp-content/uploads/static/signup_largeed.jpg '
    }
};

const OrganizationSizeButton: FC<OrganizationSizeButtonProps> = ({ type, selected, onSelect }) => {
    const item = SIZE_TYPE_DATA[type];
    return item ? (
        <ButtonBase
            disableRipple
            onClick={() => onSelect(type)}
            sx={(theme) => ({
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                flexGrow: 1,
                p: 1,
                border: `1px solid`,
                borderColor: selected ? theme.palette.primary.main : theme.palette.grey[200],
                borderRadius: '8px',
                width: '100%'
            })}
        >
            <Box
                sx={{
                    '& img': {
                        width: '100%',
                        maxWidth: '100%',
                        aspectRatio: '1/1',
                        objectFit: 'contain',
                        objectPosition: 'center',
                        userSelect: 'none'
                    }
                }}
            >
                <img src={item.url} alt="" />
            </Box>
            <Typography variant="subtitle1">{item.label}</Typography>
            <Typography variant="body1">{item.l1}</Typography>
            {item.l2 ? <Typography variant="body1">{item.l2}</Typography> : null}
        </ButtonBase>
    ) : null;
};

export default OrganizationSizeButton;
