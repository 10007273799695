import { useCallback, useMemo } from 'react';
import { Grid, TextField } from '@mui/material';
import MomentAdapter from '@mui/lab/AdapterMoment';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { Moment } from 'moment-timezone';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AppTimePicker from '../../form/time-picker/AppTimePicker';
import moment from 'moment/moment';
import { FormFieldProps } from '../index';
import { isNaN, isNumber } from 'lodash';
import useAppointmentEditRestrictions from '../../../hooks/useAppointmentEditRestrictions';
import DurationInput from './DurationInput';

const safeNumber = (num: unknown, fallback = 0): number => (isNumber(num) && !isNaN(num) ? num : fallback);

type DateTimeProps = Omit<FormFieldProps, 'setFieldTouched' | 'handleBlur'>;

const DateTime = ({ values, setFieldValue, touched, errors, disabled }: DateTimeProps) => {
    const { canEditKeyFields } = useAppointmentEditRestrictions();
    const startMoment = useMemo(() => moment(values?.start_at), [values]);
    const endMoment = useMemo(() => moment(values?.end_at), [values]);

    const setEndDate = useCallback(
        (endTime: Moment | null) => {
            if (endTime) {
                const endDate = moment(values?.end_at).set('h', endTime.hours()).set('m', endTime.minutes());
                let newDuration = moment.duration(endDate.diff(values.start_at)).asMinutes();
                // add 24 hours to get the time difference excluding calendar date
                if (newDuration < 0) newDuration += 1440;
                setFieldValue('end_at', moment(endDate).toISOString());
                setFieldValue('duration', Math.floor(newDuration));
            }
        },
        [values, setFieldValue]
    );

    const setStartDate = useCallback(
        (startDate: Moment | null) => {
            setFieldValue('start_at', moment(startDate).toISOString());
            setFieldValue('end_at', moment(startDate).add(values?.duration, 'minutes').toISOString());
        },
        [values, setFieldValue]
    );

    const setStartTime = useCallback(
        (time: Moment | null) => {
            if (time) {
                setStartDate(moment(values?.start_at).set('h', time.hours()).set('m', time.minutes()));
            }
        },
        [setStartDate, values]
    );

    const handleChangeDuration = useCallback(
        (val) => {
            const time = parseInt(val ?? 0, 10);
            const minutes = safeNumber(time);
            setFieldValue('duration', minutes);
            setFieldValue('end_at', moment(values.start_at).add(minutes, 'minutes').toISOString());
        },
        [setFieldValue, values]
    );

    const isDisabled = useMemo(() => !canEditKeyFields(values?.status) || !!disabled, [canEditKeyFields, disabled, values.status]);

    return (
        <Grid container item xs={12} rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
                <LocalizationProvider dateAdapter={MomentAdapter}>
                    <MobileDatePicker<Moment>
                        disabled={isDisabled}
                        views={['day']}
                        mask="MMMM DD, YYYY"
                        inputFormat="MMM D, YYYY"
                        showTodayButton
                        disableHighlightToday
                        showToolbar={false}
                        value={values?.start_at || null}
                        onChange={(dateArg) => setStartDate(dateArg)}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                {...params}
                                label="Date"
                                InputProps={{
                                    endAdornment: <CalendarMonthOutlinedIcon />
                                }}
                                error={Boolean(touched?.start_at && errors.start_at)}
                                helperText={touched?.start_at ? errors?.start_at : null}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
                <AppTimePicker disabled={isDisabled} value={startMoment} onChange={(pickerTime) => setStartTime(pickerTime)} label="Time" />
            </Grid>
            <Grid item xs={6} position="relative">
                <AppTimePicker disabled={isDisabled} value={endMoment} onChange={(pickerTime) => setEndDate(pickerTime)} />
            </Grid>
            <Grid item xs={12}>
                <DurationInput
                    label="Duration"
                    value={values?.duration ?? 0}
                    onChange={(v) => handleChangeDuration(v)}
                    disabled={isDisabled}
                    error={Boolean(touched?.duration && errors.duration)}
                    helperText={touched?.duration ? errors?.duration : null}
                />
            </Grid>
        </Grid>
    );
};

export default DateTime;
