import { useCallback } from 'react';
import { AppointmentStatuses } from '../models/IAppointment';

const safeStatuses = [AppointmentStatuses.Pending, AppointmentStatuses.Active];

const useAppointmentEditRestrictions = () => {
    const canEditKeyFields = useCallback((status?: AppointmentStatuses) => !!status && safeStatuses.includes(status), []);

    return { canEditKeyFields };
};

export default useAppointmentEditRestrictions;
