function buildFormData(formData: FormData, data: any, parentKey = '') {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data === null ? '' : data;

        formData.append(parentKey, value);
    }
}

const convertRecordtoFormData = (data: Record<string, any>) => {
    const fd = new FormData();
    buildFormData(fd, data);

    return fd;
};

export default convertRecordtoFormData;
